import { ValidationErrors } from "../../../../validation/ValidationErrors";
import * as yup from "yup";
import { CustomCookies } from "./components/CookiesSettings";

export const ipRegEx =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const connectSiteFormValidation = yup.object().shape({
  name: yup
    .string()
    .required(ValidationErrors.TestSuiteName)
    .max(255, ValidationErrors.TestSuiteNameMaxLength),
  primaryDomain: yup
    .string()
    .url(ValidationErrors.HttpProtocol)
    .matches(/^http(s)?:\/\/.*/, ValidationErrors.HttpProtocol)
    .test("test-name", ValidationErrors.SitePrimary, (value) => {
      try {
        const { pathname } = new URL(value as string);
        return pathname.length <= 1;
      } catch {
        return false;
      }
    })
    .required(ValidationErrors.SitePrimary),
  testSiteUsername: yup.string().when("testSiteDomainPass", {
    is: (value: unknown) => !!value,
    then: (schema) => schema.required(ValidationErrors.SiteTestUser),
    otherwise: (schema) => schema.notRequired(),
  }),
  testSiteDomainPass: yup.string().notRequired(),
  location: yup.string().required(),
  userAgent: getUserAgentSchema(),
  customDns: yup.array().of(
    yup.object().shape({
      hostname: yup.string().required(ValidationErrors.Hostname),
      ipAddress: yup
        .string()
        .required(ValidationErrors.IpAddress)
        .matches(ipRegEx, ValidationErrors.IpAddressValidity),
    }),
  ),
  rendererCookies: yup.array().of(
    yup
      .object()
      .shape({
        name: yup
          .string()
          .required(ValidationErrors.Required)
          .matches(
            /^[a-zA-Z0-9!#$%&'*+\-.^_`|~]+$/,
            ValidationErrors.CookieKey,
          ),
        value: yup
          .string()
          .matches(
            /^[a-zA-Z0-9!#$%&'()*+\-./:<=>?@[\]^_`{|}~]+$/,
            ValidationErrors.CookieValue,
          ),
      })
      .test("unique", ValidationErrors.CookieKeyUnique, (value, context) => {
        if (!value?.name) return true;

        const isUnique =
          context.parent.filter(
            (item: CustomCookies) => item.name === value.name,
          ).length === 1;

        if (!isUnique)
          throw context.createError({
            path: `${context.path}.name`,
            message: ValidationErrors.CookieKeyUnique,
          });

        return true;
      }),
  ),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUserAgentSchema(): yup.AnySchema<any, any, any> {
  return yup.object().shape({
    string: yup.string().when("code", {
      is: (code: string) => code === "Custom",
      then: (schema) => schema.required("Required"),
    }),
    token: yup.string().when("code", {
      is: (code: string) => code === "Custom",
      then: (schema) => schema.required("Required"),
    }),
    viewportWidth: yup
      .number()
      .typeError("Invalid number")
      .min(1, "Value can not be less than 1 and greater than 5000")
      .max(5000, "Value can not be less than 1 and greater than 5000"),
    viewportHeight: yup
      .number()
      .typeError("Invalid number")
      .min(1, "Value can not be less than 1 and greater than 20000")
      .max(20000, "Value can not be less than 1 and greater than 20000"),
  });
}
