import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    selectedCount: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      fontWeight: 600,
      margin: theme.spacing(1, 0),
    },
    divider: {
      backgroundColor: theme.palette.grey[300],
    },
  }),
);
