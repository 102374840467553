import React from "react";
import {
  BuildTestStatus,
  ExtendedBuildStatus,
} from "../../../_common/interfaces/BuildTestStatus";
import { TestSuiteListItemContent } from "./TestSuiteListItemContent";
import { Routes } from "../../../_common/routes/routes";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Link } from "react-router-dom";
import { BuildStatus } from "../../../graphql";
import { ColouredLeftBorder } from "../../../_common/components/ColoredLeftBorder/ColouredLeftBorder";
import { useTheme } from "@material-ui/core";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";

export interface TestSuiteListItemProps {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  lastRun: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  name: string;
  url: string;
  editable: boolean;
  testSuiteUpdatedAt: string;
  testSuiteClonedAt: string | null;
  refetchData?: () => void;
  testSuiteJiraIntegrationId?: string | null;
  createdAt: string;
}

export function TestSuiteListItem(props: TestSuiteListItemProps): JSX.Element {
  const accountId = useAccountRouteMatch();
  const theme = useTheme();

  const borderColor = getMatchingBorderColorForStatus(props.status, theme);
  const isTestSuiteRunning = props.status === BuildStatus.Running;
  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;

  const listItem = (
    <ColouredLeftBorder
      color={borderColor}
      rootBoxProps={{
        style: {
          marginBottom: 12,
        },
      }}
    >
      <TestSuiteListItemContent {...props} />
    </ColouredLeftBorder>
  );

  if (isTestSuiteRunning) {
    return (
      <div
        data-testid="test-suite-list-item"
        data-pendo={`auto-testsuitelist-item-${props.status.toLowerCase()}`}
      >
        {listItem}
      </div>
    );
  }

  const url = isNotYetRun
    ? Routes.EditTestSuite.getUrl({
        accountId: accountId,
        testSuiteId: props.id,
      })
    : Routes.Builds.getUrl({ accountId, testSuiteId: props.id });

  return (
    <Link
      style={{ textDecoration: "none" }}
      data-testid="test-suite-list-item"
      data-pendo={`auto-testsuitelist-item-${props.status.toLowerCase()}`}
      to={url}
    >
      {listItem}
    </Link>
  );
}
