import {
  GetJiraIntegrationsQuery,
  GetJiraIntegrationsQueryVariables,
  useGetJiraIntegrationsQuery as generated_useGetJiraIntegrationsQuery,
} from "../../../../graphql";

export type JiraIntegration = NonNullable<
  GetJiraIntegrationsQuery["getAccount"]
>["jiraAuthentications"]["nodes"][0]["jiraIntegrations"]["nodes"][0] & {
  jiraAuthenticationId: string;
  jiraAuthenticationName: string;
};

export function useGetJiraIntegrationsQuery(
  variables: GetJiraIntegrationsQueryVariables,
) {
  const { data, error, loading, refetch } =
    generated_useGetJiraIntegrationsQuery({
      variables,
      fetchPolicy: "cache-first",
    });

  const jiraIntegrations: JiraIntegration[] = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(
      (jiraAuthentication) =>
        jiraAuthentication.jiraIntegrations.nodes.map((jiraIntegration) => ({
          jiraAuthenticationId: jiraAuthentication.id,
          jiraAuthenticationName: jiraAuthentication.name,
          ...jiraIntegration,
        })),
    ) ?? []),
  ];

  const totalCount = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(
      (jiraAuthenticationNode) =>
        jiraAuthenticationNode.jiraIntegrations.totalCount,
    ) ?? []),
  ].reduce((acc, curr) => acc + curr, 0);

  return {
    data: { jiraIntegrations, totalCount },
    error,
    loading,
    refetch,
  };
}
