import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { MenuItem as MuiMenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Typography } from "../typography/Typography";
import { CheckSolid, ChevronRightSolid } from "../../icons";
import { focusableClass } from "./SubMenu";
import { AccountOptionsContext } from "./AccountOptions";
export function MenuItem({ menu, menuGroup, menuItem, idx, groupIdx, style, classes, setFocusedEl, onKeyDown, }) {
    const { setActiveSubMenu, closeMenu, recentlyClosedSubMenu } = useContext(AccountOptionsContext);
    const menuKey = `${menu.key}.${menuItem.name}`;
    const isList = menuGroup.search !== undefined;
    function handleClick() {
        var _a;
        const result = (_a = menuItem.onClick) === null || _a === void 0 ? void 0 : _a.call(menuItem);
        if (result === false)
            return;
        if (menuItem.subMenu) {
            setActiveSubMenu(menuKey);
        }
        else {
            closeMenu();
        }
    }
    function handleInitialFocus(e) {
        const isRecentlyCloes = recentlyClosedSubMenu === menuKey;
        const activeListItem = isList && menuItem.checked === true;
        const firstItem = groupIdx === 0 && idx === 0;
        const shouldFocus = isRecentlyCloes || activeListItem || firstItem;
        if (!shouldFocus || e === null)
            return;
        setFocusedEl(e);
    }
    const icon = getIcon(menuItem, classes);
    return (_jsxs(MuiMenuItem, Object.assign({}, getLinkProps(menuItem), { onClick: handleClick, ref: handleInitialFocus, onKeyDown: onKeyDown, className: clsx(classes === null || classes === void 0 ? void 0 : classes.menuItem, menuItem.checked ? classes === null || classes === void 0 ? void 0 : classes.checkedMenuItem : "", focusableClass), style: Object.assign(Object.assign({}, style), (isList ? { left: 10, right: 10, width: "auto" } : {})) }, menuItem.dataAttributes, (menuItem.checked ? { "data-active": "" } : {}), (menuItem.value ? { "data-value": menuItem.value } : {}), { children: [Boolean(icon) && _jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.menuIcon, children: icon }), _jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.menuContent, children: menuItem.content ? (menuItem.content) : (_jsxs(_Fragment, { children: [_jsx(Typography, { className: classes === null || classes === void 0 ? void 0 : classes.menuName, children: menuItem.name }), menuItem.value !== undefined && (_jsx(Typography, { className: classes === null || classes === void 0 ? void 0 : classes.menuValue, children: menuItem.value }))] })) }), Boolean(menuItem.subMenu) && (_jsx(ChevronRightSolid, { className: classes === null || classes === void 0 ? void 0 : classes.subMenuIcon }))] })));
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLinkProps = (menuItem) => {
    if (!menuItem.link)
        return {};
    // Note: we have to do this because react-router-dom's Link component automatically prefixes the specified string with the hostname
    // Therefore, if we want to redirect the user to a different domain, we need to use a native anchor element
    return menuItem.isLinkExternal
        ? { component: "a", href: menuItem.link }
        : { component: Link, to: menuItem.link };
};
function getIcon(menuItem, classes) {
    if (menuItem.checked)
        return _jsx(CheckSolid, { className: classes === null || classes === void 0 ? void 0 : classes.checkedMenuIcon });
    return menuItem.icon;
}
