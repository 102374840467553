import React from "react";
import { Severity } from "../../../../graphql";
import { getTestStatus, getStatusIconType } from "./TestResultIconUtils";
import {
  StatusIcon,
  StatusIconSize,
} from "../../../../_common/components/StatusIcon/StatusIcon";

export interface TestSuiteReportTestResultIconProps {
  severity: Severity;
  isPassed: boolean;
}

export function TestResultIcon(
  props: TestSuiteReportTestResultIconProps,
): JSX.Element {
  const testStatus = getTestStatus(props.isPassed, props.severity);
  const statusIconType = getStatusIconType(testStatus);

  function getIcon(): JSX.Element {
    return (
      <StatusIcon
        type={statusIconType}
        size={StatusIconSize.MD}
        inline={true}
      />
    );
  }

  return getIcon();
}
