import {
  makeStyles,
  IconButton,
  IconButtonProps,
  Typography,
  ButtonBase,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { doesUserMatchRole } from "../../utils/doesUserMatchRole/doesUserMatchRole";
import { PermissionsTooltip } from "../PermissionsTooltip/PermissionsTooltip";
import { RoleCode } from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: "8px",
    width: "36px",
    height: "36px",
    margin: 0,
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
    color: theme.palette.grey[700],
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[100],
      color: theme.palette.primary.main,
    },
  },
  errorIcon: {
    "&:hover": {
      backgroundColor: theme.palette.red[200],
      color: theme.palette.red[600],
    },
  },
  active: {
    backgroundColor: theme.palette.ultraviolet[200],
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[200],
    },
  },

  settingsText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

interface ActionIconButtonProps extends Omit<IconButtonProps, "variant"> {
  variant: "info" | "error";
  backgroundColor?: "white";
  icon: JSX.Element;
  tooltipTitle?: string;
  isActive?: boolean;
  disableForViewer?: boolean;
  textLabel?: boolean;
}

export function ActionIconButton(props: ActionIconButtonProps): JSX.Element {
  const {
    icon,
    tooltipTitle,
    isActive,
    disableForViewer,
    disabled,
    ...buttonProps
  } = props;

  const classes = useStyles(props);
  const isViewerRole = doesUserMatchRole(RoleCode.Viewer);
  const isDisabled = isViewerRole && disableForViewer ? true : disabled;

  const button = props.textLabel ? (
    <ButtonBase
      disabled={isDisabled}
      className={clsx(classes.icon, isActive && classes.active)}
      {...buttonProps}
    >
      {icon}
      <Typography className={classes.settingsText}>
        TEST SUITE SETTINGS
      </Typography>
    </ButtonBase>
  ) : (
    <IconButton
      disabled={isDisabled}
      className={clsx(
        classes.icon,
        isActive && classes.active,
        props.variant === "error" && classes.errorIcon,
      )}
      {...buttonProps}
    >
      {icon}
    </IconButton>
  );

  if (isViewerRole && disableForViewer) {
    return <PermissionsTooltip>{button}</PermissionsTooltip>;
  }

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} data-testid="action-icon-button-tooltip">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}
