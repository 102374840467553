import {
  GetBuildsStatusesQuery,
  BuildStatus,
  BuildTriggerType,
} from "../../../graphql";

export function isPriorityBuildRunning(
  builds: NonNullable<GetBuildsStatusesQuery["node"]>["builds"]["nodes"][0][],
): boolean {
  const priorityBuilds =
    builds &&
    builds.filter(
      (build) =>
        (build.status === BuildStatus.Running ||
          build.status === BuildStatus.Queued) &&
        build.triggerType !== BuildTriggerType.Schedule,
    );
  return priorityBuilds && priorityBuilds.length > 0;
}
