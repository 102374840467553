import { ApolloError, ApolloQueryResult, useQuery } from "@lumar/shared";
import { useEffect, useState } from "react";
import {
  GetBuildsStatusesDocument,
  GetBuildsStatusesQuery,
  GetBuildsStatusesQueryVariables,
} from "../../../graphql";

export function useGetBuildsStatusesQuery(testSuiteId: string): {
  data: GetBuildsStatusesQuery | undefined;
  refetch: () => Promise<ApolloQueryResult<GetBuildsStatusesQuery>>;
  error: ApolloError | undefined;
  loading: boolean;
} {
  const [internalData, setInternalData] = useState<
    GetBuildsStatusesQuery | undefined
  >();
  const [internalError, setInternalError] = useState<ApolloError | undefined>();

  const {
    data: buildsStatuses,
    refetch,
    error: errorGettingData,
    loading,
  } = useQuery<GetBuildsStatusesQuery, GetBuildsStatusesQueryVariables>(
    GetBuildsStatusesDocument,
    {
      variables: { testSuiteId },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    setInternalData(buildsStatuses);
    setInternalError(undefined);
  }, [buildsStatuses]);

  useEffect(() => {
    setInternalError(errorGettingData);
    setInternalData(undefined);
  }, [errorGettingData]);

  const refetchData = (): Promise<
    ApolloQueryResult<GetBuildsStatusesQuery>
  > => {
    return refetch().then(
      (data) => {
        setInternalData(data.data);
        setInternalError(undefined);
        return data;
      },
      (e) => {
        setInternalData(undefined);
        return e;
      },
    );
  };

  return {
    data: internalData,
    refetch: refetchData,
    error: internalError,
    loading,
  };
}
