import { PermissionCode, RoleCode } from "../graphql";
import { getAddonAvailabilityFactory } from "./factories/getAddonAvailabilityFactory";
import { hasAddonFactory } from "./factories/hasAddonFactory";
import { hasAppFactory } from "./factories/hasAppFactory";
import { hasFeatureFlagEnabledFactory } from "./factories/hasFeatureFlagEnabledFactory";
import { hasPermissionFactory } from "./factories/hasPermissionFactory";
import { hasSufficientRoleFactory } from "./factories/hasSufficientRoleFactory";
const getPreferredAccountUser = (data, prefferedAccountId) => {
    var _a;
    return (_a = data.me.accountsUsers.edges.find((edge) => edge.node.account.id === prefferedAccountId)) === null || _a === void 0 ? void 0 : _a.node;
};
const getAccountUser = (data, preferredAccountId) => {
    var _a, _b;
    if (data.sharelink) {
        return createShareLinkAccountUser(data.sharelink);
    }
    else if (preferredAccountId) {
        return (getPreferredAccountUser(data, preferredAccountId) ||
            ((_a = data.me.accountsUsers.edges[0]) === null || _a === void 0 ? void 0 : _a.node));
    }
    return (_b = data.me.accountsUsers.edges[0]) === null || _b === void 0 ? void 0 : _b.node;
};
const createShareLinkAccountUser = (sharelink) => {
    return {
        role: { name: "Viewer", code: RoleCode.Viewer },
        account: createAccountObjectFromShareLink(sharelink.account),
    };
};
const createAccountObject = (account) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    return {
        id: account.id,
        packagePlan: account.packagePlan || "Unnamed Package",
        name: account.name || "Unnamed Account",
        customLogo: account.customLogo || undefined,
        featureFlags: account.featureFlags,
        active: account.active,
        maxCrawlRate: account.maxCrawlRate,
        limitLevelsMax: account.limitLevelsMax,
        limitPagesMax: account.limitPagesMax,
        accountManagers: account.accountManagers,
        availableCredits: account.availableCredits,
        aiFeaturesEnabled: account.aiFeaturesEnabled,
        subscription: {
            segmentationAvailable: Boolean((_a = account.subscription) === null || _a === void 0 ? void 0 : _a.segmentationAvailable),
            addons: ((_b = account.subscription) === null || _b === void 0 ? void 0 : _b.addons) || [],
            currentBillingPeriod: ((_c = account.subscription) === null || _c === void 0 ? void 0 : _c.currentBillingPeriod) || {
                start: new Date(0).toISOString(),
                end: new Date(0).toISOString(),
            },
            plan: {
                name: (_e = (_d = account.subscription) === null || _d === void 0 ? void 0 : _d.plan.name) !== null && _e !== void 0 ? _e : "",
                status: (_g = (_f = account.subscription) === null || _f === void 0 ? void 0 : _f.plan.status) !== null && _g !== void 0 ? _g : "",
                minCommitmentPeriod: (_j = (_h = account.subscription) === null || _h === void 0 ? void 0 : _h.plan.minCommitmentPeriod) !== null && _j !== void 0 ? _j : 0,
                period: (_l = (_k = account.subscription) === null || _k === void 0 ? void 0 : _k.plan.period) !== null && _l !== void 0 ? _l : 0,
            },
            status: (_o = (_m = account.subscription) === null || _m === void 0 ? void 0 : _m.status) !== null && _o !== void 0 ? _o : "",
        },
        primaryAccountPackage: {
            credits: (_q = (_p = account.primaryAccountPackage) === null || _p === void 0 ? void 0 : _p.credits) !== null && _q !== void 0 ? _q : 0,
            creditsSiteSpeed: (_s = (_r = account.primaryAccountPackage) === null || _r === void 0 ? void 0 : _r.creditsSiteSpeed) !== null && _s !== void 0 ? _s : 0,
            creditsAccessibility: (_u = (_t = account.primaryAccountPackage) === null || _t === void 0 ? void 0 : _t.creditsAccessibility) !== null && _u !== void 0 ? _u : 0,
        },
        availableApps: {
            automateAvailable: Boolean((_v = account.availableApps) === null || _v === void 0 ? void 0 : _v.automateAvailable),
            impactAvailable: Boolean((_w = account.availableApps) === null || _w === void 0 ? void 0 : _w.impactAvailable),
            monitorAvailable: Boolean((_x = account.availableApps) === null || _x === void 0 ? void 0 : _x.monitorAvailable),
            analyzeAvailable: Boolean((_y = account.availableApps) === null || _y === void 0 ? void 0 : _y.analyzeAvailable),
        },
        disableUpselling: account.disableUpselling,
        contractTerminationDate: account.contractTerminationDate,
        contractStartDate: account.contractStartDate,
        maxCustomReportsPerProject: account.maxCustomReportsPerProject,
    };
};
const createAccountObjectFromShareLink = (account) => {
    var _a, _b;
    return {
        id: account.id,
        packagePlan: "Unnamed Package",
        name: account.name || "Unnamed Account",
        customLogo: undefined,
        featureFlags: account.featureFlags,
        active: false,
        maxCrawlRate: 0,
        limitLevelsMax: 0,
        limitPagesMax: 0,
        accountManagers: [],
        availableCredits: 0,
        aiFeaturesEnabled: false,
        subscription: {
            segmentationAvailable: Boolean((_a = account.subscription) === null || _a === void 0 ? void 0 : _a.segmentationAvailable),
            addons: ((_b = account.subscription) === null || _b === void 0 ? void 0 : _b.addons) || [],
            currentBillingPeriod: {
                start: new Date(0).toISOString(),
                end: new Date(0).toISOString(),
            },
            plan: {
                name: "",
                status: "",
                minCommitmentPeriod: 0,
                period: 0,
            },
            status: "",
        },
        primaryAccountPackage: {
            credits: 0,
            creditsSiteSpeed: 0,
            creditsAccessibility: 0,
        },
        availableApps: {
            automateAvailable: false,
            impactAvailable: false,
            monitorAvailable: false,
            analyzeAvailable: false,
        },
        disableUpselling: false,
        contractTerminationDate: null,
        contractStartDate: null,
        maxCustomReportsPerProject: 0,
    };
};
const createAccountUserObject = (accountUser) => {
    return {
        role: accountUser.role,
        account: createAccountObject(accountUser.account),
    };
};
const getAllRelationships = (session, additionalAccounts) => {
    return [
        ...session.me.accountsUsers.edges.map((edge) => createAccountUserObject(edge.node)),
        ...(session.sharelink
            ? [createShareLinkAccountUser(session.sharelink)]
            : []),
        ...additionalAccounts.map((acc) => createAccountUserObject(acc)),
    ];
};
const setAdminModeEnabledEmpty = () => {
    return Promise.resolve();
};
export const createSession = ({ data, preferredAccountId, adminModeEnabled, setAdminModeEnabled, timeZone, setTimeZone, invalidateSession, }) => {
    var _a, _b;
    const session = data.sessionQuery;
    const accountUser = getAccountUser(session, preferredAccountId);
    if (!accountUser)
        throw Error("Expected user to belong to an account.");
    const account = accountUser.account;
    const addons = ((_a = account.subscription) === null || _a === void 0 ? void 0 : _a.addons) || [];
    const isDeepCrawlAdmin = session.me.permissions.some((permission) => [PermissionCode.Admin, PermissionCode.AdminGrant].includes(permission.code));
    return {
        id: session.me.id,
        email: session.me.email || undefined,
        firstName: session.me.firstName,
        lastName: session.me.lastName,
        jobTitle: session.me.jobTitle,
        role: accountUser.role,
        permissions: session.me.permissions.map((permission) => ({
            code: permission.code,
        })),
        isDeepCrawlAdmin,
        isDeepCrawlAdminEnabled: isDeepCrawlAdmin ? adminModeEnabled : undefined,
        timeZone,
        isUsingSharedLink: Boolean(session.sharelink),
        account: createAccountObject(account),
        allRelationships: getAllRelationships(session, data.additionalRelationships),
        hasSufficientRole: hasSufficientRoleFactory(session.me.permissions, accountUser.role.code),
        hasAddon: hasAddonFactory(addons),
        getAddonAvailability: getAddonAvailabilityFactory(addons),
        hasFeatureFlagEnabled: hasFeatureFlagEnabledFactory(account.featureFlags),
        hasPermission: hasPermissionFactory(session.me.permissions),
        hasApp: hasAppFactory(account.availableApps),
        invalidateSession,
        setDeepCrawlAdminEnabled: isDeepCrawlAdmin
            ? setAdminModeEnabled
            : setAdminModeEnabledEmpty,
        setTimeZone,
        ssoClientId: (_b = session.me.ssoClientId) !== null && _b !== void 0 ? _b : undefined,
    };
};
