import React, { useState } from "react";
import { BuildInfo } from "../../Dashboard";
import {
  Paper,
  makeStyles,
  Box,
  Divider,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { BuildListItem } from "./BuildListItem";
import { sortBuildsByStatusAndDate } from "../../utils/sortBuildsByStatusAndDate";
import { filterByBuildInfoStatus } from "../../utils/filterByBuildInfoStatus";
import { Routes } from "../../../../_common/routes/routes";
import { ArrowNarrowRightSolid } from "@lumar/shared";
import {
  DashboardWidgetHeader,
  WIDGET_HEADER_HEIGHT,
} from "../DashboardWidget";

interface LatestTestRunsWidgetProps {
  buildsInfo: BuildInfo[];
  accountId: string;
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    padding: theme.spacing(1.5, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  buildsMessageWrapper: { minHeight: "210px" },
  link: {
    textDecoration: "none",
    color: theme.palette.grey[700],
    fontWeight: 500,
  },
  subheading: {
    color: theme.palette.navy[300],
  },
  noRunsHeader: {
    fontWeight: 600,
  },
  ul: {
    paddingInlineStart: "20px",
  },
  li: {
    lineHeight: 1.5,
  },
  tab: {
    height: "42px",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
  },
  contentWrapper: {
    height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px - 1px)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export enum BuildStatusFilter {
  All = "All",
  Passed = "Pass",
  Failed = "Fail",
  Warning = "Warn",
}

const buildStatusArray = [
  BuildStatusFilter.All,
  BuildStatusFilter.Failed,
  BuildStatusFilter.Warning,
  BuildStatusFilter.Passed,
];

export function TestSuiteRunsWidget(props: LatestTestRunsWidgetProps) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const buildsToShow = buildStatusArray[tabIndex];
  const filteredBuilds = props.buildsInfo.filter((build) =>
    filterByBuildInfoStatus(build, buildsToShow),
  );
  const sortedAndFilteredBuilds = sortBuildsByStatusAndDate(filteredBuilds);

  function handleTabChange(
    _: React.ChangeEvent<{ value?: unknown }>,
    newValue: number,
  ) {
    setTabIndex(newValue);
  }

  function getNoBuildsMessage() {
    const lowerCaseStatus = buildsToShow.toLowerCase();
    const statusMessage =
      buildsToShow !== BuildStatusFilter.Warning
        ? `${lowerCaseStatus}ed`
        : lowerCaseStatus;

    return `You have no ${statusMessage} builds.`;
  }

  return (
    <Paper
      data-testid="latest-test-run"
      data-cy="latest-test-run"
      data-pendo="auto-latest-test-suite-runs-widget"
      style={{ height: "100%" }}
    >
      <DashboardWidgetHeader>Test suite runs</DashboardWidgetHeader>
      <Divider />
      <Box className={classes.contentWrapper}>
        <div className={classes.buildsMessageWrapper}>
          {!props.buildsInfo.length && (
            <Box p={5} data-testid="no-builds-message">
              <b className={classes.noRunsHeader}>No test runs yet</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Have you followed the steps to integrate Lumar Protect into
                  your CI/CD pipeline?
                </li>
                <li className={classes.li}>
                  Have there been any deploys on your test suite&apos;s domain
                  yet?
                </li>
              </ul>
            </Box>
          )}
          {props.buildsInfo.length > 0 && (
            <div>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
              >
                <Tab
                  label="All"
                  data-testid="builds-filter-button-All"
                  data-pendo="auto-latest-testsuite-runs-all-tab"
                  className={classes.tab}
                />
                <Tab
                  label="Fails"
                  data-testid="builds-filter-button-Fail"
                  data-pendo="auto-latest-testsuite-runs-fail-tab"
                  className={classes.tab}
                />
                <Tab
                  label="Warnings"
                  data-testid="builds-filter-button-Warning"
                  data-pendo="auto-latest-testsuite-runs-warning-tab"
                  className={classes.tab}
                />
                <Tab
                  label="Passes"
                  data-testid="builds-filter-button-Pass"
                  data-pendo="auto-latest-testsuite-runs-pass-tab"
                  className={classes.tab}
                />
              </Tabs>
              <Box>
                {!sortedAndFilteredBuilds.length && (
                  <Box display="flex" justifyContent="center" paddingY={4}>
                    <Typography>{getNoBuildsMessage()}</Typography>
                  </Box>
                )}
                {sortedAndFilteredBuilds.map((buildInfo, index) => {
                  return (
                    index < 5 && (
                      <BuildListItem
                        key={buildInfo.id}
                        buildInfo={buildInfo}
                        accountId={props.accountId}
                      />
                    )
                  );
                })}
              </Box>
            </div>
          )}
        </div>
        <div className={classes.linkContainer}>
          {sortedAndFilteredBuilds.length > 0 && (
            <Link
              to={Routes.TestSuites.getUrl({ accountId: props.accountId })}
              data-testid="view-all-testsuites-link"
              data-pendo="auto-view-all-latest-testsuite-runs"
              className={classes.link}
            >
              <Button
                size="small"
                variant="outlined"
                endIcon={
                  sortedAndFilteredBuilds.length > 1 ? (
                    <ArrowNarrowRightSolid />
                  ) : undefined
                }
                style={{ height: 32 }}
              >
                See all test suites
              </Button>
            </Link>
          )}
        </div>
      </Box>
    </Paper>
  );
}
