import React, { useState } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import {
  PopoverDialog,
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers,
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { Routes } from "../../../../../_common/routes/routes";
import { defaultPopOverId } from "../../../../../_common/constants/popover";
import { PencilSolid, ToggleIconButton, XOutlined } from "@lumar/shared";
import { DisableForViewer } from "../../../../../_common/components/DisableForViewer/DisableForViewer";
import { GetTestSuiteQuery } from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.purple[100],
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: "5px 5px 5px 12px",
    "&:hover": {
      backgroundColor: theme.palette.purple[200],
      "& p": {
        color: theme.palette.purple[800],
      },
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  testsuiteName: {
    color: theme.palette.purple[800],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
  },
  editButton: {
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      "& svg": {
        color: theme.palette.grey[700],
      },
    },
  },
}));

interface GlobalTemplateAppliedProps {
  parent: NonNullable<NonNullable<GetTestSuiteQuery["node"]>["parent"]>;
  unlinkGlobalTemplate: () => void;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary",
  },
  {
    label: "Yes, remove",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "alert",
  },
];

export function GlobalTemplateApplied(
  props: GlobalTemplateAppliedProps,
): JSX.Element {
  const classes = useStyles();
  const accountId = useAccountRouteMatch();

  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const editTestSuitePath = Routes.EditTestSuite.getUrl({
    accountId,
    testSuiteId: props.parent.id,
  });

  function handleUnlinkGlobalTemplate(
    id: PopoverDialogDefaultIdentifiers | string,
  ): void {
    setPopoverAnchorElement(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      props.unlinkGlobalTemplate();
    }
  }

  function handleClickUnlink(
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ): void {
    event.preventDefault();
    setPopoverAnchorElement(event.currentTarget);
  }

  const showDisconnectIntegration = Boolean(popoverAnchorElement);
  const popoverId = showDisconnectIntegration ? defaultPopOverId : undefined;

  return (
    <>
      <Box data-testid="global-template-applied" paddingTop={3}>
        <Typography className={classes.title}>
          Edit or remove global template
        </Typography>

        <Link to={editTestSuitePath} style={{ textDecoration: "none" }}>
          <div className={classes.wrapper} data-testid="global-template-item">
            <Typography
              noWrap
              data-testid="global-template-testsuite-name"
              className={classes.testsuiteName}
            >
              {props.parent.name}
            </Typography>
            <Box display="flex" alignItems="center">
              <DisableForViewer tooltipTitle="Edit global template">
                <ToggleIconButton
                  size="small"
                  data-testid="edit-global-template"
                  data-pendo="auto-test-suite-edit-template-settings-global-template-edit"
                  className={classes.editButton}
                >
                  <PencilSolid />
                </ToggleIconButton>
              </DisableForViewer>

              <DisableForViewer tooltipTitle="Remove from global template">
                <ToggleIconButton
                  size="small"
                  colorVariant="red"
                  data-testid="unlink-global-template"
                  data-pendo="auto-test-suite-edit-template-settings-global-template-unlink"
                  onClick={handleClickUnlink}
                  active={showDisconnectIntegration}
                  style={{ marginLeft: 8 }}
                >
                  <XOutlined />
                </ToggleIconButton>
              </DisableForViewer>
            </Box>
          </div>
        </Link>
      </Box>

      <PopoverDialog
        anchorElement={popoverAnchorElement}
        handleAction={handleUnlinkGlobalTemplate}
        open={showDisconnectIntegration}
        text="This test suite will be disconnected from the global template. Any future changes made to the global template will not be applied."
        title="Remove test suite from global template?"
        buttons={popoverButtons}
        id={popoverId}
      />
    </>
  );
}
