import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AuthIcon from "@material-ui/icons/AccountBoxRounded";

interface IJiraAuthenticationProps {
  id: string;
  jiraUrl: string;
  name: string;
  onClick: () => void;
}

export function JiraAuthenticationListItem({
  id,
  jiraUrl,
  name,
  onClick,
}: IJiraAuthenticationProps): JSX.Element {
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>
        <AuthIcon />
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={jiraUrl}
        data-testid={`jira-authentication-list-item-text-${id}`}
      />
    </ListItem>
  );
}
