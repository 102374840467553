import React from "react";
import {
  Backdrop,
  Box,
  makeStyles,
  Popover,
  PopoverProps,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 470,
  },
  iconWrapper: {
    "& svg": {
      fontSize: 56,
    },
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 16px",
    gap: 8,
    backgroundColor: theme.palette.grey[50],
  },
  title: {
    color: "#000",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    marginBottom: 10,
  },
  description: {
    color: theme.palette.grey[600],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export function ConfirmationDialogue(props: {
  icon: JSX.Element;
  title: string;
  description: string;
  children: React.ReactNode;
  PopoverProps: PopoverProps;
}) {
  const classes = useStyles();

  return (
    <Backdrop open={props.PopoverProps.open} className={classes.backdrop}>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...props.PopoverProps}
      >
        <Box className={classes.wrapper} data-testid="confirmation-dialogue">
          <Box paddingY={2} paddingX={3} display="flex">
            <Box
              paddingRight={2}
              paddingTop={1}
              className={classes.iconWrapper}
            >
              {props.icon}
            </Box>

            <Box>
              <Typography
                variant="h3"
                className={classes.title}
                data-testid="confirmation-dialogue-title"
              >
                {props.title}
              </Typography>
              <Typography
                data-testid="confirmation-dialogue-description"
                className={classes.description}
              >
                {props.description}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.buttonsWrapper}>{props.children}</Box>
        </Box>
      </Popover>
    </Backdrop>
  );
}
