import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./createApolloClient";
import { useTranslation } from "../i18n";
import { useAuth } from "../auth";
import { useRollbar } from "@rollbar/react";
export const ClientProvider = ({ children, name, version, enableDevTools, }) => {
    const { i18n: { language }, } = useTranslation();
    const { getToken, logout } = useAuth();
    const rollbarInstance = useRollbar();
    const apolloClient = useMemo(() => createApolloClient({
        name,
        getToken,
        version,
        enableDevTools,
        rollbarInstance,
        locale: language,
        logout,
    }), [
        name,
        getToken,
        logout,
        version,
        enableDevTools,
        rollbarInstance,
        language,
    ]);
    return _jsx(ApolloProvider, { client: apolloClient, children: children });
};
