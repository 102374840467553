import React from "react";
import {
  Box,
  Typography,
  AccordionSummary,
  Tooltip,
  withStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InfoOutlined } from "@lumar/shared";

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  header: ({ hasChild, hasParent }: TemplateSettingsHeaderProps) => {
    const color = hasChild || hasParent ? "#FFF" : palette.grey[700];
    return {
      color,
      borderRadius: 8,
      padding: "13px 16px",
      minHeight: "0px !important",
      alignItems: "start",
      "&.Mui-expanded": {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
      "& [class*=MuiAccordionSummary-expandIcon]": {
        color,
      },
    };
  },
  title: {
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(17),
    color: "inherit",
    marginRight: spacing(1),
    fontWeight: 500,
  },
  description: ({ hasChild, hasParent }: TemplateSettingsHeaderProps) => ({
    fontWeight: 300,
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(17),
    color: hasChild || hasParent ? "inherit" : palette.grey[500],
  }),
  icon: ({ hasChild, hasParent }: TemplateSettingsHeaderProps) => ({
    color: hasChild || hasParent ? "inherit" : palette.grey[400],
    fontSize: 18,
  }),
  iconWrapper: {
    height: 18,
  },
}));

function useHeaderInfo({ hasParent, hasChild }: TemplateSettingsHeaderProps) {
  const theme = useTheme();

  if (hasParent) {
    return {
      title: "Global template applied",
      description: "Global template is applied to this test suite",
      backgroundColor: theme.palette.grey[600],
    };
  }
  if (hasChild) {
    return {
      title: "Global template",
      description: "This test suite is a global template",
      backgroundColor: theme.palette.purple[600],
    };
  }
  return {
    title: "Template settings",
    description: "Create or apply global template",
    backgroundColor: "transparent",
  };
}

interface TemplateSettingsHeaderProps {
  hasParent: boolean;
  hasChild: boolean;
}

export function TemplateSettingsHeader(props: TemplateSettingsHeaderProps) {
  const { title, description, backgroundColor } = useHeaderInfo(props);

  const classes = useStyles(props);

  return (
    <StyledAccordionSummary
      className={classes.header}
      style={{ backgroundColor }}
      expandIcon={<ExpandMoreIcon />}
      data-pendo="auto-test-suite-edit-template-settings-block"
    >
      <Box>
        <Box display="flex" alignItems="center" marginBottom="4px">
          <Typography
            className={classes.title}
            data-testid="template-settings-title"
          >
            {title}
          </Typography>

          <Tooltip
            arrow
            data-pendo="auto-tooltip-template-setttings"
            data-testid="template-tooltip"
            title={
              <>
                <h1 style={{ fontWeight: 500, fontSize: 14 }}>
                  Use global templates to link test suites together and
                  replicate their configuration.
                </h1>
                <p>
                  Make changes in one global template and apply across all
                  template instances for steps 2 (Crawl settings) and 3 (Tests)
                  of your test suite.
                </p>
              </>
            }
          >
            <span className={classes.iconWrapper}>
              <InfoOutlined className={classes.icon} />
            </span>
          </Tooltip>
        </Box>

        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </StyledAccordionSummary>
  );
}

const StyledAccordionSummary = withStyles((theme) => ({
  expanded: {
    "& .MuiAccordionSummary-content": {
      margin: "12px 0px",
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.grey[700],
  },
}))(AccordionSummary);
