import React from "react";
import {
  MenuItem,
  ListItemIcon,
  Tooltip,
  makeStyles,
  Popover,
  List,
} from "@material-ui/core";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import {
  DotsHorizontalOutlined,
  ToggleIconButton,
  TrashOutlined,
} from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.red[600],
  },
  iconWrapper: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  popoverPaper: {
    marginTop: theme.spacing(1),
    padding: "0px 4px",
  },
}));

export function TestSuiteMenu(props: {
  onActionMenuEvent: () => Promise<boolean>;
}) {
  const classes = useStyles();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "testsuite-actions-menu",
  });

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Note: we have to stop the event propagating because the containing testsuite list item is clickable
    e.stopPropagation();
    popupState.toggle(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Note: we have to stop the event propagating because the containing testsuite list item is clickable
    e.stopPropagation();
    popupState.close();
  };

  const handleRemove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleClose(e);
    props.onActionMenuEvent();
  };

  return (
    <>
      <Tooltip title="Actions">
        <span>
          <ToggleIconButton
            size="medium"
            data-pendo="auto-scheduler-detail-testsuites-item-menu"
            data-testid="actions-menu-button"
            {...bindTrigger(popupState)}
            onClick={handleOpen}
            active={popupState.isOpen}
          >
            <DotsHorizontalOutlined />
          </ToggleIconButton>
        </span>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        onClose={handleClose}
        classes={{ paper: classes.popoverPaper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List data-testid="actions-menu-list">
          <MenuItem
            data-testid="remove-action"
            data-cy="remove-action"
            onClick={handleRemove}
          >
            <ListItemIcon className={classes.iconWrapper}>
              <TrashOutlined className={classes.icon} />
            </ListItemIcon>
            Remove test suite
          </MenuItem>
        </List>
      </Popover>
    </>
  );
}
