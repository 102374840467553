import {
  Alert,
  Chip,
  formToSelectProps,
  formToTextFieldProps,
  Select,
  TextField,
  Typography,
  useNumberFormatter,
} from "@lumar/shared";
import { MenuItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FastField, FieldProps } from "formik";
import { get } from "lodash";
import { SimpleModule, UserAgentSettings, UserAgentType } from "../utils/types";
import { getUserAgents } from "../utils/getUserAgents";
import { CheckboxWithLabel } from "../../../../../_common/components/forms/CheckboxWithLabel";
import { Label } from "../../../../../_common/components/Label/Label";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  alert: {
    margin: theme.spacing(2.25, 0),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  select: {
    maxWidth: 400,
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    maxWidth: 600,
    marginBottom: theme.spacing(1.75),
  },
  viewportField: {
    maxWidth: 250,
    marginBottom: theme.spacing(1.75),
  },
  readonlyValue: {
    marginBottom: theme.spacing(1.75),
    color: theme.palette.grey[700],
  },
  suffix: {
    marginLeft: theme.spacing(2),
  },
}));

export function UserAgent({
  field,
  form,
  userAgents,
  module,
  userAgentSuffix,
}: FieldProps<UserAgentSettings> & {
  userAgents: UserAgentType[];
  module: SimpleModule;
  userAgentSuffix: string;
}): JSX.Element {
  const classes = useStyles();
  const formatNumber = useNumberFormatter();

  const agentCode = field.value.code;

  const filteredUserAgents = getUserAgents({
    userAgents,
    module,
    include: [agentCode],
  }).filter(
    (agent) =>
      agent.code === agentCode || (!agent.isDeprecated && agent.isPublic),
  );

  const userAgent = filteredUserAgents.find((x) => x.code === agentCode);
  const theme = useTheme();
  const suffix = module.userAgentSuffixEnabled ? userAgentSuffix : "";

  const isCustomUserAgent = userAgent?.code === "Custom";
  const isCustomUserAgentMobile = !!get(
    form.values,
    "userAgent.userAgentIsMobile",
  );

  const viewportWidth = formatNumber(
    isCustomUserAgent
      ? isCustomUserAgentMobile
        ? module.viewportWidthMobile
        : module.viewportWidthDesktop
      : userAgent?.viewportWidth,
  );

  const viewportHeight = formatNumber(
    isCustomUserAgent
      ? isCustomUserAgentMobile
        ? module.viewportHeightMobile
        : module.viewportHeightDesktop
      : userAgent?.viewportHeight,
  );

  return (
    <div className={classes.root}>
      <StyledInputLabel style={{ marginBottom: theme.spacing(2) }}>
        User Agent
      </StyledInputLabel>

      <Typography>
        {
          "Select which user agent you want the crawl to use when requesting pages."
        }
      </Typography>
      <Alert severity="info" size="small" className={classes.alert}>
        {
          "For example, select Googlebot Smartphone to see how your web server responds to this user agent."
        }
      </Alert>

      <Select
        {...formToSelectProps(form, "userAgent.code")}
        className={classes.select}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        SelectDisplayProps={{ "data-testid": "select-agent" } as any}
      >
        {filteredUserAgents.map((agent) => (
          <MenuItem key={agent.code} value={agent.code}>
            <Typography
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {agent.name}
              {agent.isDeprecated && (
                <Chip
                  label="Deprecated"
                  color="yellow"
                  style={{ marginLeft: "auto" }}
                />
              )}
            </Typography>
          </MenuItem>
        ))}
      </Select>

      {isCustomUserAgent ? (
        <FastField
          name="userAgent.userAgentIsMobile"
          id="mui-component-checkbox-is-mobile"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: "Treat as mobile user agent" }}
          data-testid="user-agent-is-mobile"
          className={classes.checkbox}
        />
      ) : null}

      {!isCustomUserAgent ? (
        <>
          <Label
            label="User-Agent string"
            info="The User-Agent string is included in the User-Agent request header for all requests."
            className={classes.label}
          />
          <Typography
            variant="body2"
            className={classes.readonlyValue}
            data-testid="user-agent-string"
          >
            {userAgent ? `${userAgent.string} ${suffix}` : "-"}
          </Typography>
        </>
      ) : (
        <TextField
          {...formToTextFieldProps(form, "userAgent.string")}
          label={
            <Label
              label={"User-Agent string"}
              info={
                "The User-Agent string is included in the User-Agent request header for all requests."
              }
            />
          }
          placeholder={"Custom user agent string"}
          className={classes.textField}
          InputProps={{
            endAdornment: <span className={classes.suffix}>{suffix}</span>,
          }}
          data-testid="custom-user-agent-string"
        />
      )}

      {!isCustomUserAgent ? (
        <>
          <Label
            label={"User-Agent token"}
            info={
              "The User-Agent token is used to match robots.txt rules and meta/header robots directives."
            }
            className={classes.label}
          />
          <Typography
            variant="body2"
            className={classes.readonlyValue}
            data-testid="user-agent-token"
          >
            {userAgent?.token || "-"}
          </Typography>
        </>
      ) : (
        <TextField
          {...formToTextFieldProps(form, "userAgent.token")}
          label={
            <Label
              label={"User-Agent token"}
              info={
                "The User-Agent token is used to match robots.txt rules and meta/header robots directives."
              }
            />
          }
          placeholder={"Custom user agent token"}
          className={classes.textField}
          data-testid="custom-user-agent-token"
        />
      )}

      <TextField
        {...formToTextFieldProps(form, "userAgent.viewportWidth")}
        data-testid="mui-text-user-adent-width"
        type="number"
        label={"Viewport Width"}
        placeholder={viewportWidth}
        InputProps={{ endAdornment: "px" }}
        className={classes.viewportField}
      />

      <TextField
        {...formToTextFieldProps(form, "userAgent.viewportHeight")}
        data-testid="mui-text-user-adent-height"
        type="number"
        label={"Viewport Height"}
        placeholder={viewportHeight}
        InputProps={{ endAdornment: "px" }}
        className={classes.viewportField}
      />
    </div>
  );
}
