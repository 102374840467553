import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
export const useShareToken = () => {
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const shareLinkToken = searchParams.get("token");
    const [shareToken, setShareToken] = useState(shareLinkToken);
    const stopSharing = useCallback(() => {
        setShareToken(null);
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("token");
        history.push({ search: searchParams.toString() });
    }, [setShareToken, history]);
    useEffect(() => {
        if (shareLinkToken) {
            setShareToken(shareLinkToken);
        }
    }, [shareLinkToken]);
    return {
        shareToken,
        stopSharing,
    };
};
