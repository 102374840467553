import React from "react";
import { Maybe } from "../../../graphql";

interface PaginationInfo {
  currentPage: number;
  cursor: Maybe<string> | undefined;
  direction: "forward" | "back";
}

interface PageInfo {
  __typename?: string;
  startCursor: Maybe<string> | undefined;
  endCursor: Maybe<string> | undefined;
}

export function usePagination(): {
  pagination: PaginationInfo;
  resetToPageOne: () => void;
  handleNextPage: ({ endCursor }: PageInfo) => void;
  handlePreviousPage: ({ startCursor }: PageInfo) => void;
} {
  const [state, setState] = React.useState<PaginationInfo>({
    currentPage: 1,
    cursor: null,
    direction: "forward",
  });

  const resetToPageOne = React.useCallback(() => {
    setState({
      currentPage: 1,
      cursor: null,
      direction: "forward",
    });
  }, []);

  function handleNextPage({ endCursor }: PageInfo): void {
    setState((currentState) => ({
      direction: "forward",
      currentPage: currentState.currentPage + 1,
      cursor: endCursor,
    }));
  }

  function handlePreviousPage({ startCursor }: PageInfo): void {
    setState((currentState) => ({
      direction: "back",
      currentPage: currentState.currentPage - 1,
      cursor: startCursor,
    }));
  }

  return {
    pagination: state,
    resetToPageOne,
    handleNextPage,
    handlePreviousPage,
  };
}
