var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FormControl, InputLabel, Select as MuiSelect, } from "@material-ui/core";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";
const SelectInner = React.forwardRef(function SelectInner(_a, ref) {
    var { variant, startIcon, label, dividerLeft, dividerRight, alignMenu = "left", classes = {}, className, style, fullWidth } = _a, props = __rest(_a, ["variant", "startIcon", "label", "dividerLeft", "dividerRight", "alignMenu", "classes", "className", "style", "fullWidth"]);
    return (_jsxs(FormControl, { disabled: props.disabled, className: clsx({ [classes.fullWidth || ""]: fullWidth }, className), style: style, children: [label !== undefined && (_jsx(InputLabel, { className: classes.label, id: props.name && `mui-component-select-${props.name}`, children: label })), _jsx(MuiSelect, Object.assign({}, props, { ref: ref, variant: "outlined", className: clsx(classes.root, {
                    [classes.filled || ""]: variant === "filled",
                    [classes.disabled || ""]: props.disabled,
                    [classes.rootWithStartIcon || ""]: Boolean(startIcon),
                    [classes.dividerLeft || ""]: Boolean(startIcon && dividerLeft),
                    [classes.dividerRight || ""]: Boolean(dividerRight),
                }), classes: {
                    outlined: clsx(classes.select),
                    icon: clsx(classes.endIcon),
                    iconOpen: clsx(classes.endIconOpen),
                }, MenuProps: Object.assign({ getContentAnchorEl: null, anchorOrigin: { horizontal: alignMenu, vertical: "bottom" }, transformOrigin: { horizontal: alignMenu, vertical: "top" } }, props.MenuProps), startAdornment: startIcon !== undefined && (_jsx("div", { className: classes.startIcon, children: startIcon })) }))] }));
});
export const Select = withStyles((theme) => ({
    root: {
        "& fieldset": {
            borderColor: `${theme.palette.grey[300]} !important`,
            borderWidth: `1px !important`,
        },
        "&:hover fieldset": {
            borderColor: `${theme.palette.grey[300]} !important`,
        },
        "&$disabled:hover fieldset": {
            borderColor: `${theme.palette.grey[200]} !important`,
        },
        "&$filled fieldset": {
            borderColor: "transparent !important",
        },
        "&$filled$disabled fieldset": {
            borderColor: `${theme.palette.grey[400]} !important`,
        },
    },
    rootWithStartIcon: {
        paddingLeft: 0,
        "& $select": {
            paddingLeft: theme.spacing(4.25),
        },
    },
    disabled: {
        "& $endIcon": {
            borderColor: theme.palette.grey[200],
        },
        "& $startIcon": {
            borderColor: theme.palette.grey[200],
        },
        "&$filled": {
            "& $select": {
                background: theme.palette.grey[200],
                color: theme.palette.grey[400],
                "&:hover": {
                    background: theme.palette.grey[300],
                },
            },
            "& $endIcon": {
                color: theme.palette.grey[400],
                borderColor: theme.palette.grey[400],
            },
            "& $startIcon": {
                color: theme.palette.grey[400],
                borderColor: theme.palette.grey[400],
            },
        },
    },
    dividerLeft: {
        "& $select": {
            paddingLeft: `42px !important`,
        },
        "& $startIcon": {
            borderRightColor: theme.palette.grey[300],
            borderRightWidth: 1,
            borderRightStyle: "solid",
        },
    },
    dividerRight: {
        "& $select": {
            paddingRight: `52px !important`,
        },
        "& $endIcon": {
            top: 0,
            bottom: 0,
            height: "100%",
            borderLeftColor: theme.palette.grey[300],
            borderLeftWidth: 1,
            borderLeftStyle: "solid",
            paddingLeft: 8,
            paddingRight: 1,
        },
        "& $endIconOpen": {
            borderLeftWidth: 0,
            borderRightColor: theme.palette.grey[300],
            borderRightWidth: 1,
            borderRightStyle: "solid",
            paddingLeft: 1,
            paddingRight: 8,
        },
    },
    select: {
        paddingRight: `36px !important`,
    },
    startIcon: {
        position: "absolute",
        top: 0,
        bottom: 0,
        color: theme.palette.grey[700],
        pointerEvents: "none",
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        "& svg": {
            fontSize: theme.typography.pxToRem(16),
            verticalAlign: "text-bottom",
        },
    },
    endIcon: {
        paddingRight: 2,
        boxSizing: "content-box",
    },
    endIconOpen: {
        paddingRight: 0,
        paddingLeft: 2,
    },
    label: {
        transform: "none",
        position: "initial",
        marginBottom: theme.spacing(0.875),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    filled: {
        "& $select": {
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
                background: theme.palette.primary.dark,
            },
            "&:focus": {
                background: theme.palette.primary.dark,
            },
        },
        "& $endIcon": {
            color: "white",
        },
        "& $startIcon": {
            color: "white",
        },
    },
    fullWidth: {
        width: "100%",
    },
}))(SelectInner);
