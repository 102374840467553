import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { Routes } from "../../routes/routes";

export function AuthRouteNotAuthorised(props: RouteProps): JSX.Element {
  const location = useLocation();
  const isLoginPath = location.pathname === Routes.Login.ROUTE;

  return (
    <>
      {isLoginPath ? (
        <Route {...props} />
      ) : (
        <Redirect
          to={{
            pathname: Routes.Login.ROUTE,
            state: { from: location },
          }}
        />
      )}
    </>
  );
}
