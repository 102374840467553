import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  makeStyles,
  useTheme,
  Collapse,
} from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { SectionDivider, SectionTitle } from "../../Section";
import { useFormikContext } from "formik";
import { FieldArray } from "formik";
import { FieldArrayRenderProps } from "formik";
import { DeleteButtonWithConfirmation } from "../../../../../_common/components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation";
import {
  Snackbar,
  Typography,
  ExclamationCircleSolid,
  ArrowNarrowUpSolid,
  ArrowNarrowDownSolid,
} from "@lumar/shared";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

import ace from "ace-builds/src-noconflict/ace";

import { FormikProps } from "formik";
import { FormikTouched } from "formik";
import { FieldProps } from "formik";
import { ConnectSiteFormValues } from "../ErrorEffect";

// eslint-disable-next-line fp/no-mutation
ace.Range = ace.acequire("ace/range").Range;

export const API_COOKIE_KEY_REGEXP = /^[a-zA-Z0-9!#$%&'*+\-.^_`|~]+$/;
export const API_COOKIE_VALUE_REGEXP =
  /^[a-zA-Z0-9!#$%&'()*+\-./:<=>?@[\]^_`{|}~]+$/;

const MAX_CUSTOM_COOKIES = 100;

const useStyles = makeStyles((theme) => ({
  endAdornment: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[700],
    fontWeight: 400,
  },
  customWidth: {
    maxWidth: "800px",
    "& p": {
      margin: "3px 0",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  inputControl: {
    width: 250,
  },
  value: {
    margin: theme.spacing(0, 1, 0, 2),
  },
  snackbar: {
    width: `calc(100% - ${theme.spacing(3)}px)`,
    height: 33,
    paddingTop: 0,
    paddingBottom: 0,
  },
  snackTitle: {
    maxWidth: "100%",
  },
  title: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
  },
  snackButton: {
    display: "none",
  },
  snackIcon: {
    width: 15,
    height: 15,
    alignSelf: "auto",
  },
  input: {
    margin: theme.spacing(1.375, 0, 1, 0),
  },
  error: {
    color: theme.palette.red[600],
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(0.5),
    },
  },
  accordion: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(2.625),
  },
  accordionSummary: {
    minHeight: 42,
    padding: theme.spacing(0, 3),
    "&$accordionSummaryExpanded": {
      minHeight: 42,
    },
  },
  accordionSummaryExpanded: {},
  accordionSummaryContent: {
    margin: theme.spacing(0.875, 0),
    display: "flex",
    justifyContent: "space-between",
    "&$accordionSummaryExpanded": {
      margin: theme.spacing(0.875, 0),
    },
  },
  instructionContainer: {
    margin: theme.spacing(1.25, 0, 0, 1),
  },
  instruction: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: theme.spacing(1.125),
  },
  number: {
    display: "inline-block",
    width: 23,
    height: 23,
    background: theme.palette.yellow[400],
    borderRadius: "50%",
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(23),
    fontWeight: 600,
    textAlign: "center",
    marginRight: theme.spacing(1.125),
    flexShrink: 0,
  },
  action: {
    color: theme.palette.primary.main,
  },
  footer: {
    display: "flex",
  },
  button: {
    marginLeft: "auto",
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
  },
  cookies: {
    marginTop: theme.spacing(2),
  },
}));

export function CookiesSettings({
  form,
}: FieldProps<ConnectSiteFormValues["rendererCookies"]>): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const [instructionOpen, setInstructionOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [importOpen, setImportOpen] = React.useState(false);

  const instructions = [
    "Open your domain in a new incognito window in the browser",
    "Right click + inspect",
    "Click Accept cookies in the cookie banner",
    "Type document.cookie into the Console tab within Chrome Developer Tools",
    "Copy + paste resulting string into the field below",
  ];

  if (form.isSubmitting && (error.length || importOpen)) {
    setError("");
    setImportOpen(false);
  }

  function handleImport(): void {
    if (!value) {
      setError("");
      return;
    }

    const cookies = importCookies(value);

    if (cookies.length) {
      formMergeCookies(form, cookies);
      setValue("");
      setError("");
    } else {
      setError("Invalid value detected, please check your entry.");
    }
  }

  return (
    <>
      <div
        style={{
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <SectionTitle>Cookies</SectionTitle>
        <Typography variant="body1" className={classes.cookies}>
          Add Cookies which willl be set in the browser during rendering.
        </Typography>
      </div>
      <Paper style={{ padding: theme.spacing(2) }}>
        <FieldArray
          name="rendererCookies"
          // eslint-disable-next-line
          component={CustomCookies as any}
        />
        <SectionDivider />
        <Button
          startIcon={
            importOpen ? <ArrowNarrowUpSolid /> : <ArrowNarrowDownSolid />
          }
          data-testid="import-custom-cookies-button"
          data-cy="import-custom-cookies-button"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-import-custom-cookies-button"
          variant="outlined"
          size="small"
          style={{ marginBottom: theme.spacing(2) }}
          //          onClick={handleImport}
          onClick={() => setImportOpen((x) => !x)}
        >
          Import Cookie
        </Button>

        <Collapse in={importOpen}>
          <div className={classes.accordion}>
            <Accordion
              expanded={instructionOpen}
              onChange={(_, value) => setInstructionOpen(value)}
            >
              <AccordionSummary
                classes={{
                  root: classes.accordionSummary,
                  expanded: classes.accordionSummaryExpanded,
                  content: classes.accordionSummaryContent,
                }}
              >
                <Typography variant="captionSemiBold">
                  {"Instructions on how to import cookies"}
                </Typography>
                <Typography
                  variant="captionSemiBold"
                  className={classes.action}
                >
                  {!instructionOpen ? "Show instructions" : "Hide instructions"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.instructionContainer}>
                  {instructions.map((instruction, idx) => (
                    <div className={classes.instruction} key={idx}>
                      <div className={classes.number}>{idx + 1}</div>
                      <Typography>{instruction}</Typography>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <Snackbar
            classes={{
              titleContainer: classes.snackTitle,
              root: classes.snackbar,
              closeButton: classes.snackButton,
              icon: classes.snackIcon,
              title: classes.title,
            }}
            variant="info"
            title={
              "Paste a JSON string of cookies to add or update the cookie settings."
            }
          />
          <AceEditor
            mode="text"
            editorProps={{ $blockScrolling: Infinity }}
            setOptions={{
              enableSnippets: true,
              showLineNumbers: true,
              firstLineNumber: 1,
            }}
            theme="tomorrow"
            wrapEnabled
            showGutter
            maxLines={12}
            minLines={12}
            width="100%"
            fontSize={14}
            name="cookie-import"
            value={value}
            onChange={(value) => setValue(value)}
            className={classes.input}
          />
          <div className={classes.footer}>
            {error && (
              <div className={classes.error}>
                <ExclamationCircleSolid />
                <Typography>{error}</Typography>
              </div>
            )}
            <Button
              onClick={handleImport}
              variant="outlined"
              size="large"
              className={classes.button}
            >
              Import
            </Button>
          </div>
        </Collapse>
      </Paper>
    </>
  );
}

function CustomCookies(props: FieldArrayRenderProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const {
    values: { rendererCookies },
  } = useFormikContext<{
    rendererCookies: CustomCookies[];
  }>();

  function handleAddNewCookie(): void {
    // eslint-disable-next-line fp/no-mutating-methods
    props.push({
      name: "",
      value: "",
    });
  }

  return (
    <>
      {rendererCookies.map((_, index) => {
        return (
          <div key={index} style={{ marginBottom: theme.spacing(2) }}>
            <Grid className={classes.container}>
              <Grid className={classes.inputGroup}>
                <Grid item>
                  <Field
                    name={`rendererCookies[${index}].name`}
                    data-testid={`customCookies[${index}].name`}
                    id={`rendererCookies[${index}].name`}
                    data-cy={`customCookies-${index}-name`}
                    data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-cookie-name"
                    type="text"
                    component={TextField}
                    variant="outlined"
                    className={classes.inputControl}
                  />
                </Grid>
                <Grid item className={classes.value}>
                  <Field
                    name={`rendererCookies[${index}].value`}
                    data-testid={`customCookies[${index}].value`}
                    id={`rendererCookies[${index}].value`}
                    data-cy={`customCookies-${index}-value`}
                    data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-cookie-value"
                    type="text"
                    component={TextField}
                    variant="outlined"
                    className={classes.inputControl}
                  />
                </Grid>
              </Grid>
              <Grid item xs={1} md={2}>
                <DeleteButtonWithConfirmation
                  data-pendo={`auto-custom-cookie-delete-button`}
                  dataTestId={`delete-custom-cookie-${index}`}
                  dataCy={`delete-custom-cookie-${index}`}
                  tooltipTitle="Remove cookie"
                  confirmationTitle="Are you sure you want to remove this cookie?"
                  confirmationText="Your cookie will be removed from your selected tests in step 3. Please remember to save your changes."
                  handleConfirmation={() => {
                    props.remove(index);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <Grid item xs={12}>
        <div>
          {rendererCookies.length < MAX_CUSTOM_COOKIES ? (
            <Button
              startIcon={<AddCircleIcon />}
              data-testid="add-cookie-button"
              data-cy="add-cookie-button"
              data-pendo="auto-test-suite-edit-choose-crawl-settings-cookie-add"
              variant="outlined"
              size="small"
              onClick={handleAddNewCookie}
            >
              Add Cookie
            </Button>
          ) : (
            <Typography
              data-testid="add-cookie-message"
              data-cy="add-cookie-message"
              style={{
                fontSize: 14,
                lineHeight: "17px",
                color: "#6B7280",
                fontWeight: 400,
              }}
            >
              Want to add more cookies?
              <a
                href="mailto:product@deepcrawl.com"
                data-pendo="auto-test-suite-edit-choose-crawl-settings-cookies-contact-us"
              >
                Contact us.
              </a>
            </Typography>
          )}
        </div>
      </Grid>
    </>
  );
}
export interface CustomCookies {
  name: string;
  value: string;
}

export function importCookies(value: string): CustomCookies[] {
  try {
    return formatCookieString(value);
  } catch {}

  return [];
}

function formatCookieString(value: string): CustomCookies[] {
  const trimmedValue = value.trim();
  const formattedValue = trimmedValue.match(/^"[^"]*"$/)
    ? trimmedValue.substring(1, trimmedValue.length - 1)
    : trimmedValue;

  function encode(key: string, regExp: RegExp): string {
    return key
      .split("")
      .map((x) => (regExp.test(x) ? x : encodeURIComponent(x)))
      .join("");
  }

  return formattedValue.split(";").map((row) => {
    const idx = row.indexOf("=");
    if (idx === -1) throw new Error();

    return {
      name: encode(row.substring(0, idx).trim(), API_COOKIE_KEY_REGEXP),
      value: encode(row.substring(idx + 1).trim(), API_COOKIE_VALUE_REGEXP),
    };
  });
}

function formMergeCookies(
  form: FormikProps<Pick<ConnectSiteFormValues, "rendererCookies">>,
  importCookies: CustomCookies[],
): void {
  const cookies = [...form.values.rendererCookies];
  const touched: FormikTouched<CustomCookies>[] = Array(cookies.length);

  form.touched.rendererCookies?.forEach((value, idx) => {
    // eslint-disable-next-line fp/no-mutation
    touched[idx] = value;
  });
  importCookies.forEach((cookie) => {
    const idx = cookies.findIndex((x) => x.name === cookie.name);
    if (idx !== -1) {
      // eslint-disable-next-line fp/no-mutation
      cookies[idx] = cookie;
      // eslint-disable-next-line fp/no-mutation
      touched[idx] = { name: true, value: true };
    } else {
      // eslint-disable-next-line fp/no-mutating-methods
      cookies.push(cookie);
      // eslint-disable-next-line fp/no-mutating-methods
      touched.push({ name: true, value: true });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form.setFieldTouched("rendererCookies", touched as any);
  form.setFieldValue("rendererCookies", cookies, true);
}
