import { Box, withStyles } from "@material-ui/core";

export const StickyWrapper = withStyles((theme) => ({
  root: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(2),
    height: 60,
    position: "sticky",
    bottom: 0,
    backdropFilter: "blur(8px)",
    background: "rgba(209, 213, 219,0.3)",
    borderRadius: "0 0 8px 8px",
  },
}))(Box);
