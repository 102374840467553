export var AddonAvailability;
(function (AddonAvailability) {
    AddonAvailability["Free"] = "free";
    AddonAvailability["Paid"] = "paid";
    AddonAvailability["None"] = "none";
})(AddonAvailability || (AddonAvailability = {}));
export var AppIds;
(function (AppIds) {
    AppIds["analyze"] = "analyze";
    AppIds["monitor"] = "monitor";
    AppIds["impact"] = "impact";
    AppIds["protect"] = "protect";
})(AppIds || (AppIds = {}));
