/* eslint-disable fp/no-mutating-methods */
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getRawAccountId, SessionContext } from "../../session";
const useChurnZeroVariables = () => {
    const session = React.useContext(SessionContext);
    const location = useLocation();
    const variables = useMemo(() => ({
        accountId: session === null || session === void 0 ? void 0 : session.account.id,
        email: session === null || session === void 0 ? void 0 : session.email,
        firstName: session === null || session === void 0 ? void 0 : session.firstName,
        lastName: session === null || session === void 0 ? void 0 : session.lastName,
    }), [
        session === null || session === void 0 ? void 0 : session.account.id,
        session === null || session === void 0 ? void 0 : session.email,
        session === null || session === void 0 ? void 0 : session.firstName,
        session === null || session === void 0 ? void 0 : session.lastName,
    ]);
    const canInitialize = Boolean(session && !session.isUsingSharedLink);
    React.useEffect(() => {
        const ChurnZero = window.ChurnZero;
        const accountId = variables.accountId;
        const email = variables.email;
        const firstName = variables.firstName;
        const lastName = variables.lastName;
        if (canInitialize && ChurnZero && accountId && email) {
            if (!email.includes("lumar.io") && !email.includes("deepcrawl.com")) {
                ChurnZero.push([
                    "setAppKey",
                    "1!kOrQJ-fuX-5VPyOgMYD41ZZtQ5jPyvAsxHMYyESD4IQt1425",
                ]);
                ChurnZero.push(["setContact", getRawAccountId(accountId), email]);
                if (firstName) {
                    ChurnZero.push(["setAttribute", "contact", "First Name", firstName]);
                }
                if (lastName) {
                    ChurnZero.push(["setAttribute", "contact", "Last Name", lastName]);
                }
            }
        }
    }, [variables, canInitialize, location]);
};
export const ChurnZeroProvider = ({ children, }) => {
    useChurnZeroVariables();
    return children;
};
