import React from "react";
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { ToggleIconButton, TrashSolid, Typography } from "@lumar/shared";

export type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface GridSettings {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

interface DeletableInputProps {
  value: string;
  handleDelete: () => void;
  loading: boolean;
  id: string;
  label: string;
  textFieldGrid: GridSettings;
  deleteButtonGrid: GridSettings;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    paddingBottom: 8,
  },
  textField: {
    width: 400,
    "& input[class*=MuiOutlinedInput-input]": {
      color: theme.palette.grey[600],
    },
  },
  textFieldContainer: {
    maxWidth: "fit-content",
  },
}));

export function DeletableInput(props: DeletableInputProps): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid container>
        <Typography className={classes.label}>{props.label}</Typography>
      </Grid>
      <Grid
        item
        {...props.textFieldGrid}
        className={classes.textFieldContainer}
      >
        <TextField
          disabled={props.disabled}
          className={classes.textField}
          variant="outlined"
          fullWidth
          value={props.value}
          data-testid={`${props.id}-field`}
          data-pendo={`auto-${props.id}-row-field`}
          data-cy={`${props.id}-field`}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid
        item
        {...props.deleteButtonGrid}
        className={classes.textFieldContainer}
      >
        <Box component="span" display="flex" justifyContent="center">
          {props.loading ? (
            <CircularProgress
              size="2rem"
              data-testid={`delete-${props.id}-loading`}
            />
          ) : (
            <ToggleIconButton
              style={{ marginLeft: 8 }}
              size="large"
              variant="outlined"
              colorVariant="red"
              onClick={props.handleDelete}
              data-cy={`delete-${props.id}`}
              data-testid={`delete-${props.id}-button`}
              data-pendo={`auto-${props.id}-button-delete`}
            >
              <TrashSolid />
            </ToggleIconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
