export const sharedResources = {
    en: {
        a11y: require("./locales/en/a11y.json"),
        appSidebar: require("./locales/en/appSidebar.json"),
        appSwitcher: require("./locales/en/appSwitcher.json"),
        usageLimitIndicator: require("./locales/en/usageLimitIndicator.json"),
        connectionFiltering: require("./locales/en/connectionFiltering.json"),
        grid: require("./locales/en/grid.json"),
        sharedCommon: require("./locales/en/sharedCommon.json"),
        terms: require("./locales/en/terms.json"),
    },
    ja: {
        a11y: require("./locales/ja/a11y.json"),
        appSidebar: require("./locales/ja/appSidebar.json"),
        appSwitcher: require("./locales/ja/appSwitcher.json"),
        usageLimitIndicator: require("./locales/ja/usageLimitIndicator.json"),
        connectionFiltering: require("./locales/ja/connectionFiltering.json"),
        grid: require("./locales/ja/grid.json"),
        sharedCommon: require("./locales/ja/sharedCommon.json"),
        terms: require("./locales/ja/terms.json"),
    },
};
