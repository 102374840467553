import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { connectSiteTooltips } from "../connectSiteTooltips";
import { Field, useFormikContext } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { dummyAuthPassword } from "../ConnectSiteForm";
import { ConfirmOrDelete } from "../../../../../_common/components/ConfirmOrDelete/ConfirmOrDelete";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { SectionTitle } from "../../Section";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { Alert } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  changePassword: {
    padding: 0,
    marginTop: theme.spacing(2),
    lineHeight: theme.typography.pxToRem(17),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.ultraviolet[500],
    },
  },
  alert: {
    marginTop: 19,
  },
  visibilityIcon: {
    color: theme.palette.grey[500],
    padding: 4,
    marginRight: 5,
  },
  passwordInput: {
    "& .MuiInputBase-input.Mui-disabled": {
      "&:hover": {
        cursor: "not-allowed !important",
      },
    },
  },
}));

interface AuthenticationSettingsProps {
  isAuthPasswordInitiallySet: boolean;
  handleSaveAuthPassword: (newPassword: string) => void;
  authPasswordValue: string;
  hasAuthPasswordChanged: boolean;
  isWarningVisible: boolean;
  toggleWarning: (isVisible: boolean) => void;
}

interface AuthSettingsValues {
  testSiteUsername: string;
  testSiteDomainPass: string;
}

export function AuthenticationSettings(props: AuthenticationSettingsProps) {
  const classes = useStyles();

  const { setFieldValue, values } = useFormikContext<AuthSettingsValues>();
  const [passwordSettings, setPasswordSettings] = useState({
    showPassword: false,
    showConfirmButton: false,
  });
  const isPasswordSet =
    props.isAuthPasswordInitiallySet || props.hasAuthPasswordChanged;

  function handlePasswordInputFocus() {
    setPasswordSettings((s) => ({ ...s, showConfirmButton: true }));
  }

  function handleClickShowPassword() {
    setPasswordSettings((s) => ({ ...s, showPassword: !s.showPassword }));
  }

  function handleChangePassword() {
    setPasswordSettings((s) => ({ ...s, showConfirmButton: true }));
    setFieldValue("testSiteDomainPass", "");
  }

  function handleCancelPassword() {
    setPasswordSettings({ showConfirmButton: false, showPassword: false });
    const newValue = props.authPasswordValue ? dummyAuthPassword : "";
    setFieldValue("testSiteDomainPass", newValue);
  }

  function handleConfirmPassword() {
    props.handleSaveAuthPassword(values.testSiteDomainPass);
    setPasswordSettings({ showConfirmButton: false, showPassword: false });
    const newValue = values.testSiteDomainPass ? dummyAuthPassword : "";
    setFieldValue("testSiteDomainPass", newValue);
    props.toggleWarning(true);
  }

  return (
    <Box component="div" data-cy="connect-site-form-auth-box">
      <Box component="div" display="flex" alignItems="center" mb={2}>
        <SectionTitle optional>Authentication</SectionTitle>
        <InfoTooltip
          IconProps={{
            style: {
              marginLeft: 8,
            },
          }}
          title={connectSiteTooltips.authentication}
          data-pendo="auto-tooltip-test-suite-authentication"
        />
      </Box>
      <Box style={{ marginBottom: 16 }}>
        <StyledInputLabel htmlFor="connect-site-form-auth-user">
          Username
        </StyledInputLabel>
        <Field
          style={{ maxWidth: 400 }}
          id="connect-site-form-auth-user"
          data-testid="connect-site-form-auth-user"
          data-pendo="auto-edit-add-test-suite-authentication-username"
          name="testSiteUsername"
          type="text"
          component={TextField}
          fullWidth={true}
          variant="outlined"
        />
      </Box>

      <Box display="flex" alignItems="end">
        <Box marginRight={1}>
          <StyledInputLabel htmlFor="connect-site-form-auth-pass">
            Password
          </StyledInputLabel>
          <Field
            style={{ width: 400 }}
            className={classes.passwordInput}
            id="connect-site-form-auth-pass"
            data-testid="connect-site-form-auth-pass"
            data-pendo="auto-edit-add-test-suite-authentication-password"
            name="testSiteDomainPass"
            autoComplete="new-password"
            type={passwordSettings.showPassword ? "text" : "password"}
            component={TextField}
            disabled={isPasswordSet && !passwordSettings.showConfirmButton}
            onFocus={handlePasswordInputFocus}
            fullWidth={true}
            variant="outlined"
            InputProps={{
              endAdornment: passwordSettings.showConfirmButton ? (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.visibilityIcon}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    data-testid="toggle-password-visibility"
                    data-pendo="auto-edit-add-test-suite-authentication-password-view"
                    title={
                      passwordSettings.showPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  >
                    {passwordSettings.showPassword ? (
                      <Visibility style={{ fontSize: 20 }} />
                    ) : (
                      <VisibilityOff style={{ fontSize: 20 }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Box>

        {passwordSettings.showConfirmButton && (
          <ConfirmOrDelete
            dataTestId="auth-password"
            handleConfirm={handleConfirmPassword}
            handleCancel={handleCancelPassword}
          />
        )}
      </Box>

      {isPasswordSet && !passwordSettings.showConfirmButton && (
        <Button
          color="primary"
          disableElevation
          className={classes.changePassword}
          data-testid="change-password-button"
          data-pendo="auto-edit-add-test-suite-authentication-password-change"
          type="button"
          onClick={handleChangePassword}
        >
          Change password...
        </Button>
      )}

      {props.isWarningVisible && (
        <Alert
          severity="warning"
          data-testid="save-auth-password-alert"
          style={{ marginTop: 16 }}
          size="small"
        >
          <strong>Save changes.</strong> Please save your changes in ‘Step 1’ by
          clicking on the ‘Save’ button below.
        </Alert>
      )}
    </Box>
  );
}
