import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  Collapse,
} from "@material-ui/core";
import { ExtendedTest } from "../../../pages/TestSuite/components/ChooseTestsForm/CreateTests.interfaces";
import { Severity } from "../../../graphql";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";
import { Alert, SwitchWithIcon } from "@lumar/shared";
import { StyledInputLabel } from "../StyledInputLabel/StyledInputLabel";

const useStyles = makeStyles((theme) => ({
  enableJiraTitle: {
    fontWeight: 500,
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    marginRight: theme.spacing(1),
  },
  alert: {
    marginBottom: 12,
  },
  addCustomNote: {
    fontSize: "0.875rem",
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  tooltipIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  tooltipTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.125rem",
    marginBottom: theme.spacing(1),
  },
  tooltipText: {
    color: "#202020",
    fontSize: "0.875rem",
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    color: theme.palette.navy[200],
  },
  container: {
    padding: "11px",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));

interface EnableJiraProps {
  handleEnableJiraChange: (input: {
    createJiraTicketOnFailure: boolean | null;
    jiraTicketCustomNote: string | null;
  }) => void;
  test: ExtendedTest;
  isJiraIntegrationConnected: boolean;
}

export function EnableJira(props: EnableJiraProps): JSX.Element {
  const classes = useStyles();
  const [jiraSettings, setJiraSettings] = useState({
    jiraTicketCustomNote: props.test.data.jiraTicketCustomNote,
    createJiraTicketOnFailure: props.test.data.createJiraTicketOnFailure,
  });
  const labelText = `Create Jira ticket if test ${
    props.test.data.severity === Severity.Fail ? "fails" : "warns"
  }`;

  function handleToggle(): void {
    const newSettings = {
      jiraTicketCustomNote: jiraSettings.jiraTicketCustomNote,
      createJiraTicketOnFailure: !jiraSettings.createJiraTicketOnFailure,
    };
    setJiraSettings(newSettings);
    props.handleEnableJiraChange(newSettings);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newSetting = {
      createJiraTicketOnFailure: jiraSettings.createJiraTicketOnFailure,
      jiraTicketCustomNote: event.currentTarget.value,
    };
    setJiraSettings(newSetting);
    props.handleEnableJiraChange(newSetting);
  }

  return (
    <div data-testid="enable-jira" className={classes.container}>
      <Box className={classes.headerWrapper}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.enableJiraTitle}
            data-testid="enable-jira-title"
          >
            {labelText}
          </Typography>
          <InfoTooltip
            data-pendo="auto-tooltip-enable-jira"
            data-testid="enable-jira-tooltip"
            title={
              <>
                <h1 style={{ fontWeight: 500, fontSize: 15 }}>
                  Jira Integration
                </h1>
                <p>
                  You can notify your development team about failed or received
                  a warning test with an automatic Jira integration feature. You
                  will be able to set it up in the next step
                </p>
              </>
            }
          />
        </Box>
        <SwitchWithIcon
          name="jira-ticket-toggle"
          data-testid="enable-jira-toggle"
          data-pendo={`auto-test-suite-edit-choose-tests-test-jira-ticket-create-toggle`}
          checked={jiraSettings.createJiraTicketOnFailure}
          onChange={handleToggle}
          inputProps={{ "aria-label": "Enable jira toggle" }}
        />
      </Box>

      <Collapse in={jiraSettings.createJiraTicketOnFailure}>
        <Box marginTop={2}>
          {!props.isJiraIntegrationConnected && (
            <Alert
              data-testid="enable-jira-alert"
              severity="warning"
              size="small"
              className={classes.alert}
            >
              <strong>Please enable this feature first.</strong> To enable this
              feature please connect to Jira in the next step.
            </Alert>
          )}
          <StyledInputLabel
            htmlFor="custom-note-input"
            data-testid="custom-note-header"
          >
            Add custom note to Jira ticket
          </StyledInputLabel>
          <TextField
            data-testid="custom-note-input"
            data-pendo={`auto-test-suite-edit-choose-tests-test-jira-ticket-create-note`}
            fullWidth
            variant="outlined"
            placeholder="Write note here"
            id="custom-note-input"
            value={jiraSettings.jiraTicketCustomNote || ""}
            onChange={handleInputChange}
          />
        </Box>
      </Collapse>
    </div>
  );
}
