import React, { useState } from "react";
import { TestThreshold } from "../TestThreshold/TestThreshold";
import { Collapse, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TestSeverityButton } from "../TestSeverityButton/TestSeverityButton";
import { EnableJira } from "../EnableJira/EnableJira";
import { ExtendedTest } from "../../../pages/TestSuite/components/ChooseTestsForm/CreateTests.interfaces";
import { TestExpansionPanel } from "./TestExpansionPanel";
import { isEqual } from "lodash";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";
import { SwitchWithIcon } from "@lumar/shared";
import {
  ModuleCode,
  ReportTemplateUnit,
  Severity,
  ThresholdPredicate,
  ThresholdType,
} from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  testName: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    fontSize: 15,
  },
  whiteText: {
    color: "#FFFFFF",
  },
  severityButton: {
    fontSize: "16px",
  },
  content: {
    margin: "18px 0px",
  },
  testSeverityText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(17),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(17),
  },
  smartThresholdWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 11,
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  icon: {
    color: theme.palette.navy[200],
  },
}));

export interface TestThresholdPredicate {
  label: string;
  value: ThresholdPredicate;
  default: boolean;
}

export interface Threshold {
  relative: number;
  absolute: number;
  type: ThresholdType;
  predicate: ThresholdPredicate;
}

export interface ReportTemplate {
  name: string;
  automatorSummary: string | null;
  code: string;
  unit: ReportTemplateUnit;
}

export interface TestCollapseProps {
  absoluteThresholdUpLimit: number;
  isJiraIntegrationConnected: boolean;
  test: ExtendedTest;
  onChange: (input: {
    threshold: Threshold;
    severity: Severity;
    expanded: boolean;
  }) => void;
  handleEnableJiraChange: (input: {
    createJiraTicketOnFailure: boolean | null;
    jiraTicketCustomNote: string | null;
  }) => void;
  showSmartThresholdSettings: boolean;
  handleSmartThresholdChange: (
    test: ExtendedTest,
    isSmartThresholdEnabled: boolean,
  ) => void;
}

export function arePropsEqual(
  prevProps: TestCollapseProps,
  newProps: TestCollapseProps,
): boolean {
  // We only want to return true if both objects are `equivalent`.
  // By default, React only does a shallow comparison of props, and since props.test is an object,
  // it will always return false. Hence, we are using a custom function.
  const isJiraIntegrationStatusTheSame =
    prevProps.isJiraIntegrationConnected ===
    newProps.isJiraIntegrationConnected;
  const isSmartThresholdTheSame =
    prevProps.showSmartThresholdSettings ===
    newProps.showSmartThresholdSettings;
  return (
    isEqual(prevProps.test, newProps.test) &&
    isSmartThresholdTheSame &&
    isJiraIntegrationStatusTheSame
  );
}

function TestCollapsable(props: TestCollapseProps): JSX.Element {
  const { onChange, test } = props;
  const defaultThreshold = {
    relative: test.data.relativeThreshold,
    absolute: test.data.absoluteThreshold,
    type: test.data.thresholdType,
    predicate: test.data.thresholdPredicate,
  };
  const [threshold, setThreshold] = useState<Threshold>(defaultThreshold);
  const [severity, setSeverity] = useState<Severity>(test.data.severity);
  const classes = useStyles();

  function handleSeverityChange(severity: Severity): void {
    setSeverity(severity);
    onChange({
      severity,
      threshold,
      expanded: test.extended.expanded,
    });
  }

  return (
    <TestExpansionPanel
      threshold={threshold}
      severity={severity}
      reportTemplate={test.data.reportTemplate}
      initialExpanded={test.extended.expanded}
      onChange={onChange}
      absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
    >
      <div style={{ marginBottom: "12px" }}>
        <Typography color="textPrimary" className={classes.testSeverityText}>
          Test severity
        </Typography>
        <Grid container>
          <Grid item xs={6} container>
            <TestSeverityButton
              severityType={Severity.Fail}
              isActive={severity === Severity.Fail}
              handleClick={() => {
                handleSeverityChange(Severity.Fail);
              }}
            />
          </Grid>
          <Grid item xs={6} container>
            <TestSeverityButton
              severityType={Severity.Warning}
              isActive={severity === Severity.Warning}
              handleClick={() => {
                handleSeverityChange(Severity.Warning);
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Collapse in={props.showSmartThresholdSettings}>
        <div
          className={classes.smartThresholdWrapper}
          style={{ marginBottom: "12px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="textPrimary"
              data-testid="automatic-threshold-subtitle"
              className={classes.subtitle}
            >
              Use smart thresholds
            </Typography>
            <InfoTooltip
              data-pendo="auto-tooltip-smart-threshold"
              data-testid="smart-threshold-tooltip"
              title={
                <>
                  <h1 style={{ fontWeight: 500, fontSize: 15 }}>
                    Smart thresholds
                  </h1>
                  <p>
                    Learn from previous test results and update test thresholds
                    automatically. The more tests you run and the better your
                    results, the more strict your thresholds will become and the
                    better your SEO outcome will be. The test settings will be
                    set to an optimal value based on previous test suite runs.
                  </p>
                  <ul style={{ paddingInlineStart: 15 }}>
                    <li>
                      If a test passes with a value better than the test
                      threshold, the threshold will automatically update to the
                      appropriate new value.
                    </li>
                    <li>
                      When enabled, the test thresholds will be optimized for
                      all tests in this test suite. If you want to enable this
                      for specific tests only, enable the &quot;Set on a per
                      test basis&quot; toggle.
                    </li>
                  </ul>
                </>
              }
            />
          </div>
          <SwitchWithIcon
            data-cy="automatic-threshold-toggle-individual-test"
            inputProps={{ "aria-label": "Smart threshold toggle" }}
            checked={props.test.data.automaticThresholdEnabled}
            onChange={(event) => {
              props.handleSmartThresholdChange(
                props.test,
                event.target.checked,
              );
            }}
          />
        </div>
      </Collapse>

      <TestThreshold
        absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
        testCode={test.data.reportTemplate.code}
        threshold={threshold}
        severity={severity}
        forceNumericThreshold={test.extended.moduleCode !== ModuleCode.Seo}
        onChange={(threshold): void => {
          setThreshold(threshold);
          onChange({
            severity,
            threshold,
            expanded: test.extended.expanded,
          });
        }}
        unit={test.data.reportTemplate.unit}
      />
      <EnableJira
        test={props.test}
        handleEnableJiraChange={props.handleEnableJiraChange}
        isJiraIntegrationConnected={props.isJiraIntegrationConnected}
      />
    </TestExpansionPanel>
  );
}

export const TestCollapse = React.memo(TestCollapsable, arePropsEqual);
