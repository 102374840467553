import React, { useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@material-ui/core";
import {
  getParamsForQuery,
  transformGetTestSuiteBuildResponsetoChartData,
} from "./FailPassWarnChartUtils";
import { FailPassWarnChartFilterSettings } from "./FailPassWarnChartFilter";
import {
  TestSuiteBuildsQueryParams,
  useTestSuiteBuildsQuery,
} from "../../graphql/useTestSuiteBuildsQuery";
import { Alert, EmptyState } from "@lumar/shared";
import { LineChart } from "./LineChart";
import errorAnimation from "../../../../_common/animations/exclamation-circle.json";
import Lottie from "react-lottie-player";

export interface ChartDataLoadStatus {
  hasData: boolean;
  loading: boolean;
  error: boolean;
}

export interface FailPassWarnChartProps {
  filterSettings: FailPassWarnChartFilterSettings;
  onDataLoadStatusChange: (status: ChartDataLoadStatus) => void;
  timeStampForReload: number | undefined;
  testSuites:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
}

// eslint-disable-next-line complexity
export function FailPassWarnChart(props: FailPassWarnChartProps): JSX.Element {
  const [params, setParams] = useState<TestSuiteBuildsQueryParams>(
    getParamsForQuery(props.filterSettings),
  );
  const { data, loading, error, refetch } = useTestSuiteBuildsQuery(params);
  const [lastTimeStampOfDataLoad, setLastTimeStampOfDataLoad] = useState<
    number | undefined
  >(new Date().getTime());
  const theme = useTheme();

  const { onDataLoadStatusChange: callBackAfterDataLoadingChange } = props;
  const isLoaderVisible = loading && !error;
  const isErrorMessageVisible = !!error;
  const hasData = data?.node ? data.node.builds.edges.length > 0 : false;
  const isLineChartVisible = !loading && !error && hasData;

  useEffect(() => {
    setParams(getParamsForQuery(props.filterSettings));
  }, [props.filterSettings]);

  useEffect(() => {
    if (
      lastTimeStampOfDataLoad &&
      props.timeStampForReload &&
      lastTimeStampOfDataLoad < props.timeStampForReload
    ) {
      refetch(getParamsForQuery(props.filterSettings));
      setLastTimeStampOfDataLoad(new Date().getTime());
    }
  }, [
    props.timeStampForReload,
    lastTimeStampOfDataLoad,
    props.filterSettings,
    refetch,
  ]);

  useEffect(() => {
    const statusChange: ChartDataLoadStatus = {
      error: !!error,
      hasData: hasData,
      loading: loading,
    };
    callBackAfterDataLoadingChange(statusChange);
  }, [hasData, loading, error, callBackAfterDataLoadingChange]);

  const transformedData = transformGetTestSuiteBuildResponsetoChartData(
    data,
    props.filterSettings,
    theme,
  );

  return (
    <>
      {isLoaderVisible && (
        <CircularProgress data-testid="fail-pass-warn-chart-loader" />
      )}
      {isErrorMessageVisible && (
        <Alert severity="error">Data loading error</Alert>
      )}
      {isLineChartVisible ? (
        <div
          data-testid="fail-pass-warn-chart-wrapper"
          data-pendo="auto-dashboard-widget-fails-warnings-chart"
        >
          <LineChart data={transformedData} />
        </div>
      ) : (
        <Box mb={2}>
          <EmptyState
            width="100%"
            height={400}
            title="No data available"
            description="This graph has no builds for the selected date range. This could mean that you’ve selected a date range with no activity."
            icon={
              <Lottie
                loop
                animationData={errorAnimation}
                play
                style={{ width: "80px", margin: "auto" }}
              />
            }
          />
        </Box>
      )}
    </>
  );
}
