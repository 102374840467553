import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function PlayIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12ZM9.5 9.86852C9.5 8.27113 11.2803 7.31834 12.6094 8.20442L15.8066 10.3359C16.9941 11.1275 16.9941 12.8725 15.8066 13.6641L12.6094 15.7956C11.2803 16.6817 9.5 15.7289 9.5 14.1315V9.86852ZM14.6972 12L11.5 9.86852V14.1315L14.6972 12Z"
      />
    </SvgIcon>
  );
}
