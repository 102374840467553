import {
  ExtendedTest,
  TestCategory,
  ChooseTestsFilterOptions,
} from "./CreateTests.interfaces";
import { compareStrings } from "../../../../_common/utils/string/comparisons/comparisons";
import { parseStringToLowercaseArray } from "../../../../_common/utils/string/parse/parsers";
import { MAX_TESTS_SELECTION_LIMIT } from "./CreateTestsCommonConst";

export function compareTestByName(testA: ExtendedTest, testB: ExtendedTest) {
  return compareStrings(
    testA.data.reportTemplate.name,
    testB.data.reportTemplate.name,
  );
}

export function getSelectedTests(categories: TestCategory[]) {
  return categories
    .flatMap((category) => category.tests)
    .filter((test) => test.extended.selected);
}

export function isMaxTestSelectedLimitReached(categories: TestCategory[]) {
  return getSelectedTests(categories).length >= MAX_TESTS_SELECTION_LIMIT;
}

export function getNewCategories(
  categories: TestCategory[],
  testToUpdate?: ExtendedTest | null,
): TestCategory[] {
  if (testToUpdate) {
    return categories.map((category) => {
      if (category.code === testToUpdate.extended.category) {
        return {
          ...category,
          tests: category.tests.map((test) => {
            if (
              test.data.reportTemplate.code ===
              testToUpdate.data.reportTemplate.code
            ) {
              return testToUpdate;
            } else {
              return test;
            }
          }),
        };
      } else {
        return category;
      }
    });
  } else {
    return categories;
  }
}

function isTestVisible(
  test: ExtendedTest,
  searchTexts: string[],
  filterOutSelectedTests: boolean,
) {
  if (searchTexts.length > 0) {
    const allTexts = searchTexts.filter((text) => {
      return filterOutSelectedTests
        ? test.data.reportTemplate.name?.toLowerCase().includes(text) &&
            !test.extended.selected
        : test.data.reportTemplate.name?.toLowerCase().includes(text);
    });
    return allTexts.length > 0;
  } else {
    return filterOutSelectedTests ? !test.extended.selected : true;
  }
}

export function getVisibleTests(
  testToFilter: ExtendedTest[],
  searchText: string,
  filterOutSelectedTests: boolean,
) {
  return testToFilter.filter((test) => {
    return isTestVisible(
      test,
      parseStringToLowercaseArray(searchText),
      filterOutSelectedTests,
    );
  });
}

export function getTestsByTemplateId(
  categories: TestCategory[],
  templateId: string,
) {
  return categories
    .flatMap((category) => category.tests)
    .filter((test) => test.data.reportTemplate.code === templateId)[0];
}

export function areAnyTestsSelected(categories: TestCategory[]) {
  return !!categories.find((category) => {
    return category?.tests?.findIndex((test) => test.extended.selected) !== -1;
  });
}

export function getRenderableCategories(
  filter: ChooseTestsFilterOptions,
  categories: TestCategory[],
) {
  // eslint-disable-next-line fp/no-mutating-methods
  return categories.filter((category) => {
    if (!filter.showAllCategories) {
      return category.selected;
    } else {
      return category;
    }
  });
}

export function nonMutationSortByTestName(tests: Array<ExtendedTest>) {
  // eslint-disable-next-line fp/no-mutating-methods
  return [...tests].concat().sort(compareTestByName);
}

export function isAnyCategorySelected(categories: TestCategory[]) {
  return categories.filter((category) => category.selected).length > 0;
}
