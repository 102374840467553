import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions, Skeleton } from "@material-ui/lab";
import { useGetAvailableParentTestSuites } from "../graphql/useGetAvailableParentTestSuites";
import { getParentTestsuiteOptionsFromData } from "../utils/getParentTestsuiteOptionsFromData";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { ConfirmationDialogInfoIcon, Alert } from "@lumar/shared";
import { useStyles } from "./popoverTemplateStyles";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { ConfirmationDialogue } from "./ConfirmationDialogue";
import { usePopupState, bindPopover } from "material-ui-popup-state/hooks";

export interface TestSuiteOption {
  name: string;
  id: string;
  group: string;
}

interface ApplyGlobalTemplate {
  handleApplyGlobalTemplate: (parentId: string) => Promise<void>;
  handleClose: () => void;
  testSuiteId: string;
  availableTestSuitesDetected?: (count: number) => void;
}

export function ApplyGlobalTemplate(props: ApplyGlobalTemplate): JSX.Element {
  const classes = useStyles();
  const autocompleteRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState<TestSuiteOption | null>(
    null,
  );

  const { data, error, loading } = useGetAvailableParentTestSuites();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "apply-global-template-popup",
  });

  const parentOptions = getParentTestsuiteOptionsFromData(
    data,
    props.testSuiteId,
  );

  const filterOptions = createFilterOptions<TestSuiteOption>({
    stringify: (option) => option.name,
  });

  function handleConfirmationClick(): void {
    popupState.close();

    if (selectedOption?.id) {
      props.handleClose();
      props.handleApplyGlobalTemplate(selectedOption.id);
    }
  }

  function handleAutocompleteChange(
    _: ChangeEvent<{ value?: unknown }>,
    newOption: TestSuiteOption | null,
  ): void {
    setSelectedOption(newOption);
    if (newOption) {
      popupState.open(autocompleteRef.current);
    } else {
      popupState.close();
    }
  }

  useEffect(() => {
    if (parentOptions && props.availableTestSuitesDetected) {
      props.availableTestSuitesDetected(parentOptions.length);
    }
  }, [parentOptions, props]);

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        height={60.5}
        data-testid="apply-global-template-loading"
        style={{ borderRadius: 6 }}
      />
    );
  }

  if (error) {
    return (
      <Alert severity="error" className={classes.error}>
        An error occurred. Please refresh the page and try again.
      </Alert>
    );
  }

  return (
    <Box data-testid="apply-global-template">
      {parentOptions?.length ? (
        <>
          <StyledInputLabel htmlFor="add-parent-autocomplete">
            Choose test suite
          </StyledInputLabel>
          <Autocomplete
            classes={{
              groupLabel: classes.groupLabel,
              groupUl: classes.groupUl,
              paper: classes.paper,
            }}
            openOnFocus
            popupIcon={<ExpandMoreRoundedIcon />}
            ref={autocompleteRef}
            value={selectedOption}
            onChange={handleAutocompleteChange}
            id="add-parent-autocomplete"
            data-testid="add-parent-autocomplete"
            data-pendo="auto-test-suite-edit-template-settings-link-apply-global-template-select"
            options={parentOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            filterOptions={filterOptions}
            groupBy={(option) => option.group}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Choose from existing global templates or create global template..."
              />
            )}
          />
        </>
      ) : (
        <Alert severity="info" data-testid="no-testsuites-available-info">
          All your test suites are currently linked to templates or no test
          suites are available.
        </Alert>
      )}

      <ConfirmationDialogue
        title="Apply a global template to this test suite"
        description="This will replace any configurations in steps 2 (Crawl settings) and 3 (Tests) of this test suite."
        icon={<ConfirmationDialogInfoIcon className={classes.icon} />}
        PopoverProps={bindPopover(popupState)}
      >
        <Button
          onClick={popupState.close}
          variant="outlined"
          data-testid="popover-dialog-button-cancel"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmationClick}
          variant="contained"
          color="primary"
          data-testid="popover-dialog-button-ok"
          size="large"
        >
          Apply global template
        </Button>
      </ConfirmationDialogue>
    </Box>
  );
}
