import { generatePath } from "react-router-dom";
import queryString from "query-string";
import { getApiAccountId } from "../session/helpers/getApiAccountId";
const domain = process.env.REACT_APP_AUTOMATE_APP_URL &&
    removeTrailingSlash(process.env.REACT_APP_AUTOMATE_APP_URL);
function removeTrailingSlash(value) {
    if (value.endsWith("/")) {
        return value.substring(0, value.length - 1);
    }
    return value;
}
export const ProtectRoutes = {
    Domain: domain || "",
    Dashboard: {
        ROUTE: "/accounts/:accountId/dashboard",
        getUrl: ({ accountId }) => {
            const url = generatePath(ProtectRoutes.Dashboard.ROUTE, {
                accountId: getApiAccountId(accountId),
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
    TestSuites: {
        ROUTE: "/accounts/:accountId/test-suites",
        getUrl: ({ accountId }) => {
            const url = generatePath(ProtectRoutes.TestSuites.ROUTE, {
                accountId: getApiAccountId(accountId),
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
    FrontendScheduler: {
        ROUTE: "/accounts/:accountId/scheduler/:schedulerId?",
        getUrl: ({ accountId, schedulerId, }) => {
            const url = generatePath(ProtectRoutes.FrontendScheduler.ROUTE, {
                accountId: getApiAccountId(accountId),
                schedulerId,
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
    ConnectedApps: {
        ROUTE: "/accounts/:accountId/connected-apps",
        getUrl: ({ accountId }) => {
            const url = generatePath(ProtectRoutes.ConnectedApps.ROUTE, {
                accountId: getApiAccountId(accountId),
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
    GettingStarted: {
        ROUTE: "/accounts/:accountId/getting-started",
        getUrl: ({ accountId }) => {
            const url = generatePath(ProtectRoutes.GettingStarted.ROUTE, {
                accountId,
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
};
