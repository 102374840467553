import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function DeleteIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 4C8.5 2.89543 9.39543 2 10.5 2H14.5C15.6046 2 16.5 2.89543 16.5 4V6H19.4897C19.4959 5.99994 19.5021 5.99994 19.5083 6H20.5C21.0523 6 21.5 6.44772 21.5 7C21.5 7.55228 21.0523 8 20.5 8H20.4311L19.6301 19.2137C19.518 20.7837 18.2117 22 16.6378 22H8.36224C6.78832 22 5.482 20.7837 5.36986 19.2137L4.56888 8H4.5C3.94772 8 3.5 7.55228 3.5 7C3.5 6.44772 3.94772 6 4.5 6H5.49174C5.49795 5.99994 5.50414 5.99994 5.51032 6H8.5V4ZM6.57398 8L7.36478 19.0712C7.40216 19.5946 7.8376 20 8.36224 20H16.6378C17.1624 20 17.5978 19.5946 17.6352 19.0712L18.426 8H6.57398ZM14.5 6H10.5V4H14.5V6ZM10.5 10C11.0523 10 11.5 10.4477 11.5 11V17C11.5 17.5523 11.0523 18 10.5 18C9.94772 18 9.5 17.5523 9.5 17V11C9.5 10.4477 9.94772 10 10.5 10ZM14.5 10C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18C13.9477 18 13.5 17.5523 13.5 17V11C13.5 10.4477 13.9477 10 14.5 10Z"
      />
    </SvgIcon>
  );
}
