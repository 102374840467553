import React from "react";
import {
  BuildTestStatus,
  ExtendedBuildStatus,
} from "../../../_common/interfaces/BuildTestStatus";
import { Box, Typography, Tooltip, makeStyles } from "@material-ui/core";
import { BuildStatus } from "../../../graphql";
import clsx from "clsx";
import { SchedulerTestSuiteItemInfo } from "./SchedulerTestSuiteItemInfo";
import { createLastRunText } from "../../../_common/utils/createLastRunText/createLastRunText";
import { TestResults } from "../../../_common/components/TestResults/TestResults";
import { Chip, ChipColor } from "@lumar/shared";
import { SchedulerDetailItemScheduledInfo } from "./SchedulerDetailItemScheduledInfo";
import { TestSuiteListItemWrapper } from "./detail-view/TestSuiteListItemWrapper";

export interface BasicSchedulerTestSuiteListItemProps {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  lastRun: string;
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  name: string;
  url: string;
  editable: boolean;
  testSuiteUpdatedAt: string;
  testSuiteClonedAt: string | null;
}

const useStyles = makeStyles((theme) => ({
  vericallyCentered: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  testSuiteInfoWrapper: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  testResultsContainer: {
    width: 216,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  crawlingStatusContainer: {
    width: 215,
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  lastRunText: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: 300,
    color: theme.palette.grey[600],
  },
  container: {
    width: "calc(100% - 80px - 40px)",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

export function SchedulerTestSuiteListItem(
  props: BasicSchedulerTestSuiteListItemProps & {
    onActionMenuEvent: () => Promise<boolean>;
    nextRunAt: Date | null;
  },
): JSX.Element {
  const classes = useStyles({ status: props.status });

  const { isRunning, isFinished, isNotYetRun } = useBuildStatusInfo(
    props.status,
  );

  const chipProps = useChipProps(props.status, props.nextRunAt);

  return (
    <TestSuiteListItemWrapper
      id={props.id}
      buildId={props.buildId}
      onActionMenuEvent={props.onActionMenuEvent}
      status={props.status}
      nextRunAt={props.nextRunAt}
    >
      <Box className={classes.container}>
        <Box
          className={classes.wrapper}
          style={{ height: isNotYetRun ? "auto" : "100%" }}
        >
          <Box
            className={clsx(
              classes.vericallyCentered,
              classes.testSuiteInfoWrapper,
            )}
          >
            <SchedulerTestSuiteItemInfo name={props.name} url={props.url} />
          </Box>

          <Box
            className={clsx(
              classes.vericallyCentered,
              classes.testResultsContainer,
            )}
          >
            {isFinished ? (
              <TestResults
                id={props.id}
                buildId={props.buildId}
                totalTests={props.totalTests}
                warningTests={props.warningTests}
                failedTests={props.failedTests}
                passedTests={props.passedTests}
              />
            ) : (
              <Chip data-testid="status-message" {...chipProps} />
            )}
          </Box>

          <Box
            className={clsx(
              classes.vericallyCentered,
              classes.crawlingStatusContainer,
            )}
          >
            {!isRunning && !isNotYetRun ? (
              <Tooltip title="Last crawl date">
                <Typography
                  data-cy="scheduler-test-suite-list-item-last-run"
                  data-testid="scheduler-test-suite-list-item-last-run"
                  noWrap
                  className={classes.lastRunText}
                >
                  {createLastRunText(
                    props.status,
                    props.testSuiteUpdatedAt,
                    props.lastRun,
                  )}
                </Typography>
              </Tooltip>
            ) : null}
          </Box>
        </Box>

        {isNotYetRun ? (
          <Box marginTop="13px">
            <SchedulerDetailItemScheduledInfo
              testSuiteId={props.id}
              startTime={props.nextRunAt}
            />
          </Box>
        ) : null}
      </Box>
    </TestSuiteListItemWrapper>
  );
}

function useBuildStatusInfo(status: BuildTestStatus) {
  const isRunning = status === BuildStatus.Running;
  const isFinished =
    status === ExtendedBuildStatus.Pass ||
    status === ExtendedBuildStatus.Warning ||
    status === ExtendedBuildStatus.Fail;

  const isNotYetRun = status === ExtendedBuildStatus.NotYetRun;

  return {
    isRunning,
    isFinished,
    isNotYetRun,
  };
}

function useChipProps(
  status: BuildTestStatus,
  nextRunAt: Date | null,
): { color: ChipColor; label: string } {
  switch (status) {
    case BuildStatus.Aborted:
      return { color: "red", label: "Aborted" };
    case BuildStatus.Cancelled:
      return { color: "red", label: "Cancelled" };
    case BuildStatus.Queued:
      return { color: "pink", label: "Queued" };
    case BuildStatus.Running:
      return { color: "yellow", label: "Crawling the website" };
    case ExtendedBuildStatus.NotYetRun:
      return {
        color: "lightgrey",
        label: nextRunAt ? "Scheduled" : "Not yet run",
      };
    case ExtendedBuildStatus.Scheduled:
      return { color: "lightgrey", label: "Scheduled" };
    default:
      return { color: "lightgrey", label: status };
  }
}
