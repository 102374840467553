import { TestSuiteVariables } from "./TestSuiteVariables";
import { FormikProps } from "formik";
import { useEffect } from "react";
import { CustomDnsPair } from "./ConnectSiteForm";
import {
  GetTestSuiteQuery,
  LocationCode,
  TestSuiteLocationCode,
} from "../../../../graphql";
import { UserAgentSettings } from "./utils/types";

export interface ConnectSiteFormValues
  extends Omit<
    TestSuiteVariables,
    | "location"
    | "customDns"
    | "userAgentCode"
    | "userAgentString"
    | "userAgentToken"
    | "userAgentIsMobile"
    | "viewportWidth"
    | "viewportHeight"
  > {
  location: LocationCode | TestSuiteLocationCode;
  customDns: NonNullable<NonNullable<GetTestSuiteQuery["node"]>["customDns"]>;
  userAgent: UserAgentSettings;
}

interface ErrorEffectProps {
  formik: FormikProps<ConnectSiteFormValues>;
  onSubmissionError: () => void;
}

function doesCustomDnsContainEmptyObject(
  customDnsPairs: CustomDnsPair[],
): boolean {
  return !!customDnsPairs.find((pair) => !pair.hostname && !pair.ipAddress);
}

export function ErrorEffect(props: ErrorEffectProps) {
  const { submitCount, isValid, errors, values } = props.formik;
  const isAdvancedSettingsError =
    !isValid && !errors.name && !errors.primaryDomain;

  useEffect(() => {
    if (
      submitCount > 0 &&
      (isAdvancedSettingsError ||
        doesCustomDnsContainEmptyObject(values.customDns))
    ) {
      props.onSubmissionError();
    }
  }, [isAdvancedSettingsError, props, submitCount, values.customDns]);
  return null;
}
