import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Edit, Clock, EmptyState, useSession } from "@lumar/shared";
import { RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.yellow[500],
  },
}));

export function NoTestsuitesMessage(props: { onClick: () => void }) {
  const classes = useStyles();
  const session = useSession();

  return (
    <Box data-testid="no-test-suites-message">
      <EmptyState
        icon={<Clock className={classes.icon} />}
        description="Sorry, looks like you don’t have any scheduled test suites yet."
        actions={
          session.hasSufficientRole(RoleCode.Editor)
            ? [
                {
                  type: "button",
                  icon: <Edit />,
                  title: "Edit schedule",
                  onClick: props.onClick,
                },
              ]
            : []
        }
      />
    </Box>
  );
}
