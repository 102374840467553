import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { AppSwitcher } from "./app-switcher/AppSwitcher";
import { AppSidebar } from "./AppSidebar";
import { LayoutApiProvider, useLayoutApi } from "./LayoutApi";
import { appSwitcherWidth } from "./constants";
import { SkipToMainContent } from "../skip-to-main-content/SkipToMainContent";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
    },
    main: {
        flexGrow: 1,
        maxWidth: "100%",
        minWidth: 0, // Note: min-width has to be set to 0 so overflow-x can work
        padding: theme.spacing(0, 2.5, 2.5, 2.5),
    },
}));
/**
 * Reusable app layout that consists of App Switcher, optional sidebar and main content.
 * @param props
 * @returns
 */
export function AppLayout(props) {
    return (_jsx(LayoutApiProvider, { sidebarContentIncluded: !!props.sidebarContentComponent, children: _jsx(AppLayoutInner, Object.assign({}, props)) }));
}
function AppLayoutInner(props) {
    var _a;
    const { mainContentMarginLeft } = useLayoutApi();
    const classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(SkipToMainContent, {}), _jsxs("div", { className: classes.container, children: [_jsx(AppSwitcher, { app: props.app, pendoPrefix: props.pendoPrefix }), _jsx(AppSidebar, { contentComponent: props.sidebarContentComponent }), _jsx("main", { id: "main", className: clsx((_a = props.classes) === null || _a === void 0 ? void 0 : _a.main, classes.main), style: !!props.sidebarContentComponent
                            ? {
                                marginLeft: mainContentMarginLeft,
                                transition: "margin-left 0.2s",
                            }
                            : {
                                marginLeft: appSwitcherWidth,
                            }, children: props.children }, String(!!props.sidebarContentComponent))] })] }));
}
