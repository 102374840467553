import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
    skipToMainContent: {
        position: "absolute",
        left: "-9999px",
        zIndex: 999,
        padding: "1em",
        backgroundColor: "black",
        color: "white",
        opacity: 0,
        "&:focus": {
            left: "50%",
            transform: "translateX(-50%)",
            opacity: 1,
        },
    },
});
/**
 * Allows keyboard users to reach main content of the page after clicking tab for the first time.
 * It is important for accessibility.
 */
export function SkipToMainContent(props) {
    const { contentId = "#main" } = props;
    const classes = useStyles();
    const { t } = useTranslation("a11y");
    return (_jsx("a", { href: contentId, className: classes.skipToMainContent, children: t("skipToMainContent") }));
}
