import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Alert } from "@lumar/shared";
import { getTimeDescription } from "../utils/schedulerTime";

interface SchedulerDetailItemScheduledInfoProps {
  testSuiteId: string;
  startTime: Date | null;
}

export function SchedulerDetailItemScheduledInfo(
  props: SchedulerDetailItemScheduledInfoProps,
) {
  const accountId = useAccountRouteMatch();

  const editTestSuiteBuildURL = Routes.Builds.getUrl({
    accountId: accountId,
    testSuiteId: props.testSuiteId,
  });

  return (
    <Box data-testid="scheduler-detail-item-scheduled-info">
      <Alert size="small" severity={props.startTime ? "info" : "warning"}>
        {props.startTime ? (
          <>
            Test suite run has been scheduled to start on{" "}
            {getTimeDescription(props.startTime)}. For results please see{" "}
            <Link
              to={editTestSuiteBuildURL}
              data-testid="scheduler-detail-item-scheduled-info-link"
            >
              test suite runs page
            </Link>
            .
          </>
        ) : (
          <span data-testid="scheduler-detail-item-not-scheduled-message">
            <strong>Sorry</strong>, this test suite cannot run because it is set
            to run in the past with option ‘Does not repeat’. If you want to run
            this schedule, please edit the date and time.
          </span>
        )}
      </Alert>
    </Box>
  );
}
