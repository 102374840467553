import { createTheme } from "@material-ui/core";
import * as MuiLocales from "@material-ui/core/locale";
import { baseTheme } from "./themes/baseTheme";
import { merge } from "lodash";
import { ThemeMap } from "./themes";
/**
 * Creates Lumar's Mui theme object.
 *
 * @param locale - Material UI Locale to apply
 * @param disableAnimations - Allows disabling animations. It is useful when running automated tests since it is not possible to programatically await animations which can lead to failing a11y assertions.
 * @param themeId - theme to use
 * @returns
 */
export function createAppTheme(locale = MuiLocales.enUS, disableAnimations = false, themeId = "lumar") {
    const brandTheme = ThemeMap.get(themeId);
    const theme = merge(baseTheme, brandTheme);
    return createTheme(Object.assign(Object.assign({}, insertObjectIf(disableAnimations, {
        transitions: { create: () => "none" },
    })), theme), locale);
}
function insertObjectIf(condition, value) {
    if (condition) {
        return value;
    }
    return {};
}
