import { TestSuiteOption } from "../../../pages/FrontendScheduler/components/ScheduleSettings/scheduleSettingsValidationSchema";

export function removeDuplicatesFromOptions(
  testSuiteOptions: TestSuiteOption[],
): TestSuiteOption[] {
  const mostRecentlyAddedItem = testSuiteOptions[testSuiteOptions.length - 1];
  const duplicateArray = testSuiteOptions.filter(
    (option) => option.id === mostRecentlyAddedItem.id,
  );
  return duplicateArray.length > 1
    ? testSuiteOptions.filter(
        (option) => option.id !== mostRecentlyAddedItem.id,
      )
    : testSuiteOptions;
}
