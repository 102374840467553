import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";

interface IJiraAuthenticationCreateListItemProps {
  onClick: () => void;
}

export function JiraAuthenticationCreateListItem({
  onClick,
}: IJiraAuthenticationCreateListItemProps): JSX.Element {
  return (
    <ListItem
      button
      onClick={onClick}
      data-testid="jira-authentication-create-list-item"
    >
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText
        primary="Add authentication"
        secondary="Connect your Atlassian account"
      />
    </ListItem>
  );
}
