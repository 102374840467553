import { Button, Slider, useSession } from "@lumar/shared";
import { useTheme } from "@material-ui/core";
import {
  FastField,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React, { useEffect, useRef, useState } from "react";
import { textAreaValueToArray } from "../../../../_common/utils/array/coerce/textAreaValueToArray";
import {
  GetTestSuiteQuery,
  ModuleCode,
  useSuportedModuleCodeForCustomExtractionsQuery,
  useUpdateCrawlSettingsMutation,
} from "../../../../graphql";
import { ValidationErrors } from "../../../../validation/ValidationErrors";
import { useSaveChangesContext } from "../../SaveChangesProvider";
import { StepChangeHandler } from "../../UpdateTestSuite";
import { CollapseHeader } from "../CollapseHeader";
import { Section, SectionDivider, SectionLabel } from "../Section";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import { AccesibilitySettings } from "./AccesibilitySettings";
import { IncludeExcludeUrls } from "./advanced-settings/IncludeExcludeUrls";
import { JsRenderingSettings } from "./advanced-settings/JsRenderingSettings";
import { RobotsOverwrite } from "./advanced-settings/RobotsOverwrite";
import { StartUrls } from "./advanced-settings/StartUrls";
import { TestSettings } from "./advanced-settings/TestSettings";
import { CustomExtractions } from "./CustomExtractions";
import { EnableJsRendering } from "./EnableJsRendering";
import { UrlLimit } from "./UrlLimit";
import { UrlSource } from "./UrlSource";
import { getInitialValuesFromTestSuite } from "./utils/crawlSettingsFormUtils";
import { crawlSettingsFormValidation } from "./utils/crawlSettingsFormValidation";
import { getMutationVariablesFromFormikValues } from "./utils/getMutationVariablesFromFormikValues";
import { handleSeverity } from "./utils/handleSeverity";
import { InitialCrawlSettingsValues } from "./utils/InitialCrawlSettingsValues";
import { isValidUrlArray } from "./utils/isValidUrlArray";

interface CrawlSettingsFormProps {
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  project: NonNullable<GetTestSuiteQuery["getProject"]>;
  data: GetTestSuiteQuery;
  onSubmit: StepChangeHandler;
  refetchData: () => void;
}

export function CrawlSettingsForm(props: CrawlSettingsFormProps): JSX.Element {
  const theme = useTheme();
  const { hasFeatureFlagEnabled } = useSession();

  const { testSuite, data } = props;

  const [maximumCrawlRate, setCrawlRate] = useState(
    Math.min(testSuite.maximumCrawlRate, testSuite.account.maxCrawlRate),
  );

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const accessibilityMetricContainer =
    data?.accessibilityModule?.customMetricContainers?.find(
      (e) => e.name === "AccessibilityIssues",
    );
  const accessibilityContainerId = accessibilityMetricContainer?.id;

  const [initialValues, setInitialValues] = useState(
    getInitialValuesFromTestSuite(data, undefined, testSuite.moduleCode),
  );

  const formikRef = useRef<FormikProps<InitialCrawlSettingsValues>>(null);
  const saveChangesContext = useSaveChangesContext();

  const [updateCrawlSettings, { loading }] = useUpdateCrawlSettingsMutation();
  const { data: customExtractionData, loading: loadindCustomExtractionData } =
    useSuportedModuleCodeForCustomExtractionsQuery();

  function handleSliderChange(
    _: React.ChangeEvent<{ value?: unknown }>,
    newValue: number | number[],
  ): void {
    const sliderValue = typeof newValue === "number" ? newValue : newValue[0];
    setCrawlRate(sliderValue);
  }

  const accessibilityAlreadyLinked = Boolean(
    data?.getProject?.customMetricContainerProjects.nodes.find(
      (container) =>
        container.customMetricContainer.id === accessibilityContainerId,
    ),
  );

  async function handleSave() {
    if (formikRef.current) {
      const { values } = formikRef.current;
      const mutationVariables = getMutationVariablesFromFormikValues(
        values,
        maximumCrawlRate,
        testSuite.id,
        accessibilityContainerId,
        accessibilityAlreadyLinked,
      );
      await updateCrawlSettings({
        variables: mutationVariables,
      });
    }
  }

  async function handleSubmit(
    values: InitialCrawlSettingsValues,
    actions: FormikHelpers<InitialCrawlSettingsValues>,
  ): Promise<void> {
    const isValid = await isValidUrlArray(
      textAreaValueToArray(values.rendererJsUrls),
    );

    if (!isValid) {
      actions.setFieldError("rendererJsUrls", ValidationErrors.RenderJsUrls);
      actions.setSubmitting(false);
      return;
    }
    await updateCrawlSettings({
      variables: getMutationVariablesFromFormikValues(
        values,
        maximumCrawlRate,
        testSuite.id,
        accessibilityContainerId,
        accessibilityAlreadyLinked,
      ),
    });
    props.refetchData();
    props.onSubmit();
  }

  useEffect(() => {
    setInitialValues(
      getInitialValuesFromTestSuite(
        data,
        accessibilityContainerId,
        data.node?.moduleCode ?? ModuleCode.Seo,
      ),
    );
    setCrawlRate(
      Math.min(testSuite.maximumCrawlRate, testSuite.account.maxCrawlRate),
    );
  }, [data, testSuite, accessibilityContainerId]);

  useEffect(() => {
    if (!testSuite.parent) {
      saveChangesContext.registerCallback(handleSave, "step2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maximumCrawlRate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={crawlSettingsFormValidation}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formikRef}
    >
      <Form>
        <div style={{ paddingBottom: theme.spacing(4) }}>
          <SectionDivider />
          <Section>
            <UrlSource
              testSuiteId={testSuite.id}
              coreUIUrl={testSuite.coreUIUrl}
            />
          </Section>

          <SectionDivider />

          {testSuite.moduleCode === ModuleCode.Accessibility ? (
            hasFeatureFlagEnabled("wcag-level-option") ? (
              <>
                <Section>
                  <AccesibilitySettings />
                </Section>
                <SectionDivider />
              </>
            ) : (
              <></>
            )
          ) : (
            <>
              <Section>
                <EnableJsRendering />
              </Section>
              <SectionDivider />
            </>
          )}

          <Section>
            <div style={{ maxWidth: "400" }}>
              <SectionLabel
                htmlFor="crawl-rate"
                data-cy="max-crawl-speed-label"
              >
                Maximum Crawl Speed
              </SectionLabel>
              <Slider
                severity={handleSeverity(maximumCrawlRate)}
                leftLabel={() => "Speed"}
                rightLabel={() => `${maximumCrawlRate} URL/s`}
                id="crawl-rate"
                min={1}
                max={testSuite.account.maxCrawlRate}
                step={1}
                value={maximumCrawlRate}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                data-cy="crawl-speed-slider"
                data-testid="crawl-speed-slider"
                data-pendo="auto-test-suite-edit-choose-crawl-settings-max-crawl-speed"
                style={{
                  marginBottom: 0,
                }}
                getAriaValueText={(n) =>
                  `Speed: ${n} URL per second. Severity ${
                    handleSeverity(maximumCrawlRate) ?? "low"
                  }`
                }
                aria-label="Maximum Crawl Speed"
              />
            </div>
          </Section>

          <SectionDivider />

          <Section>
            <UrlLimit />
          </Section>

          <SectionDivider />

          {(
            customExtractionData?.getReportTemplates?.nodes[0]
              ?.supportedModules ?? [testSuite.moduleCode]
          ).includes(testSuite.moduleCode) && (
            <Section>
              <FieldArray
                name="customExtractions"
                // eslint-disable-next-line
                component={CustomExtractions as any}
              />
            </Section>
          )}
          <CollapseHeader
            pendoId="auto-edit-add-test-suite-advanced-settings"
            isOpen={isCollapseOpen}
            onClick={() => {
              setIsCollapseOpen(!isCollapseOpen);
            }}
            name="Advanced Settings"
            testId="advanced-choose-crawl-settings-collapse-container"
            ButtonProps={{
              style: {
                marginLeft: 32,
                marginTop: 24,
                marginBottom: 8,
              },
            }}
          >
            <SectionDivider />
            <Section>
              <StartUrls />
            </Section>
            <SectionDivider />
            <Section>
              <IncludeExcludeUrls />
            </Section>
            <SectionDivider />
            <Section>
              <FastField name="robotsOverwrite" component={RobotsOverwrite} />
            </Section>
            <SectionDivider />
            <Section>
              <JsRenderingSettings
                hasAccessibility={
                  testSuite.moduleCode === ModuleCode.Accessibility
                }
              />
            </Section>
            <SectionDivider />
            <Section>
              <TestSettings />
            </Section>
          </CollapseHeader>
        </div>

        <StickyWrapper>
          <Button
            type="submit"
            data-testid="crawl-settings-form-save"
            data-cy="crawl-settings-form-save"
            data-pendo="auto-testsuite-edit-add-crawl-settings-save-step"
            variant="contained"
            color="primary"
            loading={loading || loadindCustomExtractionData}
          >
            Save
          </Button>
        </StickyWrapper>
      </Form>
    </Formik>
  );
}
