import React from "react";
import {
  FormControlLabel,
  Radio,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";
import { SectionLabel } from "../Section";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(1),
  },
}));

export const EnableJsRendering = React.memo(EnableJsRenderingInner);

function EnableJsRenderingInner(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div style={{ marginBottom: theme.spacing(2) }}>
        <SectionLabel htmlFor="js-rendering">JavaScript Rendering</SectionLabel>
      </div>
      <Field
        name="useRenderer"
        id="js-rendering"
        label="JS Rendering"
        component={RadioGroup}
      >
        <FormControlLabel
          control={<Radio />}
          classes={classes}
          style={{ marginLeft: 0, marginBottom: 16 }}
          value="false"
          label="Not required"
          key="not-required"
          data-cy="js-not-required-radio"
        />
        <FormControlLabel
          control={<Radio />}
          classes={classes}
          style={{ marginLeft: 0 }}
          value="true"
          label="Enable JavaScript Rendering"
          key="enable-js-rendering"
          data-cy="enable-js-rendering-radio"
        />
      </Field>
    </>
  );
}
