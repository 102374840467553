import { getRawAccountId } from "@lumar/shared";
import queryString, { StringifyOptions, UrlObject } from "query-string";
import { generatePath } from "react-router-dom";

export interface RequiredParams {
  accountId: string;
}

const domain = process.env.REACT_APP_ACCOUNTS_APP_URL;

function createAccountsURL(
  path: string,
  params: Record<string, string>,
  options?: StringifyOptions,
): string {
  const object: UrlObject = {
    url: generatePath(path, params),
    query: {
      referral: window.location.href,
    },
  };

  return `${domain}${queryString.stringifyUrl(object, options)}`;
}

export const AccountsRoutes = {
  Root: {
    ROUTE: domain,
    getUrl: () => {
      return AccountsRoutes.Root.ROUTE;
    },
  },
  Account: {
    ROUTE: "/accounts/:accountId",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.Account.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
  UserSettings: {
    ROUTE: "/accounts/:accountId/user",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.UserSettings.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
  ConnectedApps: {
    ROUTE: "/accounts/:accountId/connected-apps",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.ConnectedApps.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
  ApiAccess: {
    ROUTE: "/accounts/:accountId/api-access",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.ApiAccess.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
  Team: {
    ROUTE: "/accounts/:accountId/team",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.Team.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
  Subscription: {
    ROUTE: "/accounts/:accountId/subscription",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.Subscription.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
  CreditUsage: {
    ROUTE: "/accounts/:accountId/credit-usage",
    getUrl: ({ accountId }: RequiredParams) => {
      return createAccountsURL(AccountsRoutes.CreditUsage.ROUTE, {
        accountId: getRawAccountId(accountId),
      });
    },
  },
};
