import { getBuildTestStatus } from "../../../_common/utils/getBuildTestStatus/getBuildTestStatus";
import { getBuildTestProcessedValue } from "../../../_common/utils/getBuildTestProcessedValue/getBuildTestProcessedValue";
import { ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";
import { TestSuiteListItemProps } from "../../../pages/TestSuiteList/test-suite-list/TestSuiteListItem";
import { BasicSchedulerTestSuiteListItemProps } from "../../../pages/FrontendScheduler/components/SchedulerTestSuiteListItem";
import {
  GetNextTestSuitesQuery,
  GetPreviousTestSuitesQuery,
} from "../../../graphql";
import { TEMP_Schedule } from "../../../pages/FrontendScheduler/components/SchedulerDetailView";

type TestSuite =
  | NonNullable<
      NonNullable<
        NonNullable<GetNextTestSuitesQuery["getAccount"]>["testSuites"]
      >["edges"][0]["node"]
    >
  | NonNullable<
      NonNullable<
        NonNullable<GetPreviousTestSuitesQuery["getAccount"]>["testSuites"]
      >["edges"][0]["node"]
    >;

export type BuildTest =
  | NonNullable<
      NonNullable<
        NonNullable<GetNextTestSuitesQuery["getAccount"]>["testSuites"]
      >["edges"][0]["node"]["builds"]["nodes"][0]
    >
  | NonNullable<
      NonNullable<
        NonNullable<GetPreviousTestSuitesQuery["getAccount"]>["testSuites"]
      >["edges"][0]["node"]["builds"]["nodes"][0]
    >;

function getTestCount(testCount: number | null): number {
  return testCount || 0;
}

export function getTestSuiteInfoWithLatestBuildTest(
  suite: TestSuite,
  buildTest: BuildTest,
): TestSuiteListItemProps {
  const status = getBuildTestStatus(buildTest);
  const failedTests = getTestCount(buildTest.failedTestCount);
  const warningTests = getTestCount(buildTest.warnedTestCount);
  const passedTests = getTestCount(buildTest.passedTestCount);
  const totalTests = failedTests + passedTests + warningTests;
  return {
    id: suite.id,
    name: suite.name,
    url: suite.primaryDomain,
    status,
    editable: true,
    lastRun: getBuildTestProcessedValue(buildTest.finishedAt, status),
    totalTests: getBuildTestProcessedValue(totalTests, status),
    failedTests: getBuildTestProcessedValue(failedTests, status),
    warningTests: getBuildTestProcessedValue(warningTests, status),
    passedTests: getBuildTestProcessedValue(passedTests, status),
    testSuiteClonedAt: suite.clonedAt,
    testSuiteUpdatedAt: suite.updatedAt,
    buildId: suite.builds?.nodes[0]?.id || buildTest.id,
    testSuiteJiraIntegrationId: suite.testSuiteJiraIntegration?.id ?? null,
    createdAt: suite.createdAt,
  };
}

export function getSchedulerTestSuiteInfoWithLatestBuildTest(
  suite: TestSuite,
  buildTest: BuildTest,
): TestSuiteListItemProps {
  const status = getBuildTestStatus(buildTest);
  const failedTests = getTestCount(buildTest.failedTestCount);
  const warningTests = getTestCount(buildTest.warnedTestCount);
  const passedTests = getTestCount(buildTest.passedTestCount);
  const totalTests = failedTests + passedTests + warningTests;
  return {
    id: suite.id,
    name: suite.name,
    url: suite.primaryDomain,
    status,
    editable: true,
    lastRun: getBuildTestProcessedValue(buildTest.finishedAt, status),
    totalTests: getBuildTestProcessedValue(totalTests, status),
    failedTests: getBuildTestProcessedValue(failedTests, status),
    warningTests: getBuildTestProcessedValue(warningTests, status),
    passedTests: getBuildTestProcessedValue(passedTests, status),
    testSuiteClonedAt: suite.clonedAt,
    testSuiteUpdatedAt: suite.updatedAt,
    buildId: buildTest.id,
    testSuiteJiraIntegrationId: suite.testSuiteJiraIntegration?.id ?? null,
    createdAt: suite.createdAt,
  };
}

export function getTestSuiteInfoWithoutBuildTest(
  suite: TestSuite,
): TestSuiteListItemProps {
  return {
    id: suite.id,
    name: suite.name,
    url: suite.primaryDomain,
    lastRun: "Not yet run",
    totalTests: "Not yet run",
    failedTests: "Not yet run",
    warningTests: "Not yet run",
    passedTests: "Not yet run",
    status: ExtendedBuildStatus.NotYetRun,
    editable: true,
    testSuiteClonedAt: suite.clonedAt,
    testSuiteUpdatedAt: suite.updatedAt,
    buildId: null,
    testSuiteJiraIntegrationId: suite.testSuiteJiraIntegration?.id ?? null,
    createdAt: suite.createdAt,
  };
}

function getLatestTestBuild(
  suite: TestSuite,
):
  | false
  | NonNullable<
      NonNullable<
        NonNullable<GetNextTestSuitesQuery["getAccount"]>["testSuites"]
      >["edges"][0]["node"]["builds"]["nodes"][0]
    > {
  const areBuildsPresent = Array.isArray(suite?.builds?.nodes);

  const sortedBuilds =
    // eslint-disable-next-line fp/no-mutating-methods
    suite?.builds?.nodes?.sort((item1: BuildTest, item2: BuildTest) =>
      item1.updatedAt >= item2.updatedAt ? -1 : 1,
    );
  return areBuildsPresent && sortedBuilds[0];
}

export function testSuiteItemInformationFactory(
  suite: TestSuite,
): TestSuiteListItemProps {
  const latestBuildTest = getLatestTestBuild(suite);
  if (latestBuildTest) {
    return getTestSuiteInfoWithLatestBuildTest(suite, latestBuildTest);
  }
  return getTestSuiteInfoWithoutBuildTest(suite);
}

// FIXME: Type casts hide a problem with types used in this file.
// Scheduler doesn't care about fields used by test suite list.
// The code works (because it is using only first-level properties that will be returned as undefined)
// but could be improved.
export function schedulerSuiteItemInformationFactory(
  node: TEMP_Schedule["buildScheduleTestSuites"]["nodes"][0],
): BasicSchedulerTestSuiteListItemProps {
  const latestBuildTest = node.lastRunBuild;
  if (latestBuildTest) {
    return getSchedulerTestSuiteInfoWithLatestBuildTest(
      node.testSuite as TestSuite,
      latestBuildTest as unknown as BuildTest,
    );
  }
  return getTestSuiteInfoWithoutBuildTest(node.testSuite as TestSuite);
}
