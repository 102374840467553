var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../auth";
import { createSession } from "./createSession";
import { usePreferredAccountId } from "./legacy/preferredAccount";
import { useComposedSessionQuery } from "./legacy/useComposedSessionQuery";
import { SessionError } from "./SessionError";
import { TermsAndConditions } from "./TermsAndConditions";
import { useAdminModeState } from "./useAdminModeState";
import { useTimeZoneState } from "./useTimeZoneState";
import { useRollbarPerson } from "@rollbar/react";
import { LoadingScreen } from "../components";
import { getRawUserId } from "./helpers/getRawUserId";
export const SessionContext = createContext(undefined);
export const SessionProvider = ({ children, publicRoutes = [], onSessionError, }) => {
    const { getToken, login, logout } = useAuth();
    const router = useLocation();
    const preferredAccountId = usePreferredAccountId();
    const ignoreSession = useMemo(() => {
        return publicRoutes.some((route) => router.pathname.startsWith(route));
    }, [router.pathname, publicRoutes]);
    const { data, loading, error } = useComposedSessionQuery(getToken, preferredAccountId, ignoreSession);
    const [adminModeEnabled, setAdminModeEnabled] = useAdminModeState(data === null || data === void 0 ? void 0 : data.sessionQuery);
    const [timeZone, setTimeZone] = useTimeZoneState(data === null || data === void 0 ? void 0 : data.sessionQuery);
    const sessionContext = useMemo(() => {
        if (data) {
            return createSession({
                data,
                preferredAccountId,
                adminModeEnabled,
                setAdminModeEnabled,
                timeZone,
                setTimeZone,
                invalidateSession: () => __awaiter(void 0, void 0, void 0, function* () {
                    const token = yield getToken();
                    if ((token === null || token === void 0 ? void 0 : token.type) === "auth0") {
                        yield logout();
                    }
                }),
            });
        }
    }, [
        data,
        preferredAccountId,
        adminModeEnabled,
        setAdminModeEnabled,
        timeZone,
        setTimeZone,
        logout,
        getToken,
    ]);
    useEffect(() => {
        if (!loading && !sessionContext && !ignoreSession) {
            login();
        }
    }, [login, loading, sessionContext, ignoreSession]);
    useRollbarPerson(data
        ? {
            id: getRawUserId(data.sessionQuery.me.id),
        }
        : {});
    if (ignoreSession) {
        return _jsx(_Fragment, { children: children });
    }
    if (loading || !sessionContext) {
        return _jsx(LoadingScreen, {});
    }
    if (error &&
        error.networkError &&
        "statusCode" in error.networkError &&
        error.networkError.statusCode !== 401) {
        return (_jsx(SessionError, { message: onSessionError.message, actions: onSessionError.actions }));
    }
    return (_jsxs(SessionContext.Provider, { value: sessionContext, children: [data ? (_jsx(TermsAndConditions, { accepted: data.sessionQuery.me.termsAgreed, logout: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield sessionContext.invalidateSession();
                }) })) : null, children] }));
};
export const useSession = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw Error("'SessionContext' not found in the tree. Make sure 'SessionProvider' is in your component tree.");
    }
    return Object.assign(Object.assign({}, context), { 
        /**
         * Invalidate user session in backend just before removing the token from the cookie
         * @author Alex Sánchez
         */
        invalidateSession: context.invalidateSession });
};
