import React from "react";
export function useHelpToggle() {
    const beaconInitRef = React.useRef(false);
    const setStateRef = React.useRef();
    React.useEffect(() => {
        const openHandler = () => { var _a; return (_a = setStateRef.current) === null || _a === void 0 ? void 0 : _a.call(setStateRef, true); };
        const closeHandler = () => { var _a; return (_a = setStateRef.current) === null || _a === void 0 ? void 0 : _a.call(setStateRef, false); };
        window.addEventListener("onHelpOpen", openHandler);
        window.addEventListener("onHelpClose", closeHandler);
        return () => {
            if (window.Beacon) {
                window.Beacon("close");
            }
            else {
                window.dispatchEvent(new CustomEvent("onHelpClose", {}));
            }
            window.removeEventListener("onHelpOpen", openHandler);
            window.removeEventListener("onHelpClose", closeHandler);
        };
    }, []);
    return React.useCallback(({ state, setState }) => {
        setStateRef.current = setState;
        toggleHelp(state, setState, beaconInitRef);
    }, [beaconInitRef]);
}
function toggleHelp(state, setState, beaconInitRef) {
    var _a, _b;
    if (!beaconInitRef.current) {
        beaconInitRef.current = true;
        (_a = window.Beacon) === null || _a === void 0 ? void 0 : _a.call(window, "on", "open", () => setState(true));
        (_b = window.Beacon) === null || _b === void 0 ? void 0 : _b.call(window, "on", "close", () => setState(false));
    }
    if (window.Beacon) {
        window.Beacon("toggle");
    }
    else {
        if (state) {
            window.dispatchEvent(new CustomEvent("onHelpClose", {}));
        }
        else {
            window.dispatchEvent(new CustomEvent("onHelpOpen", {}));
        }
    }
}
