import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { buildTestItemInfoFactory } from "./factories/buildTestItemInfoFactory";
import { BuildTestListItem } from "./components/BuildTestListItem";
import { Skeleton } from "@material-ui/lab";
import { Alert, Pagination } from "@lumar/shared";

import { TestSuiteItemLinks } from "../TestSuiteList/test-suite-list/TestSuiteItemLinks";
import notFoundGraphic from "../../images/no-permissions-graphic.svg";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { makeStyles, Grid, Typography, Box, Fade } from "@material-ui/core";
import { usePagination } from "../TestSuiteList/utils/usePagination";
import { BuildStatus, useGetBuildTestsQuery } from "../../graphql";
export type BuildTestListProps = RouteComponentProps<{ testSuiteId: string }>;

const useStyles = makeStyles((theme) => ({
  editButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.grey[900],
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(35),
  },
  subtitle: {
    paddingTop: "6px",
    margin: "2px 0",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 600,
    color: theme.palette.grey[600],
  },
  url: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.grey[600],
  },
  settings: {
    paddingTop: "4px",
    margin: "2px 0",
    fontSize: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(18),
    display: "flex",
    alignItems: "center",
  },
  settingsIcon: {
    color: theme.palette.primary.main,
    margin: 0,
    lineHeight: theme.typography.pxToRem(18),
    fontSize: theme.typography.pxToRem(12),
    display: "flex",
  },
  imgWrapper: {
    maxWidth: 160,
  },
  img: {
    width: "100%",
  },
  wrapper: {
    border: "1px dashed rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 505,
    margin: "0 auto",
    padding: theme.spacing(8),
  },
  header: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    textAlign: "center",
    color: theme.palette.navy[300],
  },
  headerGrid: {
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

// eslint-disable-next-line complexity, max-lines-per-function
const itemsPerPage = 20;
const BuildTestListContent = withRouter(function BuildTestListContent(
  props: BuildTestListProps,
): JSX.Element {
  const testSuiteId = props.match.params.testSuiteId;
  const { pagination, handleNextPage, handlePreviousPage } = usePagination();
  const directionForward = pagination.direction === "forward";
  const { loading, data, error } = useGetBuildTestsQuery({
    variables: {
      testSuiteId,
      first: directionForward ? itemsPerPage : null,
      cursorUp: directionForward ? pagination.cursor || null : null,
      last: directionForward ? null : itemsPerPage,
      cursorDown: directionForward ? null : pagination.cursor || null,
    },
    fetchPolicy: "cache-and-network",
  });
  const classes = useStyles();

  if (loading) {
    return (
      <>
        <Grid
          container
          direction="row"
          data-testid="build-test-list-loader"
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerGrid}
        >
          <Grid item xs={10}>
            <Skeleton variant="text" height={40} width={200} />
          </Grid>
        </Grid>
        <Skeleton variant="rect" height={100} width="100%" />
      </>
    );
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="build-test-list-error">
        An error ocurred while loading test suites.
      </Alert>
    );
  }

  if (!data || !data?.node?.builds) {
    return (
      <Alert severity="warning" data-testid="build-test-list-warning">
        No data.
      </Alert>
    );
  }

  const testSuite = data.node;
  const buildTests = testSuite.builds.nodes.map((build) =>
    buildTestItemInfoFactory(build, testSuite.id),
  );

  const currentPage = pagination.currentPage;

  const totalItems = testSuite.builds?.totalCount;

  const getTotalPages = (): number =>
    buildTests.length > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;

  const isBuildCurrentlyRunning = !!buildTests.filter(
    (build) => build.status === BuildStatus.Running,
  ).length;

  const cursors = {
    endCursor: testSuite.builds?.pageInfo?.endCursor,
    startCursor: testSuite.builds?.pageInfo?.startCursor,
  };

  return (
    <Fade in>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerGrid}
        >
          <Grid item xs={10}>
            <Typography className={classes.title} variant="h1">
              Test suite runs ({totalItems})
            </Typography>
            <Typography
              noWrap
              title={testSuite.name}
              className={classes.subtitle}
            >
              {testSuite.name}
            </Typography>
            <Typography className={classes.url}>
              {testSuite.primaryDomain}
            </Typography>
            {!isBuildCurrentlyRunning && (
              <Box className={classes.settings}>
                <TestSuiteItemLinks
                  id={testSuiteId}
                  name={testSuite.name}
                  deletable={false}
                  editable={false}
                  clonable={false}
                  editTestSuite={true}
                  pendoIdSuffix="build-list"
                  className={classes.settingsIcon}
                  textLabel
                />
              </Box>
            )}
          </Grid>
        </Grid>

        <div>
          {buildTests.length === 0 && (
            <Box className={classes.wrapper} data-testid="build-test-list-info">
              <Box className={classes.imgWrapper}>
                <img
                  src={notFoundGraphic}
                  alt="404 not found graphic"
                  width={160}
                  height={131}
                  className={classes.img}
                  data-testid="not-found-graphic"
                />
              </Box>
              <Typography
                variant="h2"
                className={classes.header}
                color="textPrimary"
                data-testid="not-found-title"
              >
                No test suite runs found
              </Typography>
            </Box>
          )}
          {buildTests.map((buildTest) => {
            return (
              <span key={buildTest.id}>
                <BuildTestListItem {...buildTest} />
              </span>
            );
          })}
          <Box>
            {!loading && getTotalPages() > 1 ? (
              <Box pt={2}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={getTotalPages()}
                  onNext={() => {
                    handleNextPage(cursors);
                  }}
                  onPrevious={() => {
                    handlePreviousPage(cursors);
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </div>
      </Box>
    </Fade>
  );
});

export function BuildTestList() {
  return (
    <div data-testid="build-test-list">
      <PageContainer>
        <BuildTestListContent />
      </PageContainer>
    </div>
  );
}
