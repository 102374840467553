var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useSetTimeZoneMutation } from "../graphql";
export function useTimeZoneState(session) {
    var _a;
    const [timeZoneInternal, setTimeZoneInternal] = React.useState(undefined);
    const [setTimeZoneMutation] = useSetTimeZoneMutation();
    const timeZone = (_a = timeZoneInternal !== null && timeZoneInternal !== void 0 ? timeZoneInternal : session === null || session === void 0 ? void 0 : session.me.timeZone) !== null && _a !== void 0 ? _a : defaultTimeZone;
    const isUsingSharedLink = Boolean(session === null || session === void 0 ? void 0 : session.sharelink);
    const setTimeZone = React.useCallback((timeZone) => __awaiter(this, void 0, void 0, function* () {
        setTimeZoneInternal(timeZone);
        if (!isUsingSharedLink)
            yield setTimeZoneMutation({ variables: { timeZone: timeZone } });
    }), [setTimeZoneMutation, setTimeZoneInternal, isUsingSharedLink]);
    return [timeZone, setTimeZone];
}
const defaultTimeZone = {};
