import { makeStyles, Popover } from "@material-ui/core";
import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { EditIcon } from "../../svg/EditIcon";
import { DeleteIcon } from "../../svg/DeleteIcon";
import { CopyIcon } from "../../svg/CopyIcon";
import { PlayIcon } from "../../svg/PlayIcon";
import { DotsHorizontalOutlined, ToggleIconButton } from "@lumar/shared";
import { DisableForViewer } from "../DisableForViewer/DisableForViewer";

const useStyles = makeStyles((theme) => ({
  popoverRoot: {},
  popoverPaper: {
    marginTop: theme.spacing(1),
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
  },
  listRoot: {
    width: 208,
    borderRadius: 6,
    padding: 8,
  },
  listItemRoot: {
    margin: "2px 0",
  },
  listItemGutters: {
    margin: "2px 0",
    padding: 0,
    height: 36,
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  listItemIcon: {
    minWidth: 0,
  },
  listItemText: {
    paddingLeft: "4px",
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.navy[500],
  },
  icon: {
    width: "20px",
    padding: "8px",
    color: theme.palette.grey[400],
  },
  deleteIcon: {
    padding: "8px",
    width: "20px",
    color: theme.palette.error.main,
  },
  runNowIcon: {
    padding: "8px",
    width: "20px",
    color: theme.palette.grey[400],
  },
}));

export enum ActionMenuIconType {
  DELETE = "delete",
  EDIT = "edit",
  CLONE = "clone",
  RUN_NOW = "run_now",
}

export interface ActionMenuItem {
  label: string;
  id: string;
  iconType: ActionMenuIconType;
  dataId: string;
  closeMenuOnClick: boolean;
}

export interface ActionsMenuProps {
  onActionMenuEvent: (
    action: string,
    e: React.MouseEvent<HTMLElement>,
  ) => Promise<boolean>;
  customActions: ActionMenuItem[];
  pendoId: string;
}

export function ActionsMenu(props: ActionsMenuProps): JSX.Element {
  const classes = useStyles();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  function openActionsMenu(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();
    setActionsMenuAnchorEl(event.currentTarget);
  }

  function closeActionsMenu(): void {
    setActionsMenuAnchorEl(null);
  }

  async function onActionSelection(
    e: React.MouseEvent<HTMLElement>,
    id: string,
  ): Promise<void> {
    const action = props.customActions.filter((item) => item.id === id)[0];
    if (action.closeMenuOnClick) closeActionsMenu();
    e.stopPropagation();
    if (props.onActionMenuEvent) {
      const close = await props.onActionMenuEvent(id, e);
      if (close) closeActionsMenu();
    } else {
      closeActionsMenu();
    }
  }

  function getIcon(actionType: ActionMenuIconType): JSX.Element {
    switch (actionType) {
      case ActionMenuIconType.DELETE:
        return <DeleteIcon className={classes.deleteIcon} />;
      case ActionMenuIconType.EDIT:
        return <EditIcon className={classes.icon} />;
      case ActionMenuIconType.CLONE:
        return <CopyIcon className={classes.icon} />;
      case ActionMenuIconType.RUN_NOW:
        return <PlayIcon className={classes.runNowIcon} />;
      default:
        return <CopyIcon className={classes.icon} />;
    }
  }

  return (
    <>
      <DisableForViewer tooltipTitle="Actions">
        <ToggleIconButton
          onClick={openActionsMenu}
          active={Boolean(actionsMenuAnchorEl)}
          data-testid="actions-menu-button"
          data-pendo={props.pendoId}
          data-cy="action-icon-button"
        >
          <DotsHorizontalOutlined />
        </ToggleIconButton>
      </DisableForViewer>

      <Popover
        classes={{ root: classes.popoverRoot, paper: classes.popoverPaper }}
        id="simple-menu"
        data-cy="simple-menu"
        anchorEl={actionsMenuAnchorEl}
        open={Boolean(actionsMenuAnchorEl)}
        onClose={(e: React.MouseEvent<{ value: string }>) => {
          e.stopPropagation();
          closeActionsMenu();
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List
          component="nav"
          data-testid="actions-menu-list"
          classes={{ root: classes.listRoot }}
        >
          {props.customActions?.map((action) => {
            return (
              <ListItem
                key={action.id}
                button
                data-testid={action.dataId}
                data-cy={action.dataId}
                data-pendo={`${props.pendoId}-${action.dataId}`}
                onClick={(e) => {
                  onActionSelection(e, action.id);
                }}
                classes={{
                  root: classes.listItemRoot,
                  gutters: classes.listItemGutters,
                }}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  {getIcon(action.iconType)}
                </ListItemIcon>
                <ListItemText
                  primary={action.label}
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}
