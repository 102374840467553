import React, { useEffect } from "react";
import { LinearProgress } from "@material-ui/core";

export interface BufferedLinearProgressProps {
  completed: number | undefined;
  buffered: number | undefined;
  totalCount: number | undefined;
  testId?: string;
}

export function BufferedLinearProgress(
  props: BufferedLinearProgressProps,
): JSX.Element {
  const [completed, setCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(0);

  const progress = React.useRef(() => {
    //
  });

  useEffect(() => {
    progress.current = () => {
      if (props.completed === props.totalCount) {
        setCompleted(0);
        setBuffer(0);
      } else {
        const completed =
          ((props.completed || 0) / (props.totalCount || 1)) * 100;
        const buffered =
          ((props.buffered || 0) / (props.totalCount || 1)) * 100;
        setCompleted(Math.min(Math.max(0, completed), 100));
        setBuffer(Math.min(completed + buffered, 100));
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (completed === 100) {
        clearInterval(timer);
      } else {
        progress.current();
      }
    }, 500);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [completed]);

  return (
    <LinearProgress
      variant="buffer"
      value={completed}
      valueBuffer={buffer}
      color="primary"
      data-testid={props.testId}
    />
  );
}
