import { BreadcrumbsProps, AppIds } from "@lumar/shared";
import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import { matchPath, Redirect, RouteProps, useLocation } from "react-router-dom";
import { TopNav } from "../../../routes/navigation/top-nav/TopNav";
import { AuthContext } from "../../contexts/Permissions/AuthContext";
import { AuthRouteAuthorised } from "./AuthRouteAuthorised";
import { AuthRouteNotAuthorised } from "./AuthRouteNotAuthorised";
import useResizeObserver from "use-resize-observer";
import { PageContainer } from "../PageContainer/PageContainer";
import { AutomateMarketingPage } from "./AutomateMarketingPage";
import { URL_PREFIX } from "../../routes/routes";
import { NotFound } from "../../../pages/NotFound/NotFound";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { useAutomatorSession } from "../../hooks/useAutomatorSession/useAutomatorSession";

function AutomateAccessGuard(props: {
  children: React.ReactNode;
}): JSX.Element {
  const session = useAutomatorSession();

  const hasAutomateAccess = session.hasApp(AppIds.protect);

  return hasAutomateAccess ? (
    <>{props.children}</>
  ) : (
    <PageContainer>
      <AutomateMarketingPage />
    </PageContainer>
  );
}

export interface AuthRouteProps extends RouteProps {
  // Note: this should be set to false for pages that require breadcrumbs. This is because the breadcrumbs need to take
  // up the full width of the page. The <PageContainer /> component can then be used to wrap any content below the breadcrumbs.
  includePageContainer?: boolean;
  breadcrumbsProps?: BreadcrumbsProps;
}

export function AuthRoute(props: AuthRouteProps): JSX.Element {
  const {
    includePageContainer = true,
    breadcrumbsProps,
    ...otherProps
  } = props;
  const authContext = useContext(AuthContext);

  const { ref: topNavRef, height = 52 } = useResizeObserver<HTMLDivElement>({
    box: "border-box",
  });

  if (!authContext.isAuthorised)
    return <AuthRouteNotAuthorised {...otherProps} />;

  return (
    <>
      <TopNav breadcrumbsProps={breadcrumbsProps} ref={topNavRef} />

      {/* Note: the margin is to offset the page contents so that it is not covered by the top nav */}
      <Box marginTop={`${height}px`}>
        <ErrorBoundary>
          <OldUrlRedirect>
            <AutomateAccessGuard>
              <AuthorizationGuard>
                <AuthRouteAuthorised
                  {...otherProps}
                  includePageContainer={includePageContainer}
                />
              </AuthorizationGuard>
            </AutomateAccessGuard>
          </OldUrlRedirect>
        </ErrorBoundary>
      </Box>
    </>
  );
}

/** Stops a user from accessing an account that they shouldn't */
function AuthorizationGuard(props: { children: React.ReactNode }): JSX.Element {
  const { allRelationships } = useAutomatorSession();
  const location = useLocation();

  const match = matchPath<{ accountId: string }>(location.pathname, {
    path: URL_PREFIX,
    exact: false,
  });

  const hasAccess = Boolean(
    allRelationships.find((x) => x.account.id === match?.params.accountId),
  );

  if (match && !hasAccess) {
    return <NotFound />;
  }

  return <>{props.children}</>;
}

function OldUrlRedirect(props: { children: React.ReactNode }): JSX.Element {
  const location = useLocation();

  const isOldPath = location.pathname.includes("/account/");

  if (isOldPath) {
    const newPath = location.pathname.replace("account", "accounts");
    return <Redirect to={newPath} />;
  }

  return <>{props.children}</>;
}
