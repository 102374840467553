import React, { useState, useEffect } from "react";
import { TestSuiteList } from "./test-suite-list/TestSuiteList";
import { useTestSuitesQuery } from "./graphql/useTestSuitesQuery";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Box, Fade, Paper } from "@material-ui/core";
import { getTotalPagesCount } from "./utils/getTotalPagesCount";
import { getPageInfo } from "./utils/getPageInfo";
import { Alert, EmptyState, Pagination } from "@lumar/shared";
import { PageHeader } from "../../_common/components/PageHeader/PageHeader";
import { useDebounce } from "../../_common/hooks/useDebounce/UseDebounce";
import { usePagination } from "./utils/usePagination";
import { SearchBar } from "./search-bar/SearchBar";
import { SearchTermContext } from "./SearchTermContext";
import { ErrorBoundary } from "../../_common/components/ErrorBoundary/ErrorBoundary";
import Lottie from "react-lottie-player";
import noDataAnimation from "../../_common/animations/searching-document.json";

const ITEMS_PER_PAGE = 20;

export function PaginatedTestSuiteList(): JSX.Element {
  const accountId = useAccountRouteMatch();

  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { pagination, resetToPageOne, handleNextPage, handlePreviousPage } =
    usePagination();

  const { loading, error, data, refetch } = useTestSuitesQuery(
    ITEMS_PER_PAGE,
    pagination.direction === "forward",
    pagination.cursor,
    debouncedSearchTerm || null,
  );

  const pageInfo = getPageInfo(data);
  const totalPages = getTotalPagesCount(data, ITEMS_PER_PAGE);
  const currentPage = pagination.currentPage;
  const totalTestSuites = data?.getAccount?.unfilteredTestSuites?.totalCount;

  const shouldShowNoTestsuitesView =
    !loading && !debouncedSearchTerm && totalTestSuites === 0;

  function handleSearchTermChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void {
    setSearchTerm(e.target.value);
  }

  function handleClear(): void {
    setSearchTerm("");
  }

  useEffect(() => {
    const hasNoTestSuites = data?.getAccount?.testSuites?.edges.length === 0;
    const isOnNonExistingPage = hasNoTestSuites && currentPage !== 1;

    if (isOnNonExistingPage) {
      resetToPageOne();
    }
  }, [data, currentPage, resetToPageOne]);

  useEffect(() => {
    resetToPageOne();
  }, [debouncedSearchTerm, accountId, resetToPageOne]);

  if (error) {
    return (
      <Alert severity="error" data-testid="testsuites-list-error">
        Error {error.message}
      </Alert>
    );
  }

  return (
    <>
      <PageHeader
        mainText={`Test suites${
          typeof totalTestSuites === "number" ? ` (${totalTestSuites})` : ""
        }`}
        includeAddTestSuite={true}
        pendoIdSuffix="test-suite-list"
      />
      <SearchBar
        value={searchTerm}
        onChange={handleSearchTermChange}
        onClear={handleClear}
        disabled={shouldShowNoTestsuitesView}
      />

      {shouldShowNoTestsuitesView ? (
        <Fade in>
          <Paper>
            <EmptyState
              width="100%"
              height={400}
              title="You currently have no test suites"
              description="Integrate Lumar Protect into your CI/CD Pipeline. Protect your site from SEO regressions and automate your SEO Testing."
              icon={
                <Lottie
                  loop
                  animationData={noDataAnimation}
                  play
                  style={{ width: "80px", margin: "auto" }}
                />
              }
            />
          </Paper>
        </Fade>
      ) : (
        <ErrorBoundary>
          <SearchTermContext.Provider
            value={{ searchTerm: debouncedSearchTerm }}
          >
            <TestSuiteList
              testSuites={data?.getAccount?.testSuites}
              isLoading={loading}
              totalTestSuiteCount={data?.getAccount?.testSuites?.totalCount}
              refetchData={refetch}
            />
          </SearchTermContext.Provider>
        </ErrorBoundary>
      )}

      {!loading && totalPages > 1 ? (
        <Box pt={2}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={() => {
              handleNextPage(pageInfo);
            }}
            onPrevious={() => {
              handlePreviousPage(pageInfo);
            }}
          />
        </Box>
      ) : null}
    </>
  );
}
