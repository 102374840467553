import { GetAvailableParentTestSuitesQuery } from "../../../../../graphql";
import { TestSuiteOption } from "../components/ApplyGlobalTemplate";

export enum TestSuiteGroup {
  Parent = "Existing global templates",
  NotLinked = "Create global template from test suite",
}

function getOptionsFromTestSuites(
  testSuites:
    | NonNullable<
        GetAvailableParentTestSuitesQuery["getAccount"]
      >["parentTestSuites"]
    | NonNullable<
        GetAvailableParentTestSuitesQuery["getAccount"]
      >["notLinkedTestSuites"]
    | null,
  groupLabel: string,
): {
  name: string;
  id: string;
  group: string;
}[] {
  if (!testSuites) return [];

  return testSuites.edges.map((testSuite) => {
    return {
      name: testSuite.node.name,
      id: testSuite.node.id,
      group: groupLabel,
    };
  });
}

export function getParentTestsuiteOptionsFromData(
  data: GetAvailableParentTestSuitesQuery | undefined,
  testSuiteId?: string,
): TestSuiteOption[] | null {
  if (!data || data.getAccount?.__typename !== "Account") {
    return null;
  }

  const parentOptions = getOptionsFromTestSuites(
    data.getAccount.parentTestSuites,
    TestSuiteGroup.Parent,
  );
  const notLinkedOptions = getOptionsFromTestSuites(
    data.getAccount.notLinkedTestSuites,
    TestSuiteGroup.NotLinked,
  );
  return [...parentOptions, ...notLinkedOptions].filter(
    (option) => option.id !== testSuiteId,
  );
}
