import { EmptyState, Clock } from "@lumar/shared";
import { Box, useTheme, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { RoleCode } from "../../../graphql";
import { doesUserMatchRole } from "../../../_common/utils/doesUserMatchRole/doesUserMatchRole";

interface NoSchedulesViewProps {
  openScheduleSettings: () => void;
}

export function NoSchedulesView(props: NoSchedulesViewProps) {
  const theme = useTheme();
  const isViewOnlyUser = doesUserMatchRole(RoleCode.Viewer);

  return (
    <Paper data-testid="no-schedules-view">
      <Box
        style={{ maxWidth: "360px", margin: "0 auto", padding: "150px 0px" }}
      >
        <EmptyState
          icon={
            <Clock style={{ fontSize: 38, color: theme.palette.yellow[500] }} />
          }
          title="Sorry, looks like you don’t have any scheduled runs yet."
          description="Run a test suite by yourself on the frontend (no need for engineers or integration with a CICD pipeline)."
          actions={[
            {
              type: "button",
              title: "New schedule",
              onClick: props.openScheduleSettings,
              icon: <AddIcon />,
              "data-pendo": "scheduler-empty-add-new",
              "data-testid": "no-schedules-add-new-schedule",
              disabled: isViewOnlyUser,
            },
          ]}
        />
      </Box>
    </Paper>
  );
}
