import { ApolloError, useQuery } from "@lumar/shared";
import { useEffect, useState } from "react";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { shouldRefetchData } from "../../../_common/utils/testSuiteRefetchEvaluation/testSuiteRefetchEvaluation";
import {
  GetNextTestSuitesDocument,
  GetNextTestSuitesQuery,
  GetPreviousTestSuitesDocument,
  GetPreviousTestSuitesQuery,
  Maybe,
} from "../../../graphql";

// eslint-disable-next-line max-params
export function useTestSuitesQuery(
  itemsPerPage: number,
  isNext: boolean,
  cursor: Maybe<string> | undefined,
  searchTerm: string | null,
): {
  data: GetNextTestSuitesQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
} {
  const accountId = useAccountRouteMatch();
  const [loadedData, setLoadedData] = useState<
    GetNextTestSuitesQuery | GetPreviousTestSuitesQuery
  >();
  const [oldData, setOldData] = useState<
    GetNextTestSuitesQuery | GetPreviousTestSuitesQuery
  >();
  const [msOfLastRefetch, setMsOfLastRefetch] = useState<number>(
    new Date().getTime(),
  );
  const [forceRefetch, setForceRefetch] = useState<boolean>(false);

  const query = isNext
    ? GetNextTestSuitesDocument
    : GetPreviousTestSuitesDocument;

  const { data, loading, error, refetch } = useQuery<
    GetNextTestSuitesQuery | GetPreviousTestSuitesQuery
  >(query, {
    variables: {
      accountId,
      itemsPerPage,
      cursor,
      searchTerm,
    },
  });

  useEffect(() => {
    setLoadedData(data);
  }, [data]);

  useEffect(() => {
    const evaluateReload = (): void => {
      if (
        !loading &&
        (shouldRefetchData(msOfLastRefetch, oldData || loadedData) ||
          forceRefetch)
      ) {
        setForceRefetch(false);
        const msOfDateNow = new Date().getTime();
        setMsOfLastRefetch(msOfDateNow);
        setOldData(loadedData);
        setLoadedData(undefined);
        refetch().then((data) => {
          setLoadedData(data.data);
        });
      }
    };

    const interval = setInterval(() => {
      evaluateReload();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [refetch, loadedData, oldData, loading, msOfLastRefetch, forceRefetch]);

  function setRefetch(): void {
    setForceRefetch(true);
  }

  return {
    data: loadedData || oldData,
    loading,
    error: error,
    refetch: setRefetch,
  };
}
