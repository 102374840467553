export const automatorSdkUrl = "https://github.com/deepcrawl/automator-sdk";

function getTestSuiteName(
  testSuiteName: string,
  testSuiteSitePrimary: string,
): string {
  return `Test Suite: ${testSuiteName ? testSuiteName : "N/A"} - ${
    testSuiteSitePrimary ? testSuiteSitePrimary : "N/A"
  }`;
}

function getTestSuiteId(testSuiteId: string): string {
  return `Test Suite ID: ${testSuiteId ? testSuiteId : "N/A"}`;
}

function geFirstParagraph(): string {
  return `Lumar Protect is our system for integrating Lumar in your CI/CD pipeline. This allows us to run a crawl on development environments as soon as new code is deployed to them.`;
}

function getSecondParagraph(): string {
  return `To integrate Lumar into your pipeline, please follow the instructions on ${automatorSdkUrl}.`;
}

function getSecondParagraphWithHTMLLink(): string {
  return `To integrate Lumar into your pipeline, please follow the instructions on <a href="${automatorSdkUrl}" target="_blank" rel="noopener noreferrer">${automatorSdkUrl}</a>.`;
}

export function generateEmailTextForCiCdPipelineAsHTML(
  testSuiteName: string,
  testSuiteSitePrimary: string,
  testSuiteId: string,
): string {
  return `${geFirstParagraph()}
  <br/><br/>
  ${getSecondParagraphWithHTMLLink()}
  <br/><br/>
  ${getTestSuiteName(testSuiteName, testSuiteSitePrimary)}
  <br/>
  ${getTestSuiteId(testSuiteId)}`;
}

export function generateEmailTextForCiCdPipeline(
  testSuiteName: string,
  testSuiteSitePrimary: string,
  testSuiteId: string,
): string {
  return `${geFirstParagraph()}

  ${getSecondParagraph()}

  ${getTestSuiteName(testSuiteName, testSuiteSitePrimary)}
  ${getTestSuiteId(testSuiteId)}`;
}

export function generateEmailTextAndCopyToClipboard(
  testSuiteName: string,
  testSuiteSitePrimary: string,
  testSuiteId: string,
): void {
  navigator.clipboard.writeText(
    generateEmailTextForCiCdPipeline(
      testSuiteName,
      testSuiteSitePrimary,
      testSuiteId,
    ),
  );
}
