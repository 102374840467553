import { textAreaValueToArray } from "../../../../../_common/utils/array/coerce/textAreaValueToArray";
import {
  CustomExtractionSettingInput,
  RenderingRobotsCheckMode,
  UpdateCrawlSettingsMutationVariables,
} from "../../../../../graphql";
import {
  removeTypenameFromCustomExtractions,
  getArrayOfCrawlTypes,
} from "./crawlSettingsFormUtils";
import { InitialCrawlSettingsValues } from "./InitialCrawlSettingsValues";

export function getMutationVariablesFromFormikValues(
  values: InitialCrawlSettingsValues,
  maximumCrawlRate: number,
  testSuiteId: string,
  accessibilityContainerId: string | undefined,
  accessibilityAlreadyLinked: boolean,
): UpdateCrawlSettingsMutationVariables {
  return {
    testSuiteId,
    input: {
      testSuiteId,
      limitPagesMax: values.limitUrlsMax,
      rendererBlockAds: values.rendererBlockAds,
      rendererBlockAnalytics: values.rendererBlockAnalytics,
      rendererJsString: values.rendererJsString,
      useRobotsOverwrite: values.robotsOverwrite.enable,
      robotsOverwrite: values.robotsOverwrite.robots,
      renderingRobotsCheckMode: values.robotsOverwrite.renderingRobotsEnabled
        ? RenderingRobotsCheckMode.RequestDisallowed
        : RenderingRobotsCheckMode.BlockDisallowed,
      ignoreRobotsForNavigationRequests:
        values.robotsOverwrite.ignoreRobotsForNavigationRequests,

      emptyPageThreshold: values.emptyPageThreshold,
      highLogSummaryRequests: values.logSummaryRequestsHigh,
      lowLogSummaryRequests: values.logSummaryRequestsLow,
      maxContentSize: values.maxBodyContentLength,
      maxDescriptionLength: values.maxDescriptionLength,
      maxExternalLinks: values.maxExternalLinks,
      maxHtmlSize: values.maxHtmlSize,
      maxLinks: values.maxLinks,
      maxLoadTime: values.maxLoadTime,
      maxRedirections: values.maxRedirections,
      maxTitleWidth: values.maxTitleWidth,
      maxUrlLength: values.maxUrlLength,
      duplicatePrecision: values.duplicatePrecision,
      minDescriptionLength: values.minDescriptionLength,
      minTitleLength: values.minTitleLength,
      thinPageThreshold: values.thinPageThreshold,
      startUrls: values.startUrls,
      // Note: we are type casting because of this bug in Apollo CLI: https://github.com/apollographql/apollo-tooling/issues/1036
      // Even though the schema defines default values for some of the fields in CustomExtractionSettingInput, the generated interface still requires those fields
      customExtractions: removeTypenameFromCustomExtractions(
        values.customExtractions || [],
      ) as CustomExtractionSettingInput[],
      crawlTypes: getArrayOfCrawlTypes(values.crawlTypes),
      urlsExcluded: textAreaValueToArray(values.excludeUrlPatterns),
      urlsIncluded: textAreaValueToArray(values.includeUrlPatterns),
      rendererBlockCustom: textAreaValueToArray(values.rendererBlockCustom),
      rendererJsUrls: textAreaValueToArray(values.rendererJsUrls),
      crawlRate: maximumCrawlRate,
      useRenderer: values.useRenderer === "true",
      selectedWcagLevel: values.selectedWcagLevel,
      selectedWcagVersion: values.selectedWcagVersion,
      includeBestPractices: values.includeBestPractices,
    },
    accessibilityCustomJs: [values.customJsScripts],
    accessibilityContainerId: accessibilityContainerId ?? "",
    updateAccessibilityContainer: Boolean(
      accessibilityContainerId && accessibilityAlreadyLinked,
    ),
  };
}
