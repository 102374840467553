import { GetTestSuitesQuery } from "../../../graphql";
import { BuildInfo } from "../Dashboard";
import { buildsInfoFactory } from "./buildsInfoFactory";

export function getAllBuildsFromAccount(
  account: NonNullable<GetTestSuitesQuery["getAccount"]>,
): BuildInfo[] {
  const testSuiteEdges = account.testSuites?.edges || [];
  return testSuiteEdges
    .map((testSuiteEdge) => {
      return buildsInfoFactory(testSuiteEdge);
    })
    .flatMap((arr) => arr);
}
