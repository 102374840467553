import React from "react";

export function generateTooltips(coreUIUrl: string) {
  const addedSentences = (
    <span data-testid="tooltip-link-sentence">
      You can change this setting in{" "}
      {
        <a
          href={coreUIUrl + "?tab=edit&step=2"}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="tooltip-link"
        >
          Lumar Analyze
        </a>
      }
      .
    </span>
  );

  const webTooltip =
    "Crawl your web site only, following links to deeper levels.";
  const listTooltip =
    "Upload a text list of URLs to be included in the crawl. URLs found in links on these pages will not be crawled unless they are discovered during the web crawl.";

  const sitemapsTooltip = (
    <>
      <span data-testid="tooltip-text">
        Crawl a set of Sitemaps, and the URLs in those Sitemaps. URLs found in
        links on these pages will not be crawled unless they are discovered
        during the web crawl.
      </span>
      <br />
      {addedSentences}
    </>
  );
  const analyticsTooltip = (
    <>
      <span data-testid="tooltip-text">
        Crawl a set of URLs found in your web analytics, either retrieved from a
        Google Analytics, Adobe Analytics/Omniture account, or as a file upload.
        URLs found in links on these pages will not be crawled unless they are
        discovered during the web crawl.
      </span>
      <br />
      {addedSentences}
    </>
  );
  const backlinksTooltip = (
    <>
      <span data-testid="tooltip-text">
        Upload a list of backlink target URLs on your site, with backlink count
        and linking domains metrics, as a CSV file. Any new URLs not found in
        any other sources will be crawled, and the backlink metrics will be
        associated with the crawled pages.
      </span>
      <br />
      {addedSentences}
    </>
  );
  const gscTooltip = (
    <>
      <span data-testid="tooltip-text">
        Crawl URLs found in Search Console Analytics, and retrieve impressions,
        clicks, click-through-rate and position data. URLs found in links on
        these pages will not be crawled unless they are discovered during the
        web crawl.{" "}
      </span>
      <br />
      {addedSentences}
    </>
  );
  const logSummaryTooltip = (
    <>
      <span data-testid="tooltip-text">
        Crawl URLs which have been crawled by Search Engines, and import the
        number of hits. URLs found in links on these pages will not be crawled
        unless they are found during the web crawl.
      </span>
      <br />
      {addedSentences}
    </>
  );
  return {
    webTooltip,
    listTooltip,
    sitemapsTooltip,
    analyticsTooltip,
    backlinksTooltip,
    gscTooltip,
    logSummaryTooltip,
  };
}
