import React from "react";
import { Field, FieldArrayRenderProps, useFormikContext } from "formik";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import AddIcon from "@material-ui/icons/Add";
import { crawlSettingsTooltips } from "./crawlSettingsTooltips";
import { isRegexValid } from "../../../../_common/utils/regex/regex";
import { DeleteButtonWithConfirmation } from "../../../../_common/components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation";
import { SectionTitle } from "../Section";
import { InfoTooltip } from "../../../../_common/components/InfoTooltip/InfoTooltip";
import { StyledInputLabel } from "../../../../_common/components/StyledInputLabel/StyledInputLabel";

export interface CustomExtraction {
  label: string;
  regex: string;
  reportTemplateCode: string;
  __typename?: string;
}

const useStyles = makeStyles((theme) => ({
  header: { margin: "3px 0 19px 0" },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  inputControl: {
    width: 250,
  },
  regexPattern: {
    margin: theme.spacing(0, 1, 0, 2),
  },
}));

const MAX_CUSTOM_EXTRACTIONS = 30;

export function CustomExtractions(props: FieldArrayRenderProps) {
  const classes = useStyles();

  const {
    values: { customExtractions },
  } = useFormikContext<{
    customExtractions: CustomExtraction[];
  }>();

  function GetFirstEmptyCustomExtractionCode(): string | undefined {
    const customExtractionCodes = new Array(MAX_CUSTOM_EXTRACTIONS)
      .fill(null)
      .map((_, index) => `custom_extraction_${index + 1}`)
      .filter(
        (e) => !customExtractions.find((ce) => ce.reportTemplateCode === e),
      );
    return customExtractionCodes[0];
  }

  function handleAddNewExtraction(): void {
    const reportTemplateCode = GetFirstEmptyCustomExtractionCode();

    if (reportTemplateCode) {
      // eslint-disable-next-line fp/no-mutating-methods
      props.push({
        label: "",
        regex: "",
        reportTemplateCode,
      });
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.header}>
        <SectionTitle optional data-testid="custom-extraction-title">
          Custom extraction
        </SectionTitle>
        <InfoTooltip
          interactive
          title={crawlSettingsTooltips.customExtractions}
          data-pendo="auto-tooltip-custom-extraction"
          data-testid="custom-extractions-tooltip"
          IconProps={{
            style: {
              marginLeft: 8,
            },
          }}
        />
      </Box>
      <>
        {customExtractions.map((e, index) => {
          return (
            <Box key={index} mb={2}>
              <Grid className={classes.container}>
                <Grid className={classes.inputGroup}>
                  <Grid item>
                    <StyledInputLabel
                      htmlFor={`customExtractions[${index}].label`}
                    >
                      Custom Extraction
                    </StyledInputLabel>
                    <Field
                      name={`customExtractions[${index}].label`}
                      data-testid={`customExtractions[${index}].label`}
                      id={`customExtractions[${index}].label`}
                      data-cy={`customExtractions-${index}-label`}
                      data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-name"
                      type="text"
                      component={TextField}
                      variant="outlined"
                      className={classes.inputControl}
                    />
                  </Grid>
                  <Grid item className={classes.regexPattern}>
                    <StyledInputLabel
                      htmlFor={`customExtractions[${index}].regex`}
                    >
                      Regex pattern
                    </StyledInputLabel>
                    <Field
                      name={`customExtractions[${index}].regex`}
                      data-testid={`customExtractions[${index}].regex`}
                      id={`customExtractions[${index}].regex`}
                      data-cy={`customExtractions-${index}-regex`}
                      data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-regex"
                      type="text"
                      component={TextField}
                      validate={validateRegex}
                      variant="outlined"
                      className={classes.inputControl}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1} md={2}>
                  <Box mt={3}>
                    <DeleteButtonWithConfirmation
                      data-pendo={`auto-custom-extractions-delete-button`}
                      dataTestId={`delete-custom-extraction-${index}`}
                      dataCy={`delete-custom-extraction-${index}`}
                      tooltipTitle="Remove custom extraction"
                      confirmationTitle="Are you sure you want to remove this custom extraction?"
                      confirmationText="Your custom extraction will be removed from your selected tests in step 3. Please remember to save your changes."
                      handleConfirmation={() => {
                        props.remove(index);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
        <Grid item xs={12}>
          <Box component="div">
            {customExtractions.length < MAX_CUSTOM_EXTRACTIONS ? (
              <Button
                startIcon={<AddIcon />}
                data-testid="add-custom-extraction-button"
                data-cy="add-custom-extraction-button"
                data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-add"
                variant="outlined"
                size="large"
                onClick={handleAddNewExtraction}
              >
                Add Custom Extraction Rule
              </Button>
            ) : (
              <Typography
                data-testid="add-custom-extractions-message"
                data-cy="add-custom-extractions-message"
                style={{
                  fontSize: 14,
                  lineHeight: "17px",
                  color: "#6B7280",
                  fontWeight: 400,
                }}
              >
                Want to add more custom extractions?{" "}
                <a
                  href="mailto:product@deepcrawl.com"
                  data-pendo="auto-test-suite-edit-choose-crawl-settings-custom-extraction-contact-us"
                >
                  Contact us.
                </a>
              </Typography>
            )}
          </Box>
        </Grid>
      </>
    </>
  );
}

function validateRegex(value: string) {
  return isRegexValid(value) ? "" : "This regex is invalid";
}
