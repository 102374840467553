import { RECOMMENDED_TESTS_CATEGORY_CODE } from "../CreateTestsCommonConst";
import {
  ChooseTestsFilterOptions,
  TestCategory,
} from "../CreateTests.interfaces";

export function shouldRenderRecomendedTests(
  filter: ChooseTestsFilterOptions,
  categories: TestCategory[],
) {
  if (filter.showAllCategories) {
    return true;
  } else {
    const filteredCategories = categories.filter(
      (cat) => cat.code === RECOMMENDED_TESTS_CATEGORY_CODE,
    );
    return filteredCategories.length > 0 && filteredCategories[0].selected;
  }
}
