import React from "react";
import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import { PrimaryActionButton } from "../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { InfoTooltip } from "../../../_common/components/InfoTooltip/InfoTooltip";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  headerNameArrowBox: {
    minHeight: "40px",
  },
  pointer: {
    cursor: "pointer",
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(28),
    color: theme.palette.grey[900],
    marginRight: 10.5,
  },
  infoIcon: {
    color: theme.palette.navy[300],
    padding: 10,
    borderRadius: "4px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  headerBackIconBox: {
    minHeight: "40px",
    minWidth: "40px",
  },
  headerBackIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
    fontSize: "30px",
    cursor: "pointer",
  },
  buttonAndInfo: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      marginTop: theme.spacing(3),
    },
  },
  tooltipUl: { paddingInlineStart: "15px" },
}));

export interface SchedulerPageHeaderProps {
  selectedSchedulerId: string | undefined;
  onGoBack: () => void;
  openScheduleSettings: () => void;
  schedulersCount?: number;
}

export function SchedulerPageHeader(props: SchedulerPageHeaderProps) {
  const classes = useStyles();

  return (
    <Grid className={classes.headerWrapper} container>
      <Grid
        alignItems="center"
        xs={12}
        md={7}
        item
        container
        className={classes.headerNameArrowBox}
      >
        {props.selectedSchedulerId && (
          <Hidden lgUp>
            <Box
              className={classes.headerBackIconBox}
              display="flex"
              alignItems="center"
            >
              <ArrowBackIcon
                className={classes.headerBackIcon}
                onClick={() => props.onGoBack()}
                data-testid="scheduler-back-arrow"
              />
            </Box>
          </Hidden>
        )}
        <Typography
          variant="h1"
          className={clsx(
            classes.title,
            props.selectedSchedulerId && classes.pointer,
          )}
          data-testid="scheduler-page-title"
          onClick={() => props.selectedSchedulerId && props.onGoBack()}
        >
          Scheduler
          {typeof props.schedulersCount === "number"
            ? ` (${props.schedulersCount})`
            : ""}
        </Typography>
        <InfoTooltip
          data-pendo="auto-tooltip-scheduler-info"
          data-testid="schedule-info-tooltip"
          title={
            <>
              <p style={{ fontWeight: 500, fontSize: 14, marginTop: 0 }}>
                Add Test Suite Schedule
              </p>
              <ul className={classes.tooltipUl}>
                <li>
                  Automatically run a test suite without needing a CI/CD
                  pipeline deployment to trigger the tests
                </li>
                <li>Schedule your test suites to run at a specific time</li>
                <li>
                  Add a recurring schedule to repeatedly run your test suites on
                  a regular basis
                </li>
              </ul>
            </>
          }
        />
      </Grid>
      <Grid
        alignItems="center"
        xs={12}
        md={5}
        item
        container
        className={classes.buttonAndInfo}
      >
        <PrimaryActionButton
          data-pendo="scheduler-heaeder-add"
          dataTestId="add-new-schedule"
          label="Add schedule"
          startIcon={<AddIcon />}
          onClick={props.openScheduleSettings}
          disableForViewerRole={true}
        />
      </Grid>
    </Grid>
  );
}
