import { useEffect, useRef, useState } from "react";
import { FileUploader } from "../../../../../_common/components/FileUploader/FileUploader";
import { Alert, Snackbar } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  CrawlType,
  ProjectUploadType,
  UrlFileUploadStatus,
  useCreateFileUploadMutation,
  useDeleteFileUploadMutation,
  useGetTestSuiteFileUploadsQuery,
} from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

export interface UrlListUploaderProps {
  testSuiteId: string;
}

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
export function UrlListUploader(props: UrlListUploaderProps) {
  const [deleteFile, { error: deleteError }] = useDeleteFileUploadMutation();
  const [createFile, { error: createError }] = useCreateFileUploadMutation();

  const { loading, error, data, refetch } = useGetTestSuiteFileUploadsQuery({
    variables: {
      id: props.testSuiteId,
    },
  });

  const urlFileUploadNodes = data?.node?.urlFileUploads.nodes || [];
  const refFiles = useRef(data?.node?.urlFileUploads.nodes || []);
  // eslint-disable-next-line
  refFiles.current = data?.node?.urlFileUploads.nodes || [];
  const [processingFiles, setIsProcessingFiles] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<number | undefined>();
  const [totalFilesInProcess, setTotalFilesInProcess] = useState<
    number | undefined
  >();
  const [totalFilesToUpload, setTotalFilesToUpload] = useState<
    number | undefined
  >();
  const [uploadedFileIds, setUploadedFileIds] = useState<string[]>([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  async function handleFileUpload(file: File) {
    const convertedFileName = file.name.replace(/([^a-z0-9.]+)/gi, "-");
    const { data: createData } = await createFile({
      variables: {
        testSuiteId: props.testSuiteId,
        fileName: convertedFileName,
        crawlTypeCode: CrawlType.List,
        projectUploadType: ProjectUploadType.ListTxt,
      },
    });

    if (!createData) return;

    setUploadedFileIds((fileIds) => [
      ...fileIds,
      createData.createSignedUrlFileUpload.urlFileUpload.id,
    ]);
    await fetch(createData?.createSignedUrlFileUpload?.signedS3UploadUrl, {
      method: "PUT",
      body: file,
    });
  }

  useEffect(() => {
    const erroredFiles = data?.node?.urlFileUploads.nodes.filter(
      (file) =>
        file.status === UrlFileUploadStatus.Errored &&
        uploadedFileIds.includes(file.id),
    );
    if (!erroredFiles?.length) return;

    setUploadedFileIds((fileIds) =>
      fileIds.filter((fileId) => !erroredFiles.find((x) => x.id === fileId)),
    );
    erroredFiles.forEach((file) =>
      enqueueSnackbar(
        <Snackbar
          title={`Failed to process file ${file.fileName}`}
          variant="error"
        />,
      ),
    );
  }, [data, uploadedFileIds, enqueueSnackbar]);

  function processFilesX(
    addedFiles: File[],
    position: number,
    resolveSoFar: boolean,
  ): Promise<boolean> {
    if (addedFiles.length === position) {
      return new Promise((resolve, reject) =>
        resolveSoFar ? resolve(resolveSoFar) : reject(resolveSoFar),
      );
    }
    setTotalFilesInProcess(1);
    return handleFileUpload(addedFiles[position]).then(
      () => {
        setTotalFilesInProcess(0);
        const newPostion = position + 1;
        setUploadedFiles(position);
        return processFilesX(addedFiles, newPostion, true && resolveSoFar);
      },
      () => {
        setTotalFilesInProcess(0);
        const newPostion = position + 1;
        setUploadedFiles(position);
        return processFilesX(addedFiles, newPostion, false);
      },
    );
  }

  async function onFilesAdded(addedFiles: File[]) {
    setIsProcessingFiles(true);
    setTotalFilesToUpload(addedFiles.length);
    setTotalFilesInProcess(0);
    setUploadedFiles(0);
    const multipleFiles = addedFiles.length > 1;
    processFilesX(addedFiles, 0, true)
      .then(() => {
        enqueueSnackbar(
          <Snackbar
            title={`${multipleFiles ? "Files" : "File"} uploaded successfully`}
            variant="success"
          />,
        );
      })
      .catch(() => {
        enqueueSnackbar(
          <Snackbar
            title={`Error during uploading ${multipleFiles ? "files" : "file"}`}
            variant="error"
          />,
        );
      })
      .finally(() => {
        if (refetch) {
          refetch();
        }
        setIsProcessingFiles(false);
        setTotalFilesToUpload(undefined);
        setUploadedFiles(undefined);
      });
  }

  function onFileDeleted(fileId: string) {
    setIsProcessingFiles(true);
    deleteFile({
      variables: {
        fileUploadId: fileId,
      },
    })
      .then(
        () => {
          enqueueSnackbar(
            <Snackbar title="File deleted successfully" variant="success" />,
          );
        },
        () => {
          enqueueSnackbar(
            <Snackbar title="Unable to delete file" variant="error" />,
          );
        },
      )
      .finally(() => {
        refetch();
        setIsProcessingFiles(false);
      });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (refFiles.current.length > 0) {
        const needsRefetch = refFiles.current
          .map((node) => node.totalRows === null)
          .reduce((last, now) => last || now);
        if (needsRefetch) {
          refetch();
        }
      }
    }, 3000);

    const clear = () => {
      if (interval) clearInterval(interval);
    };

    return clear;
  }, [data, refetch]);

  return (
    <div data-testid="url-list-uploader">
      {error && (
        <Alert className={classes.alert} severity="error">
          {error.message}
        </Alert>
      )}
      {deleteError && (
        <Alert className={classes.alert} severity="error">
          {deleteError.message}
        </Alert>
      )}
      {createError && (
        <Alert className={classes.alert} severity="error">
          {createError.message}
        </Alert>
      )}
      <FileUploader
        files={urlFileUploadNodes}
        onFilesAdded={onFilesAdded}
        onFileDeleted={onFileDeleted}
        processing={processingFiles || loading}
        filesBeeingUploaded={totalFilesInProcess}
        filesToUpload={totalFilesToUpload}
        filesUploaded={uploadedFiles}
      />
    </div>
  );
}
