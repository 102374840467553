/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIn } from "formik";
import { toPath } from "lodash";
export function getProperty(object, properties) {
    return toPath(properties).reduce((value, property) => value && value[property], object);
}
export function updateIfPropsChanged(property) {
    return (newProps, oldProps) => {
        if (getDefaultFastFieldChanged(newProps, oldProps))
            return true;
        const properties = Array.isArray(property) ? property : [property];
        if (properties.find((property) => getProperty(newProps, property) !== getProperty(oldProps, property)))
            return true;
        return false;
    };
}
export function updateIfFieldsChanged(field) {
    return (newProps, oldProps) => {
        if (getDefaultFastFieldChanged(newProps, oldProps))
            return true;
        const fields = Array.isArray(field) ? field : [field];
        if (fields.find((field) => getIn(oldProps.formik.values, field) !==
            getIn(newProps.formik.values, field) ||
            getIn(oldProps.formik.errors, field) !==
                getIn(newProps.formik.errors, field) ||
            getIn(oldProps.formik.touched, field) !==
                getIn(newProps.formik.touched, field)))
            return true;
        return false;
    };
}
// This funtion is the FastField's default shouldUpdate function
// https://github.com/jaredpalmer/formik/blob/formik%402.2.6/packages/formik/src/FastField.tsx (line 79)
function getDefaultFastFieldChanged(newProps, oldProps) {
    return (oldProps.name !== newProps.name ||
        getIn(oldProps.formik.values, newProps.name) !==
            getIn(newProps.formik.values, newProps.name) ||
        getIn(oldProps.formik.errors, newProps.name) !==
            getIn(newProps.formik.errors, newProps.name) ||
        getIn(oldProps.formik.touched, newProps.name) !==
            getIn(newProps.formik.touched, newProps.name) ||
        Object.keys(newProps).length !== Object.keys(oldProps).length ||
        oldProps.formik.isSubmitting !== newProps.formik.isSubmitting);
}
