import { generatePath } from "react-router-dom";
import queryString from "query-string";
const domain = process.env.REACT_APP_ANALYZE_APP_URL &&
    removeTrailingSlash(process.env.REACT_APP_ANALYZE_APP_URL);
function removeTrailingSlash(value) {
    if (value.endsWith("/")) {
        return value.substring(0, value.length - 1);
    }
    return value;
}
export const AnalyzeRoutes = {
    Domain: domain || "",
    Projects: {
        ROUTE: `/accounts/:accountId/projects`,
        getUrl({ accountId, type, }) {
            const url = generatePath(AnalyzeRoutes.Projects.ROUTE, {
                accountId,
            });
            const preferredLanguage = localStorage.getItem("i18nextLng");
            return queryString.stringifyUrl({
                url,
                query: {
                    type: type !== null && type !== void 0 ? type : AccountProjectsVariant.All,
                    lng: preferredLanguage !== null && preferredLanguage !== void 0 ? preferredLanguage : "en",
                },
            });
        },
    },
    AccountTasks: {
        ROUTE: `/accounts/:accountId/issues`,
        getUrl({ accountId }) {
            const url = generatePath(AnalyzeRoutes.AccountTasks.ROUTE, {
                accountId,
            });
            const preferredLanguage = localStorage.getItem("i18nextLng");
            return queryString.stringifyUrl({
                url,
                query: {
                    lng: preferredLanguage !== null && preferredLanguage !== void 0 ? preferredLanguage : "en",
                },
            });
        },
    },
};
export var AccountProjectsVariant;
(function (AccountProjectsVariant) {
    AccountProjectsVariant["All"] = "all";
    AccountProjectsVariant["SEO"] = "seo";
    AccountProjectsVariant["Accessibility"] = "accessibility";
    AccountProjectsVariant["SiteSpeed"] = "site_speed";
    AccountProjectsVariant["Running"] = "running";
    AccountProjectsVariant["TestSuite"] = "test_suite";
})(AccountProjectsVariant || (AccountProjectsVariant = {}));
