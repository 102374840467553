import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Severity } from "../../../graphql";
import { ExclamationCircleSolid, ExclamationSolid } from "@lumar/shared";

interface TestSeverityButtonProps {
  handleClick: () => void;
  isActive: boolean;
  severityType: Severity;
}

const useStyles = makeStyles((theme) => ({
  button: (props: TestSeverityButtonProps) => {
    const isFail = props.severityType === Severity.Fail;

    return {
      border: props.isActive
        ? `2px solid ${theme.palette.ultraviolet[500]}`
        : `2px solid ${theme.palette.grey[300]}`,
      backgroundColor: props.isActive
        ? theme.palette.ultraviolet[50]
        : "transparent",
      padding: "23px 12px 23px 20px",
      width: "100%",
      justifyContent: "flex-start",
      marginRight: isFail ? 4 : 0,
      marginLeft: isFail ? 0 : 4,
      borderRadius: theme.shape.borderRadius,
      height: "auto",
      "&:hover": {
        backgroundColor: props.isActive
          ? theme.palette.ultraviolet[50]
          : theme.palette.grey[50],
      },
    };
  },
  container: {
    textAlign: "left",
    lineHeight: 1.2,
  },
  title: {
    paddingBottom: 5,
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(20),
  },
  text: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(15),
  },
  icon: (props: TestSeverityButtonProps) => ({
    fontSize: 32,
    color:
      props.severityType === Severity.Fail
        ? theme.palette.red[600]
        : theme.palette.yellow[500],
  }),
}));

export function TestSeverityButton(
  props: TestSeverityButtonProps,
): JSX.Element {
  const isFail = props.severityType === Severity.Fail;
  const classes = useStyles(props);

  return (
    <Button
      disableElevation
      onClick={props.handleClick}
      className={classes.button}
      data-testid={`${props.severityType}-severity-btn`}
      data-cy={`${props.severityType}-severity-btn`}
      data-pendo={`auto-test-suite-edit-choose-tests-test-${props.severityType}`}
    >
      <Box display="flex" alignItems="center" className={classes.container}>
        <Box mr={2} mt="3px">
          {isFail ? (
            <ExclamationCircleSolid className={classes.icon} />
          ) : (
            <ExclamationSolid className={classes.icon} />
          )}
        </Box>
        <Box>
          <Box
            display="block"
            className={classes.title}
            data-testid="severity-btn-title"
          >
            {isFail ? "Fail" : "Warning"}
          </Box>
          <Box className={classes.text} data-testid="severity-btn-info">
            {isFail ? "Build is stopped" : "Build continues with warning"}
          </Box>
        </Box>
      </Box>
    </Button>
  );
}
