var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useCallback, useMemo } from "react";
import { useShareToken } from "./useShareToken";
import { Box } from "@material-ui/core";
import { EmptyState } from "../../components";
import { XCircleOutlined } from "../../icons";
export const InternalAuthContext = createContext(undefined);
export const InternalAuthProvider = ({ domain, clientId, children, }) => {
    const _a = useAuth0(), { loginWithRedirect, getAccessTokenSilently, logout: auth0Logout, error, user: auth0User } = _a, auth0 = __rest(_a, ["loginWithRedirect", "getAccessTokenSilently", "logout", "error", "user"]);
    const { shareToken, stopSharing } = useShareToken();
    const login = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield loginWithRedirect({
            appState: { returnTo: window.location.pathname },
        });
    }), [loginWithRedirect]);
    const isAuthenticated = Boolean(shareToken || auth0.isAuthenticated);
    const getToken = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (shareToken)
            return { type: "share", token: shareToken };
        try {
            const token = yield getAccessTokenSilently();
            return { type: "auth0", token };
        }
        catch (error) {
            return;
        }
    }), [getAccessTokenSilently, shareToken]);
    const logout = useCallback((options) => __awaiter(void 0, void 0, void 0, function* () {
        if (options === null || options === void 0 ? void 0 : options.onBeforeLogout) {
            yield options.onBeforeLogout();
        }
        const token = yield getToken();
        if (!token)
            return;
        switch (token.type) {
            case "auth0":
                return auth0Logout({
                    logoutParams: { returnTo: window.location.origin },
                });
            case "share":
                return stopSharing();
            default:
                throw Error("Token type not supported");
        }
    }), [auth0Logout, getToken, stopSharing]);
    const resetPassword = React.useCallback((email) => __awaiter(void 0, void 0, void 0, function* () {
        yield fetch(`https://${domain}/dbconnections/change_password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                client_id: clientId,
                email,
                connection: "business-database",
            }),
        });
    }), [clientId, domain]);
    const authContext = useMemo(() => ({
        isAuthenticated,
        getToken,
        login,
        logout,
        resetPassword,
        clientId,
        auth0User,
    }), [
        isAuthenticated,
        getToken,
        login,
        logout,
        resetPassword,
        clientId,
        auth0User,
    ]);
    if (error) {
        const errorName = error.name && error.name !== "Error" ? `(name: ${error.name})` : "";
        const errorCause = error.cause ? `(cause: ${error.cause})` : "";
        const description = `${error.message} ${errorName} ${errorCause}`;
        console.error(error);
        return (_jsx(Box, { height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", children: _jsx(EmptyState, { title: "Authentication error", description: description, icon: _jsx(XCircleOutlined, { fontSize: "large" }) }) }));
    }
    return (_jsx(InternalAuthContext.Provider, { value: authContext, children: children }));
};
