import React from "react";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../../_common/routes/routes";
import { ActionIconButton } from "../../../_common/components/ActionIconButton/ActionIconButton";
import { DeleteButtonWithConfirmation } from "../../../_common/components/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation";
import { useSnackbar } from "notistack";
import { EditIcon } from "../../../_common/svg/EditIcon";
import { SettingsIcon } from "../../../_common/svg/SettingsIcon";
import { DuplicateSolid, Snackbar } from "@lumar/shared";
import {
  useCloneTestSuiteMutation,
  useDeleteTestSuiteMutation,
  useUnlinkJiraIntegrationFromTestSuiteMutation,
} from "../../../graphql";

export interface TestSuiteItemLinksProps {
  id: string;
  name: string;
  editable: boolean;
  deletable: boolean;
  clonable: boolean;
  editTestSuite?: boolean;
  isTemplate?: boolean;
  pendoIdSuffix: string;
  testSuiteJiraIntegrationId?: string | null;
  className?: string;
  textLabel?: boolean;
}

const popOverDialogTitleForTemaplate =
  "Are you sure you want to delete this Global Template permanently?";
const popOverDialogTitleDefault =
  "Are you sure you want to delete this test suite?";
const popoverDialogTextDefault = "This cannot be undone.";
const popoverDialogTextForTemplate =
  "This cannot be undone. This will disconnect all connected test suites and all your current configurations in steps 2 and 3 of your connected test suites will be disconnected/stand alone.";

// FIXME: delete this component as it supports too many use cases
export function TestSuiteItemLinks(
  props: TestSuiteItemLinksProps,
): JSX.Element {
  const accountId = useAccountRouteMatch();
  const history = useHistory();
  const [cloneTestSuiteMutation] = useCloneTestSuiteMutation({
    refetchQueries: ["getNextTestSuites", "getPreviousTestSuites"],
  });
  const [deleteTestSuiteMutation] = useDeleteTestSuiteMutation({
    refetchQueries: [
      "getNextTestSuites",
      "getPreviousTestSuites",
      "getJiraIntegrations",
      "getJiraIntegrationsWithTestSuites",
    ],
  });
  const [unlinkJiraIntegration] = useUnlinkJiraIntegrationFromTestSuiteMutation(
    { refetchQueries: ["getTestSuite"] },
  );
  const { enqueueSnackbar } = useSnackbar();

  const popoverSettings = props.isTemplate
    ? {
        title: popOverDialogTitleForTemaplate,
        text: popoverDialogTextForTemplate,
      }
    : { title: popOverDialogTitleDefault, text: popoverDialogTextDefault };

  async function handleCloneTestSuite(): Promise<void> {
    try {
      // eslint-disable-next-line max-depth
      if (props.testSuiteJiraIntegrationId) {
        await unlinkJiraIntegration({
          variables: {
            testSuiteJiraIntegrationId: props.testSuiteJiraIntegrationId,
          },
        });
      }

      await cloneTestSuiteMutation({
        variables: { testSuiteId: props.id },
      });
      enqueueSnackbar(
        <Snackbar
          title="Test suite duplicated successfully"
          variant="success"
        />,
        {},
      );
      history.push(Routes.TestSuites.getUrl({ accountId }));
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Test suite duplication failed. Please refresh the page and try again."
          variant="error"
        />,
      );
    }
  }

  function handleEditTestSuite(): void {
    history.push(
      Routes.EditTestSuite.getUrl({ accountId, testSuiteId: props.id }),
    );
  }

  async function handleDeleteTestSuite(): Promise<void> {
    try {
      await deleteTestSuiteMutation({
        variables: { testSuiteId: props.id },
      });
      enqueueSnackbar(
        <Snackbar title="Test suite deleted successfully" variant="success" />,
      );
      history.push(Routes.TestSuites.getUrl({ accountId }));
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Test suite deletion failed. Please refresh the page and try again."
          variant="error"
        />,
      );
    }
  }

  return (
    <>
      {props.clonable && (
        <ActionIconButton
          disableForViewer={true}
          tooltipTitle="Duplicate test suite"
          data-testid="clone-test-suite-button"
          data-pendo={`auto-${props.pendoIdSuffix}-duplicate-testsuite`}
          onClick={(e) => {
            e.stopPropagation();
            handleCloneTestSuite();
          }}
          icon={<DuplicateSolid />}
          variant="info"
        />
      )}
      {props.editable && (
        <ActionIconButton
          disableForViewer={true}
          tooltipTitle="Edit test suite"
          data-testid="edit-test-suite-button"
          data-pendo={`auto-${props.pendoIdSuffix}-edit-testsuite`}
          onClick={(e) => {
            e.stopPropagation();
            handleEditTestSuite();
          }}
          icon={<EditIcon />}
          variant="info"
        />
      )}
      {props.editTestSuite && (
        <ActionIconButton
          tooltipTitle="Edit test suite"
          data-testid="edit-test-suite-button"
          data-pendo={`auto-${props.pendoIdSuffix}-edit-testsuite`}
          onClick={(e) => {
            e.stopPropagation();
            handleEditTestSuite();
          }}
          icon={<SettingsIcon />}
          variant="info"
          className={props.className}
          textLabel={props.textLabel}
        />
      )}
      {props.deletable && (
        <DeleteButtonWithConfirmation
          disableForViewer={true}
          tooltipTitle="Delete test suite"
          dataTestId="delete-test-suite-button"
          data-pendo={`auto-${props.pendoIdSuffix}-delete-testsuite`}
          confirmationText={popoverSettings.text}
          confirmationTitle={popoverSettings.title}
          handleConfirmation={handleDeleteTestSuite}
        />
      )}
    </>
  );
}
