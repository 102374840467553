import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, Grid, useTheme } from "@material-ui/core";
import { useState } from "react";
import { AppSwitcherItem } from "./app-switcher-item/AppSwitcherItem";
import { MainAppMenuItem } from "./app-switcher-item/MainAppMenuItem";
import { LumarIcon } from "../../../icons/LumarIcon";
import { Scrollbar } from "../../scrollbar/Scrollbar";
import { Link } from "react-router-dom";
import { appSwitcherWidth } from "../constants";
import { useAppSwitcherConfig } from "./appSwitcherConfig";
const useStyles = makeStyles((theme) => {
    var _a, _b, _c, _d;
    return ({
        root: Object.assign({ display: "block", flex: "0 0 auto", width: appSwitcherWidth, zIndex: theme.zIndex.drawer + 1, position: "fixed", height: "100%", background: "#020034", borderRight: "none" }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.AppSwitcher) === null || _b === void 0 ? void 0 : _b.root),
        container: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            left: 0,
            right: "auto",
        },
        actionContainer: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
        },
        icon: {
            fontSize: theme.typography.pxToRem(24),
            color: "#D2B8FF",
            cursor: "pointer",
        },
        iconContainer: Object.assign({ margin: "auto", textAlign: "center", width: "70%", paddingTop: 21, paddingBottom: 17, marginBottom: theme.spacing(1.25), borderBottomColor: theme.palette.grey[700], borderBottomWidth: 1, borderBottomStyle: "solid" }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.AppSwitcher) === null || _d === void 0 ? void 0 : _d.iconContainer),
    });
});
export function AppSwitcher(props) {
    var _a, _b, _c, _d, _e;
    const theme = useTheme();
    const classes = useStyles();
    const { pendoPrefix, app, BrandIconComponent = ((_b = (_a = theme.props) === null || _a === void 0 ? void 0 : _a.AppSwitcher) === null || _b === void 0 ? void 0 : _b.BrandIconComponent) ||
        LumarIcon, BrandIconComponentProps = ((_d = (_c = theme.props) === null || _c === void 0 ? void 0 : _c.AppSwitcher) === null || _d === void 0 ? void 0 : _d.BrandIconComponentProps) || { className: classes.icon }, } = props;
    const { appSwitcherItems: items, appSwitcherActions: actions, appSwitcherBrandLogoLink, } = useAppSwitcherConfig(app);
    const [selected, setSelected] = useState((_e = items.find((app) => app.isMain)) === null || _e === void 0 ? void 0 : _e.id);
    const apps = [
        ...items.filter((x) => x.enabled),
        ...items.filter((x) => !x.enabled),
    ];
    return (_jsx("div", { className: classes.root, children: _jsxs(Scrollbar, { classes: { content: classes.container }, children: [_jsxs(Grid, { container: true, alignItems: "center", direction: "row", alignContent: "flex-start", justifyContent: "space-between", children: [appSwitcherBrandLogoLink.type === "internal" ? (_jsx(Link, { className: classes.iconContainer, "aria-label": "Home", to: appSwitcherBrandLogoLink.to, children: _jsx(BrandIconComponent, Object.assign({}, BrandIconComponentProps)) })) : (_jsx("a", { className: classes.iconContainer, "aria-label": "Home", href: appSwitcherBrandLogoLink.to, children: _jsx(BrandIconComponent, Object.assign({}, BrandIconComponentProps)) })), apps.map((app) => (_createElement(AppSwitcherItem, Object.assign({}, app, { key: app.id, pendoPrefix: pendoPrefix, selected: app.id === selected, setSelected: () => setSelected(app.id) }))))] }), _jsx(Grid, { container: true, alignItems: "center", direction: "column", className: classes.actionContainer, style: { marginTop: "auto" }, children: actions.map((action) => (_jsx(MainAppMenuItem, { item: {
                            name: action.name,
                            id: action.id,
                            href: action.href || "#",
                            onClick: action.onClick,
                            testId: action.testId,
                            icon: action.icon,
                        }, isAction: true, isMain: true, pendoPrefix: pendoPrefix }, action.id))) })] }) }));
}
