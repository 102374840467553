import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { HomeAppIcon } from "./HomeAppIcon";
import { AnalyzeAppIcon } from "./AnalyzeAppIcon";
import { ImpactAppIcon } from "./ImpactAppIcon";
import { MonitorAppIcon } from "./MonitorAppIcon";
import { ProtectAppIcon } from "./ProtectAppIcon";
import clsx from "clsx";
import { AppIds } from "../../../../../session/SessionContext.types";
const appIdToIcon = {
    [AppIds.analyze]: AnalyzeAppIcon,
    [AppIds.monitor]: MonitorAppIcon,
    [AppIds.protect]: ProtectAppIcon,
    [AppIds.impact]: ImpactAppIcon,
};
const useStyles = makeStyles((theme) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return ({
        icon: Object.assign({ color: "#706994", fill: "#020034" }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.AppSwitcherIcon) === null || _b === void 0 ? void 0 : _b.icon),
        iconFocused: Object.assign({ color: "#D2B8FF" }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.AppSwitcherIcon) === null || _d === void 0 ? void 0 : _d.iconFocused),
        enabled: Object.assign({ color: "#877FA8" }, (_f = (_e = theme.overrides) === null || _e === void 0 ? void 0 : _e.AppSwitcherIcon) === null || _f === void 0 ? void 0 : _f.enabled),
        enabledFocused: Object.assign({ color: "#DDD3F2" }, (_h = (_g = theme.overrides) === null || _g === void 0 ? void 0 : _g.AppSwitcherIcon) === null || _h === void 0 ? void 0 : _h.enabledFocused),
        active: Object.assign({ color: theme.palette.primary.main, fill: "#FFFFFF" }, (_k = (_j = theme.overrides) === null || _j === void 0 ? void 0 : _j.AppSwitcherIcon) === null || _k === void 0 ? void 0 : _k.active),
        activeFocused: Object.assign({ color: theme.palette.ultraviolet[500] }, (_m = (_l = theme.overrides) === null || _l === void 0 ? void 0 : _l.AppSwitcherIcon) === null || _m === void 0 ? void 0 : _m.activeFocused),
    });
});
export function AppIcon({ appId, enabled, focused, active, isMain, }) {
    const classes = useStyles();
    const IconComponent = active && isMain && focused ? HomeAppIcon : appIdToIcon[appId];
    return (_jsx(IconComponent, { enabled: Boolean(enabled), className: clsx({
            [classes.icon]: !active,
            [classes.iconFocused]: !active && focused,
            [classes.enabled]: enabled,
            [classes.enabledFocused]: enabled && focused,
            [classes.active]: active,
            [classes.activeFocused]: active && focused,
        }) }));
}
