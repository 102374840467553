import { createAppTheme } from "@lumar/shared";
import * as MuiLocales from "@material-ui/core/locale";

export enum ResolutionStep {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}

const theme = createAppTheme(MuiLocales.enUS);

const getValueOfStep = (step: ResolutionStep): number => {
  return theme.breakpoints?.values?.[step] ?? 0;
};

const getWindowWidth = (): number => {
  return window.innerWidth;
};

const isResolutionAbove = (step: ResolutionStep): boolean => {
  return getWindowWidth() >= getValueOfStep(step);
};

const isResolutionBelow = (step: ResolutionStep): boolean => {
  return getWindowWidth() < getValueOfStep(step);
};

const isResolutionExactly = (step: ResolutionStep): boolean => {
  return getWindowWidth() === getValueOfStep(step);
};

export const isResolutionWithin = (
  step1: ResolutionStep,
  step2?: ResolutionStep,
): boolean => {
  if (step1 && step2) {
    return step1 !== step2
      ? isResolutionAbove(step1) && isResolutionBelow(step2)
      : isResolutionExactly(step1);
  } else {
    return isResolutionAbove(step1);
  }
};
