import { makeStyles, createStyles } from "@material-ui/core/styles";
import { hexToRgbA } from "../../../../../_common/utils/colors/colors";
import { ChooseTestsSelectionListProps } from "./ChooseTestsSelectionList";

export const useStyles = makeStyles(
  createStyles({
    scrollableList: (props: ChooseTestsSelectionListProps) => ({
      maxHeight: props.maxHeight,
      overflow: "-moz-scrollbars-vertical",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        "-webkit-appearance": "none",
        width: "10px",
      },

      "&::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        backgroundColor: hexToRgbA("#000", 0.5),
        "-webkit-box-shadow": `0 0 1px ${hexToRgbA("#FFF", 0.5)}`,
      },
    }),
    selectedTestsGrid: {
      width: "100%",
      padding: "16px 0 4px 0",
    },
  }),
);
