import { createContext } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { BuildTestList } from "../pages/BuildTestList/BuildTestList";
import { ConnectedApps } from "../pages/ConnectedApps/ConnectedApps";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { FrontendScheduler } from "../pages/FrontendScheduler/FrontendScheduler";
import { HelpPortal } from "../pages/HelpPortal/HelpPortal";
import { NotFound } from "../pages/NotFound/NotFound";
import { InitialiseTestSuite } from "../pages/TestSuite/InitialiseTestSuite";
import { PaginatedTestSuiteList } from "../pages/TestSuiteList/PaginatedTestSuiteList";
import { TestSuiteReport } from "../pages/TestSuiteReport/TestSuiteReport";
import { AuthRoute } from "../_common/components/AuthRoute/AuthRoute";
import { PostLoginLayer } from "../_common/components/PostLoginLayer/PostLoginLayer";
import { useAccountRouteMatch } from "../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../_common/routes/routes";

export interface AppRoutesProps {
  logoutHandler: () => void;
}

type AppRoutesComponentProps = RouteComponentProps<
  Record<string, string | undefined>
> &
  AppRoutesProps;

export interface LogoutContextType {
  logoutHandler: () => void;
}

export const LogoutContext = createContext<LogoutContextType>({
  logoutHandler: () => null,
});

export const AppRoutesPrivate = withRouter((props: AppRoutesComponentProps) => {
  const contextValue = {
    logoutHandler: props.logoutHandler,
  };

  const accountId = useAccountRouteMatch();

  const match = useRouteMatch<{ testSuiteId: string }>(Routes.Builds.ROUTE);

  const dashboardLink = {
    label: "Home",
    link: Routes.Dashboard.getUrl({ accountId }),
  };

  const defaultLinks = [
    dashboardLink,
    {
      label: "Test suites",
      link: Routes.TestSuites.getUrl({ accountId }),
      "data-cy": "bcb-my-test-suites",
      "data-testid": "bcb-my-test-suites",
      "data-pendo": "auto-global-breadcrumbs-test-suites-active",
    },
  ];

  const schedulerLinks = [
    dashboardLink,
    {
      label: "Scheduler",
      link: Routes.FrontendScheduler.getUrl({ accountId }),
      "data-cy": "bcb-my-scheduler",
      "data-testid": "bcb-my-scheduler",
      "data-pendo": "auto-global-breadcrumbs-scheduler",
    },
  ];

  const connectAppLinks = [
    dashboardLink,
    {
      label: "Jira Integrations",
      link: Routes.ConnectedApps.getUrl({ accountId }),
      "data-cy": "bcb-my-connected-apps",
      "data-testid": "bcb-my-connected-apps",
      "data-pendo": "auto-global-breadcrumbs-connected-apps",
    },
  ];

  const gettingStartedLinks = [
    dashboardLink,
    {
      label: "Help portal",
      link: Routes.ConnectedApps.getUrl({ accountId }),
      "data-cy": "bcb-help",
      "data-testid": "bcb-help",
      "data-pendo": "auto-global-breadcrumbs-help",
    },
  ];

  return (
    <div data-testid="app-routes-private">
      <LogoutContext.Provider value={contextValue}>
        <Switch>
          <Route exact path={Routes.Root.ROUTE} component={PostLoginLayer} />
          <AuthRoute
            exact
            path={Routes.LoginCallback.ROUTE}
            render={() => <PostLoginLayer />}
          />
          <AuthRoute
            exact
            path={Routes.Login.ROUTE}
            render={() => <Redirect to={Routes.Root.ROUTE} />}
          />
          <AuthRoute
            exact
            path={Routes.GettingStarted.ROUTE}
            component={HelpPortal}
            breadcrumbsProps={{
              breadcrumbItems: gettingStartedLinks,
            }}
          />
          <AuthRoute
            exact
            path={Routes.Dashboard.ROUTE}
            component={Dashboard}
          />
          <AuthRoute
            exact
            path={Routes.TestSuites.ROUTE}
            component={PaginatedTestSuiteList}
            breadcrumbsProps={{
              breadcrumbItems: defaultLinks,
            }}
          />

          <AuthRoute
            exact
            path={Routes.Builds.ROUTE}
            component={BuildTestList}
            includePageContainer={false}
            breadcrumbsProps={{
              breadcrumbItems: [...defaultLinks, { label: "Test suite runs" }],
            }}
          />
          <AuthRoute
            exact
            path={Routes.TestResults.ROUTE}
            component={TestSuiteReport}
            includePageContainer={false}
            breadcrumbsProps={{
              breadcrumbItems: [
                ...defaultLinks,
                {
                  label: "Test suite runs",
                  link: Routes.Builds.getUrl({
                    accountId,
                    testSuiteId: match?.params.testSuiteId || "",
                  }),
                },
                { label: "Results" },
              ],
            }}
          />
          <AuthRoute
            exact
            path={Routes.CreateTestSuite.ROUTE}
            component={InitialiseTestSuite}
            includePageContainer={false}
            breadcrumbsProps={{
              breadcrumbItems: [
                ...defaultLinks,
                { label: "Add test suite", "data-cy": "bcb-add-test-suite" },
              ],
            }}
          />
          <AuthRoute
            exact
            path={Routes.EditTestSuite.ROUTE}
            component={InitialiseTestSuite}
            includePageContainer={false}
            breadcrumbsProps={{
              breadcrumbItems: [
                ...defaultLinks,
                { label: "Edit test suite", "data-cy": "bcb-edit-test-suite" },
              ],
            }}
          />

          <AuthRoute
            exact
            path={Routes.ConnectedApps.ROUTE}
            component={ConnectedApps}
            breadcrumbsProps={{
              breadcrumbItems: connectAppLinks,
            }}
          />
          <AuthRoute
            exact
            path={Routes.JiraIntegration.ROUTE}
            component={ConnectedApps}
          />
          <AuthRoute
            exact
            path={Routes.FrontendScheduler.ROUTE}
            component={FrontendScheduler}
            breadcrumbsProps={{
              breadcrumbItems: schedulerLinks,
            }}
          />
          <AuthRoute path={Routes.Any.ROUTE} component={NotFound} />
        </Switch>
      </LogoutContext.Provider>
    </div>
  );
});
