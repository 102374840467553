import { Box, BoxProps, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
  },
  border: ({ color }: { color: string }) => ({
    borderRadius: 7,
    borderLeft: `20px solid ${color}`,
  }),
  contentWrapper: {
    borderRadius: 8,
    marginLeft: -16,
    backgroundColor: "white",
  },
}));

interface ColouredLeftBorderProps {
  children: React.ReactNode;
  color: string;
  /**
   * The properties applied to the root level `Box` component.
   */
  rootBoxProps?: BoxProps;
}

export function ColouredLeftBorder({
  children,
  color,
  rootBoxProps,
}: ColouredLeftBorderProps): JSX.Element {
  const classes = useStyles({ color });
  return (
    <Box className={classes.root} {...rootBoxProps}>
      <Box className={classes.border}>
        <Box className={classes.contentWrapper}>{children}</Box>
      </Box>
    </Box>
  );
}
