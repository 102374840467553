import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import { withStyles } from "@material-ui/styles";
import { Typography } from "../typography/Typography";
import { CheckCircle, XCircle, XCircleSolid } from "../../icons";
import { Link } from "react-router-dom";
import { Button } from "../buttons/Button";
import { Info } from "../../icons";
import { useTranslation } from "react-i18next";
function SnackbarInner({ id, title, variant, icon: Icon, children, link, to, onLinkClick, actions, className, classes, closeIcon, }) {
    var _a, _b, _c, _d, _e;
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const { t } = useTranslation("sharedCommon");
    const IconToUse = (_c = closeIcon !== null && closeIcon !== void 0 ? closeIcon : (_b = (_a = theme.props) === null || _a === void 0 ? void 0 : _a.Snackbar) === null || _b === void 0 ? void 0 : _b.closeIcon) !== null && _c !== void 0 ? _c : XCircleSolid;
    const getProps = () => {
        switch (variant) {
            case "success":
                return {
                    DefaultIcon: CheckCircle,
                    iconClass: clsx(classes === null || classes === void 0 ? void 0 : classes.icon, classes === null || classes === void 0 ? void 0 : classes.successIcon),
                    rootClass: classes === null || classes === void 0 ? void 0 : classes.success,
                };
            case "error":
                return {
                    DefaultIcon: XCircle,
                    iconClass: clsx(classes === null || classes === void 0 ? void 0 : classes.icon, classes === null || classes === void 0 ? void 0 : classes.errorIcon),
                    rootClass: classes === null || classes === void 0 ? void 0 : classes.error,
                };
            default:
                return {
                    DefaultIcon: Info,
                    iconClass: classes === null || classes === void 0 ? void 0 : classes.icon,
                };
        }
    };
    const { DefaultIcon, iconClass, rootClass } = getProps();
    const hasLink = Boolean(link) || Boolean(onLinkClick);
    const linkProps = to
        ? {
            component: Link,
            to,
            onClick: () => {
                closeSnackbar(id);
            },
        }
        : {
            onClick: () => {
                onLinkClick === null || onLinkClick === void 0 ? void 0 : onLinkClick();
                closeSnackbar(id);
            },
        };
    return (_jsxs(Box, { display: "flex", className: clsx(className, classes === null || classes === void 0 ? void 0 : classes.root, rootClass), alignItems: "center", id: "notistack-snackbar", "data-testid": "custom-snackbar", children: [Icon ? (_jsx(Icon, { className: iconClass })) : (_jsx(DefaultIcon, { className: iconClass })), _jsxs(Box, { className: classes === null || classes === void 0 ? void 0 : classes.titleContainer, children: [isString(title) ? (_jsx(Title, { variant: "subtitle3Medium", className: classes === null || classes === void 0 ? void 0 : classes.title, children: title })) : (title), children, actions !== undefined && (_jsxs("div", { className: classes === null || classes === void 0 ? void 0 : classes.actionsConatiner, children: [_jsx(Button, { onClick: () => {
                                    var _a;
                                    const result = (_a = actions.onSecondaryButtonClick) === null || _a === void 0 ? void 0 : _a.call(actions);
                                    if (result !== false)
                                        closeSnackbar(id);
                                }, size: "large", className: classes === null || classes === void 0 ? void 0 : classes.secondaryButton, children: (_d = actions.secondaryButtonLabel) !== null && _d !== void 0 ? _d : t("cancel") }), _jsx(Button, { onClick: () => {
                                    var _a;
                                    const result = (_a = actions.onPrimaryButtonClick) === null || _a === void 0 ? void 0 : _a.call(actions);
                                    if (result !== false)
                                        closeSnackbar(id);
                                }, size: "large", variant: "contained", color: "primary", className: classes === null || classes === void 0 ? void 0 : classes.primaryButton, children: (_e = actions.primaryButtonLabel) !== null && _e !== void 0 ? _e : t("ok") })] }))] }), hasLink && (_jsx(Typography, Object.assign({ variant: "subtitle3Medium" }, linkProps, { className: classes === null || classes === void 0 ? void 0 : classes.link, children: link }))), !actions && (_jsx(IconButton, { size: "small", onClick: () => closeSnackbar(id), className: classes === null || classes === void 0 ? void 0 : classes.closeButton, "data-testid": "custom-snackbar-close-button", children: _jsx(IconToUse, { className: classes === null || classes === void 0 ? void 0 : classes.closeIcon }) }))] }));
}
function isString(value) {
    return typeof value === "string";
}
export const Snackbar = withStyles((theme) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    return ({
        root: Object.assign({ border: "solid 1px", borderRadius: theme.spacing(1), padding: theme.spacing(1, 1.5), width: "max-content", background: theme.palette.primary.light, borderColor: theme.palette.primary.main, marginLeft: "auto" }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.Snackbar) === null || _b === void 0 ? void 0 : _b.root),
        titleContainer: Object.assign({ maxWidth: 280, textAlign: "initial" }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.Snackbar) === null || _d === void 0 ? void 0 : _d.titleContainer),
        title: Object.assign({ display: "block" }, (_f = (_e = theme.overrides) === null || _e === void 0 ? void 0 : _e.Snackbar) === null || _f === void 0 ? void 0 : _f.title),
        icon: Object.assign({ width: "28px", height: "28px", margin: theme.spacing(0.25, 1.25, 0.25, 0.25), color: theme.palette.primary.main, alignSelf: "flex-start" }, (_h = (_g = theme.overrides) === null || _g === void 0 ? void 0 : _g.Snackbar) === null || _h === void 0 ? void 0 : _h.icon),
        closeButton: Object.assign({ width: "24px", height: "24px", color: theme.palette.primary.main, marginLeft: theme.spacing(1), marginTop: theme.spacing(0.5), "&:hover": {
                background: "transparent",
                "& $closeIcon": {
                    color: theme.palette.grey[400],
                },
            }, alignSelf: "flex-start" }, (_k = (_j = theme.overrides) === null || _j === void 0 ? void 0 : _j.Snackbar) === null || _k === void 0 ? void 0 : _k.closeButton),
        closeIcon: Object.assign({ fontSize: theme.typography.pxToRem(20), color: theme.palette.grey[500] }, (_m = (_l = theme.overrides) === null || _l === void 0 ? void 0 : _l.Snackbar) === null || _m === void 0 ? void 0 : _m.closeIcon),
        success: Object.assign({ background: theme.palette.green[50], borderColor: theme.palette.green[300] }, (_p = (_o = theme.overrides) === null || _o === void 0 ? void 0 : _o.Snackbar) === null || _p === void 0 ? void 0 : _p.success),
        successIcon: Object.assign({ color: theme.palette.green[500] }, (_r = (_q = theme.overrides) === null || _q === void 0 ? void 0 : _q.Snackbar) === null || _r === void 0 ? void 0 : _r.successIcon),
        error: Object.assign({ background: theme.palette.red[50], borderColor: theme.palette.red[300] }, (_t = (_s = theme.overrides) === null || _s === void 0 ? void 0 : _s.Snackbar) === null || _t === void 0 ? void 0 : _t.error),
        errorIcon: Object.assign({ color: theme.palette.red[400] }, (_v = (_u = theme.overrides) === null || _u === void 0 ? void 0 : _u.Snackbar) === null || _v === void 0 ? void 0 : _v.errorIcon),
        link: Object.assign({ color: theme.palette.primary.main, textDecoration: "none", marginLeft: theme.spacing(1) }, (_x = (_w = theme.overrides) === null || _w === void 0 ? void 0 : _w.Snackbar) === null || _x === void 0 ? void 0 : _x.link),
        actionsConatiner: Object.assign({ display: "flex", justifyContent: "end", marginTop: theme.spacing(1) }, (_z = (_y = theme.overrides) === null || _y === void 0 ? void 0 : _y.Snackbar) === null || _z === void 0 ? void 0 : _z.actionsContainer),
        primaryButton: Object.assign({}, (_1 = (_0 = theme.overrides) === null || _0 === void 0 ? void 0 : _0.Snackbar) === null || _1 === void 0 ? void 0 : _1.primaryButton),
        secondaryButton: Object.assign({ marginRight: theme.spacing(1.5), boxShadow: "none" }, (_3 = (_2 = theme.overrides) === null || _2 === void 0 ? void 0 : _2.Snackbar) === null || _3 === void 0 ? void 0 : _3.secondaryButton),
    });
})(SnackbarInner);
const Title = withStyles((theme) => ({
    root: {
        lineHeight: theme.typography.pxToRem(17),
        fontSize: theme.typography.pxToRem(14),
        marginTop: theme.spacing(0.625),
        marginBottom: theme.spacing(0.625),
        color: theme.palette.grey[900],
    },
}))(Typography);
