import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { SchedulerListItem } from "./SchedulerListItem";
import { GetBuildSchedulesQuery } from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  listPaper: {
    padding: "7px 5px",
    background: "#EBEFF3",
    minHeight: "688px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: "none",
    filter: "none",
    [theme.breakpoints.down("md")]: {
      minHeight: "0px",
      overflow: "hidden",
      paddingBottom: theme.spacing(2),
    },
  },
}));

export interface SchedulerManageListProps {
  schedulers: NonNullable<
    GetBuildSchedulesQuery["getAccount"]
  >["buildSchedules"]["nodes"];
  selectedSchedulerId: string | undefined;
  accountId: string;
  onSelectItem: (itemId: string) => void;
}

export function SchedulerManageList(props: SchedulerManageListProps) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.listPaper}
      data-testid="scheduler-manage-list"
      data-pendo="auto-scheduler-list"
    >
      {props.schedulers.map((scheduler) => (
        <SchedulerListItem
          key={scheduler.id}
          scheduler={scheduler}
          onSelect={props.onSelectItem}
          selected={props.selectedSchedulerId === scheduler.id}
        />
      ))}
    </Paper>
  );
}
