import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Dialog, makeStyles, Typography } from "@material-ui/core";
import { Button } from "../buttons/Button";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useBrand } from "../..";
const useStyles = makeStyles((theme) => ({
    buttonsWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    title: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: theme.spacing(1),
    },
    text: {
        fontWeight: 300,
        fontSize: "16px",
        marginBottom: theme.spacing(4),
    },
    dialog: {
        padding: theme.spacing(4.5, 3, 2, 3),
        maxWidth: 402, // 450px - 24px - 24px
    },
    button: {
        height: 36,
    },
    containedButton: {
        marginLeft: theme.spacing(1),
    },
    link: {
        color: theme.palette.blue[600],
    },
}));
export function TermsAndConditionsDialog({ agree, decline, loading, }) {
    const { t } = useTranslation("terms");
    const brand = useBrand();
    const classes = useStyles();
    return (_jsxs(Dialog, { "data-testid": "user-terms-dialog", open: true, disableBackdropClick: true, disableEscapeKeyDown: true, classes: { paper: classes.dialog }, maxWidth: "xs", children: [_jsx(Typography, { color: "textPrimary", variant: "h1", className: classes.title, children: t("title") }), _jsxs(Typography, { color: "textPrimary", className: classes.text, children: [t("desc"), " ", _jsx("a", { href: brand.termsOfUseLink, target: "_blank", rel: "noopener noreferrer", className: classes.link, children: t("lumarTerms") }), "."] }), _jsxs(Box, { className: classes.buttonsWrapper, children: [_jsx(Button, { "data-testid": "decline-terms", className: classes.button, variant: "outlined", disabled: loading, onClick: decline, children: t("decline") }), _jsx(Button, { "data-testid": "accept-terms", className: clsx(classes.button, classes.containedButton), variant: "contained", color: "secondary", loading: loading, onClick: () => !loading && agree(), children: t("agree") })] })] }));
}
