var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import { isBoolean, isFunction } from "lodash";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { XCircleV2 } from "../../../../icons/lumar/XCircleV2";
import { isPathMatch } from "../../../helpers";
import clsx from "clsx";
const useStyles = makeStyles((theme) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        menuItem: Object.assign({ width: 30, height: 30, borderRadius: 6, position: "relative", cursor: "pointer", margin: theme.spacing(0.375), backgroundColor: "transparent", color: "#8F8DB8", "&:hover": {
                backgroundColor: "#353366",
                color: "#F2EAFF",
            }, "button&": {
                borderStyle: "none",
                padding: 0,
            } }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.AppSwitcherMainAppMenuItem) === null || _b === void 0 ? void 0 : _b.menuItem),
        menuItemSelected: Object.assign({ backgroundColor: "#353366", color: "#F2EAFF" }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.AppSwitcherMainAppMenuItem) === null || _d === void 0 ? void 0 : _d.menuItemSelected),
        action: Object.assign({ "&:hover": {
                backgroundColor: "transparent",
                "& $icon": {
                    fill: `${theme.palette.ultraviolet[50]}33`,
                },
            }, "&$menuItemSelected": {
                backgroundColor: "transparent",
                "& $icon": {
                    fill: `${theme.palette.ultraviolet[50]}33`,
                },
            } }, (_f = (_e = theme.overrides) === null || _e === void 0 ? void 0 : _e.AppSwitcherMainAppMenuItem) === null || _f === void 0 ? void 0 : _f.action),
        icon: {
            width: 22,
            height: 22,
        },
        appIconCountBadge: {
            borderRadius: 22.5,
            position: "absolute",
            backgroundColor: theme.palette.red[600],
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: 13,
            lineHeight: theme.typography.pxToRem(16),
            padding: "0px 4px 0px 4px",
            top: "-5px",
            right: "-11px",
        },
        tooltip: {
            background: "#020034",
            marginLeft: 26,
        },
        tooltipArrow: {
            color: "#020034",
        },
    });
});
export function MainAppMenuItem({ item, pendoPrefix, isAction, isMain, }) {
    const { pathname, search } = useLocation();
    const isSelected = React.useCallback(() => {
        return isFunction(item.isSelected)
            ? item.isSelected(pathname, search)
            : isBoolean(item.isSelected)
                ? item.isSelected
                : isPathMatch(pathname, search, item.href);
    }, [item, pathname, search]);
    const [selected, setSelected] = useState(isSelected());
    const classes = useStyles();
    useEffect(() => {
        if (item.onClick)
            return;
        setSelected(isSelected());
    }, [pathname, search, item.href, item.onClick, isSelected]);
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function getProps() {
        if (item.onClick)
            return {
                component: "button",
                "aria-label": item.name,
                MenuIcon: selected ? XCircleV2 : item.icon,
                onClick: () => {
                    var _a;
                    const result = (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item, {
                        state: selected,
                        setState: setSelected,
                    });
                    if (isBoolean(result))
                        setSelected(result);
                },
            };
        if (isMain)
            return {
                MenuIcon: item.icon,
                component: Link,
                to: item.href,
            };
        return {
            MenuIcon: item.icon,
            component: "a",
            href: item.href,
        };
    }
    const _a = getProps(), { MenuIcon } = _a, props = __rest(_a, ["MenuIcon"]);
    return (_jsx(Tooltip, { title: item.name, placement: "right", arrow: true, classes: { tooltip: classes.tooltip, arrow: classes.tooltipArrow }, children: _jsxs(Grid, Object.assign({}, props, { item: true, container: true, alignItems: "center", justifyContent: "center", direction: "column", className: clsx(classes.menuItem, {
                [classes.menuItemSelected]: selected,
                [classes.action]: isAction,
            }), "data-testid": item.testId, "data-pendo": pendoPrefix
                ? `${pendoPrefix}-main-app-menu-item-${item.id}`
                : undefined, children: [item.countBadgeValue ? (_jsx("div", { className: classes.appIconCountBadge, children: _jsx(item.countBadgeValue, {}) })) : null, _jsx(MenuIcon, { className: classes.icon })] })) }, item.id));
}
