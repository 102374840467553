var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useAcceptTermsAndConditionsMutation } from "../graphql";
import { TermsAndConditionsDialog } from "../components";
import { useBrand } from "../brand/BrandProvider";
export function TermsAndConditions({ accepted: accepredInitial, logout, }) {
    const brand = useBrand();
    const [accepted, setAccepted] = React.useState(accepredInitial);
    const [acceptTermsAndConditions, { loading }] = useAcceptTermsAndConditionsMutation({});
    if (accepted || !brand.checkLegalTerms)
        return _jsx(_Fragment, {});
    return (_jsx(TermsAndConditionsDialog, { agree: () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield acceptTermsAndConditions();
            if (((_a = result.data) === null || _a === void 0 ? void 0 : _a.acceptTermsAndConditions.termsAgreed) === true) {
                setAccepted(true);
            }
        }), decline: logout, loading: loading }));
}
