import { format } from "date-fns";
import { BuildScheduleRepetitionRate } from "../../../graphql";

export function formatSchedulingInterval(
  timeStamp: Date,
  intervalToLocate: BuildScheduleRepetitionRate | null,
) {
  if (intervalToLocate === BuildScheduleRepetitionRate.EveryWeekday) {
    return "Every weekday (Monday to Friday)";
  }
  if (intervalToLocate === BuildScheduleRepetitionRate.Weekly) {
    const dayOfWeek = format(timeStamp, "iiii");
    return `Weekly on ${dayOfWeek}s`;
  }
  if (intervalToLocate === BuildScheduleRepetitionRate.Hourly) {
    return "Every hour";
  }
  if (intervalToLocate === BuildScheduleRepetitionRate.EveryFourHours) {
    return "Every 4 hours";
  }
  if (intervalToLocate === BuildScheduleRepetitionRate.EveryTwelveHours) {
    return "Every 12 hours";
  }

  return intervalToLocate || "Does not repeat";
}

export function getTimeDescription(timeStamp: string | Date) {
  const timeStampAsDate = new Date(timeStamp);
  const time = format(timeStampAsDate, "h:mmaaaa").split(".").join("");
  const date = format(timeStampAsDate, "MMM d, yyyy");

  return `${date} at ${time}`;
}
