var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from "@apollo/client";
import React from "react";
import { composedSessionQuery } from "./composedSessionQuery";
/**
 * @author Alex Sánchez
 * @deprecated Needs refactor to remove unused code portions and optimize fetching
 */
export function useComposedSessionQuery(getToken, preferredAccountId, skip) {
    const client = useApolloClient();
    const fetch = React.useCallback(() => {
        if (skip)
            return;
        setResult((result) => (Object.assign(Object.assign({}, result), { loading: true, error: undefined })));
        composedSessionQuery(client, preferredAccountId)
            .then((data) => setResult({ loading: false, data, error: undefined, refetch: fetch }))
            .catch((error) => __awaiter(this, void 0, void 0, function* () {
            // NOTE: If by the time the error comes back, the session token no longer exists, then we
            // want to discard this error as it is the result of an earlier request that was made when
            // we still had a a session token (has has since been removed. - Saul.
            // IMPROVEMENT: Could cancel the previous call to composedSessionQuery on subsequent calls
            // so we wouldn't have to discard the response this way. - Saul.
            const token = yield getToken();
            if (token) {
                setResult({ loading: false, error, data: undefined, refetch: fetch });
            }
            else {
                setResult({
                    loading: false,
                    error: undefined,
                    data: undefined,
                    refetch: fetch,
                });
            }
        }));
    }, [client, preferredAccountId, getToken, skip]);
    const [result, setResult] = React.useState({
        loading: true,
        refetch: fetch,
        error: undefined,
        data: undefined,
    });
    React.useEffect(() => {
        /**
         * Declare an internal method to be able to call async method
         * within the context of the useEffect. Would need to refactor this
         * code afterwards to remove unnecesary token checks (it should be
         * handle already by the Apollo client)
         * @author Alex Sánchez
         */
        const handleResult = () => __awaiter(this, void 0, void 0, function* () {
            const token = yield getToken();
            if (!token) {
                setResult({
                    loading: false,
                    data: undefined,
                    error: undefined,
                    refetch: fetch,
                });
            }
            else {
                fetch();
            }
        });
        handleResult();
    }, [fetch, getToken]);
    return result;
}
