import React, { useState } from "react";
import { Button, Menu, MenuItem, useTheme } from "@material-ui/core";
import { TemplateSettingsType } from "./NoTemplateSettingsView";
import { ConfirmationDialogWarningIcon, PlusSolid } from "@lumar/shared";

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { ConfirmationDialogue } from "./ConfirmationDialogue";

interface TemplateSettingsMenuProps {
  openTemplateDialog: () => void;
  updateTemplateSettingsType: (templateType: TemplateSettingsType) => void;
}

export function TemplateSettingsMenu({
  openTemplateDialog,
  updateTemplateSettingsType,
}: TemplateSettingsMenuProps): JSX.Element {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "smart-threshold-popup",
  });
  const { onClick: openPopup, ...triggerProps } = bindTrigger(popupState);

  const theme = useTheme();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  function openTemplateMenu(event: React.MouseEvent<HTMLButtonElement>): void {
    setMenuAnchorEl(event.currentTarget);
  }

  function closeTemplateMenu(): void {
    setMenuAnchorEl(null);
  }

  function handleMenuItemClick(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    templateType: TemplateSettingsType,
  ) {
    updateTemplateSettingsType(templateType);
    openPopup(e);
  }

  function handleContinueButtonClick() {
    popupState.close();
    closeTemplateMenu();
    openTemplateDialog();
  }

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        aria-controls="template-settings-menu"
        aria-haspopup="true"
        size="small"
        onClick={openTemplateMenu}
        startIcon={<PlusSolid />}
        data-testid="link-test-suite-button"
        data-pendo="auto-test-suite-edit-template-settings-link"
      >
        Template options
      </Button>
      <Menu
        id="template-settings-menu"
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={closeTemplateMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          {...triggerProps}
          onClick={(e) => {
            handleMenuItemClick(e, TemplateSettingsType.AddChildren);
          }}
          data-testid="use-as-global-template"
          data-pendo="auto-test-suite-edit-template-settings-link-as-global-template"
        >
          Create global template
        </MenuItem>
        <MenuItem
          {...triggerProps}
          onClick={(e) => {
            handleMenuItemClick(e, TemplateSettingsType.AddParent);
          }}
          data-testid="apply-global-template"
          data-pendo="auto-test-suite-edit-template-settings-link-apply-global-template"
        >
          Apply global template
        </MenuItem>
      </Menu>

      <ConfirmationDialogue
        PopoverProps={bindPopover(popupState)}
        title="Smart threshold settings will be disabled"
        description="If you continue, smart threshold settings will be disabled for all your tests in this test suite."
        icon={
          <ConfirmationDialogWarningIcon
            style={{ color: theme.palette.yellow[400], margin: -6 }}
          />
        }
      >
        <Button
          onClick={popupState.close}
          variant="outlined"
          data-testid="popover-dialog-button-cancel"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleContinueButtonClick}
          variant="contained"
          color="primary"
          data-testid="popover-dialog-button-ok"
          size="large"
        >
          Continue
        </Button>
      </ConfirmationDialogue>
    </>
  );
}
