import React from "react";
import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  makeStyles,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

interface CollapseHeaderProps {
  name: string;
  children: React.ReactNode;
  isOpen: boolean;
  testId?: string;
  onClick: () => void;
  pendoId: string;
  ButtonProps?: ButtonProps;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginLeft: -15,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.ultraviolet[500],
    },
  },
}));

export function CollapseHeader(props: CollapseHeaderProps) {
  const { name, children, isOpen, testId, onClick, ButtonProps } = props;
  const classes = useStyles();

  return (
    <>
      <Box component="div" data-pendo={props.pendoId}>
        <Button
          className={classes.root}
          {...ButtonProps}
          color="primary"
          size="large"
          onClick={onClick}
          endIcon={isOpen ? <RemoveCircleIcon /> : <AddCircleIcon />}
          data-cy={testId}
          data-testid={testId}
        >
          {name}
        </Button>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  );
}
