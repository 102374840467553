import { useEffect, useState } from "react";
import { AppIds } from "@lumar/shared";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useAutomatorSession } from "../../../_common/hooks/useAutomatorSession/useAutomatorSession";
import { Routes } from "../../../_common/routes/routes";
import { useHistory } from "react-router-dom";
import { LocalStorageKeys } from "../../../_common/constants/localStorageKeys";
import { setLastSelectedAccount } from "../../../_common/utils/LastSelectedAccount/setLastSelectedAccount";

export function useSelectedAccountId(): [string, (accountId: string) => void] {
  const session = useAutomatorSession();
  const history = useHistory();
  const accountIdFromUrl = useAccountRouteMatch();

  const lastSelectedAccountId = localStorage.getItem(
    LocalStorageKeys.LAST_SELECTED_ACCOUNT,
  );

  const isLastSelectedAccountValid = session.allRelationships.some(
    ({ account }) => account.id === lastSelectedAccountId,
  );

  const [value, setValue] = useState<string>(
    isLastSelectedAccountValid && lastSelectedAccountId
      ? lastSelectedAccountId
      : session.allRelationships[0]?.account.id,
  );

  const isLoginPath = location.pathname === Routes.Login.ROUTE;
  const hasAutomateAccess = session.hasApp(AppIds.protect);
  useEffect(() => {
    // FIXME: this is not ideal, but we need to ensure that the user is not left on the /login path if they don't have Automator access
    if (isLoginPath && value && !hasAutomateAccess) {
      history.push(Routes.Dashboard.getUrl({ accountId: value }));
    }
  }, [history, isLoginPath, value, hasAutomateAccess]);

  useEffect(() => {
    if (accountIdFromUrl) {
      setValue(accountIdFromUrl);
      setLastSelectedAccount(accountIdFromUrl);
    }
  }, [accountIdFromUrl, value]);

  useEffect(() => {
    // FIXME: update the `doesUserMatchRole` function to get the role from the session
    // Then we will no longer need to set the user role in local storage
    localStorage.setItem(LocalStorageKeys.USER_ROLE, session.role.code);
  }, [session]);

  return [value, setValue];
}
