import { AddonAvailability } from "../SessionContext.types";
/** Addons need to be idenfitied by addon's settings code as addon code
 *  is a mix of the name of the addon + the subscription tier and the
 *  source of truth is the settings code.
 */
export function getAddonAvailabilityFactory(addons) {
    return function getAddonAvailability(code) {
        if (addons.some((addon) => addon.settings.find((setting) => setting.code === code) &&
            !addon.prices.find((price) => price.price > 0))) {
            return AddonAvailability.Free;
        }
        if (addons.some((addon) => addon.settings.find((setting) => setting.code === code) &&
            addon.prices.find((price) => price.price > 0))) {
            return AddonAvailability.Paid;
        }
        return AddonAvailability.None;
    };
}
