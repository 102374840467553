import React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";
import { doesUserMatchRole } from "../../utils/doesUserMatchRole/doesUserMatchRole";
import { RoleCode } from "../../../graphql";

interface DisableForViewerProps {
  /**
   * Should be some sort of user input such as a `Button` or `TextField`. It will disable the input and wrap it in a tooltip if the user
   * has a role of view-only.
   */
  children: React.ReactNode;
  /**
   * If you want to include a tooltip for the child component when the user is not a view-only user, you can provide a tooltip title here.
   */
  tooltipTitle?: TooltipProps["title"];
}

/**
 * A wrapper component that disables the child component for view-only users, and wraps the child in a tooltip explaining why the
 * component is disabled. Usually used for elements such as buttons.
 */
export function DisableForViewer({
  children,
  tooltipTitle,
}: DisableForViewerProps): JSX.Element {
  const isViewerRole = doesUserMatchRole(RoleCode.Viewer);

  if (isViewerRole) {
    return (
      <Tooltip
        title="Looks like you don't have permission to perform this action. Please contact your account admin or contact support if you need help."
        data-testid="no-permissions-tooltip"
      >
        <span>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return React.cloneElement(child as any, { disabled: true });
            }
            return null;
          })}
        </span>
      </Tooltip>
    );
  }

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} data-testid="button-tooltip">
        <span>{children}</span>
      </Tooltip>
    );
  }

  return <>{children}</>;
}
