import React from "react";
import {
  Box,
  InputLabel,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { ToggleIconButton, DuplicateSolid, Snackbar } from "@lumar/shared";

export interface CopyBlockProps {
  label: string;
  text: string;
  copyMessage: string;
}

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
  },
  textFieldWrapper: {
    marginRight: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1)}px - 36px)`,
  },
}));

export function CopyBlock(props: CopyBlockProps): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function copyToClipboard(): void {
    navigator.clipboard.writeText(props.text);
    enqueueSnackbar(<Snackbar title={props.copyMessage} variant="success" />);
  }

  return (
    <Box display="flex" alignItems="end">
      <Box className={classes.textFieldWrapper}>
        <InputLabel className={classes.inputLabel}>Test suite ID</InputLabel>
        <TextField
          fullWidth
          data-cy="test-suite-id"
          variant="outlined"
          data-testid="test-suite-id"
          value={props.text}
          inputProps={{
            readOnly: true,
          }}
        />
      </Box>

      <Tooltip title="Copy test suite ID">
        <span>
          <ToggleIconButton
            data-testid="copy-block-action"
            data-cy="copy-block-action"
            data-pendo="auto-step5-copy-testsuite-id"
            onClick={copyToClipboard}
            variant="outlined"
            size="large"
          >
            <DuplicateSolid />
          </ToggleIconButton>
        </span>
      </Tooltip>
    </Box>
  );
}
