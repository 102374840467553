import {
  GetTestSuiteTestsAndCustomExtractionsQuery,
  ModuleCode,
  ReportTemplateUnit,
} from "../../../../graphql";

export const CUSTOM_EXTRACTION_CATEGORY = "custom-extractions";

export interface SuiteTestNodesExtended
  extends Omit<
    NonNullable<
      GetTestSuiteTestsAndCustomExtractionsQuery["node"]
    >["tests"]["nodes"][0],
    "reportTemplate"
  > {
  reportTemplate: Omit<
    NonNullable<
      GetTestSuiteTestsAndCustomExtractionsQuery["node"]
    >["tests"]["nodes"][0]["reportTemplate"],
    "metadata"
  > & {
    id: string;
    category: string;
    unit: ReportTemplateUnit;
  };
}

export interface ExtendedTest {
  data: SuiteTestNodesExtended;
  extended: {
    selected: boolean;
    category: string;
    expanded: boolean;
    recomended: boolean;
    moduleCode: ModuleCode;
  };
}

export interface TestCategory {
  tests: ExtendedTest[];
  name: string;
  code: string;
  selected: boolean;
}

export interface ChooseTestsFilterOptions {
  showSelected: boolean;
  showAllCategories: boolean;
  textToSearchBy: string;
}
