var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { IconButton, makeStyles, } from "@material-ui/core";
import { useBrand } from "../../brand/BrandProvider";
function getColors(theme, brandId, colorVariant) {
    switch (colorVariant) {
        case "red":
            return {
                color: theme.palette.red[600],
                hoverBackgroundColor: theme.palette.red[100],
                activeBackgroundColor: theme.palette.red[200],
            };
        case "grey":
            return {
                color: theme.palette.grey[700],
                hoverBackgroundColor: theme.palette.grey[200],
                activeBackgroundColor: theme.palette.grey[200],
            };
        case "blue":
            return {
                color: theme.palette.blue[600],
                hoverBackgroundColor: theme.palette.blue[100],
                activeBackgroundColor: theme.palette.blue[200],
            };
        case "primary":
            if (brandId === "similarweb") {
                return {
                    color: theme.palette.primary.main,
                    hoverBackgroundColor: "#3E74FE1A",
                    activeBackgroundColor: "#3E74FE1A",
                };
            }
            return {
                color: theme.palette.primary.main,
                hoverBackgroundColor: theme.palette.secondary.main,
                activeBackgroundColor: theme.palette.ultraviolet[200],
            };
        default:
            return {
                color: "inherit",
                hoverBackgroundColor: "inherit",
                activeBackgroundColor: "inherit",
            };
    }
}
const sizeMap = {
    small: 24,
    medium: 32,
    large: 36,
};
const iconSizeMap = {
    small: 18,
    medium: 19,
    large: 21.5,
};
const useStyles = makeStyles((theme) => ({
    root: ({ variant, active, colorVariant, size, brandId }) => {
        const { color, activeBackgroundColor, hoverBackgroundColor } = getColors(theme, brandId, colorVariant);
        const buttonSize = sizeMap[size !== null && size !== void 0 ? size : "medium"];
        const border = variant === "outlined"
            ? `1px solid ${active ? activeBackgroundColor : theme.palette.grey[300]}`
            : "none";
        const hoverColor = active ? activeBackgroundColor : hoverBackgroundColor;
        return {
            borderRadius: 6,
            color: active ? color : theme.palette.grey[700],
            backgroundColor: active ? activeBackgroundColor : "white",
            height: buttonSize,
            width: buttonSize,
            border,
            "& svg": {
                fontSize: iconSizeMap[size !== null && size !== void 0 ? size : "medium"],
            },
            "&:hover": {
                color,
                borderColor: hoverColor,
                backgroundColor: hoverColor,
            },
        };
    },
}));
export const ToggleIconButton = React.forwardRef(function ToggleIconButton(_a, ref) {
    var { variant = "standard", colorVariant = "primary", active = false, size = "medium" } = _a, iconProps = __rest(_a, ["variant", "colorVariant", "active", "size"]);
    const { brandId } = useBrand();
    const classes = useStyles({ variant, active, colorVariant, size, brandId });
    return _jsx(IconButton, Object.assign({ ref: ref, classes: classes }, iconProps));
});
