import React from "react";
import { InfoOutlined } from "@lumar/shared";
import {
  makeStyles,
  SvgIconProps,
  Tooltip,
  TooltipProps,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: "490px",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.grey[400],
    fontSize: 19,
  },
}));

interface InfoTooltipProps extends Omit<TooltipProps, "children"> {
  IconProps?: SvgIconProps;
}

export function InfoTooltip({
  IconProps,
  ...tooltipProps
}: InfoTooltipProps): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.customWidth }} {...tooltipProps}>
      <span className={classes.wrapper}>
        <InfoOutlined {...IconProps} className={classes.icon} />
      </span>
    </Tooltip>
  );
}
