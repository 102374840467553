import { GetNextTestSuitesQuery } from "../../../graphql";

export function getPageInfo(testSuiteData: GetNextTestSuitesQuery | undefined) {
  return (
    testSuiteData?.getAccount?.testSuites?.pageInfo || {
      startCursor: null,
      endCursor: null,
    }
  );
}
