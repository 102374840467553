import React from "react";
import { useLocation } from "react-router-dom";
export function assert(
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
condition, errorMessage = "") {
    if (!condition) {
        throw new Error(errorMessage ? `Assertion Error: ${errorMessage}` : "Assertion Error");
    }
}
export function useURLSearchParams() {
    const location = useLocation();
    return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
}
export function isPathMatch(path, search, link) {
    const searchIndex = link.indexOf("?");
    const linkPath = searchIndex > 0 ? link.slice(0, searchIndex) : link;
    if (path !== linkPath)
        return false;
    if (searchIndex > 0) {
        const searchParams = search
            .replace(/^\?/, "")
            .split("&")
            // We don't care about the language.
            .filter((param) => !param.includes("lng="));
        const linkSearchParams = link
            .slice(searchIndex + 1)
            .split("&")
            // We don't care about the language.
            .filter((param) => !param.includes("lng="));
        if (linkSearchParams.find((param) => !searchParams.includes(param)))
            return false;
    }
    return true;
}
