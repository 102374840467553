import React, { useContext, useEffect, useState } from "react";
import { TestCategory } from "./../CreateTests.interfaces";
import { useTheme } from "@material-ui/core";
import {
  areAnyTestsSelected,
  getTestsByTemplateId,
  nonMutationSortByTestName,
} from "../CreateTestsCommonUtils";
import {
  TestCollapse,
  Threshold,
} from "../../../../../_common/components/TestCollapse/TestCollapse";
import { ExtendedTest } from "../CreateTests.interfaces";
import { Severity } from "../../../../../graphql";
import {
  getNewTestFromInput,
  isThresholdAndSeverityDifferent,
} from "./CollapsableTestsListUtil";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { useGradualNumber } from "../../../../../_common/hooks/useGradualNumber/useGradualNumber";
import { BufferedLinearProgress } from "../../../../../_common/components/BufferedLinearProgress/BufferedLinearProgress";
import { numericSort } from "../../../../../_common/utils/array/numeric_sort/numericSort";
import { Alert } from "@lumar/shared";

export interface CollapsableTestsListProps {
  absoluteThresholdUpLimit: number;
  noItemsMessage?: string;
  noItemsMessageId?: string;
  categories: TestCategory[];
  isJiraIntegrationConnected: boolean;
  clearSubmitError: () => void;
  renderingProgressed?: () => void;
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
}

//eslint-disable-next-line max-lines-per-function
export function CollapsableTestsList(props: CollapsableTestsListProps) {
  const [renderingStarted] = useState(new Date().getTime());
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const theme = useTheme();

  const totalTestsToShow = nonMutationSortByTestName(
    props.categories.flatMap((category) => category.tests),
  ).filter((test) => test.extended.selected);

  const { value: maxTestsShown, clear: clearTimeoutForCategoriesRender } =
    useGradualNumber(renderingStarted, totalTestsToShow.length, 20, 250);

  useEffect(() => {
    if (props.renderingProgressed) props.renderingProgressed();
  }, [props, maxTestsShown]);

  const testsToShow = totalTestsToShow.slice(0, maxTestsShown);
  const testToShowNumericalFiltered = numericSort(testsToShow);

  useEffect(() => {
    return function cleanup() {
      clearTimeoutForCategoriesRender();
    };
  }, [clearTimeoutForCategoriesRender]);

  function handleTestUpdate(test: ExtendedTest) {
    props.clearSubmitError();
    chooseTestsFormDispatch({ type: "TEST_UPDATE", payload: { test } });
  }

  function handleThresholdChange(
    test: ExtendedTest,
    input: {
      threshold: Threshold;
      severity: Severity;
      expanded: boolean;
    },
  ) {
    const testToUpdate = getNewTestFromInput(test, input);
    const testToCompare = getTestsByTemplateId(
      props.categories,
      test.data.reportTemplate.code,
    );

    if (isThresholdAndSeverityDifferent(testToCompare, testToUpdate)) {
      handleTestUpdate(testToUpdate);
    }
  }

  function handleEnableJiraChange(
    test: ExtendedTest,
    input: {
      createJiraTicketOnFailure: boolean | null;
      jiraTicketCustomNote: string | null;
    },
  ) {
    const testToUpdate: ExtendedTest = {
      ...test,
      data: {
        ...test.data,
        createJiraTicketOnFailure: Boolean(input.createJiraTicketOnFailure),
        jiraTicketCustomNote: input.jiraTicketCustomNote,
      },
    };

    handleTestUpdate(testToUpdate);
  }

  function handleSmartThresholdChange(
    test: ExtendedTest,
    isSmartThresholdEnabled: boolean,
  ) {
    const updatedTest: ExtendedTest = {
      ...test,
      data: {
        ...test.data,
        automaticThresholdEnabled: isSmartThresholdEnabled,
      },
    };
    handleTestUpdate(updatedTest);
  }

  const showLoader =
    totalTestsToShow.length !== 0 && maxTestsShown < totalTestsToShow.length;

  return (
    <div style={{ marginBottom: theme.spacing(5) }}>
      {showLoader && (
        <div>
          <div
            style={{ marginBottom: theme.spacing(1) }}
          >{`Loading tests: ${maxTestsShown} out of ${totalTestsToShow.length}.`}</div>
          <div style={{ marginBottom: theme.spacing(1) }}>
            <BufferedLinearProgress
              completed={maxTestsShown}
              totalCount={totalTestsToShow.length}
              buffered={totalTestsToShow.length - maxTestsShown}
            />
          </div>
        </div>
      )}

      {areAnyTestsSelected(props.categories) ? (
        testToShowNumericalFiltered.map((test) => (
          <div
            key={test.data.reportTemplate.code + "-collapsable"}
            data-testid={test.data.reportTemplate.code + "-collapsable"}
            style={{ marginBottom: theme.spacing(1) }}
          >
            <TestCollapse
              absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
              isJiraIntegrationConnected={props.isJiraIntegrationConnected}
              test={test}
              onChange={(input) => {
                handleThresholdChange(test, input);
              }}
              handleEnableJiraChange={(input) => {
                handleEnableJiraChange(test, input);
              }}
              showSmartThresholdSettings={
                !props.smartThresholdSettings.enabledForAllTests &&
                props.smartThresholdSettings.isEnabled
              }
              handleSmartThresholdChange={handleSmartThresholdChange}
            />
          </div>
        ))
      ) : (
        <Alert
          severity="info"
          data-cy={props.noItemsMessageId || "no-selected-tests-label"}
          data-testid="no-selected-tests-label"
        >
          {props.noItemsMessage ??
            "You have no other tests selected. If you want to add other tests, please select it from the list of tests on the left."}
        </Alert>
      )}
    </div>
  );
}
