import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable i18next/no-literal-string */
export const ErrorDisplay = () => {
    return (_jsxs("div", { style: {
            display: "flex",
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            overflow: "hidden",
            backgroundColor: "#f0f3f7",
        }, children: [_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", fill: "currentColor", style: { width: 72, height: 72, color: "red" }, children: _jsx("path", { fillRule: "evenodd", d: "M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z", clipRule: "evenodd" }) }), _jsx("p", { style: {
                    fontSize: "2rem",
                    fontWeight: "bold",
                    marginBottom: 16,
                    color: "#111827",
                    fontFamily: '"Inter", sans-serif',
                    lineHeight: 1.167,
                }, children: "Something went wrong" }), _jsx("p", { style: {
                    fontSize: "1rem",
                    fontFamily: '"Inter", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    color: "#111827",
                    marginBottom: 40,
                }, children: "Our team has been notified about this error. Please try again later." }), _jsxs("div", { style: { display: "flex" }, children: [_jsx("button", { style: {
                            margin: 8,
                            color: "#374151",
                            border: "unset",
                            padding: "7.5px 16px",
                            boxShadow: "inset 0px 0px 0px 1px #D1D5DB",
                            backgroundColor: "#FFF",
                            fontSize: "0.875rem",
                            minWidth: 64,
                            boxSizing: "border-box",
                            transition: "none",
                            fontFamily: '"Inter", sans-serif',
                            fontWeight: 500,
                            lineHeight: 1.7,
                            borderRadius: 6,
                            textTransform: "none",
                            verticalAlign: "baseline",
                            cursor: "pointer",
                        }, onClick: () => window.history.back(), children: "Go back" }), _jsx("button", { style: {
                            margin: 8,
                            color: "#6933C4",
                            border: "unset",
                            padding: "7.5px 16px",
                            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                            backgroundColor: "#EAE4F5",
                            fontSize: "0.875rem",
                            minWidth: 64,
                            boxSizing: "border-box",
                            transition: "none",
                            fontFamily: '"Inter", sans-serif',
                            fontWeight: 500,
                            lineHeight: 1.7,
                            borderRadius: 6,
                            textTransform: "none",
                            verticalAlign: "baseline",
                            cursor: "pointer",
                        }, onClick: () => window.location.assign("/"), children: "Go to main page" })] })] }));
};
