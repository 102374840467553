import { useEffect } from "react";
export function setDocumentTitle(title) {
    // eslint-disable-next-line fp/no-mutation
    document.title = title;
}
export function useDocumentTitle(title) {
    useEffect(() => {
        // eslint-disable-next-line fp/no-mutation
        setDocumentTitle(title);
    }, [title]);
}
