import { Container, makeStyles } from "@material-ui/core";
import { AppRoutesPublic } from "./AppRoutesPublic";

const useStyles = makeStyles({
  fullSizeContainer: {
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0",
  },
});

export function LoggedOutView(): JSX.Element {
  const classes = useStyles();

  return (
    <Container
      maxWidth={false}
      className={classes.fullSizeContainer}
      data-testid="logged-out-container"
    >
      <AppRoutesPublic />
    </Container>
  );
}
