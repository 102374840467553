import React from "react";
import { makeStyles, Typography, withStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface FaqExpansionPanelProps {
  config: { title: string; content: JSX.Element; testId?: string };
  index: number;
  isDefaultExpanded: boolean;
}

const StyledAccordion = withStyles((theme) => ({
  root: {
    border: "1px solid #FFF",
    filter: "none",
    "&.Mui-expanded": {
      margin: "5px 0px",
    },
    "&:before": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  expanded: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 8,
    "& + div": {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  rounded: {
    "&:first-child": {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    "&:last-child": {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
}))(Accordion);

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: 0,
    "&.Mui-expanded": {
      minHeight: 0,
    },
    padding: theme.spacing(4),
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.grey[700],
  },
}))(AccordionSummary);

const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    color: theme.palette.grey[900],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(23),
  },
  accordionDetails: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 4),
  },
}));

export function FaqExpansionPanel(props: FaqExpansionPanelProps) {
  const classes = useStyles();

  return (
    <StyledAccordion defaultExpanded={props.isDefaultExpanded}>
      <StyledAccordionSummary
        aria-controls={`panel${props.index}a-content`}
        id={`panel${props.index}a-header`}
        data-cy={`${props.config.testId}-panel`}
        data-testid={`${props.config.testId}-panel`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h2" className={classes.accordionTitle}>
          {props.config.title}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails
        data-cy={`${props.config.testId}-content`}
        data-testid={`${props.config.testId}-content`}
        className={classes.accordionDetails}
      >
        <Box maxWidth="670px">{props.config.content}</Box>
      </AccordionDetails>
    </StyledAccordion>
  );
}
