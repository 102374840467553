import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  icons: {
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(5),
  },
}));
