import React from "react";
import { useAccountRouteMatch } from "../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { NotYetRunWidget } from "./components/not-yet-run-widget/NotYetRunWidget";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import { useTestSuiteQuery } from "./graphql/useTestSuiteQuery";
import { Fade } from "@material-ui/core";
import { DashboardEmpty } from "./components/DashboardEmpty";
import { Skeleton } from "@material-ui/lab";
import { Alert } from "@lumar/shared";
import { getNotYetRunTestsuitesInfo } from "./utils/getNotYetRunTestsuitesInfo";
import { TestSuiteRunsWidget } from "./components/test-suite-runs-widget/TestSuiteRunsWidget";
import { getAllBuildsFromAccount } from "./utils/getAllBuildsFromAccount";
import { FailPassWarnChartWrapper } from "./components/fails-and-warnings-chart/FailPassWarnChartWrapper";
import { getTestSuitesFromData } from "./utils/getTestSuitesFromData";
import { SeoHealthBar } from "./components/seo-health-bar/SeoHealthBar";
import { AddTestSuiteButton } from "../../_common/components/AddTestSuiteButton/AddTestSuiteButton";
import { GetNextTestSuitesQuery, GetTestSuitesQuery } from "../../graphql";

type BuildInfoBase = NonNullable<
  NonNullable<GetNextTestSuitesQuery["getAccount"]>["testSuites"]
>["edges"][0]["node"]["builds"]["nodes"][0];
export interface BuildInfo extends BuildInfoBase {
  primaryDomain: string;
  name: string;
  testSuiteId: string;
}

const smallColumnWidth = 6;
const largeColumnWidth = 12;

function extractAccount(
  accountId: string,
  testsuiteData: GetTestSuitesQuery | undefined,
): NonNullable<GetTestSuitesQuery["getAccount"]> {
  return (
    testsuiteData?.getAccount || {
      __typename: "Account",
      id: accountId,
      testSuites: null,
    }
  );
}

const ITEMS_PER_REQUEST = 100;

function DashboardContent() {
  const accountId = useAccountRouteMatch();

  const { loading, error, data, lastDataRetirevalAttemptTimestamp } =
    useTestSuiteQuery(accountId, ITEMS_PER_REQUEST);

  const account = extractAccount(accountId, data);
  const notYetRunTestsuitesInfo = getNotYetRunTestsuitesInfo(account);
  const allBuilds = getAllBuildsFromAccount(account);

  const isDashboardEmpty = (account.testSuites?.totalCount || 0) < 1;

  const latestTestRunsGridSize = notYetRunTestsuitesInfo.length
    ? smallColumnWidth
    : largeColumnWidth;

  if (loading) {
    return (
      <Skeleton
        height={700}
        width="100%"
        variant="rect"
        style={{ borderRadius: 6 }}
        data-testid="dashboard-loader"
      />
    );
  }

  if (error) {
    return (
      <Fade in>
        <Alert severity="error" data-testid="dashboard-error">
          An error occurred while trying to load your data. Please refresh the
          page and try again.
        </Alert>
      </Fade>
    );
  }

  if (isDashboardEmpty) {
    return <DashboardEmpty accountId={accountId} />;
  }

  return (
    <Fade in>
      <Box>
        <SeoHealthBar
          accountId={accountId}
          timeStampForDataReload={lastDataRetirevalAttemptTimestamp}
        />
        <Grid container spacing={2}>
          <Grid item md={latestTestRunsGridSize} sm={12} xs={12}>
            <TestSuiteRunsWidget buildsInfo={allBuilds} accountId={accountId} />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <NotYetRunWidget
              testSuites={notYetRunTestsuitesInfo}
              accountId={accountId}
            />
          </Grid>
        </Grid>

        <FailPassWarnChartWrapper
          testSuites={getTestSuitesFromData(data)}
          timeStampForDataReload={lastDataRetirevalAttemptTimestamp}
        />
      </Box>
    </Fade>
  );
}

export function Dashboard(): JSX.Element {
  const accountId = useAccountRouteMatch();

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={1}
      >
        <Typography
          variant="h1"
          data-cy="page-header"
          style={{ fontSize: 26, fontWeight: 500 }}
        >
          Dashboard
        </Typography>
        <AddTestSuiteButton
          dataTestId="page-header-add-test-suite"
          data-pendo="auto-header-dashboard-add-test-suite"
          accountId={accountId}
          dataCy="btn-add-test-suite"
        />
      </Box>

      <Divider style={{ marginBottom: 16 }} />

      <DashboardContent />
    </Box>
  );
}
