import React from "react";
import {
  ResponsiveTabs,
  ResponsiveTabsConfig,
} from "../../../../_common/components/ResposiveTabs/ResponsiveTabs";
import { ConnectViaCiCd } from "./ConnectViaCiCd";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../../../_common/routes/routes";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import { RunTestSuiteNow } from "./components/RunTestSuiteNow";
import { MailSolid, PlayOutlined, ApolloQueryResult } from "@lumar/shared";
import {
  GetTestSuiteQuery,
  GetTestSuiteQueryVariables,
} from "../../../../graphql";

export interface ConnectCiCdToolProps {
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  refetchTestSuite: (
    variables?: GetTestSuiteQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<GetTestSuiteQuery>>;
}

export function ConnectCiCdTool({
  testSuite,
  refetchTestSuite,
}: ConnectCiCdToolProps) {
  const history = useHistory();
  const accountId = useAccountRouteMatch();

  function handleSubmit() {
    history.push(Routes.TestSuites.getUrl({ accountId }));
  }

  const tabs: ResponsiveTabsConfig[] = [
    {
      icon: <MailSolid />,
      label: "Run via CI/CD",
      id: "connect-via-ci-cd",
      content: (
        <ConnectViaCiCd
          testSuiteId={testSuite.id}
          testSuiteName={testSuite.name}
          testSuiteSitePrimary={testSuite.primaryDomain}
        />
      ),
    },
    {
      icon: <PlayOutlined />,
      label: "Run manually",
      id: "run-now",
      content: (
        <RunTestSuiteNow
          refetchTestSuite={refetchTestSuite}
          testSuiteId={testSuite.id}
          disabled={!Boolean(testSuite.testsTotalCount)}
        />
      ),
    },
  ];

  return (
    <div data-cy="connect-cicd-content">
      <div style={{ minHeight: 600 }}>
        <ResponsiveTabs config={tabs} label="Run test suite" />
      </div>

      <StickyWrapper>
        <Button
          data-cy="connect-cicd-save-button"
          data-testid="connect-cicd-save-button"
          data-pendo="auto-testsuite-edit-add-connect-cicd-save-step"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Go to test suites
        </Button>
      </StickyWrapper>
    </div>
  );
}
