import { withStyles, Box, Typography } from "@material-ui/core";

export const Section = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
}))(Box);

export const SectionTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}))(Typography);

export const SectionBody = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 400,
  },
}))(Typography);
