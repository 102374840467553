import { GetTestSuitesQuery } from "../../../graphql";
import { BuildInfo } from "../Dashboard";

export function buildsInfoFactory(
  testSuiteEdge: NonNullable<
    NonNullable<GetTestSuitesQuery["getAccount"]>["testSuites"]
  >["edges"][0],
): BuildInfo[] {
  const builds = testSuiteEdge.node.builds.nodes;
  return builds.map((build) => {
    return {
      name: testSuiteEdge.node.name,
      primaryDomain: testSuiteEdge.node.primaryDomain,
      testSuiteId: testSuiteEdge.node.id,
      ...build,
    };
  });
}
