import React from "react";
import { TestSuiteListItemProps, TestSuiteListItem } from "./TestSuiteListItem";
import { Box, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@lumar/shared";

function getTestSuiteCypressId(suite: TestSuiteListItemProps): string {
  const splitterString = "Copy of ";
  const suiteNameWithoutFrontTimestamp = suite.name.split(splitterString);
  const usableName =
    suiteNameWithoutFrontTimestamp.length > 1
      ? splitterString + suiteNameWithoutFrontTimestamp[1]
      : suite.name;
  return `suite-${usableName.trim().toLocaleLowerCase().split(" ").join("_")}`;
}

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(28.5),
    color: theme.palette.grey[900],
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 19,
    color: theme.palette.grey[500],
  },
  iconWrapper: {
    // Note: this is needed in order for the icon to be center aligned with the title
    height: 19,
  },
  tooltip: {
    padding: "23px 13px",
    maxWidth: 490,
  },
  tooltipHeader: {
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(28.5),
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  tooltipSubheader: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
  ol: {
    paddingInlineStart: 15,
    marginBlockEnd: 22,
  },
  li: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: 1.5,
    marginBottom: theme.spacing(1),
  },
  integrationDetailsWrapper: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.cyan[400],
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.cyan[400],
    },
  },
}));

interface TestSuiteCompactListProps {
  testRuns: TestSuiteListItemProps[];
  refetchData: () => void;
}

export function TestSuiteCompactList(
  props: TestSuiteCompactListProps,
): JSX.Element {
  const classes = useStyles();

  return (
    <Box data-testid="test-suite-compact-list">
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography className={classes.header}>Not yet run</Typography>
        <Tooltip
          arrow
          interactive
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-start"
          title={
            <Box>
              <Typography className={classes.tooltipHeader}>
                No runs/builds for your test suite detected yet
              </Typography>
              <Typography className={classes.tooltipSubheader}>
                Instructions
              </Typography>
              <ol className={classes.ol}>
                <li className={classes.li}>
                  Click &apos;Copy to clipboard&apos; to copy all CI/CD
                  integration details onto your clipboard.
                </li>
                <li className={classes.li}>
                  Paste details into your messaging platform of choice
                  (email/slack/jira etc.) and send to an admin who has access to
                  your CI/CD pipeline. They will need to follow the instructions
                  to complete the integration.
                </li>
              </ol>
              <Box className={classes.integrationDetailsWrapper}>
                <Typography
                  className={classes.tooltipSubheader}
                  style={{ marginBottom: 8 }}
                >
                  CI/CD Integration Details:
                </Typography>
                <a
                  href="https://github.com/deepcrawl/automator-sdk"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.link}
                >
                  https://github.com/deepcrawl/automator-sdk
                </a>
              </Box>
            </Box>
          }
        >
          <span className={classes.iconWrapper}>
            <InfoOutlined className={classes.icon} />
          </span>
        </Tooltip>
      </Box>
      {/* eslint-disable-next-line fp/no-mutating-methods */}
      {[...props.testRuns]
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        .map((suite) => (
          <div
            data-testid={`suite-${suite.id}`}
            data-cy={getTestSuiteCypressId(suite)}
            key={suite.id}
          >
            <TestSuiteListItem {...suite} refetchData={props.refetchData} />
          </div>
        ))}
    </Box>
  );
}
