import { GridSystem } from "../../../../icons";
import { ImpactRoutes } from "../../../../routes/impactRoutes";
import { getRawAccountId } from "../../../../session";
import { AppIds } from "../../../../session/SessionContext.types";
export function getImpactAppSwitcherConfig({ isMain, session, labels, name, }) {
    const enabled = session.hasApp(AppIds.impact) || session.isDeepCrawlAdmin;
    const isExternal = !isMain;
    const accountId = getRawAccountId(session.account.id);
    function getHref(path) {
        return isExternal ? `${ImpactRoutes.Domain}${path}` : path;
    }
    function getIsSelected(isSelected) {
        return isExternal ? false : isSelected;
    }
    return {
        id: AppIds.impact,
        name,
        isMain,
        enabled,
        href: getHref(ImpactRoutes.Projects.getUrl({ accountId })),
        testId: "impact-app-icon",
        items: [
            {
                id: "projects",
                name: labels.allProjects,
                icon: GridSystem,
                href: getHref(ImpactRoutes.Projects.getUrl({ accountId })),
                isSelected: getIsSelected(),
            },
        ],
    };
}
