import React, { useState } from "react";
import { SlackInstructions } from "./SlackInstructions";
import {
  Box,
  Divider,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { AddSlackWebhook } from "./AddSlackWebhook";
import { SlackWebhookRow } from "./SlackWebhookRow";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { GetTestSuiteQuery } from "../../../../../graphql";

export interface SlackTabProps {
  slackWebhooks: NonNullable<
    NonNullable<GetTestSuiteQuery["node"]>["slackWebhooks"]
  >["nodes"];
  testSuiteId: string;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
  },
  addNewButton: {
    minWidth: 120,
    height: 36,
  },
  topDivider: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(6, 0, 3, 0),
  },
  bottomDivider: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(3, 0, 3, 0),
  },
  header: {
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const MAX_NUMBER_OF_WEBHOOKS = 100; // this limit is currently not enforced by the API

export function SlackTab(props: SlackTabProps): JSX.Element {
  const { slackWebhooks } = props;

  const classes = useStyles();
  const [isConfirmationBtnVisible, setIsConfirmationBtnVisible] =
    useState(false);

  function showConfirmationButton(): void {
    setIsConfirmationBtnVisible(true);
  }

  function hideConfirmationButton(): void {
    setIsConfirmationBtnVisible(false);
  }

  function handleAddNewWebhook(): void {
    setIsConfirmationBtnVisible(true);
  }

  return (
    <Box component="div">
      <Box className={classes.header} marginBottom={6}>
        <Typography className={classes.title} color="textPrimary">
          Slack notifications
        </Typography>
        <Button
          startIcon={<AddRoundedIcon />}
          variant="contained"
          color="secondary"
          data-testid="add-new-slack-webhook"
          data-pendo="auto-add-new-slack-webhook-btn"
          onClick={handleAddNewWebhook}
          size="small"
          className={classes.addNewButton}
          disabled={
            isConfirmationBtnVisible ||
            slackWebhooks.length >= MAX_NUMBER_OF_WEBHOOKS
          }
        >
          New webhook
        </Button>
      </Box>
      <SlackInstructions />

      {isConfirmationBtnVisible && (
        <Box marginBottom={2}>
          <Divider className={classes.topDivider} />
          <AddSlackWebhook
            slackWebhooks={slackWebhooks}
            testSuiteId={props.testSuiteId}
            showConfirmationButton={showConfirmationButton}
            hideConfirmationButton={hideConfirmationButton}
          />
        </Box>
      )}

      {slackWebhooks.map((webhook) => (
        <>
          <Divider className={classes.bottomDivider} />
          <SlackWebhookRow key={webhook.id} slackWebhook={webhook} />
        </>
      ))}
    </Box>
  );
}
