import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  withStyles,
} from "@material-ui/core";
import { useStyles } from "./FailPassWarnChartStyles";
import { FailPassWarnChartFilterSettings } from "./FailPassWarnChartFilter";
import { shouldUpdateVisibility } from "./FailPassWarnChartFilterSwitchesUtils";

export interface FailPassWarnChartFilterSwithchesProps {
  settings: FailPassWarnChartFilterSettings;
  onSettingsChange: (newSettings: FailPassWarnChartFilterSettings) => void;
}

export enum TestSuiteShowType {
  PASS = "pass",
  FAIL = "fail",
  WARN = "warn",
}

const FailsSwitch = withStyles((theme) => ({
  switchBase: {
    "&$checked + $track": {
      backgroundColor: theme.palette.red[600],
    },
  },
  checked: {},
  track: { backgroundColor: theme.palette.grey[200], opacity: 1 },
}))(Switch);

const WarningSwitch = withStyles((theme) => ({
  switchBase: {
    "&$checked + $track": {
      backgroundColor: theme.palette.yellow[500],
    },
  },
  checked: {},
  track: { backgroundColor: theme.palette.grey[200], opacity: 1 },
}))(Switch);

const PassSwitch = withStyles((theme) => ({
  switchBase: {
    "&$checked + $track": {
      backgroundColor: theme.palette.green[500],
    },
  },
  checked: {},
  track: { backgroundColor: theme.palette.grey[200], opacity: 1 },
}))(Switch);

// eslint-disable-next-line max-lines-per-function, max-statements
export function FailPassWarnChartFilterSwithches(
  props: FailPassWarnChartFilterSwithchesProps,
): JSX.Element {
  const classes = useStyles();

  function handleVisibilityChange(
    selectedId: TestSuiteShowType,
    checked: boolean,
  ) {
    if (shouldUpdateVisibility(selectedId, checked, props.settings)) {
      props.onSettingsChange({
        ...props.settings,
        show: {
          fails:
            selectedId === TestSuiteShowType.FAIL
              ? checked
              : props.settings.show.fails,
          passes:
            selectedId === TestSuiteShowType.PASS
              ? checked
              : props.settings.show.passes,
          warnings:
            selectedId === TestSuiteShowType.WARN
              ? checked
              : props.settings.show.warnings,
        },
      });
    }
  }

  return (
    <FormGroup row className={classes.justifyRight}>
      <FormControlLabel
        className={classes.switch}
        data-pendo="auto-dashboard-widget-switch-fails"
        classes={{ label: classes.label }}
        control={
          <FailsSwitch
            checked={props.settings.show.fails}
            onChange={(event, value) =>
              handleVisibilityChange(TestSuiteShowType.FAIL, value)
            }
            name="fails"
            data-testid="fails-switch"
          />
        }
        label="Fail"
      />
      <FormControlLabel
        className={classes.switch}
        data-pendo="auto-dashboard-widget-switch-warnings"
        classes={{ label: classes.label }}
        control={
          <WarningSwitch
            checked={props.settings.show.warnings}
            onChange={(event, value) =>
              handleVisibilityChange(TestSuiteShowType.WARN, value)
            }
            name="warnings"
            data-testid="warnings-switch"
          />
        }
        label="Warn"
      />
      <FormControlLabel
        className={classes.switch}
        data-pendo="auto-dashboard-widget-switch-passes"
        classes={{ label: classes.label }}
        control={
          <PassSwitch
            checked={props.settings.show.passes}
            onChange={(event, value) =>
              handleVisibilityChange(TestSuiteShowType.PASS, value)
            }
            name="passes"
            data-testid="passes-switch"
          />
        }
        label="Pass"
      />
    </FormGroup>
  );
}
