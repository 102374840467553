import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { ConnectSiteForm } from "./ConnectSiteForm";
import { TestSuiteVariables } from "./TestSuiteVariables";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { Routes } from "../../../../_common/routes/routes";
import { Snackbar } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../_common/utils/error/getErrorMessage";
import {
  ModuleCode,
  useCreateAccessibilityTestSuiteMutation,
  useCreateSiteSpeedTestSuiteMutation,
  useCreateTestSuiteMutation,
} from "../../../../graphql";

interface Props {
  moduleCode: ModuleCode;
}

export const CreateTestSuite = (props: Props) => {
  const [testSuiteId, setTestSuiteId] = useState<string | null>(null);
  const [createSEOTestSuite] = useCreateTestSuiteMutation();
  const [createAccessibilityTestSuite] =
    useCreateAccessibilityTestSuiteMutation();
  const [createSiteSpeedTestSuite] = useCreateSiteSpeedTestSuiteMutation();
  const accountId = useAccountRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const creationFunctions: Partial<Record<ModuleCode, any>> = {
    [ModuleCode.Seo]: createSEOTestSuite,
    [ModuleCode.Accessibility]: createAccessibilityTestSuite,
    [ModuleCode.SiteSpeed]: createSiteSpeedTestSuite,
  };

  return testSuiteId ? (
    <Redirect to={Routes.EditTestSuite.getUrl({ accountId, testSuiteId })} />
  ) : (
    <ConnectSiteForm
      moduleCode={props.moduleCode}
      onSubmit={async (testSuiteVariables: TestSuiteVariables) => {
        try {
          const createFunction =
            creationFunctions[testSuiteVariables.moduleCode];
          if (!createFunction) return;
          const data = await createFunction({
            variables: {
              ...testSuiteVariables,
              accountId,
              automaticThreshold: false,
              automaticThresholdEnabledForAll: true,
            },
          });
          if (data && data.data) {
            setTestSuiteId(data.data.createTestSuite.testSuite.id);
          }
        } catch (error) {
          enqueueSnackbar(
            <Snackbar variant="error" title={getErrorMessage(error)} />,
          );
        }
      }}
    />
  );
};
