import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import clsx from "clsx";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

import { updateIfPropsChanged } from "@lumar/shared";
import { CheckboxWithLabel } from "../../../../../_common/components/forms/CheckboxWithLabel";
import { Label } from "../../../../../_common/components/Label/Label";
import { RobotsOverwriteSettings } from "./types";
import { SectionTitle } from "../../Section";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { crawlSettingsTooltips } from "../crawlSettingsTooltips";

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  robotsOverwriteField: {
    zIndex: 0,
  },
  robotsOverwriteCheckbox: {
    zIndex: 1,
    marginBottom: theme.spacing(1),
  },
  label: {
    width: "fit-content",
  },
}));

export const RobotsOverwrite = React.memo(RobotsOverwriteInner);

function RobotsOverwriteInner({
  field,
  form,
}: FieldProps<RobotsOverwriteSettings>): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <SectionTitle>Robots.txt </SectionTitle>
        <InfoTooltip
          IconProps={{ style: { marginLeft: 8 } }}
          title={crawlSettingsTooltips.robotsOverwrite}
          data-pendo="auto-tooltip-robots-override"
        />
      </Box>
      <div style={{ display: "flex", flexFlow: "column" }}>
        <div>
          <Field
            name="robotsOverwrite.ignoreRobotsForNavigationRequests"
            component={CheckboxWithLabel}
            disabled={form.isSubmitting}
            type="checkbox"
            Label={{
              label: (
                <Label
                  className={classes.label}
                  label="Ignore robots.txt for navigation requests"
                  info="The robots.txt will not be observed for crawled pages."
                />
              ),
            }}
            data-testid="ignore-robots-for-navigation"
          />
        </div>
        <div>
          <Field
            name="robotsOverwrite.renderingRobotsEnabled"
            component={CheckboxWithLabel}
            disabled={form.isSubmitting}
            type="checkbox"
            Label={{
              label: (
                <Label
                  className={classes.label}
                  label="Ignore robots.txt for resources"
                  info="When this setting is enabled, the robots.txt will be ignored for all resource URLs requested during rendering."
                />
              ),
            }}
            data-testid="rendering-robots-enabled"
          />
        </div>
        <div>
          <Field
            name="robotsOverwrite.enable"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{ label: "Use Robots Overwrite" }}
            disabled={form.isSubmitting || !field.value.robots.length}
            data-testid="use-robots-overwrite"
            shouldUpdate={updateIfPropsChanged("disabled")}
            className={classes.robotsOverwriteCheckbox}
          />
        </div>
        <Typography variant="caption">
          {"Replace the live robots.txt for the "}
          <b>{"primary domain only"}</b>
          {" , with an alternative version in the field below."}
        </Typography>

        <Typography variant="caption" className={classes.indent}>
          {
            "Select 'Use Robots Overwrite' option when starting a crawl to use this version instead of the live robots.txt file."
          }
        </Typography>
        <code className={clsx(classes.example, classes.indent)}>
          {"e.g. User-agent: *\nDisallow: /folder/"}
        </code>

        <div
          data-testid="robots-overwrite-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-robots-override"
          style={{ maxWidth: 800, width: "100%" }}
        >
          <AceEditor
            name="robots-overwrite"
            mode="text"
            value={field.value.robots}
            onChange={(newValue) => {
              form.setFieldValue("robotsOverwrite.robots", newValue);
              form.setFieldValue("robotsOverwrite.enable", newValue.length > 0);
            }}
            readOnly={form.isSubmitting}
            debounceChangePeriod={300}
            editorProps={{ $blockScrolling: Infinity }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
            }}
            theme="tomorrow"
            wrapEnabled
            showGutter
            showPrintMargin={false}
            maxLines={12}
            minLines={12}
            width="100%"
            fontSize={14}
            className={clsx(classes.indent, classes.robotsOverwriteField)}
          />
        </div>
      </div>
    </>
  );
}
