import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function SettingsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="-1 -3 25 25" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3403 2.8539C9.99923 1.44877 8.00087 1.44877 7.65975 2.8539C7.43939 3.76159 6.39945 4.19235 5.6018 3.70633C4.36701 2.95396 2.95396 4.36701 3.70633 5.6018C4.19235 6.39945 3.76159 7.43939 2.8539 7.65975C1.44877 8.00087 1.44877 9.99923 2.8539 10.3403C3.76159 10.5607 4.19235 11.6006 3.70633 12.3983C2.95396 13.6331 4.36701 15.0461 5.6018 14.2938C6.39945 13.8077 7.43939 14.2385 7.65975 15.1462C8.00087 16.5513 9.99923 16.5513 10.3403 15.1462C10.5607 14.2385 11.6006 13.8077 12.3983 14.2938C13.6331 15.0461 15.0461 13.6331 14.2938 12.3983C13.8077 11.6006 14.2385 10.5607 15.1462 10.3403C16.5513 9.99923 16.5513 8.00087 15.1462 7.65975C14.2385 7.43939 13.8077 6.39945 14.2938 5.6018C15.0461 4.36701 13.6331 2.95396 12.3983 3.70633C11.6006 4.19235 10.5607 3.76159 10.3403 2.8539ZM9.00005 11.7C10.4912 11.7 11.7 10.4912 11.7 9.00005C11.7 7.50888 10.4912 6.30005 9.00005 6.30005C7.50888 6.30005 6.30005 7.50888 6.30005 9.00005C6.30005 10.4912 7.50888 11.7 9.00005 11.7Z"
      />
    </SvgIcon>
  );
}
