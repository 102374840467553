import * as yup from "yup";
import { isPast, differenceInSeconds } from "date-fns";

import { BuildScheduleRepetitionRate } from "../../../../graphql";

export interface TestSuiteOption {
  name: string;
  id: string;
}

export enum DoNotRepeatBuildScheduleFrequency {
  Never = "Never",
}

export type ScheduleFrequency =
  | BuildScheduleRepetitionRate
  | DoNotRepeatBuildScheduleFrequency;

export interface ScheduleSettingsInitialValues {
  scheduleName: string;
  testSuite: TestSuiteOption[];
  date: Date;
  frequency: ScheduleFrequency;
}

const allowedFrequencies: ScheduleFrequency[] = [
  DoNotRepeatBuildScheduleFrequency.Never,
  ...Object.values(BuildScheduleRepetitionRate),
];

const scheduleNameValidation = yup
  .string()
  .required("Please add title for scheduled run to continue.")
  .max(70, "Please only use a maximum of 70 characters.");

const testSuitesStructure = yup
  .object()
  .nullable()
  .required("Please choose a test suite to continue.")
  .shape({
    name: yup.string().required("Please choose a test suite to continue."),
    id: yup.string().required("Please choose a test suite to continue."),
  });

const testSuiteValidation = yup
  .array()
  .of(testSuitesStructure)
  .min(1, "Please choose a test suite to continue.");

const testSuiteValidationWithEmpty = yup.array().of(testSuitesStructure);

const frequencyValidation = yup
  .string()
  .required()
  .test(
    "frequency-allowed",
    "Scheduler frequency is not allowed.",
    (value: string | null | undefined) => {
      return !!allowedFrequencies.filter((freq) => freq === value).length;
    },
  );

const dateTimeValidationMessage =
  "Date or time occurs in the past. Please select date and time that is in the future.";

const dateValidation = yup
  .date()
  .required()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .test("date-inpast", dateTimeValidationMessage, (date: any) => {
    return !isPast(date);
  });

function getDateValidationWithInitialDate(
  initialDate: Date,
  initialFrequency: BuildScheduleRepetitionRate | null | undefined,
) {
  const initialFrequencyTransformed =
    initialFrequency === null
      ? DoNotRepeatBuildScheduleFrequency.Never
      : initialFrequency;
  return yup
    .date()
    .required()
    .when("frequency", {
      is: (val: unknown) => {
        return val === initialFrequencyTransformed;
      },
      then: (schema) =>
        schema.test(
          "date-inpast-or-not-as-initial",
          dateTimeValidationMessage,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (date: any) =>
            !isPast(date) ||
            differenceInSeconds(new Date(initialDate), date) === 0,
        ),
      otherwise: (schema) =>
        schema.test(
          "date-inpast",
          dateTimeValidationMessage,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (date: any) => !isPast(date),
        ),
    });
}

export function getValidationSchema(
  initialDate: Date,
  initialFrequency: BuildScheduleRepetitionRate | null | undefined,
) {
  return yup.object().shape({
    scheduleName: scheduleNameValidation,
    testSuite:
      initialDate || initialFrequency
        ? testSuiteValidationWithEmpty
        : testSuiteValidation,
    frequency: frequencyValidation,
    date:
      initialDate || initialFrequency
        ? getDateValidationWithInitialDate(initialDate, initialFrequency)
        : dateValidation,
  });
}
