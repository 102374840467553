import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line max-lines-per-function
export const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 400,
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(14),
    display: "inline",
  },
  darkerText: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  tableHeadings: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(17),
  },
  tableHeadingsBox: {
    textAlign: "left",
    alignItems: "center",
    display: "flex",
    margin: theme.spacing(2, 0),
  },
  margin: {
    margin: theme.spacing(3),
  },
  noTestSuitesText: {
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.navy[300],
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  divider: {
    background: theme.palette.grey[200],
  },
  testSuiteBoxTableHeaderBox: {
    margin: theme.spacing(3, 0, 3, 0),
  },
  timeBoxInfoBlock: {
    marginRight: theme.spacing(8),
    display: "inline-block",
  },
  emptyStateWrapper: {
    maxWidth: 360,
    margin: "0 auto",
    padding: "50px 0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));
