import React from "react";
import { Tooltip, makeStyles, TooltipClassKey } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { PendoTagProps } from "../../../typings/pendo.prototype";

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: ({ maxWidth }: { maxWidth?: number }) => {
    return {
      backgroundColor: theme.palette.primary.light,
      fontSize: "1rem",
      color: theme.palette.text.secondary,
      border: `1px ${theme.palette.grey[50]}`,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2),
      minWidth: "350px",
      maxWidth: maxWidth ? maxWidth + "px" : "450px",
    };
  },
  arrow: {
    color: theme.palette.primary.light,
  },
}));

const useIconStyles = makeStyles((theme) => ({
  root: (props: DeepcrawlTooltipProps) => {
    return {
      color: props.variant === "white" ? "#FFFFFF" : theme.palette.navy[200],
      padding: props.size ? props.size / 2 : 0,
      borderRadius: "4px",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.navy[300],
      },
    };
  },
}));

export interface DeepcrawlTooltipProps extends PendoTagProps {
  content: string | JSX.Element;
  testId?: string;
  cyId?: string;
  size?: number;
  toolTipClasses?: Partial<Record<TooltipClassKey, string>>;
  maxWidth?: number;
  iconComponent?: JSX.Element;
  variant?: "white";
}

export function combineTooltipStypes(
  stylesA: Partial<Record<TooltipClassKey, string>>,
  stylesB: Partial<Record<TooltipClassKey, string>> | undefined,
): Partial<Record<TooltipClassKey, string>> {
  const combinedStyles: Partial<Record<TooltipClassKey, string>> = {
    arrow: stylesB?.arrow ? stylesB.arrow : stylesA.arrow,
    tooltip: stylesB?.tooltip ? stylesB.tooltip : stylesA.tooltip,
  };
  return combinedStyles;
}

export function DeepcrawlTooltip(props: DeepcrawlTooltipProps): JSX.Element {
  const {
    iconComponent,
    testId,
    cyId,
    content,
    toolTipClasses,
    maxWidth,
    ...rest
  } = props;
  const classes: Partial<Record<TooltipClassKey, string>> =
    combineTooltipStypes(useTooltipStyles({ maxWidth }), toolTipClasses);
  const iconClasses = useIconStyles(props);

  return (
    <Tooltip
      classes={classes}
      arrow
      placement="top"
      interactive
      title={content}
      data-testid={testId || "tooltip"}
      data-cy={cyId || "tooltip"}
      {...rest}
    >
      {iconComponent ? (
        iconComponent
      ) : (
        <InfoIcon classes={iconClasses} fontSize="small" />
      )}
    </Tooltip>
  );
}
