import { useState } from "react";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../../_common/utils/mergePageInfo/mergePageInfo";
import {
  GetBuildScheduleWithTestSuiteQuery,
  GetBuildScheduleWithTestSuiteQueryVariables,
  useGetBuildScheduleWithTestSuiteQuery,
} from "../../../../graphql";
import { TEMP_Schedule } from "../SchedulerDetailView";

export function useGetBuildScheduleWithTestSuitesQuery(
  variables: GetBuildScheduleWithTestSuiteQueryVariables,
) {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { data, loading, error, fetchMore, refetch } =
    useGetBuildScheduleWithTestSuiteQuery({
      variables,
      fetchPolicy: "network-only",
    });

  function loadMore() {
    setIsFetchingMore(true);

    const newVariables = {
      ...variables,
      cursor: (data?.node as TEMP_Schedule).buildScheduleTestSuites.pageInfo
        .endCursor,
    };

    fetchMore({
      variables: newVariables,
      updateQuery: (
        previousResult: GetBuildScheduleWithTestSuiteQuery,
        { fetchMoreResult },
      ) => {
        if (
          // FIXME: replace node query after api introduces it
          (fetchMoreResult?.node as TEMP_Schedule & { __typename: string })
            ?.__typename === "BuildSchedule" &&
          (fetchMoreResult.node as TEMP_Schedule).buildScheduleTestSuites
        ) {
          return merge(previousResult, fetchMoreResult, defaultMergeOptions);
        }
        return previousResult;
      },
    }).finally(() => {
      setIsFetchingMore(false);
    });
  }

  return {
    data,
    error,
    loading,
    isFetchingMore,
    loadMore,
    refetch,
  };
}
