import { GetTestSuiteReportQuery } from "../../../graphql";
import { getCustomExtractionIndexInReportTemplates } from "../../../pages/TestSuite/components/ChooseTestsForm/utils/customExtractionWithReportTemplate";

export function getCustomExtractionTestName(
  reportTemplate: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0]["reportTemplate"],
  customExtractions:
    | NonNullable<
        GetTestSuiteReportQuery["node"]
      >["testSuite"]["customExtractions"]
    | undefined
    | null,
): string {
  const index = getCustomExtractionIndexInReportTemplates(
    reportTemplate,
    customExtractions,
  );
  return index > -1 && customExtractions
    ? `Custom Extraction (${customExtractions[index].label})`
    : reportTemplate.name;
}
