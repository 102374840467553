import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  selectsGrid: {
    marginTop: theme.spacing(2),
  },
  datePicker: {
    marginTop: 0,
    marginBottom: 0,
    width: 180,
    "& button": {
      color: theme.palette.grey[800],
      borderRadius: 4,
      padding: 5,
      marginRight: 4,
    },
  },
  durationHidden: {
    display: "none",
  },
  switch: {
    margin: theme.spacing(
      0,
      theme.typography.pxToRem(24),
      theme.typography.pxToRem(22),
      0,
    ),
  },
  customRangeContainer: {
    marginLeft: "0",
  },
  menuPaper: {
    maxHeight: 240,
  },
  selectMinutes: {
    minWidth: 230,
  },
  formControl: {
    height: "57px",
  },
  filterBox: {
    padding: theme.spacing(0, 1.5, 0, 1.5),
    backgroundColor: "#EBEFF3",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  chartBox: {
    padding: theme.spacing(0, 3.5, 0, 3.5),
  },
  justifyRight: {
    justifyContent: "flex-end",
  },
  inputLabel: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    marginLeft: theme.typography.pxToRem(12),
  },
  calendar: {
    color: theme.palette.grey[700],
  },

  menuItem: {
    "&.Mui-focusVisible": {
      boxShadow: "none",
    },
    "&.Mui-selected $listIcon": {
      opacity: 1,
    },
  },
  listIcon: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    width: 15,
    height: 15,
    opacity: 0,
    marginLeft: "auto",
  },
}));
