import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progresssBar: {
      width: "100%",
    },
    selectedItemsBox: {
      marginTop: "28px",
    },
    selectedItemsWrapper: {
      border: `1px solid ${theme.palette.grey[50]}`,
      backgroundColor: "white",
      boxShadow: "none",
      "&::before": {
        content: "",
        display: "none",
      },
    },
    selectedItemsHeader: {
      margin: "0",
      padding: "0 14px",
      "&.Mui-expanded": {
        minHeight: "unset",
        "& .MuiExpansionPanelSummary-content": {
          margin: "0",
        },
      },
    },
    selectedItemsDetails: {
      padding: "0",
    },
    collapsableListHeadingCustomExtraction: {
      marginBottom: theme.spacing(2.75),
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.grey[800],
    },
    collapsableListHeadingOtherTests: {
      marginBottom: theme.spacing(2.75),
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.grey[800],
    },
    submitError: {
      marginTop: theme.spacing(3),
    },
    submittingProgress: {
      marginRight: "10px",
      color: "white",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    icon: {
      color: theme.palette.purple[500],
    },
    containerStart: {
      alignItems: "start",
    },
    padding: {
      padding: theme.spacing(3, 4.25, 3, 3.5),
    },
    filterAndControlsWrapper: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(2, 1),
      borderRadius: 8,
      backgroundColor: "#EBEFF3",
    },
    totalTestsTitle: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      fontWeight: 600,
      marginBottom: 12,
    },
  }),
);
