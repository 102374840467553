import { GetTestSuitesQuery } from "../../../graphql";
import { getTestSuiteInfo } from "./getTestSuiteInfo";

export function getNotYetRunTestsuitesInfo(
  account: NonNullable<GetTestSuitesQuery["getAccount"]>,
) {
  const testSuiteEdges = account.testSuites?.edges || [];
  const testSuitesInfo = testSuiteEdges.map(getTestSuiteInfo);
  // Other functions mutate the testSuite object to include the "NotYetRun" status string.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return testSuitesInfo.filter((testSuite) => testSuite.status === "NotYetRun");
}
