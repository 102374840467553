import { Threshold } from "../TestCollapse/TestCollapse";

export function getHelperTextForAbsoluteThreshold(
  threshold: Threshold,
  absoluteThresholdUpLimit: number,
): string {
  if (isNaN(threshold.absolute) || threshold.absolute < 1) {
    return "Please enter at least 1 URL";
  } else if (threshold.absolute > absoluteThresholdUpLimit) {
    return "Please enter a number lower then 1,000,000";
  } else {
    return "";
  }
}

export function isAbsoluteThresholdInError(
  threshold: Threshold,
  absoluteThresholdUpLimit: number,
): boolean {
  return (
    isNaN(threshold.absolute) ||
    threshold.absolute < 1 ||
    threshold.absolute > absoluteThresholdUpLimit
  );
}
