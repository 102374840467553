import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ResponsiveTabsConfig } from "./ResponsiveTabs";
import { Button } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  box: {
    width: "100%",
    display: "flex",
    padding: "9.5px",
    background: "white",
    boxShadow: "none",
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.navy[50],
    },
    color: theme.palette.navy[500],
    transition: "all 0.2s ease",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "inherit",
      padding: theme.spacing(2, 3.5, 2, 3.5),
      justifyContent: "space-between",
    },
  },
  selectedBox: {
    width: "100%",
    padding: "9.5px",
    marginBottom: theme.spacing(1),
    justifyContent: "flex-start",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

interface ResponsiveTabsMenuProps {
  config: ResponsiveTabsConfig[];
  setSelectedTabId: (selectedTabId: string) => void;
  selectedTabId: string | null;
}

export function ResponsiveTabsMenu(
  props: ResponsiveTabsMenuProps,
): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {props.config.map((tabItem) => {
        return (
          <Button
            startIcon={tabItem.icon}
            variant="contained"
            color="secondary"
            size="small"
            className={clsx(
              classes.selectedBox,
              tabItem.id !== props.selectedTabId && classes.box,
            )}
            key={tabItem.label}
            data-testid={`${tabItem.id}-tab`}
            data-pendo={`auto-${tabItem.id}-tab`}
            data-cy={`${tabItem.id}-tab`}
            onClick={() => props.setSelectedTabId(tabItem.id)}
          >
            {tabItem.label}
          </Button>
        );
      })}
    </Box>
  );
}
