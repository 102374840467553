import React from "react";
import { Grid, makeStyles, Divider, Typography } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import clsx from "clsx";
import { GetJiraIntegrationsQuery } from "../../../../graphql";

export interface JiraIntegrationItem {
  name: string;
  id: string;
  url: string;
  suitesConnected: number | null;
}

export interface JiraIntegrationListItemProps {
  jiraIntegration: NonNullable<
    GetJiraIntegrationsQuery["getAccount"]
  >["jiraAuthentications"]["nodes"][0]["jiraIntegrations"]["nodes"][0];
  isSelected?: boolean;
  onClick: (id: string) => void;
}

export enum JiraIntegrationMetadataKeys {
  URL = "url",
  PROJECT_NAME = "project_name",
  ISSUE_TYPE_NAME = "issue_type_name",
}

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(3, 2, 3, 3.5),
    alignItems: "center",
    transition: "all 0.2s ease-in-out",
    borderLeft: "3px solid",
    borderColor: theme.palette.navy[50],
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  integrationName: {
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(2),
  },
  integrationUrl: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.navy[300],
    paddingBottom: theme.spacing(1),
  },
  integrationConnection: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#616161",
  },
  connected: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.green[600],
    fontWeight: 500,
  },
  notConnected: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.red[600],
    fontWeight: 500,
  },
  stepIntoIcon: { color: theme.palette.navy[100], fontSize: "40px" },
  selectedItem: {
    backgroundColor: theme.palette.ultraviolet[50],
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[50],
    },
  },
}));

export function JiraIntegrationListItem(props: JiraIntegrationListItemProps) {
  const classes = useStyles();

  const testSuitesConnected =
    props.jiraIntegration.testSuiteJiraIntegrations.totalCount;

  return (
    <>
      <Grid
        container
        className={clsx(classes.item, props.isSelected && classes.selectedItem)}
        onClick={() => props.onClick(props.jiraIntegration.id)}
        data-testid="integration-list-item"
        data-cy="integration-list-item"
        id={`integration-list-item-${props.jiraIntegration.id}`}
        data-pendo={`auto-jira-integration-list-item`}
      >
        <Grid xs={11} container item>
          <Grid
            item
            xs={12}
            data-testid="integration-name"
            data-cy="integration-name"
          >
            <Typography noWrap={true} className={classes.integrationName}>
              {props.jiraIntegration.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.integrationUrl}
            data-testid="integration-url"
            data-cy="integration-url"
          >
            Jira Project URL: {props.jiraIntegration.jiraUrl}
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.integrationConnection}
            data-testid="integration-status"
            data-cy="integration-status"
          >
            <span>Status: </span>
            <span
              className={
                testSuitesConnected ? classes.connected : classes.notConnected
              }
            >
              {testSuitesConnected
                ? `Connected to ${testSuitesConnected} test suites`
                : "Not connected to any test suites"}
            </span>
          </Grid>
        </Grid>
        <Grid xs={1} item>
          <ChevronRightRoundedIcon
            className={classes.stepIntoIcon}
            data-test-id="integration-detail-icon"
            data-cy="integration-detail-icon"
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
