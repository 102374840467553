import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, Drawer, IconButton } from "@material-ui/core";
import { Scrollbar } from "../scrollbar/Scrollbar";
import React from "react";
import { ChevronLeft, ChevronRight } from "../../icons";
import { appSwitcherWidth, closedSidebarWidth, sidebarWidth, } from "./constants";
import { useLayoutApi } from "./LayoutApi";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => {
    var _a, _b, _c, _d;
    return ({
        paper: {
            flexDirection: "row",
            borderRadius: 0,
            background: "transparent",
            overflowX: "visible",
        },
        drawerInner: Object.assign({ padding: "9px 15px 15px 15px", backgroundColor: "#EBEFF3", width: sidebarWidth, overflowY: "auto", overflowX: "visible", boxSizing: "border-box" }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.AppSidebar) === null || _b === void 0 ? void 0 : _b.drawerInner),
        paperAnchorDockedLeft: { borderRight: "none", left: appSwitcherWidth },
        box: Object.assign({ height: "100%", background: "#EBEFF3", borderRight: "solid 1px #D1D5DB", overflowX: "visible" }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.AppSidebar) === null || _d === void 0 ? void 0 : _d.box),
        toggle: {
            zIndex: theme.zIndex.drawer + 1,
            position: "absolute",
            top: 24,
            right: 4,
            width: 18,
            height: 18,
            padding: 10,
            border: "1px solid #D1D5DB",
            background: theme.palette.background.paper,
            "&:hover": {
                background: theme.palette.grey[50],
            },
        },
    });
});
export function AppSidebar(props) {
    const { sidebarState, sidebarRef, openSidebar, closeSidebar, hoverSidebar, unblockSidebar, } = useLayoutApi();
    const classes = useStyles({ collapsed: sidebarState === "closed" });
    const { t } = useTranslation("appSidebar");
    const [boxRef, setBoxRef] = React.useState(null);
    const maintainToggleFocusRef = React.useRef(false);
    const toggleRef = React.useRef(null);
    React.useEffect(() => {
        var _a;
        if (maintainToggleFocusRef.current) {
            (_a = toggleRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            maintainToggleFocusRef.current = false;
        }
    }, [sidebarState]);
    React.useEffect(() => {
        function enterListener() {
            if (sidebarState !== "open") {
                maintainToggleFocusRef.current =
                    document.activeElement === toggleRef.current;
                hoverSidebar();
            }
        }
        function leaveListener() {
            if (sidebarState !== "open") {
                maintainToggleFocusRef.current =
                    document.activeElement === toggleRef.current;
                closeSidebar();
            }
        }
        boxRef === null || boxRef === void 0 ? void 0 : boxRef.addEventListener("mouseenter", enterListener);
        boxRef === null || boxRef === void 0 ? void 0 : boxRef.addEventListener("mouseleave", leaveListener);
        return () => {
            boxRef === null || boxRef === void 0 ? void 0 : boxRef.removeEventListener("mouseenter", enterListener);
            boxRef === null || boxRef === void 0 ? void 0 : boxRef.removeEventListener("mouseleave", leaveListener);
        };
    }, [boxRef, hoverSidebar, closeSidebar, sidebarState]);
    if (!props.contentComponent) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(Drawer, { open: true, variant: "permanent", anchor: "left", "data-testid": "sidebar", classes: {
            paper: classes.paper,
            paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
        }, children: [sidebarState === "closed" ? (_jsx(IconButton, { ref: toggleRef, id: "sidebar-toggle", className: classes.toggle, onClick: () => {
                    maintainToggleFocusRef.current =
                        document.activeElement === toggleRef.current;
                    openSidebar();
                }, "aria-label": t("expandSidebar"), children: _jsx(ChevronRight, { fontSize: "small" }) })) : null, _jsx("div", { style: {
                    backgroundColor: "transparent",
                    height: "100%",
                    width: (sidebarState === "closed" ? closedSidebarWidth : sidebarWidth) +
                        11,
                    transition: "width 0.2s",
                }, children: _jsxs("div", { ref: (ref) => {
                        sidebarRef.current = ref;
                        setBoxRef(ref);
                    }, className: classes.box, style: {
                        width: "calc(100% - 11px - 3px)",
                    }, children: [sidebarState !== "closed" ? (_jsx(IconButton, { ref: toggleRef, id: "sidebar-toggle", "aria-label": sidebarState === "hovered"
                                ? t("keepSidebarOpened")
                                : t("closeSidebar"), className: classes.toggle, onClick: () => {
                                maintainToggleFocusRef.current =
                                    document.activeElement === toggleRef.current;
                                if (sidebarState === "hovered") {
                                    openSidebar();
                                }
                                else {
                                    closeSidebar();
                                    unblockSidebar();
                                }
                            }, children: sidebarState === "hovered" ? (_jsx(ChevronRight, { fontSize: "small" })) : (_jsx(ChevronLeft, { fontSize: "small" })) })) : null, _jsx(Scrollbar, { reactScrollbarProps: { noScrollY: sidebarState === "closed" }, children: _jsx("div", { className: classes.drawerInner, 
                                // Ensures visual consistency during width transition
                                style: {
                                    visibility: sidebarState === "closed" ? "hidden" : "initial",
                                }, children: _jsx(props.contentComponent, {}) }) })] }) })] }));
}
