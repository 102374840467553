import { useState } from "react";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../_common/utils/mergePageInfo/mergePageInfo";
import { ApolloError } from "@lumar/shared";
import {
  GetTestSuiteChildrenQuery,
  GetTestSuiteChildrenQueryVariables,
  useGetTestSuiteChildrenQuery as generated_useGetTestSuiteChildrenQuery,
} from "../../../graphql";

function getPreviousResultAsLast(
  data: GetTestSuiteChildrenQuery,
): GetTestSuiteChildrenQuery {
  if (data.node?.__typename === "TestSuite" && data.node.children) {
    // eslint-disable-next-line fp/no-mutation
    data.node.children.pageInfo.hasNextPage = false;
    return data;
  }
  return data;
}

export function useTestSuiteChildrenQuery(
  variables: GetTestSuiteChildrenQueryVariables,
): {
  loading: boolean;
  data: GetTestSuiteChildrenQuery | undefined;
  error: ApolloError | undefined;
  fetchMore: () => void;
  isFetchingMore: boolean;
} {
  const { loading, data, error, fetchMore } =
    generated_useGetTestSuiteChildrenQuery({
      variables,
      fetchPolicy: "cache-and-network",
    });
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  function fetchMoreChildren(): void {
    if (
      !isFetchingMore &&
      !loading &&
      data &&
      data?.node?.children?.pageInfo.hasNextPage
    ) {
      setIsFetchingMore(true);
      fetchMore({
        variables: {
          cursor: data?.node?.children?.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult?.node?.__typename === "TestSuite" &&
            fetchMoreResult.node.children
          ) {
            return merge(
              previousResult,
              fetchMoreResult,
              defaultMergeOptions,
            ) as GetTestSuiteChildrenQuery;
          }
          return getPreviousResultAsLast(previousResult);
        },
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  }

  return {
    loading: loading || isFetchingMore,
    data,
    error,
    fetchMore: fetchMoreChildren,
    isFetchingMore,
  };
}
