import React from "react";
import {
  Button,
  Box,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  generateEmailTextAndCopyToClipboard,
  automatorSdkUrl,
} from "./CiCdFormUtils";
import { CopyBlock } from "../CopyBlock/CopyBlock";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { useSnackbar } from "notistack";
import { DuplicateSolid, Snackbar, ToggleIconButton } from "@lumar/shared";

export interface CiCdProps {
  testSuiteId: string;
  testSuiteName: string;
  testSuiteSitePrimary: string;
}

const useStyles = makeStyles((theme) => ({
  ciCdWrapper: {
    borderColor: theme.palette.grey[300],
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  link: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 400,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  httpsWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export function CiCdForm(props: CiCdProps): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      border={1}
      borderRadius={8}
      py={4}
      px={3}
      data-testid="ci-cd-instructions"
      className={classes.ciCdWrapper}
    >
      <Box mb={3}>
        <Typography color="textPrimary" className={classes.title}>
          Instructions for CI/CD admin:
        </Typography>
        <Box component="span" display="block">
          <a
            href={automatorSdkUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-pendo="auto-ci-cd-instructions-admin"
            className={classes.link}
          >
            <span className={classes.httpsWrapper}>https://</span>
            github.com/deepcrawl/automator-sdk
          </a>
        </Box>
      </Box>
      <Box mb={2}>
        <CopyBlock
          label="Test Suite ID"
          text={props.testSuiteId}
          copyMessage="Test Suite ID copied to clipboard"
        />
      </Box>

      <Box display="flex">
        <Button
          onClick={() => {
            generateEmailTextAndCopyToClipboard(
              props.testSuiteName,
              props.testSuiteSitePrimary,
              props.testSuiteId,
            );
            enqueueSnackbar(
              <Snackbar
                title="Instruction information copied to clipboard."
                variant="success"
              />,
            );
          }}
          variant="outlined"
          size="small"
          data-testid="cicd-form-copy-button"
          data-pendo="auto-ci-cd-form-instructions-copy"
          startIcon={<DuplicateSolid />}
          style={{ marginRight: 8 }}
        >
          Copy instructions
        </Button>
        <Tooltip
          interactive
          title={
            <>
              <p>
                Lumar Protect is our system for integrating Lumar in your CI/CD
                pipeline. This allows us to run a crawl on development
                environments as soon as new code is deployed to them.
              </p>
              <p>
                To integrate Lumar into your pipeline, please follow the
                instructions on{" "}
                <a
                  href={automatorSdkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {automatorSdkUrl}
                </a>
                .
              </p>
              <p>
                Test Suite: {props.testSuiteName} - {props.testSuiteSitePrimary}
              </p>
              <p>Test Suite ID: {props.testSuiteId}</p>
            </>
          }
          data-testid="show-intergration-details"
          data-pendo="auto-tooltip-ci-cd-form"
        >
          <span>
            <ToggleIconButton
              variant="outlined"
              size="large"
              data-pendo="auto-ci-cd-form-instructions-view"
            >
              <VisibilityRoundedIcon />
            </ToggleIconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}
