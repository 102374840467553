import { JiraIntegrationKey } from "./decorateMetadata";

export function getRowLabelFromKey(key: string): string {
  const names = new Map<JiraIntegrationKey | string, string>([
    [JiraIntegrationKey.NAME, "Integration name"],
    [JiraIntegrationKey.JIRA_URL, "URL"],
    [JiraIntegrationKey.JIRA_PROJECT_NAME, "Jira project name"],
    [JiraIntegrationKey.JIRA_ISSUE_TYPE_NAME, "Issue type"],
    [JiraIntegrationKey.JIRA_INTEGRATION_STATUS, "Integration status"],
  ]);

  return names.get(key) || "";
}
