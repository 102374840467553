import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tooltip, TooltipProps } from "@material-ui/core";
import { InformationCircleOutlined } from "@lumar/shared";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  infoIcon: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: "text-bottom",
  },
}));

interface LabelProps {
  label: string;
  htmlFor?: string;
  info?: NonNullable<React.ReactNode>;
  TooltipProps?: Partial<TooltipProps>;
  className?: string;
}

export function Label({
  label,
  htmlFor,
  info,
  TooltipProps,
  className,
}: LabelProps): JSX.Element {
  const classes = useStyles();

  const infoIcon = info !== undefined && (
    <Tooltip title={info} placement="right" {...TooltipProps}>
      <InformationCircleOutlined
        color="inherit"
        fontSize="inherit"
        className={classes.infoIcon}
        tabIndex={0}
        aria-hidden="false"
      />
    </Tooltip>
  );

  return htmlFor ? (
    <label htmlFor={htmlFor} className={clsx(classes.label, className)}>
      {label}
      {infoIcon}
    </label>
  ) : (
    <span className={clsx(classes.label, className)}>
      {label}
      {infoIcon}
    </span>
  );
}
