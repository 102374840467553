import { EmailAlertType } from "../../../../../../graphql";

export function getInitialValuesFromAlerts(alertTypes: EmailAlertType[]) {
  const [pass, warning, fail] = [
    EmailAlertType.Pass,
    EmailAlertType.Warning,
    EmailAlertType.Fail,
  ].map((type) => alertTypes.includes(type));
  return { pass, warning, fail };
}
