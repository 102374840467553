/*
 * Cloned from https://github.com/stackworx/formik-mui/blob/1ea5ab12f5f185522f8a8a9f18be8878d5534843/packages/formik-material-ui/src/CheckboxWithLabel.tsx
 * and modified to use @lumar/shared checkbox instead.
 */

import * as React from "react";
import FormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@material-ui/core/FormControlLabel";
import { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import { Checkbox } from "@lumar/shared";
import { FieldProps } from "formik";

interface CheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      | "name"
      | "value"
      | "error"
      | "form"
      | "checked"
      | "defaultChecked"
      // Excluded for conflict with Field type
      | "type"
    > {
  type?: string;
}

interface CheckboxWithLabelProps extends FieldProps, CheckboxProps {
  Label: Omit<
    MuiFormControlLabelProps,
    "checked" | "name" | "value" | "control"
  >;
}

export const CheckboxWithLabel: React.ComponentType<CheckboxWithLabelProps> = ({
  Label,
  className,
  ...props
}) => {
  return (
    <FormControlLabel
      control={<Checkbox {...fieldToCheckbox(props)} />}
      className={className}
      {...Label}
    />
  );
};

function fieldToCheckbox({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting },
  onBlur,
  ...props
}: CheckboxProps): MuiCheckboxProps {
  const indeterminate = !Array.isArray(field.value) && field.value == null;

  return {
    disabled: disabled ?? isSubmitting,
    indeterminate,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}
