import React, { useState } from "react";
import { Box, Divider, Fade, Typography } from "@material-ui/core";
import {
  formatSchedulingInterval,
  getTimeDescription,
} from "../utils/schedulerTime";
import { useGetBuildScheduleWithTestSuitesQuery } from "./graphql/useGetBuildScheduleWithTestSuitesQuery";
import { Alert, Snackbar } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import clsx from "clsx";
import { sortedArrayAreSame } from "../../../_common/utils/array/comparison/arrayCompare";
import { useStyles } from "./SchedulerDetailViewStyles";
import { SchedulerTestSuiteListItem } from "./SchedulerTestSuiteListItem";
import { schedulerSuiteItemInformationFactory } from "../../../_common/utils/testSuiteItemInformationFactory/testSuiteItemInformationFactory";
import { SchedulerDetailViewHeader } from "./SchedulerDetailViewHeader";
import { LoadMoreItems } from "../../../_common/components/LoadMoreItems/LoadMoreItems";
import { SchedulerDetailLoadingView } from "./SchedulerDetailLoadingView";
import { ScheduleSettings } from "./ScheduleSettings/ScheduleSettings";
import { NoTestsuitesMessage } from "./detail-view/NoTestsuitesMessage";
import {
  BuildScheduleRepetitionRate,
  BuildStatus,
  ObjectID,
  SetBuildScheduleTestSuitesMutationVariables,
  UpdateBuildScheduleMutationVariables,
  useDeleteBuildScheduleMutation,
  useSetBuildScheduleTestSuitesMutation,
  useUnlinkTestSuiteFromBuildScheduleMutation,
  useUpdateBuildScheduleMutation,
} from "../../../graphql";

export interface SchedulerDetailViewProps {
  schedulerId: string;
  onUpdateCompleted: () => void;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type TEMP_Schedule = {
  id: ObjectID;
  name: string;
  repetitionRate: BuildScheduleRepetitionRate | null;
  startAt: any;
  nextRunAt: any | null;
  buildScheduleTestSuites: {
    totalCount: number;
    pageInfo: { endCursor: string | null };
    nodes: Array<{
      id: ObjectID;
      lastRunBuild: {
        id: ObjectID;
        status: BuildStatus;
        finishedAt: any | null;
        ciBuildId: string | null;
        updatedAt: any;
        passed: boolean;
        failedTestCount: number | null;
        passedTestCount: number | null;
        warnedTestCount: number | null;
      } | null;
      testSuite: {
        name: string;
        id: ObjectID;
        primaryDomain: string;
        updatedAt: any;
        clonedAt: any | null;
        tests: { totalCount: number };
      };
    }>;
  };
};
/* eslint-enable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
export function SchedulerDetailView(props: SchedulerDetailViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const accountId = useAccountRouteMatch();

  const classes = useStyles();

  const [isScheduleSettingsOpen, setIsScheduleSettingsOpen] = useState(false);

  const { data, error, loading, refetch, loadMore, isFetchingMore } =
    useGetBuildScheduleWithTestSuitesQuery({
      buildScheduleId: props.schedulerId,
      itemsPerRequest: 20,
      cursor: "",
    });
  const [deleteBuildSchedule] = useDeleteBuildScheduleMutation({
    refetchQueries: ["getBuildSchedules"],
  });
  const [unlinkTestSuiteFromBuildSchedule] =
    useUnlinkTestSuiteFromBuildScheduleMutation({
      refetchQueries: ["getScheduleForScheduleSettings"],
    });

  const schedule = (data?.node as TEMP_Schedule) ?? null;

  const [updateBuildSchedule] = useUpdateBuildScheduleMutation();
  const [setScheduleTestSuites] = useSetBuildScheduleTestSuitesMutation({
    refetchQueries: ["getScheduleForScheduleSettings"],
  });

  function openScheduleSettings() {
    setIsScheduleSettingsOpen(true);
  }

  async function handleEditSchedule(
    input: {
      accountId?: string;
      name: string;
      repetitionRate?: BuildScheduleRepetitionRate | undefined | null;
      startAt: Date | undefined;
      buildScheduleId?: string;
    },
    testSuiteIds: string[],
  ) {
    try {
      const variables: UpdateBuildScheduleMutationVariables = {
        input: {
          name: input.name,
          repetitionRate: input.repetitionRate,
          startAt: input.startAt,
          buildScheduleId: input.buildScheduleId,
        },
      };
      await updateBuildSchedule({ variables });
      const previousTestSuitesId = schedule?.buildScheduleTestSuites.nodes.map(
        (node) => node.testSuite.id as string,
      );
      if (!sortedArrayAreSame(previousTestSuitesId, testSuiteIds)) {
        const variables: SetBuildScheduleTestSuitesMutationVariables = {
          testSuiteIds,
          buildScheduleId: input.buildScheduleId,
        };
        await setScheduleTestSuites({ variables });
      }
      props.onUpdateCompleted();
      enqueueSnackbar(
        <Snackbar title="Schedule edited successfully" variant="success" />,
      );
      refetch();
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Something went wrong. Please refresh the page and try again."
          variant="error"
        />,
        { persist: true },
      );
    }
  }

  async function handleConfirmScheduleDeletion() {
    try {
      await deleteBuildSchedule({
        variables: { buildScheduleId: props.schedulerId },
      });
      enqueueSnackbar(
        <Snackbar title="Schedule deleted successfully" variant="success" />,
      );
      history.push(
        Routes.FrontendScheduler.getUrl({ accountId, schedulerId: undefined }),
      );
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Something went wrong. Please refresh the page and try again."
          variant="error"
        />,
        { persist: true },
      );
    }
  }

  async function removeTestSuite(connectionId: string) {
    try {
      await unlinkTestSuiteFromBuildSchedule({
        variables: { buildScheduleTestSuiteId: connectionId },
      });
      enqueueSnackbar(
        <Snackbar title={`Removed from ${schedule?.name}`} variant="success" />,
      );
      props.onUpdateCompleted();
      refetch();
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Something went wrong. Please refresh the page and try again."
          variant="error"
        />,
        { persist: true },
      );
    }
  }

  if (loading) return <SchedulerDetailLoadingView />;

  if (error || !data || !schedule) {
    return (
      <Alert
        severity="error"
        className={classes.margin}
        data-testid="scheduler-detail-error"
      >
        Something went wrong. Please refresh the page and try again.
      </Alert>
    );
  }

  return (
    <Fade in>
      <Box data-testid="scheduler-detail-view-content">
        <>
          <SchedulerDetailViewHeader
            scheduleId={schedule.id}
            scheduleName={schedule.name}
            handleConfirmScheduleDeletion={handleConfirmScheduleDeletion}
            handleEditSchedule={openScheduleSettings}
          />
          <Divider className={classes.divider} />

          <Box marginY="10px">
            <Box className={classes.timeBoxInfoBlock}>
              <Typography className={clsx(classes.text, classes.darkerText)}>
                Starts:
              </Typography>
              <Typography
                className={classes.text}
                data-testid="scheduler-detail-view-time"
              >
                {getTimeDescription(schedule?.startAt)}
              </Typography>
            </Box>
            <Box className={classes.timeBoxInfoBlock}>
              <Typography className={clsx(classes.text, classes.darkerText)}>
                Repeat:
              </Typography>
              <Typography
                className={classes.text}
                data-testid="scheduler-detail-view-frequency"
              >
                {formatSchedulingInterval(
                  new Date(schedule.startAt),
                  schedule.repetitionRate,
                )}
              </Typography>
            </Box>
          </Box>

          <Divider className={classes.divider} />
          <Box className={classes.tableHeadingsBox}>
            <Typography
              variant="body1"
              className={classes.tableHeadings}
              data-testid="scheduler-detail-view-test-suites-coutner"
            >
              Scheduled test suite runs (
              {schedule.buildScheduleTestSuites.totalCount})
            </Typography>
          </Box>

          <Box>
            {schedule.buildScheduleTestSuites.nodes.length ? (
              <>
                {schedule.buildScheduleTestSuites.nodes.map((node) => {
                  const testSuite = schedulerSuiteItemInformationFactory(node);
                  return (
                    <Box
                      data-testid="scheduler-detail-testsuite-item"
                      data-cy={`scheduler-detail-testsuite-item-${testSuite.id}`}
                      key={node.testSuite.id}
                    >
                      <SchedulerTestSuiteListItem
                        {...testSuite}
                        editable={false}
                        onActionMenuEvent={() => {
                          removeTestSuite(node.id);
                          return new Promise((resolve) => resolve(false));
                        }}
                        nextRunAt={schedule.nextRunAt}
                      />
                    </Box>
                  );
                })}
                <LoadMoreItems
                  isLoading={isFetchingMore || loading}
                  onClick={loadMore}
                  dataId="scheduler-detail-view"
                  currentCount={schedule.buildScheduleTestSuites.nodes.length}
                  totalCount={schedule.buildScheduleTestSuites.totalCount}
                />
              </>
            ) : (
              <NoTestsuitesMessage onClick={openScheduleSettings} />
            )}
          </Box>
        </>
        <ScheduleSettings
          isOpen={isScheduleSettingsOpen}
          handleClose={() => setIsScheduleSettingsOpen(false)}
          handleSave={handleEditSchedule}
          schedule={schedule}
        />
      </Box>
    </Fade>
  );
}
