import React from "react";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import { useStyles } from "./StatusIconStyles";
import { Tooltip, Typography } from "@material-ui/core";
import {
  ExclamationCircleSolid,
  ExclamationSolid,
  RunningSolid,
  XCircleSolid,
  ViewBoardsSolid,
  ClockSolid,
} from "@lumar/shared";
import { CheckCircle } from "@material-ui/icons";

export enum StatusIconType {
  ALERT = "alert",
  WARNING = "warning",
  ERROR = "error",
  CHECK = "check",
  CANCELLED = "cancelled",
  LOADING = "loading",
  NA = "n/a",
  NOT_YET_RUN = "not_yet_run",
  SCHEDULED = "scheduled",
  QUEUED = "queued",
  ROUND_ERROR = "round_error",
}

export enum StatusIconSize {
  XL = "xl",
  LG = "lg",
  MD = "md",
  SM = "sm",
  XS = "xs",
}

export interface StatusIconProps {
  type: StatusIconType;
  size?: StatusIconSize;
  text?: string;
  inline?: boolean;
  tooltipVariant?: boolean;
}

export function StatusIcon(props: StatusIconProps): JSX.Element {
  const classes = useStyles(props);

  // eslint-disable-next-line complexity
  function getIcon(): JSX.Element {
    switch (props.type) {
      case StatusIconType.CHECK:
        return <CheckCircle className={classes.icon} />;
      case StatusIconType.ERROR:
      case StatusIconType.CANCELLED:
        return <ExclamationCircleSolid className={classes.icon} />;
      case StatusIconType.WARNING:
        return <ExclamationSolid className={classes.icon} />;
      case StatusIconType.ALERT:
        return <ExclamationSolid className={classes.icon} />;
      case StatusIconType.NOT_YET_RUN:
        return <RemoveCircleRoundedIcon className={classes.icon} />;
      case StatusIconType.SCHEDULED:
        return <ClockSolid className={classes.icon} />;
      case StatusIconType.QUEUED:
        return <ViewBoardsSolid className={classes.icon} />;
      case StatusIconType.ROUND_ERROR:
        return <XCircleSolid className={classes.icon} />;
      case StatusIconType.LOADING:
        return <RunningSolid className={classes.icon} />;
      default:
        return <RunningSolid className={classes.icon} />;
    }
  }

  const icon = getIcon();

  const iconContent =
    props.tooltipVariant && props.text ? (
      <Tooltip
        title={props.text[0].toUpperCase() + props.text.slice(1).toLowerCase()}
        data-testid="status-icon-tooltip"
      >
        <span>{icon}</span>
      </Tooltip>
    ) : (
      icon
    );

  return (
    <div
      className={`${classes.status}`}
      data-testid="status-icon"
      data-cy="status-icon"
      id={`status-icon-${props.type}-${props.size}-${props.inline}`}
    >
      {iconContent}
      {!props.tooltipVariant && props.text && (
        <Typography
          className={classes.statusText}
          data-testid="status-icon-description"
          data-cy="status-icon-description"
        >
          {props.text}
        </Typography>
      )}
    </div>
  );
}
