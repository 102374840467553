import React from "react";
import {
  Box,
  BoxProps,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  contentWrapper: ({ active }: { active?: boolean }) => ({
    padding: "8px 8px 8px 21px",
    color: active ? theme.palette.primary.main : theme.palette.grey[800],
    borderLeft: `3px solid ${
      active ? theme.palette.primary.main : "transparent"
    }`,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      fontSize: 20,
    },
  }),
  text: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    marginLeft: theme.spacing(1),
  },
  listItem: {
    padding: 0,
  },
}));

interface OverviewLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children: React.ReactNode;
  icon: React.ReactNode;
  active?: boolean;
}

export function OverviewLink({
  active,
  children,
  icon,
  ...anchorProps
}: OverviewLinkProps) {
  const classes = useStyles({ active });

  return (
    <ListItem className={classes.listItem}>
      <a {...anchorProps} className={classes.link}>
        <Box
          display="flex"
          alignItems="center"
          className={classes.contentWrapper}
        >
          {icon}
          <Typography className={classes.text}>{children}</Typography>
        </Box>
      </a>
    </ListItem>
  );
}

const useOverviewStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 8,
    backgroundColor: "#EBEFF3",
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  title: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    fontWeight: 500,
    padding: theme.spacing(2, 3),
  },
  list: {
    paddingTop: 0,
  },
}));

export function Overview(props: BoxProps) {
  const classes = useOverviewStyles();

  return (
    <Box className={classes.container} {...props}>
      <Typography variant="h2" className={classes.title}>
        Overview
      </Typography>
      <List className={classes.list}>{props.children}</List>
    </Box>
  );
}
