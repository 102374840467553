import React from "react";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import { BuildStatusIcon } from "../../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { StatusIconSize } from "../../../../_common/components/StatusIcon/StatusIcon";
import { getMatchingBorderColorForStatus } from "../../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { BuildInfo } from "../../Dashboard";
import { formatDistance } from "date-fns";
import { getFinishedStatusFromBuild } from "../../utils/getFinishedStatusFromBuild";
import { BuildStatus } from "../../../../graphql";
import { TestCountChips } from "../../../TestSuiteList/test-suite-list/TestCountChips";
import { dateTimeToHumanReadableFormat } from "../../../../_common/utils/date/date";

interface BuildListItemContentProps {
  buildInfo: BuildInfo;
}

const useStyles = makeStyles((theme) => ({
  buildListItem: (props: BuildListItemContentProps) => {
    const status = getFinishedStatusFromBuild(props.buildInfo);
    const borderColor = getMatchingBorderColorForStatus(status, theme);
    const isBuildFinished = props.buildInfo.status === BuildStatus.Finished;

    return {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      borderLeft: `3px solid ${borderColor}`,
      height: 88,
      paddingRight: theme.spacing(2),
      cursor: isBuildFinished ? "pointer" : "default",
      transition: isBuildFinished ? "all 0.2s ease-in-out" : "none",
      "&:hover": {
        backgroundColor: isBuildFinished ? theme.palette.grey[100] : "none",
      },
    };
  },
  name: {
    lineHeight: 1.2,
    fontSize: 15,
    fontWeight: 400,
  },
  primaryDomain: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.grey[600],
    lineHeight: theme.typography.pxToRem(20),
  },
  elapsedMessage: {
    fontSize: 11,
    fontWeight: 400,
    color: theme.palette.grey[400],
    lineHeight: theme.typography.pxToRem(20),
  },
  chevonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export function BuildListItemContent(props: BuildListItemContentProps) {
  const classes = useStyles(props);
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const { buildInfo } = props;
  const testStatus = getFinishedStatusFromBuild(buildInfo);

  const warningTestCount = buildInfo.warnedTestCount ?? 0;
  const failedTestCount = buildInfo.failedTestCount ?? 0;
  const passedTestCount = buildInfo.passedTestCount ?? 0;

  function getTimeElapsedMessage(): string {
    const message = `${formatDistance(
      new Date(buildInfo.finishedAt || buildInfo.createdAt),
      new Date(),
    )} ago`;
    return message[0].toUpperCase() + message.substring(1);
  }

  function handleMouseOver() {
    setIsMouseOver(true);
  }

  function handleMouseLeave() {
    setIsMouseOver(false);
  }

  return (
    <Box
      className={classes.buildListItem}
      data-testid="build-list-item-box"
      data-pendo="auto-latest-run-testsuite-item"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item sm={7} md={5} lg={7} xs={6}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box data-testid="build-list-item-status" style={{ width: 60 }}>
              <BuildStatusIcon status={testStatus} size={StatusIconSize.SM} />
            </Box>
            <Box minWidth={0}>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.name}
                noWrap
                data-testid="build-list-item-name"
                title={buildInfo.name}
              >
                {buildInfo.name}
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                data-testid="build-list-item-site-primary"
                className={classes.primaryDomain}
              >
                {buildInfo.primaryDomain}
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                data-testid="build-list-item-elapsed-message"
                className={classes.elapsedMessage}
              >
                {isMouseOver
                  ? dateTimeToHumanReadableFormat(
                      new Date(buildInfo.finishedAt || buildInfo.createdAt),
                      "N/A",
                    )
                  : getTimeElapsedMessage()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={5} md={7} sm={5} xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <TestCountChips
              testSuiteId={buildInfo.testSuiteId}
              buildId={buildInfo.id}
              totalTests={warningTestCount + passedTestCount + failedTestCount}
              warningTests={warningTestCount}
              failedTests={failedTestCount}
              passedTests={passedTestCount}
              status={buildInfo.status}
              pendoIdSuffix="testsuitelist"
              showLabel={true}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
