var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Chip as MuiChip, makeStyles, } from "@material-ui/core";
import clsx from "clsx";
import { useBrand } from "../../brand/BrandProvider";
// TODO: Consider making a separate component that doesn't extend Mui's <Chip> component.
// The problem is that the specifity of <Chip>'s API forced us to generate new class for every single instance.
// It is not optimal given perf issues of jss. Also, I'm running out of time. I'm leaving refactoring to you my dear reader.
// - Michal
export const Chip = React.forwardRef(function Chip(_a, ref) {
    var { color, rounded = false, size } = _a, chipProps = __rest(_a, ["color", "rounded", "size"]);
    const { brandId } = useBrand();
    const classes = useStyles({ brandId, color });
    return (_jsx(MuiChip, Object.assign({ ref: ref, classes: {
            root: clsx(classes.root, classes.color, {
                [classes.rounded]: rounded,
                [classes.small]: size === "small",
            }),
            deleteIcon: classes.deleteIcon,
            label: classes.label,
            clickable: classes.clickable,
            deletable: classes.deletable,
        } }, chipProps)));
});
const useStyles = makeStyles((theme) => {
    return {
        root: {
            borderRadius: 4,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            lineHeight: theme.typography.pxToRem(20),
            height: 24,
        },
        small: {
            height: 20,
        },
        rounded: {
            borderRadius: 16,
        },
        deleteIcon: {
            width: "15px",
            height: "15px",
        },
        label: {
            paddingLeft: 8,
            paddingRight: 8,
        },
        color: ({ color, brandId }) => getColorClassProperties(color, brandId, theme),
        deletable: ({ color, brandId }) => getDeletableClassProperties(color, brandId, theme),
        clickable: ({ color, brandId }) => getClickableClassProperties(color, brandId, theme),
    };
});
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getColorClassProperties(color, brandId, theme) {
    const props = getColorProps(color, brandId, theme);
    return {
        backgroundColor: props.backgroundColor,
        color: props.color,
        boxShadow: props.borderColor
            ? `inset 0px 0px 0px 1px ${props.borderColor}`
            : undefined,
    };
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getDeletableClassProperties(color, brandId, theme) {
    const props = getColorProps(color, brandId, theme);
    return {
        "&$deletable:focus": {
            backgroundColor: props.backgroundColor,
        },
    };
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getClickableClassProperties(color, brandId, theme) {
    const props = getColorProps(color, brandId, theme);
    return {
        "&$clickable": {
            "&:focus": {
                backgroundColor: `${props.backgroundColor} !important`,
            },
            "&:hover": {
                backgroundColor: `${props.backgroundHover} !important`,
            },
        },
    };
}
function getColorProps(color, brandId, theme) {
    const getPrimary = () => {
        if (brandId === "similarweb") {
            return {
                backgroundColor: "#3E74FE1A",
                color: "#3E74FE",
                backgroundHover: "#3E74FE1A",
                iconColor: "#3E74FE",
                iconHoverColor: "#3E74FE",
            };
        }
        return {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.dark,
            backgroundHover: theme.palette.ultraviolet[50],
            iconColor: theme.palette.ultraviolet[400],
            iconHoverColor: theme.palette.primary.main,
        };
    };
    const colorMap = {
        red: {
            backgroundColor: theme.palette.red[200],
            color: theme.palette.red[800],
            backgroundHover: theme.palette.red[100],
            iconColor: theme.palette.red[400],
            iconHoverColor: theme.palette.red[600],
        },
        yellow: {
            backgroundColor: theme.palette.yellow[200],
            color: theme.palette.yellow[800],
            backgroundHover: theme.palette.yellow[100],
            iconColor: theme.palette.yellow[400],
            iconHoverColor: theme.palette.yellow[600],
        },
        lightgrey: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[800],
            backgroundHover: theme.palette.grey[50],
            iconColor: theme.palette.grey[400],
            iconHoverColor: theme.palette.grey[600],
        },
        grey: {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.grey[800],
            backgroundHover: theme.palette.grey[200],
            iconColor: theme.palette.grey[500],
            iconHoverColor: theme.palette.grey[700],
        },
        blue: {
            backgroundColor: theme.palette.blue[100],
            color: theme.palette.blue[800],
            backgroundHover: theme.palette.blue[200],
            iconColor: theme.palette.blue[400],
            iconHoverColor: theme.palette.blue[600],
        },
        purple: {
            backgroundColor: theme.palette.purple[100],
            color: theme.palette.purple[700],
            backgroundHover: theme.palette.purple[50],
            iconColor: theme.palette.purple[400],
            iconHoverColor: theme.palette.purple[600],
        },
        cyan: {
            backgroundColor: theme.palette.cyan[100],
            color: theme.palette.cyan[700],
            backgroundHover: theme.palette.cyan[50],
            iconColor: theme.palette.cyan[400],
            iconHoverColor: theme.palette.cyan[600],
        },
        pink: {
            backgroundColor: theme.palette.pink[100],
            color: theme.palette.pink[700],
            backgroundHover: theme.palette.pink[50],
            iconColor: theme.palette.pink[400],
            iconHoverColor: theme.palette.pink[600],
        },
        green: {
            backgroundColor: theme.palette.green[200],
            color: theme.palette.green[800],
            backgroundHover: theme.palette.green[100],
            iconColor: theme.palette.green[400],
            iconHoverColor: theme.palette.green[600],
        },
        white: {
            backgroundColor: "white",
            color: theme.palette.grey[800],
            borderColor: theme.palette.grey[300],
            backgroundHover: theme.palette.grey[50],
            iconColor: theme.palette.grey[400],
            iconHoverColor: theme.palette.grey[600],
        },
        orange: {
            backgroundColor: theme.palette.orange[200],
            color: theme.palette.orange[800],
            backgroundHover: theme.palette.orange[100],
            iconColor: theme.palette.orange[400],
            iconHoverColor: theme.palette.orange[600],
        },
        primary: getPrimary(),
    };
    return colorMap[color];
}
