import {
  AccountMenuItem,
  AccountMenuItems,
  BatteryHighOutlined,
  CreditCardOutlined,
  getApiAccountId,
  getRawAccountId,
  insertIf,
  LightningBoltOutlined,
  LogoutOutlined,
  OfficeBuildingOutlined,
  PuzzleOutlined,
  useAccountSelectionSubMenu,
  useAdminAccountSearch,
  useBrand,
  UserArrowRightOutlined,
  UserGroupOutlined,
  UserOutlined,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { RoleCode } from "../../../graphql";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useAutomatorSession } from "../../../_common/hooks/useAutomatorSession/useAutomatorSession";
import { AccountsRoutes } from "../../../_common/routes/accountsRoutes";
import { Routes } from "../../../_common/routes/routes";
import { useSelectedAccountId } from "./useSelectedAccountId";

const useIconStyles = makeStyles((theme) => ({
  icon: {
    height: "20px",
  },
  logout: {
    color: theme.palette.red[500],
  },
}));

interface NavigationMenuItem extends AccountMenuItem {
  minimumRole?: RoleCode;
}

type NavigationMenuItems = {
  name?: string;
  items: (NavigationMenuItem | undefined)[];
}[];

// eslint-disable-next-line max-lines-per-function
export function useAccountMenuItems(
  logoutCallback: () => void,
  currentUserRole: RoleCode,
  isDeepCrawlAdmin: boolean,
): AccountMenuItems {
  const classes = useIconStyles();
  const brand = useBrand();

  const session = useAutomatorSession();
  const history = useHistory();

  const accountIdFromUrl = useAccountRouteMatch();
  const [selectedAccountId] = useSelectedAccountId();

  const generateAccountsUrl = (route: {
    getUrl: ({ accountId }: { accountId: string }) => string;
  }): string => {
    if (!selectedAccountId) return "#";
    return route.getUrl({ accountId: selectedAccountId });
  };

  // eslint-disable-next-line fp/no-mutating-methods
  const availableAccounts = [...session.allRelationships].sort((a, b) =>
    a.account.name.localeCompare(b.account.name),
  );
  const shouldShowAccountSwitcher =
    isDeepCrawlAdmin ||
    Boolean(
      availableAccounts.find(({ account }) => account.id !== accountIdFromUrl),
    );

  function handleAccountSwitch(id: string): void {
    if (id === selectedAccountId) return;
    history.push(
      Routes.Dashboard.getUrl({
        accountId: id,
      }),
    );
  }

  const accountsSubMenu = useAccountSelectionSubMenu({
    accounts: availableAccounts.map((x) => ({
      id: getRawAccountId(x.account.id),
      name: x.account.name,
    })),
    selectedAccountId: selectedAccountId && getRawAccountId(selectedAccountId),
    onAccountSelected: (id) => handleAccountSwitch(getApiAccountId(id)),
    isDeepCrawlAdmin,
    searchPlaceholder: "Search account",
    noResultMessage: "No account found",
    formatValue: (id) => `ID ${id}`,
    adminAccountSearch: useAdminAccountSearch(),
    dataAttributes: {
      "data-pendo": "auto-headernav-accounts-account-select-option",
      "data-testid": "account-select-option-menu-item",
    },
  });

  const menuItems: NavigationMenuItems = [
    {
      items: [
        shouldShowAccountSwitcher
          ? {
              name: "Switch Account",
              icon: <UserArrowRightOutlined className={classes.icon} />,
              dataAttributes: {
                "data-pendo": "auto-headernav-accounts-account-select",
                "data-testid": "account-select-menu-item",
              },
              minimumRole: RoleCode.Viewer,
              subMenu: accountsSubMenu,
            }
          : undefined,
      ],
    },
    {
      name: "User",
      items: [
        ...insertIf(brand.featureAvailability.myProfilePage, {
          name: "My Profile",
          icon: <UserOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-user",
            "data-testid": "user-menu-item",
          },
          link: generateAccountsUrl(AccountsRoutes.UserSettings),
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        }),
        {
          name: "Connected apps",
          icon: <PuzzleOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-connectedapps",
            "data-testid": "connected-apps-menu-item",
          },
          link: generateAccountsUrl(AccountsRoutes.ConnectedApps),
          isLinkExternal: true,
          minimumRole: RoleCode.Editor,
        },
        ...insertIf(brand.featureAvailability.apiAccessPage, {
          name: "API access",
          icon: <LightningBoltOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-api",
            "data-testid": "api-access-menu-item",
          },
          link: generateAccountsUrl(AccountsRoutes.ApiAccess),
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        }),
      ],
    },
    {
      name: "Organization",
      items: [
        {
          name: "Account",
          icon: <OfficeBuildingOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-account",
            "data-testid": "account-menu-item",
          },
          isLinkExternal: true,
          link: generateAccountsUrl(AccountsRoutes.Account),
          minimumRole: RoleCode.Viewer,
        },
        ...insertIf(brand.featureAvailability.teamsPage, {
          name: "Team",
          icon: <UserGroupOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-org",
            "data-testid": "team-menu-item",
          },
          link: generateAccountsUrl(AccountsRoutes.Team),
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        }),
        {
          name: "Subscription",
          icon: <CreditCardOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-subscription",
            "data-testid": "subscription-menu-item",
          },
          link: generateAccountsUrl(AccountsRoutes.Subscription),
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        },
        {
          name: "Credit usage",
          icon: <BatteryHighOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "auto-headernav-accounts-credit",
            "data-testid": "credit-usage-menu-item",
          },
          link: generateAccountsUrl(AccountsRoutes.CreditUsage),
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        },
      ],
    },
  ];

  function filterByRole(
    item: NavigationMenuItem | undefined,
  ): item is NavigationMenuItem {
    if (item === undefined) return false;
    if (isDeepCrawlAdmin) return true;
    if (!item.minimumRole) return false;
    return (
      getRolePriority(currentUserRole) >= getRolePriority(item.minimumRole)
    );
  }

  const filteredMenuItems = menuItems.reduce((result, group) => {
    const items = group.items.filter(filterByRole);
    return items.length ? [...result, { ...group, items }] : result;
  }, [] as AccountMenuItems);

  return [
    ...filteredMenuItems,
    {
      items: [
        {
          name: "Log out",
          icon: (
            <LogoutOutlined className={clsx(classes.icon, classes.logout)} />
          ),
          onClick: logoutCallback,
          dataAttributes: {
            "data-testid": "logout-link",
            "data-pendo": "auto-headernav-accounts-logout",
          },
        },
      ],
    },
  ];
}

function getRolePriority(roleCode: RoleCode): number {
  switch (roleCode) {
    case RoleCode.Admin:
      return 3;
    case RoleCode.Editor:
      return 2;
    case RoleCode.Viewer:
      return 1;
    default:
      return 0;
  }
}
