import { makeStyles, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Theme } from "@material-ui/core";
import { ToggleIconButton, TrashSolid, PencilSolid } from "@lumar/shared";
import { DisableForViewer } from "../../../_common/components/DisableForViewer/DisableForViewer";
import {
  PopoverDialogDefaultIdentifiers,
  PopoverDialog,
} from "../../../_common/components/PopoverDialog/PopoverDialog";
import { defaultPopOverId } from "../../../_common/constants/popover";

const useStyles = makeStyles((theme: Theme) => ({
  headerGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  header: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    color: theme.palette.grey[700],
    fontWeight: 600,
    display: "block",
  },
}));

interface SchedulerDetailViewHeaderProps {
  scheduleName: string;
  scheduleId: string;
  handleEditSchedule: () => void;
  handleConfirmScheduleDeletion: () => Promise<void>;
}

export function SchedulerDetailViewHeader(
  props: SchedulerDetailViewHeaderProps,
) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.headerGrid}>
        <Typography
          variant="h3"
          className={classes.header}
          data-testid="scheduler-detail-view-name"
          noWrap
        >
          {props.scheduleName}
        </Typography>
        <Box display="flex" alignItems="center" marginLeft={1}>
          <DisableForViewer tooltipTitle="Edit schedule">
            <ToggleIconButton
              onClick={props.handleEditSchedule}
              data-testid="edit-schedule"
              data-cy="action-icon-button"
              data-pendo="auto-scheduler-detail-edit"
              size="medium"
              variant="outlined"
            >
              <PencilSolid />
            </ToggleIconButton>
          </DisableForViewer>

          <DeleteButtonWithConfirmation
            handleConfirmation={props.handleConfirmScheduleDeletion}
          />
        </Box>
      </Box>
    </>
  );
}

function DeleteButtonWithConfirmation(props: {
  handleConfirmation: () => void;
}): JSX.Element {
  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const isPopoverOpen = Boolean(popoverAnchorElement);
  const popoverId = isPopoverOpen ? defaultPopOverId : undefined;

  function handleDelete(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    event.stopPropagation();
    setPopoverAnchorElement(event.currentTarget);
  }

  function handleAction(id: string): void {
    setPopoverAnchorElement(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      props.handleConfirmation();
    }
  }

  return (
    <>
      <DisableForViewer tooltipTitle="Delete schedule">
        <ToggleIconButton
          onClick={handleDelete}
          data-testid="delete-schedule"
          data-cy="action-icon-button"
          data-pendo="auto-scheduler-detail-delete-button"
          size="medium"
          variant="outlined"
          colorVariant="red"
          style={{ marginLeft: 8 }}
        >
          <TrashSolid />
        </ToggleIconButton>
      </DisableForViewer>

      <PopoverDialog
        anchorElement={popoverAnchorElement}
        handleAction={handleAction}
        open={isPopoverOpen}
        title="Are you sure you want to delete this scheduled run permanently?"
        text="This cannot be undone. This will stop all test suites from running in this scheduled test suite run but they will still be running in the CI CD pipeline."
        buttons={[
          {
            label: "Cancel",
            identifier: PopoverDialogDefaultIdentifiers.CANCEL,
            color: "primary",
          },
          {
            label: "Delete",
            identifier: PopoverDialogDefaultIdentifiers.OK,
            color: "alert",
          },
        ]}
        id={popoverId}
      />
    </>
  );
}
