import { EmailAlertType } from "../../../../../../graphql";

export function getAlertTypesFromNotificationSettings(
  pass: boolean,
  warning: boolean,
  fail: boolean,
) {
  const notificationSettings = [pass, warning, fail];
  return [
    EmailAlertType.Pass,
    EmailAlertType.Warning,
    EmailAlertType.Fail,
  ].filter((_, i) => notificationSettings[i]);
}
