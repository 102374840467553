import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Collapse, Grid, makeStyles, Tooltip, Typography, } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AppIcon } from "./app-icon/AppIcon";
import clsx from "clsx";
import { MainAppMenuItem } from "./MainAppMenuItem";
const useStyles = makeStyles((theme) => {
    var _a, _b;
    return ({
        root: {
            marginBottom: theme.spacing(1.5),
        },
        iconContainer: {
            cursor: "pointer",
            zIndex: 1,
            lineHeight: 0,
            background: "none",
            border: "none",
        },
        appMenu: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        appName: Object.assign({ color: "white", textAlign: "center", textTransform: "uppercase", fontSize: 10, cursor: "default", marginTop: theme.spacing(0.25) }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.AppSwitcherItem) === null || _b === void 0 ? void 0 : _b.appName),
        appNameDisabled: {
            opacity: 0.6,
        },
        appNameFocused: {
            opacity: 1,
            fontWeight: 600,
        },
        tooltip: {
            background: "#020034",
            marginLeft: 22,
            textTransform: "capitalize",
        },
        tooltipArrow: {
            color: "#020034",
        },
    });
});
export function AppSwitcherItem(props) {
    var _a, _b;
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const isOpen = Boolean(props.selected) &&
        Boolean(props.enabled) &&
        Boolean((_a = props.items) === null || _a === void 0 ? void 0 : _a.length);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function getButtonProps() {
        if (!props.enabled || isOpen)
            return props.isMain
                ? {
                    component: Link,
                    to: props.href,
                }
                : {
                    component: "a",
                    href: props.href,
                };
        return {
            onClick: () => { var _a; return props.enabled && ((_a = props.setSelected) === null || _a === void 0 ? void 0 : _a.call(props)); },
            component: "button",
        };
    }
    return (_jsxs(Grid, { container: true, alignItems: "center", direction: "column", className: classes.root, "data-pendo": props.pendoPrefix
            ? `${props.pendoPrefix}-app-switcher-item-${props.id}`
            : undefined, children: [_jsx(Tooltip, { title: props.name, placement: "right", classes: { tooltip: classes.tooltip, arrow: classes.tooltipArrow }, children: _jsx(Grid, Object.assign({ item: true, className: classes.iconContainer }, getButtonProps(), { onMouseEnter: () => setFocused(true), onMouseLeave: () => setFocused(false), "data-testid": props.testId, "aria-label": props.id, children: _jsx(AppIcon, { appId: props.id, enabled: Boolean(props.enabled), focused: focused, active: isOpen, isMain: props.isMain }) })) }), _jsx(Collapse, { in: isOpen, children: _jsx(Grid, { item: true, className: classes.appMenu, children: (_b = props.items) === null || _b === void 0 ? void 0 : _b.map((item) => (_jsx(MainAppMenuItem, { item: item, isMain: props.isMain, pendoPrefix: props.pendoPrefix }, item.id))) }) }), _jsx(Collapse, { in: !isOpen, children: _jsx(Grid, { item: true, children: _jsx(Typography, { className: clsx(classes.appName, {
                            [classes.appNameDisabled]: !props.enabled,
                            [classes.appNameFocused]: focused,
                        }), children: props.name }) }) })] }));
}
