import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, LinearProgress, makeStyles, useTheme } from "@material-ui/core";
import { LumarLogo } from "../../icons/LumarLogo";
const useStyles = makeStyles((theme) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        container: Object.assign({ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", height: "100vh", overflow: "hidden", minWidth: "340px", minHeight: "390px", position: "relative", background: `url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 1024" fill="none" preserveAspectRatio="none"><path d="M0 0H1440V1024H0V0Z" fill="url(%23paint0_radial_11783_53349)" /><defs><radialGradient id="paint0_radial_11783_53349" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1440 34.9097) rotate(174.271) scale(1771.7 15427.9)" ><stop stop-color="%23D2B8FF" /><stop offset="0.581598" stop-color="%2306025E" /><stop offset="0.951396" stop-color="%23020034" /></radialGradient></defs></svg>')`, backgroundSize: "cover" }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.LoadingScreen) === null || _b === void 0 ? void 0 : _b.container),
        innerContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
        },
        progress: {
            height: "6px",
            width: "234px",
            borderRadius: "6px",
            marginTop: theme.spacing(6.5),
            zIndex: 1,
        },
        progressColorPrimary: Object.assign({ background: theme.palette.grey[900] }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.LoadingScreen) === null || _d === void 0 ? void 0 : _d.progressColorPrimary),
        progressBarColorPrimary: Object.assign({ background: "white" }, (_f = (_e = theme.overrides) === null || _e === void 0 ? void 0 : _e.LoadingScreen) === null || _f === void 0 ? void 0 : _f.progressBarColorPrimary),
        text: {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(18),
            lineHeight: "27px",
        },
        background: {
            position: "absolute",
            inset: 0,
            width: "100%",
            maxHeight: "100%",
            minHeight: "100%",
        },
        logo: {
            maxWidth: "350px",
            with: "100%",
        },
    });
});
export function LoadingScreen() {
    var _a, _b;
    const theme = useTheme();
    const LogoComponent = ((_b = (_a = theme.props) === null || _a === void 0 ? void 0 : _a.Logo) === null || _b === void 0 ? void 0 : _b.LogoComponent) || LumarLogo;
    const classes = useStyles();
    return (_jsx(Box, { className: classes.container, "data-testid": "session-loading", children: _jsxs(Box, { className: classes.innerContainer, children: [_jsx("div", { style: { zIndex: 1 }, children: _jsx(LogoComponent, { className: classes.logo }) }), _jsx(LinearProgress, { variant: "indeterminate", color: "primary", className: classes.progress, classes: {
                        colorPrimary: classes.progressColorPrimary,
                        barColorPrimary: classes.progressBarColorPrimary,
                    } })] }) }));
}
