import TimeAgo from "javascript-time-ago";
import countries from "i18n-iso-countries";
import en from "javascript-time-ago/locale/en";
import ja from "javascript-time-ago/locale/ja";
TimeAgo.addLocale(en);
TimeAgo.addLocale(ja);
TimeAgo.setDefaultLocale("en");
export function getFormatters(languages) {
    initCountyLocales(languages);
    return [
        [
            "number",
            (value, lng, options) => {
                return Number(value).toLocaleString(lng, {
                    minimumFractionDigits: options === null || options === void 0 ? void 0 : options.minimumFractionDigits,
                    maximumFractionDigits: options === null || options === void 0 ? void 0 : options.maximumFractionDigits,
                });
            },
        ],
        [
            "compactNumber",
            (value, lng, options) => {
                return new Intl.NumberFormat(lng, {
                    notation: "compact",
                    minimumFractionDigits: options === null || options === void 0 ? void 0 : options.minimumFractionDigits,
                    maximumFractionDigits: options === null || options === void 0 ? void 0 : options.maximumFractionDigits,
                }).format(Number(value));
            },
        ],
        [
            "percent",
            (value, lng, options) => {
                return Number(value).toLocaleString(lng, {
                    style: "percent",
                    minimumFractionDigits: options === null || options === void 0 ? void 0 : options.minimumFractionDigits,
                    maximumFractionDigits: options === null || options === void 0 ? void 0 : options.maximumFractionDigits,
                });
            },
        ],
        [
            "dateTime",
            (value, lng, options) => {
                return new Intl.DateTimeFormat(lng, {
                    dateStyle: (options === null || options === void 0 ? void 0 : options.dateFormat) || "medium",
                    timeStyle: (options === null || options === void 0 ? void 0 : options.timeFormat) || "short",
                }).format(value);
            },
        ],
        [
            "timeAgo",
            (value, lng) => {
                if (!(value instanceof Date))
                    return value;
                return new TimeAgo(lng).format(value);
            },
        ],
        [
            "country",
            (value, lng) => {
                return countries.getName(value, lng !== null && lng !== void 0 ? lng : "en");
            },
        ],
    ];
}
function initCountyLocales(languages) {
    function getCountryLocale(lng) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const locale = require(`i18n-iso-countries/langs/${lng}.json`);
            return locale;
        }
        catch (_a) {
            return;
        }
    }
    languages.forEach((lng) => {
        const locale = getCountryLocale(lng);
        if (!locale)
            return;
        countries.registerLocale(locale);
    });
}
