import React, { useState } from "react";
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Box,
} from "@material-ui/core";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { useHistory } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import {
  PopoverDialog,
  PopoverDialogDefaultIdentifiers,
  PopoverDialogButton,
} from "../../../../_common/components/PopoverDialog/PopoverDialog";
import { Routes } from "../../../../_common/routes/routes";
import { defaultPopOverId } from "../../../../_common/constants/popover";
import { DisableForViewer } from "../../../../_common/components/DisableForViewer/DisableForViewer";
import { ToggleIconButton } from "@lumar/shared";
import { GetJiraIntegrationsWithTestSuitesQuery } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    border: "1px solid #e0e0e0",
  },
  tableHeaderText: {
    color: theme.palette.navy[400],
    fontSize: "16px",
    lineHeight: "20px",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}));

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary",
  },
  {
    label: "Disconnect",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "alert",
  },
];

export interface JiraIntegrationConnectedTestSuitesTableProps {
  testSuites: NonNullable<
    GetJiraIntegrationsWithTestSuitesQuery["getAccount"]
  >["jiraAuthentications"]["nodes"][0]["jiraIntegrations"]["nodes"][0]["testSuiteJiraIntegrations"]["nodes"][0]["testSuite"][];
  isUnlinkingIntegrationLoading: boolean;
  unlinkIntegration: (testSuiteToUnlinkId: string | undefined) => void;
}
// eslint-disable-next-line max-lines-per-function
export function JiraIntegrationConnectedTestSuitesTable(
  props: JiraIntegrationConnectedTestSuitesTableProps,
) {
  const classes = useStyles();
  const history = useHistory();
  const accountId = useAccountRouteMatch();
  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLButtonElement | null>(null);
  const [testSuiteToUnlinkId, setTestSuiteToUnlinkId] = useState<string>();

  const showDisconnectIntegration = Boolean(popoverAnchorElement);
  const popoverId = showDisconnectIntegration ? defaultPopOverId : undefined;

  function handleRemoveIntegration(
    id: PopoverDialogDefaultIdentifiers | string,
  ) {
    setPopoverAnchorElement(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      props.unlinkIntegration(testSuiteToUnlinkId);
    }
  }

  function editTestSuite(testSuiteId: string) {
    if (!props.isUnlinkingIntegrationLoading) {
      history.push(Routes.EditTestSuite.getUrl({ accountId, testSuiteId }));
    }
  }

  function handleClickDisconnect(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    testSuiteId: string,
  ) {
    if (!props.isUnlinkingIntegrationLoading) {
      setTestSuiteToUnlinkId(testSuiteId);
      setPopoverAnchorElement(event.currentTarget);
    }
  }

  return (
    <>
      <PopoverDialog
        anchorElement={popoverAnchorElement}
        handleAction={handleRemoveIntegration}
        open={showDisconnectIntegration}
        text="If you disconnect this test suite, you won’t receive any new tickets in your Jira for this Test Suite."
        title="Are you sure you want to disconnect this test suite?"
        buttons={popoverButtons}
        id={popoverId}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-label="simple table"
          data-testid="jira-integration-detail-testsuite-table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderText}>
                Test suite name
              </TableCell>
              <TableCell
                align="right"
                size="small"
                className={classes.tableHeaderText}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* eslint-disable-next-line complexity */}
            {props.testSuites.map((testSuite) => (
              <TableRow
                key={testSuite.id}
                className={classes.row}
                data-cy={`jira-integration-detail-testsuite-item`}
                data-testid={`jira-integration-detail-testsuite-${testSuite.id}`}
              >
                <TableCell
                  component="th"
                  scope="row"
                  data-cy={`jira-integration-detail-testsuite-item-name`}
                >
                  {testSuite.name}
                </TableCell>
                <TableCell align="right" size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <DisableForViewer tooltipTitle="Edit test suite">
                      <ToggleIconButton
                        variant="outlined"
                        onClick={() => editTestSuite(testSuite.id)}
                        data-testid="jira-integration-detail-testsuite-edit"
                        data-pendo="auto-jira-integration-detail-testsuite-edit"
                      >
                        <CreateRoundedIcon />
                      </ToggleIconButton>
                    </DisableForViewer>

                    <DisableForViewer tooltipTitle="Disconnect test suite">
                      <ToggleIconButton
                        style={{ marginLeft: 8 }}
                        onClick={(event) =>
                          handleClickDisconnect(event, testSuite.id)
                        }
                        variant="outlined"
                        colorVariant="red"
                        data-testid="jira-integration-detail-testsuite-remove"
                        data-pendo="auto-jira-integration-detail-testsuite-remove"
                        active={showDisconnectIntegration}
                      >
                        <ClearRoundedIcon />
                      </ToggleIconButton>
                    </DisableForViewer>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
