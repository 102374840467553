var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useSetAdminDisabledMutation } from "../graphql";
export function useAdminModeState(session) {
    const [enabledInternal, setEnabledInternal] = React.useState(undefined);
    const [setAdminDisabled] = useSetAdminDisabledMutation();
    const enabled = enabledInternal !== null && enabledInternal !== void 0 ? enabledInternal : !(session === null || session === void 0 ? void 0 : session.me.adminDisabled);
    const setEnabled = React.useCallback((enabled) => __awaiter(this, void 0, void 0, function* () {
        yield setAdminDisabled({ variables: { disabled: !enabled } });
        setEnabledInternal(enabled);
    }), [setAdminDisabled, setEnabledInternal]);
    return [enabled, setEnabled];
}
