var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Typography as MuiTypography, withStyles, } from "@material-ui/core";
export const TypographyInner = React.forwardRef(function TypographyInner(_a, ref) {
    var { variant, classes } = _a, props = __rest(_a, ["variant", "classes"]);
    const component = variant && variantComponentMap[variant];
    return (_jsx(MuiTypography, Object.assign({}, (component ? { component } : {}), props, { ref: ref, variant: "inherit", classes: { root: variant ? classes === null || classes === void 0 ? void 0 : classes[variant] : classes === null || classes === void 0 ? void 0 : classes.root } })));
});
const variantComponentMap = {
    h6: "h6",
    h6Medium: "h6",
    h6SemiBold: "h6",
    h6Bold: "h6",
    h5: "h5",
    h5Medium: "h5",
    h5SemiBold: "h5",
    h5Bold: "h5",
    h4: "h4",
    h4Medium: "h4",
    h4SemiBold: "h4",
    h4Bold: "h4",
    h3: "h3",
    h3Medium: "h3",
    h3SemiBold: "h3",
    h3Bold: "h3",
    h2: "h2",
    h2Medium: "h2",
    h2SemiBold: "h2",
    h2Bold: "h2",
    h1: "h1",
    h1Medium: "h1",
    h1SemiBold: "h1",
    h1Bold: "h1",
    subtitle2Medium: "h6",
    subtitle1: "h6",
    subtitle2: "h6",
    body1: "p",
    body2: "p",
};
export const Typography = withStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
    },
    overline: Object.assign(Object.assign({}, theme.typography.overline), { lineHeight: undefined }),
    overlineLarge: theme.typography.overlineLarge,
    caption: Object.assign(Object.assign({}, theme.typography.caption), { lineHeight: undefined }),
    captionMedium: theme.typography.captionMedium,
    captionSemiBold: theme.typography.captionSemiBold,
    captionBold: theme.typography.captionBold,
    button: Object.assign(Object.assign({}, theme.typography.button), { lineHeight: undefined }),
    body2: Object.assign(Object.assign({}, theme.typography.body2), { lineHeight: undefined }),
    body1: Object.assign(Object.assign({}, theme.typography.body1), { lineHeight: undefined }),
    subtitle4: theme.typography.subtitle4,
    subtitle4Medium: theme.typography.subtitle4Medium,
    subtitle4SemiBold: theme.typography.subtitle4SemiBold,
    subtitle4Bold: theme.typography.subtitle4Bold,
    subtitle3: theme.typography.subtitle3,
    subtitle3Medium: theme.typography.subtitle3Medium,
    subtitle3SemiBold: theme.typography.subtitle3SemiBold,
    subtitle3Bold: theme.typography.subtitle3Bold,
    subtitle2: Object.assign(Object.assign({}, theme.typography.subtitle2), { lineHeight: undefined }),
    subtitle2Medium: theme.typography.subtitle2Medium,
    subtitle2SemiBold: theme.typography.subtitle2SemiBold,
    subtitle2Bold: theme.typography.subtitle2Bold,
    subtitle1: Object.assign(Object.assign({}, theme.typography.subtitle1), { lineHeight: undefined }),
    subtitle1Medium: theme.typography.subtitle1Medium,
    subtitle1SemiBold: theme.typography.subtitle1SemiBold,
    subtitle1Bold: theme.typography.subtitle1Bold,
    h6: Object.assign(Object.assign({}, theme.typography.h6), { lineHeight: undefined }),
    h6Medium: theme.typography.h6Medium,
    h6SemiBold: theme.typography.h6SemiBold,
    h6Bold: theme.typography.h6Bold,
    h5: Object.assign(Object.assign({}, theme.typography.h5), { lineHeight: undefined }),
    h5Medium: theme.typography.h5Medium,
    h5SemiBold: theme.typography.h5SemiBold,
    h5Bold: theme.typography.h5Bold,
    h4: Object.assign(Object.assign({}, theme.typography.h4), { lineHeight: undefined }),
    h4Medium: theme.typography.h4Medium,
    h4SemiBold: theme.typography.h4SemiBold,
    h4Bold: theme.typography.h4Bold,
    h3: Object.assign(Object.assign({}, theme.typography.h3), { lineHeight: undefined }),
    h3Medium: theme.typography.h3Medium,
    h3SemiBold: theme.typography.h3SemiBold,
    h3Bold: theme.typography.h3Bold,
    h2: Object.assign(Object.assign({}, theme.typography.h2), { lineHeight: undefined }),
    h2Medium: theme.typography.h2Medium,
    h2SemiBold: theme.typography.h2SemiBold,
    h2Bold: theme.typography.h2Bold,
    h1: Object.assign(Object.assign({}, theme.typography.h1), { lineHeight: undefined }),
    h1Medium: theme.typography.h1Medium,
    h1SemiBold: theme.typography.h1SemiBold,
    h1Bold: theme.typography.h1Bold,
}), { name: "AppTypography" })(TypographyInner);
