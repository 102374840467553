import React, { useState } from "react";
import { TemplateDialog } from "./TemplateDialog";
import { ApplyGlobalTemplate } from "./ApplyGlobalTemplate";
import {
  UseAsGlobalTemplate,
  UseAsGlobalTemplateVariant,
} from "./UseAsGlobalTemplate";
import { TemplateSettingsMenu } from "./TemplateSettingsMenu";

interface NoTemplateSettingsViewProps {
  applyGlobalTemplate: (parentId: string) => Promise<void>;
  testSuiteId: string;
  useAsGlobalTemplate: (childId: string, childName: string) => Promise<void>;
}

export enum TemplateSettingsType {
  AddParent = "Add parent",
  AddChildren = "Add children",
}

export function NoTemplateSettingsView(
  props: NoTemplateSettingsViewProps,
): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [templateSettingsType, setTemplateSettingsType] = useState<
    string | null
  >(null);
  const [availableChildrenDetected, setAvailableChildrenDetected] = useState<
    number | null
  >(null);
  const [availableParentsDetected, setAvailableParentsDetected] = useState<
    number | null
  >(null);

  function updateTemplateSettingsType(
    templateType: TemplateSettingsType,
  ): void {
    setTemplateSettingsType(templateType);
  }

  function closeTemplateDialog(): void {
    setIsDialogOpen(false);
    setTemplateSettingsType(null);
  }

  function openTemplateDialog(): void {
    setIsDialogOpen(true);
  }

  const addingChildren =
    templateSettingsType === TemplateSettingsType.AddChildren;

  return (
    <div data-testid="no-template-settings-view" style={{ paddingTop: 8 }}>
      <TemplateSettingsMenu
        openTemplateDialog={openTemplateDialog}
        updateTemplateSettingsType={updateTemplateSettingsType}
      />
      <TemplateDialog
        isOpen={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        title={
          addingChildren
            ? "Create global template and apply to an existing test suite"
            : "Apply global template"
        }
        message={
          addingChildren
            ? "This will replace any configurations in steps 2 (Crawl settings) and 3 (Tests) of linked test suite."
            : "This will replace any configurations in steps 2 (Crawl settings) and 3 (Tests) of this test suite."
        }
        skipMessage={
          addingChildren
            ? availableChildrenDetected === 0
            : availableParentsDetected === 0
        }
        component={
          addingChildren ? (
            <UseAsGlobalTemplate
              handleClose={closeTemplateDialog}
              handleUseAsGlobalTemplate={props.useAsGlobalTemplate}
              label="Choose test suite"
              placeholder="Choose from available test suites..."
              variant={UseAsGlobalTemplateVariant.AddNewChild}
              testSuiteId={props.testSuiteId}
              availableTestSuitesDetected={setAvailableChildrenDetected}
            />
          ) : (
            <ApplyGlobalTemplate
              handleApplyGlobalTemplate={props.applyGlobalTemplate}
              handleClose={closeTemplateDialog}
              testSuiteId={props.testSuiteId}
              availableTestSuitesDetected={setAvailableParentsDetected}
            />
          )
        }
      />
    </div>
  );
}
