export function sortedArrayAreSame(
  array1: string[] | undefined | null,
  array2: string[] | undefined | null,
): boolean {
  if (array1 === undefined && array2 === undefined) return true;
  if (array1 === null && array2 === null) return true;
  if (
    array1 &&
    array2 &&
    Array.isArray(array1) &&
    Array.isArray(array2) &&
    array1.length === array2.length
  ) {
    if (array1.length === 0 && array2.length === 0) return true;
    // eslint-disable-next-line fp/no-mutating-methods
    const previousTestSuitesId = array1.sort();
    // eslint-disable-next-line fp/no-mutating-methods
    const newTestSuitesId = array2.sort();
    const arrayAreSame = previousTestSuitesId
      .map((id, index) => {
        return id === newTestSuitesId[index];
      })
      .reduce((prev, curr) => prev && curr);
    return arrayAreSame;
  }
  return false;
}
