var _a, _b;
/**
 * Don't include extra imports here, as the idea is to isolate the legacy content
 * to be easily removed afterwards.
 * @author Alex Sánchez
 */
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { kebabCase } from "lodash";
import Cookies from "js-cookie";
import { getRawAccountId } from "../helpers/getRawAccountId";
import { getApiAccountId } from "../helpers/getApiAccountId";
function assert(
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
condition, errorMessage = "") {
    if (!condition) {
        throw new Error(errorMessage ? `Assertion Error: ${errorMessage}` : "Assertion Error");
    }
}
export const PREFERRED_ACCOUNT_KEY = kebabCase(`preferred-account-${(_a = process.env.REACT_APP_COOKIE_DOMAIN) !== null && _a !== void 0 ? _a : ""}`);
const isJpDomain = /\.jp$/.test(window.location.hostname);
const secureDomainOptions = {
    domain: isJpDomain
        ? (_b = process.env.REACT_APP_COOKIE_DOMAIN) === null || _b === void 0 ? void 0 : _b.replace(".com", ".jp")
        : process.env.REACT_APP_COOKIE_DOMAIN,
    secure: true,
    expires: new Date("9999-12-31T23:59:59"),
};
const isLocalhost = window.location.hostname.includes("localhost");
if (!isLocalhost) {
    assert(process.env.REACT_APP_COOKIE_DOMAIN, "REACT_APP_COOKIE_DOMAIN is not set!");
}
export function rememberPreferredAccountId(value) {
    const cookieOptions = isLocalhost ? undefined : secureDomainOptions;
    if (value) {
        const accountId = getRawAccountId(value);
        Cookies.set(PREFERRED_ACCOUNT_KEY, accountId, cookieOptions);
    }
    else {
        Cookies.remove(PREFERRED_ACCOUNT_KEY, cookieOptions);
        // FIXME: Removing top-level and host domain cookie
        // to make sure that we don't accidentally re-read
        // cookies that match current domain. Ideally, we
        // should have different top-level domains for each environment
        // so we don't have to worry about session cookies
        // clash between dev/staging and prod environments.
        if (!isLocalhost) {
            Cookies.remove(PREFERRED_ACCOUNT_KEY, Object.assign(Object.assign({}, cookieOptions), { domain: ".lumar.io" }));
            Cookies.remove(PREFERRED_ACCOUNT_KEY, Object.assign(Object.assign({}, cookieOptions), { domain: window.location.host }));
        }
    }
}
export function getRememberedPreferredAccountId() {
    const value = Cookies.get(PREFERRED_ACCOUNT_KEY);
    const accountId = (() => {
        try {
            return value ? Number.parseInt(getRawAccountId(value)) : undefined;
        }
        catch (_a) { }
    })();
    if (accountId && !isNaN(accountId)) {
        return getApiAccountId(accountId.toString());
    }
    return undefined;
}
/**
 * @author Alex Sánchez
 * @deprecated Needs to look for an alternative to store the preferred account
 * to be able to delete cookies all-together, possibly on the backend?
 */
export function usePreferredAccountId() {
    const match = useRouteMatch({
        path: "/accounts/:accountId",
        exact: false,
    });
    const accountId = match === null || match === void 0 ? void 0 : match.params.accountId;
    React.useEffect(() => {
        if (accountId) {
            rememberPreferredAccountId(accountId);
        }
    }, [accountId]);
    return React.useMemo(() => {
        if (accountId) {
            return getApiAccountId(accountId);
        }
        return getRememberedPreferredAccountId();
    }, [accountId]);
}
export function getPreferredAccountUser(data, prefferedAccountId) {
    var _a;
    return (_a = data.me.accountsUsers.edges.find((edge) => edge.node.account.id === prefferedAccountId)) === null || _a === void 0 ? void 0 : _a.node;
}
