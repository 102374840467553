import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography, makeStyles, withStyles, } from "@material-ui/core";
import { ArrowNarrowLeftSolid, ArrowNarrowRightSolid } from "../../icons";
import { useTranslation } from "../../i18n";
const useStyles = makeStyles((theme) => ({
    container: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: 7,
    },
    leftButton: {
        paddingLeft: 3,
    },
    rightButton: {
        paddingRight: 3,
    },
    text: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 500,
    },
}));
export function Pagination({ currentPage, totalPages, onNext, onPrevious, }) {
    const classes = useStyles();
    const { t } = useTranslation("grid");
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    return (_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "baseline", className: classes.container, children: [_jsx(PaginationButton, { onClick: onPrevious, disabled: isFirstPage, className: classes.leftButton, "data-testid": "pagination-previous-page", startIcon: _jsx(ArrowNarrowLeftSolid, {}), size: "large", children: t("pagination.previous") }), _jsxs(Typography, { "data-testid": "pagination-label", className: classes.text, children: [currentPage, " ", t("pagination.of"), " ", totalPages] }), _jsx(PaginationButton, { onClick: onNext, disabled: isLastPage, className: classes.rightButton, "data-testid": "pagination-next-page", endIcon: _jsx(ArrowNarrowRightSolid, {}), size: "large", children: t("pagination.next") })] }));
}
const PaginationButton = withStyles((theme) => ({
    root: {
        boxShadow: "none",
        color: theme.palette.grey[500],
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-disabled": {
            color: theme.palette.grey[300],
            "& svg": {
                color: theme.palette.grey[300],
            },
        },
    },
    startIcon: { color: theme.palette.grey[400] },
    endIcon: {
        color: theme.palette.grey[400],
    },
}))(Button);
