import { GetLocationsQuery, LocationCode } from "../../../../../graphql";

export function sortLocationData(
  locations: GetLocationsQuery["getTestSuiteLocations"],
): GetLocationsQuery["getTestSuiteLocations"] {
  const defaultLocation = locations.find(
    (location) => location.code === LocationCode.Default,
  );
  if (!defaultLocation) {
    return locations;
  }
  const filteredData = locations.filter(
    (location) => location.code !== LocationCode.Default,
  );
  return [defaultLocation, ...filteredData];
}
