import { Box, Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Snackbar, Alert } from "@lumar/shared";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { LogoutContext } from "../../../routes/AppRoutesPrivate";
import { Routes } from "../../routes/routes";
import { PageContainer } from "../PageContainer/PageContainer";
import { AuthRouteProps } from "./AuthRoute";
import {
  useAcceptTermsAndConditionsMutation,
  useGetUserWithTermsQuery,
} from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  title: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: theme.spacing(1),
  },
  text: {
    fontWeight: 300,
    fontSize: "16px",
    marginBottom: theme.spacing(4),
  },
  dialog: {
    padding: theme.spacing(4.5, 3, 2, 3),
    maxWidth: 402, // 450px - 24px - 24px
  },
  button: {
    height: 36,
  },
  containedButton: {
    marginLeft: theme.spacing(1),
  },
  loader: {
    marginTop: theme.spacing(3.5),
  },
}));

// eslint-disable-next-line max-statements
export function AuthRouteAuthorised({
  includePageContainer = true,
  ...otherProps
}: AuthRouteProps): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const isLoginPath = location.pathname === Routes.Login.ROUTE;
  const logoutContext = useContext(LogoutContext);
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useGetUserWithTermsQuery();
  const [acceptTermsAndConditions, { loading: acceptTermsLoading }] =
    useAcceptTermsAndConditionsMutation({
      refetchQueries: ["getUserWithTerms"],
    });

  const areTermsAgreed = !!data?.me.termsAgreed;

  async function handleAgree(): Promise<void> {
    try {
      await acceptTermsAndConditions();
      enqueueSnackbar(
        <Snackbar title="Terms and conditions accepted" variant="success" />,
      );
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Something went wrong. Please refresh the page and try again"
          variant="error"
        />,
      );
    }
  }

  if (loading) {
    return (
      <PageContainer>
        <Skeleton
          data-testid="loading-user-terms"
          variant="rect"
          height={56}
          width="100%"
          className={classes.loader}
        />
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <Alert severity="error" data-testid="user-terms-error">
          Something went wrong. Please refresh the page and try again.
        </Alert>
      </PageContainer>
    );
  }

  if (!areTermsAgreed) {
    return (
      <Dialog
        data-testid="user-terms-dialog"
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: classes.dialog }}
        maxWidth="xs"
      >
        <Typography color="textPrimary" variant="h1" className={classes.title}>
          Terms and Conditions
        </Typography>
        <Typography color="textPrimary" className={classes.text}>
          Before you can continue to the site, you must accept the{" "}
          <a
            href="https://www.lumar.io/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lumar Protect Terms and Conditions
          </a>
          .
        </Typography>
        <Box className={classes.buttonsWrapper}>
          <Button
            data-testid="decline-terms"
            className={classes.button}
            variant="outlined"
            onClick={() => {
              logoutContext.logoutHandler();
            }}
          >
            Decline
          </Button>
          <Button
            data-testid="accept-terms"
            className={clsx(classes.button, classes.containedButton)}
            variant="contained"
            color="secondary"
            disabled={acceptTermsLoading}
            onClick={handleAgree}
          >
            {acceptTermsLoading ? "Loading..." : "Agree"}
          </Button>
        </Box>
      </Dialog>
    );
  }

  const pageContent = isLoginPath ? (
    <Redirect
      to={{
        pathname: Routes.LoginCallback.ROUTE,
        state: { from: location },
      }}
    />
  ) : (
    <Route {...otherProps} />
  );

  return includePageContainer ? (
    <PageContainer>{pageContent}</PageContainer>
  ) : (
    pageContent
  );
}
