import { SearchOutlined, XCircleSolid } from "@lumar/shared";
import {
  Box,
  InputAdornment,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#EBEFF3",
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: `10px 16px`,
    marginBottom: theme.spacing(3.5),
    borderRadius: 8,
  },
}));

interface SearchBarProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onClear: () => void;
  disabled: boolean;
}

export function SearchBar(props: SearchBarProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <TextField
        style={{
          width: 290,
          maxWidth: "100%",
        }}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        placeholder="Search test suites"
        variant="outlined"
        data-pendo="auto-search-test-suites"
        data-testid="search-test-suites"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: props.value ? (
            <InputAdornment position="end">
              <IconButton
                onClick={props.onClear}
                title="Clear search bar"
                style={{
                  padding: 4,
                  marginRight: 7,
                }}
              >
                <XCircleSolid fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Box>
  );
}
