import React from "react";
import { makeStyles, FormHelperText } from "@material-ui/core";
import { FormikErrors } from "formik";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "5px",
    color: theme.palette.red[600],
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  icon: {
    color: theme.palette.red[600],
    fontSize: "18px",
    marginBottom: "-4px",
    marginRight: theme.spacing(1),
  },
}));

export enum FormHelperTextStyledType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export interface FormHelperTextStyledProps {
  text: FormikErrors<Date | string | number> | undefined;
  id: string;
  show?: boolean;
  type?: FormHelperTextStyledType;
  testId?: string;
}

export function FormHelperTextStyled(
  props: FormHelperTextStyledProps,
): JSX.Element {
  const {
    text,
    id,
    type = FormHelperTextStyledType.INFO,
    show = true,
    testId,
  } = props;
  const classes = useStyles(props);

  return show ? (
    <FormHelperText
      id={id}
      className={classes.main}
      data-testid={testId}
      data-cy={testId}
    >
      {type === FormHelperTextStyledType.ERROR && (
        <ErrorOutlineRoundedIcon className={classes.icon} />
      )}
      {type === FormHelperTextStyledType.WARNING && (
        <WarningRoundedIcon className={classes.icon} />
      )}
      {type === FormHelperTextStyledType.SUCCESS && (
        <CheckCircleRoundedIcon className={classes.icon} />
      )}
      {text}
    </FormHelperText>
  ) : (
    <></>
  );
}
