import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import useLocalStorageState from "use-local-storage-state";
import { brandConfigurations } from "./configurations/brandConfigurations";
const BrandContext = React.createContext(brandConfigurations.lumar);
export function BrandProvider(props) {
    const hostname = window.location.hostname;
    const [forcedBrand] = useLocalStorageState("force-brand", {
        defaultValue: "",
    });
    const config = React.useMemo(() => {
        if (Object.keys(brandConfigurations).includes(forcedBrand)) {
            return Object.assign(Object.assign({}, brandConfigurations[forcedBrand]), { 
                // Prevent using different identity provider while debugging the brand.
                // Not having this might require devs to clear all cookies from the browser
                // because of invalid state that is annoying to debug and leave.
                connectionId: undefined, auth0ClientsOverrides: {} });
        }
        if (hostname.includes("similarweb")) {
            return brandConfigurations.similarweb;
        }
        return brandConfigurations.lumar;
    }, [forcedBrand, hostname]);
    return (_jsx(BrandContext.Provider, { value: config, children: props.children }));
}
export function useBrand() {
    return React.useContext(BrandContext);
}
