import { makeStyles } from "@material-ui/core";

export const useTemplateSettingsStyles = makeStyles((theme) => ({
  error: {
    marginBottom: theme.spacing(2),
  },
  textBlock: {
    marginBottom: theme.spacing(2),
  },
  content: { padding: theme.spacing(0, 2, 2, 2) },
  contentWrapper: {
    width: "100%",
  },
}));
