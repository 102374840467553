import React from "react";

export const SearchTermContext = React.createContext({ searchTerm: "" });

export function useSearchTermContext(): { searchTerm: string } {
  const context = React.useContext(SearchTermContext);

  if (!context) {
    throw new Error(
      "useSearchTermContext must be used within SearchTermContext.Provider",
    );
  }

  return context;
}
