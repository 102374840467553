import React from "react";
import { Box } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { crawlSettingsTooltips } from "../crawlSettingsTooltips";
import { Field } from "formik";
import { SectionTitle } from "../../Section";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";

export function IncludeExcludeUrls() {
  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <SectionTitle>Include and Exclude URLs</SectionTitle>
        <InfoTooltip
          IconProps={{
            style: {
              marginLeft: 8,
            },
          }}
          title={crawlSettingsTooltips.includeAndExcludeUrls}
          data-pendo="auto-tooltip-include-exclude-url"
        />
      </Box>
      <Box mb={2}>
        <StyledInputLabel htmlFor="urls-included">
          Include Only URLs
        </StyledInputLabel>
        <Field
          fullWidth
          component={TextField}
          multiline={true}
          rows="4"
          name="includeUrlPatterns"
          id="urls-included"
          data-testid="include-only-urls-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-include-urls"
          type="text"
          variant="outlined"
          style={{ maxWidth: 800 }}
        />
      </Box>

      <Box>
        <StyledInputLabel htmlFor="urls-excluded">
          Exclude URLs
        </StyledInputLabel>
        <Field
          fullWidth
          component={TextField}
          multiline={true}
          id="urls-excluded"
          rows="4"
          name="excludeUrlPatterns"
          data-testid="exclude-urls-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-exclude-urls"
          variant="outlined"
          type="text"
          style={{ maxWidth: 800 }}
        />
      </Box>
    </>
  );
}
