var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { SvgIcon, withStyles } from "@material-ui/core";
// Note: This function is based on the MUI's createSvgIcon function, and extends it with
// a defaultProps parameter which allows to overwrite the SvgIcon's default paramters. - Csaba
// https://github.com/mui/material-ui/blob/v4.11.2/packages/material-ui/src/utils/createSvgIcon.js
// eslint-disable-next-line import/no-default-export
export default function createSvgIcon(path, displayName, defaultProps) {
    const _a = defaultProps || {}, { isMarketingFriendly, isLumar } = _a, svgProps = __rest(_a, ["isMarketingFriendly", "isLumar"]);
    const Component = (props, ref) => {
        const Icon = (() => {
            if (isMarketingFriendly)
                return MarketingFriendlySvgIcon;
            if (isLumar)
                return LumarSvgIcon;
            return SvgIcon;
        })();
        return (_jsx(Icon, Object.assign({ "data-mui-test": `${displayName}Icon`, ref: ref }, svgProps, props, { children: path })));
    };
    if (process.env.NODE_ENV !== "production") {
        // Need to set `displayName` on the inner component for React.memo.
        // React prior to 16.14 ignores `displayName` on the wrapper.
        // eslint-disable-next-line fp/no-mutation
        Component.displayName = `${displayName}Icon`;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line fp/no-mutation
    Component.muiName = SvgIcon.muiName;
    return React.memo(React.forwardRef(Component));
}
// Note: Marketing friendly icons has a strokeWidth of 2, setting this value in the default
// style will allow us to overwrite the strokeWidth locally if it's needed. - Csaba
const MarketingFriendlySvgIcon = withStyles(() => ({
    root: {
        strokeWidth: 2,
    },
}))(SvgIcon);
const LumarSvgIcon = withStyles(() => ({
    root: {
        fill: "none",
    },
}))(SvgIcon);
