import React from "react";
import { Tabs, Tab, Typography, makeStyles } from "@material-ui/core";
import { GettingStarted } from "./GettingStarted";
import { About } from "./About";
import { Faqs } from "./Faqs/Faqs";

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TAB_ID_PREFIX = "help-tab";
const TAB_PANEL_ID_PREFIX = "help-tabpanel";

function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${TAB_PANEL_ID_PREFIX}-${index}`}
      aria-labelledby={`${TAB_ID_PREFIX}-${index}`}
    >
      {value === index ? children : null}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `${TAB_ID_PREFIX}-${index}`,
    "aria-controls": `${TAB_PANEL_ID_PREFIX}-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  tabs: {
    borderBottom: "none",
    marginBottom: theme.spacing(3),
  },
}));

// FIXME: add e2e tests for Help Portal page - https://deepcrawl.atlassian.net/browse/TEST-184
export function HelpPortal() {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (
    _: React.ChangeEvent<{ value?: unknown }>,
    newValue: number,
  ) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        Help portal
      </Typography>

      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="Help portal tabs"
        className={classes.tabs}
      >
        <Tab label="Getting Started" {...a11yProps(0)} />
        <Tab label="About" data-testid="about-tab" {...a11yProps(1)} />
        <Tab label="FAQ" data-testid="faq-tab" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <GettingStarted />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <About />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Faqs />
      </TabPanel>
    </>
  );
}
