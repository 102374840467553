/* eslint-disable max-lines */
import {
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import { useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  makeStyles,
  Button,
  useTheme,
} from "@material-ui/core";
import { TestSuiteVariables } from "./TestSuiteVariables";
import { CollapseHeader } from "../CollapseHeader";
import { ErrorEffect, ConnectSiteFormValues } from "./ErrorEffect";
import { CustomDnsPairs } from "./CustomDnsPairs";
import { ValidationErrors } from "../../../../validation/ValidationErrors";
import { getDuplicateCustomDnsFields } from "./utils/getDuplicateCustomDnsFields";
import { connectSiteTooltips } from "./connectSiteTooltips";
import { sortLocationData } from "./utils/sortLocationData";
import { removeTypenameFromDnsSettings } from "./utils/removeTypenameFromDnsSettings";
import { getInitialValues } from "./ConnectSiteFormUtils";
import { connectSiteFormValidation } from "./connectSiteFormValidation";
import { AuthenticationSettings } from "./components/AuthenticationSettings";
import { useSnackbar } from "notistack";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import { useSaveChangesContext } from "../../SaveChangesProvider";
import { InfoTooltip } from "../../../../_common/components/InfoTooltip/InfoTooltip";
import { Section, SectionDivider, SectionTitle } from "../Section";
import { StyledInputLabel } from "../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { Snackbar, ApolloQueryResult, useSession } from "@lumar/shared";
import { CookiesSettings } from "./components/CookiesSettings";
import {
  GetTestSuiteQuery,
  GetTestSuiteQueryVariables,
  GetUserAgentsQuery,
  LocationCode,
  ModuleCode,
  TestSuiteLocationCode,
  useGetLocationsQuery,
  useGetModuleQuery,
  useGetUserAgentsQuery,
} from "../../../../graphql";
import { AccountsRoutes } from "../../../../_common/routes/accountsRoutes";
import { UserAgent } from "./components/UserAgent";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { UserAgentType } from "./utils/types";

export interface ConnectSiteFormProps {
  onSubmit: (testSuiteVariables: TestSuiteVariables) => Promise<void>;
  onSuccess?: () => void;
  testSuite?: NonNullable<GetTestSuiteQuery["node"]>;
  project?: NonNullable<GetTestSuiteQuery["getProject"]>;
  refetchTestSuite?: (
    variables?: GetTestSuiteQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<GetTestSuiteQuery>>;
  moduleCode?: ModuleCode;
}

export interface CustomDnsPair {
  hostname: string;
  ipAddress: string;
  __typename?: string;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    maxWidth: 400,
  },
  radioLabelRoot: {
    marginLeft: 0,
  },
  radioLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  radio: { marginRight: theme.spacing(1) },
}));

// This is required for populating the `testSiteDomainPass` field when the password is set.
// We don't want to populate the field with the actual password.
export const dummyAuthPassword = "fakepassword";

function clearValue(value: number | string | null | undefined): number | null {
  if (typeof value === "string") return value.length ? parseInt(value) : null;
  return value ?? null;
}

export function ConnectSiteForm(props: ConnectSiteFormProps): JSX.Element {
  const { testSuite, onSubmit } = props;
  const classes = useStyles();
  const theme = useTheme();
  const accountId = useAccountRouteMatch();
  const session = useSession();

  const { loading: isLocationsLoading, data: locationData } =
    useGetLocationsQuery();

  const { data: userAgentsData, loading: isUserAgentsLoading } =
    useGetUserAgentsQuery();

  const userAgents = getUserAgents(userAgentsData, testSuite);

  const isAuthPasswordInitiallySet = testSuite
    ? testSuite.hasTestSitePassword
    : false;

  const initialValues = getInitialValues(
    testSuite,
    isAuthPasswordInitiallySet,
    props.moduleCode,
  );

  const moduleCode =
    testSuite?.moduleCode ?? props.moduleCode ?? ModuleCode.Seo;

  const { data: moduleData, loading: isModuleDataLoading } = useGetModuleQuery({
    variables: {
      code: moduleCode,
      accountId,
    },
  });

  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef<FormikProps<ConnectSiteFormValues>>(null);
  const saveChangesContext = useSaveChangesContext();

  const [authPassword, setAuthPassword] = useState({
    hasBeenChanged: false,
    value: isAuthPasswordInitiallySet ? dummyAuthPassword : "",
  });

  const {
    hasBeenChanged: hasAuthPasswordBeenChanged,
    value: authPasswordValue,
  } = authPassword;

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  function handleSaveAuthPassword(newPassword: string) {
    setAuthPassword((current) => ({
      ...current,
      value: newPassword,
      hasBeenChanged: true,
    }));
  }

  function toggleAuthPasswordWarning(isVisible: boolean) {
    setIsWarningVisible(isVisible);
  }

  function resetAuthPasswordState() {
    toggleAuthPasswordWarning(false);
    setAuthPassword((s) => ({ ...s, hasBeenChanged: false }));
  }

  function handleAuthPasswordSnackbar() {
    if (isAuthPasswordInitiallySet && authPassword.hasBeenChanged) {
      enqueueSnackbar(
        <Snackbar title="Password changed successfully" variant="success" />,
      );
    }
  }

  const saveChanges = async () => {
    if (formikRef.current) {
      const { values } = formikRef.current;

      const customDnsSettings = removeTypenameFromDnsSettings(
        values.customDns || [],
      );

      const isCustom = values.userAgent.code === "Custom";
      await onSubmit({
        ...values,
        location: values.location as TestSuiteLocationCode,
        customDns: customDnsSettings,
        testSiteDomainPass: hasAuthPasswordBeenChanged
          ? authPasswordValue
          : null,
        userAgentCode: isCustom ? null : values.userAgent.code,
        userAgentString: isCustom ? values.userAgent.string : null,
        userAgentToken: isCustom ? values.userAgent.token : null,
        userAgentIsMobile: values.userAgent.userAgentIsMobile,
        viewportWidth: clearValue(values.userAgent.viewportWidth),
        viewportHeight: clearValue(values.userAgent.viewportHeight),
        rendererCookies: values.rendererCookies,
      });
    }
  };

  useEffect(() => {
    saveChangesContext.registerCallback(saveChanges, "step1");
    // Note: we only need to subscribe the function if the auth password state has been changed.
    // This is so that the function is always called with the most up to date auth password value.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPasswordValue, hasAuthPasswordBeenChanged]);

  async function handleSubmit(
    values: ConnectSiteFormValues,
    actions: FormikHelpers<ConnectSiteFormValues>,
  ) {
    const customDnsSettings = removeTypenameFromDnsSettings(
      values.customDns || [],
    );
    const fieldsToValidate = getDuplicateCustomDnsFields(customDnsSettings);

    if (fieldsToValidate.length > 0) {
      fieldsToValidate.forEach((field) => {
        actions.setFieldError(field, ValidationErrors.CustomDnsDuplication);
      });
      actions.setSubmitting(false);
      return;
    }

    const isCustom = values.userAgent.code === "Custom";
    await props.onSubmit({
      ...values,
      location: values.location as TestSuiteLocationCode,
      customDns: customDnsSettings,
      testSiteDomainPass: authPassword.hasBeenChanged
        ? authPassword.value
        : null,
      userAgentIsMobile: values.userAgent.userAgentIsMobile,
      userAgentCode: isCustom ? null : values.userAgent.code,
      userAgentString: isCustom ? values.userAgent.string : null,
      userAgentToken: isCustom ? values.userAgent.token : null,
      viewportHeight: clearValue(values.userAgent.viewportHeight),
      viewportWidth: clearValue(values.userAgent.viewportWidth),
      rendererCookies: values.rendererCookies,
    });
    props.refetchTestSuite?.();
    actions.setSubmitting(false);
    resetAuthPasswordState();
    handleAuthPasswordSnackbar();

    if (props.onSuccess) {
      props.onSuccess();
    }
  }

  const sortedLoactionData = locationData
    ? sortLocationData(locationData.getTestSuiteLocations)
    : [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={connectSiteFormValidation}
      innerRef={formikRef}
    >
      {
        //eslint-disable-next-line max-lines-per-function
        (formik) => (
          <Form>
            <ErrorEffect
              formik={formik}
              onSubmissionError={() => {
                setIsCollapseOpen(true);
              }}
            />
            <div style={{ paddingBottom: theme.spacing(4) }}>
              <SectionDivider />
              <Section>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <SectionTitle>Name &amp; Domain</SectionTitle>
                  <InfoTooltip
                    interactive
                    IconProps={{
                      style: {
                        marginLeft: 8,
                      },
                    }}
                    data-pendo="auto-tooltip-connect-site-form"
                    title={connectSiteTooltips.nameAndDomain}
                  />
                </div>
                <div style={{ marginBottom: theme.spacing(2) }}>
                  <StyledInputLabel htmlFor="connect-site-form-name">
                    Test Suite Name
                  </StyledInputLabel>
                  <Field
                    className={classes.textField}
                    data-testid="connect-site-form-name"
                    data-pendo="auto-edit-add-test-suite-name"
                    id="connect-site-form-name"
                    name="name"
                    type="text"
                    component={TextField}
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
                <div style={{ marginBottom: theme.spacing(3) }}>
                  <StyledInputLabel htmlFor="connect-site-form-domain">
                    Domain/subdomain
                  </StyledInputLabel>
                  <Field
                    className={classes.textField}
                    data-testid="connect-site-form-domain"
                    data-pendo="auto-edit-add-test-suite-domain"
                    id="connect-site-form-domain"
                    name="primaryDomain"
                    type="text"
                    placeholder="https://example.com"
                    component={TextField}
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
              </Section>

              <CollapseHeader
                name={
                  moduleCode === ModuleCode.Accessibility
                    ? "Authentication, Whitelisting and Cookies"
                    : "Authentication and Whitelisting"
                }
                isOpen={isCollapseOpen}
                pendoId="auto-edit-add-test-suite-step1-authentication-whitelisting"
                testId="connect-site-form-auth-and-whitelist-container"
                onClick={() => {
                  setIsCollapseOpen(!isCollapseOpen);
                }}
                ButtonProps={{
                  style: {
                    marginLeft: 32,
                    marginBottom: 8,
                  },
                }}
              >
                <SectionDivider />

                <Section>
                  <AuthenticationSettings
                    isAuthPasswordInitiallySet={isAuthPasswordInitiallySet}
                    handleSaveAuthPassword={handleSaveAuthPassword}
                    authPasswordValue={authPassword.value}
                    hasAuthPasswordChanged={authPassword.hasBeenChanged}
                    isWarningVisible={isWarningVisible}
                    toggleWarning={toggleAuthPasswordWarning}
                  />
                </Section>

                <SectionDivider />
                {moduleCode === ModuleCode.Accessibility ? (
                  <>
                    <Section>
                      <FastField
                        component={CookiesSettings}
                        name={"rendererCookies"}
                      />
                    </Section>
                    <SectionDivider />{" "}
                  </>
                ) : undefined}

                <div data-cy="connect-site-form-whitelist-box">
                  <Section>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: theme.spacing(2.5),
                      }}
                    >
                      <SectionTitle optional>Whitelisting</SectionTitle>
                      <InfoTooltip
                        IconProps={{
                          style: {
                            marginLeft: 8,
                          },
                        }}
                        title={connectSiteTooltips.whitelisting}
                        data-pendo="auto-tooltip-whitelisting"
                      />
                    </div>
                    <Grid container>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: theme.spacing(2) }}>
                          {!isLocationsLoading && locationData ? (
                            <Field
                              name="location"
                              label="Whitelisting Settings"
                              component={RadioGroup}
                              variant="filled"
                            >
                              {sortedLoactionData.map((location, i) => (
                                <div
                                  key={location.code}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: theme.spacing(
                                      i === 0 ? 1 : 0,
                                    ),
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Radio className={classes.radio} />
                                    }
                                    id={`location-radio-${location.code}`}
                                    data-testid={`location-radio-${location.code}`}
                                    data-pendo={`auto-edit-add-test-suite-whitelisting-${location.code}`}
                                    key={location.code}
                                    value={location.code}
                                    label={location.name}
                                    classes={{
                                      label: classes.radioLabel,
                                      root: classes.radioLabelRoot,
                                    }}
                                  />
                                  {location.code === LocationCode.Custom ? (
                                    <a
                                      href={AccountsRoutes.Account.getUrl({
                                        accountId: session.account.id,
                                      })}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ fontSize: 14 }}
                                    >
                                      View details
                                    </a>
                                  ) : null}
                                </div>
                              ))}
                            </Field>
                          ) : (
                            <span>Location data did not load</span>
                          )}
                        </div>
                      </Grid>
                      {true ? undefined : ( // just turned off , not deleted (don't know what tomorrow brings)
                        <>
                          <Grid item xs={12}>
                            <div style={{ marginBottom: theme.spacing(2) }}>
                              <StyledInputLabel htmlFor="connect-site-form-custom-user-agent-full">
                                Crawler User Agent
                              </StyledInputLabel>
                              <Field
                                className={classes.textField}
                                name="userAgentString"
                                id="connect-site-form-custom-user-agent-full"
                                data-testid="connect-site-form-custom-user-agent-full"
                                data-pendo="auto-edit-add-test-suite-whitelisting-crawler-user-agent"
                                component={TextField}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <StyledInputLabel htmlFor="connect-site-form-custom-user-agent-short">
                              User Agent for Robots.txt Rule Matching
                            </StyledInputLabel>
                            <Field
                              className={classes.textField}
                              name="userAgentToken"
                              id="connect-site-form-custom-user-agent-short"
                              data-testid="connect-site-form-custom-user-agent-short"
                              data-pendo="auto-edit-add-test-suite-whitelisting-user-agent-robots"
                              component={TextField}
                              fullWidth={true}
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      )}
                      {!isUserAgentsLoading &&
                      userAgentsData &&
                      !isModuleDataLoading &&
                      moduleData ? (
                        <Grid item xs={12}>
                          <FastField
                            name="userAgent"
                            component={UserAgent}
                            userAgents={userAgents}
                            module={moduleData.getModule}
                            userAgentSuffix={
                              moduleData.getAccount?.userAgentSuffix ?? ""
                            }
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Section>

                  <SectionDivider />

                  <Section>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: theme.spacing(2.5),
                      }}
                    >
                      <SectionTitle optional>Custom DNS</SectionTitle>
                      <InfoTooltip
                        interactive
                        IconProps={{
                          style: {
                            marginLeft: 8,
                          },
                        }}
                        title={connectSiteTooltips.customDns}
                        data-pendo="auto-tooltip-custom-dns"
                      />
                    </div>
                    <Grid container>
                      <Grid container item xs={10} spacing={2}>
                        <FieldArray
                          name="customDns"
                          // eslint-disable-next-line
                          component={CustomDnsPairs as any}
                        />
                      </Grid>
                    </Grid>
                  </Section>
                </div>
              </CollapseHeader>
            </div>
            <StickyWrapper>
              <Button
                type="submit"
                data-testid="connect-site-form-save"
                data-oncopycapture="connect-site-form-save"
                data-pendo="auto-testsuite-edit-add-connect-site-save-step"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </StickyWrapper>
          </Form>
        )
      }
    </Formik>
  );
}

function getUserAgents(
  userAgentsData: GetUserAgentsQuery | undefined,
  testSuite?: NonNullable<GetTestSuiteQuery["node"]>,
): UserAgentType[] {
  const testSuiteUserAgent = testSuite?.userAgent;
  const publicUserAgents = (userAgentsData?.getUserAgents ?? []).map(
    (agent) => ({
      ...agent,
      isPublic: true,
    }),
  );

  return [
    ...publicUserAgents,
    ...(testSuiteUserAgent &&
    !publicUserAgents.find((x) => x.code === testSuiteUserAgent.code)
      ? [{ ...testSuiteUserAgent, isPublic: false }]
      : []),
  ];
}
