import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

export const WIDGET_HEADER_HEIGHT = 64;

const useStyles = makeStyles((theme) => ({
  heading: {
    height: WIDGET_HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  h2: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(27),
  },
}));

export function DashboardWidgetHeader(props: { children: React.ReactNode }) {
  const classes = useStyles();

  return (
    <Box className={classes.heading}>
      <Typography variant="h2" className={classes.h2}>
        {props.children}
      </Typography>
    </Box>
  );
}
