import React, { Component, ErrorInfo, ReactNode } from "react";
import { Alert } from "@lumar/shared";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/* eslint-disable fp/no-class, fp/no-this */
export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // FIXME: log this to Rollbar
    console.error("Uncaught error:", error, errorInfo);
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          <strong>Sorry, an unexpected error occurred.</strong> Please refresh
          the page and try again.
        </Alert>
      );
    }

    return this.props.children;
  }
}
