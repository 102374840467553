import React from "react";
import { Grid, Paper, makeStyles, Box } from "@material-ui/core";
import { Overview, OverviewLink } from "./Overview";
import {
  BookOpen,
  UserSquare,
  DesktopTower,
  Heartbeat,
  Timer,
} from "@lumar/shared";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Section, SectionTitle, SectionBody } from "./Section";
import { useVisibleElement } from "./useVisibleElement";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 800,
    margin: "0px auto",
    padding: theme.spacing(3, 2),
    paddingTop: 60,
  },
}));

function useAboutPageContent() {
  return [
    {
      title: "What is Lumar Protect?",
      id: "whatisautomator",
      linkIcon: <BookOpen />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            Engineering teams often release code without understanding the
            impact it can have on the overall SEO performance of the website.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            This creates risk for sudden losses of traffic, rankings and in turn
            overall revenue.
          </SectionBody>
          <SectionBody variant="body1">
            Lumar Protect gives development teams the ability to test their code
            for SEO impact before pushing to production with a smart, automated
            and frictionless tool that allows for better collaboration between
            development &amp; SEO/Marketing teams.
          </SectionBody>
        </>
      ),
    },
    {
      title: "Who should use Lumar Protect?",
      id: "whoshoulduseautomator",
      linkIcon: <UserSquare />,
      body: (
        <SectionBody variant="body1">
          Lumar Protect works by integrating into a business’s CI / CD tools e.g
          Jenkins, Github Actions or TeamCity using a wide range of
          authentication options in order to cater to any need. Lumar Protect
          enables SEO to become part of the core workflow which means teams can
          fix and identify problems proactively on an ongoing basis rather than
          having to go back after releases.
        </SectionBody>
      ),
    },
    {
      title: "How can Lumar Protect help Developers?",
      id: "howautomatorhelps",
      linkIcon: <DesktopTower />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            Lumar Protect has been designed to help solidify the communication
            between marketing and engineering teams and reduce the chance of
            human error.
          </SectionBody>
          <SectionBody variant="body1">
            In becoming part of the development process Lumar Protect can be
            used by engineers, QAs, Release Managers and SEOs.
          </SectionBody>
        </>
      ),
    },
    {
      title: "How can Lumar Protect help QAs?",
      id: "helpQA",
      linkIcon: <Heartbeat />,
      body: (
        <SectionBody variant="body1">
          Lumar Protect can be set up to run alongside the normal QA process,
          reducing time needed to check for SEO issues. It can deploy across
          multiple pre-production test /QA environments and you can select from
          more than 160 tests and receive instant notifications of issues over
          email or slack.
        </SectionBody>
      ),
    },
    {
      title: "How can Lumar Protect help Release Managers?",
      id: "helpRM",
      linkIcon: <Timer />,
      body: (
        <SectionBody variant="body1">
          As Lumar Protectb is part of the development and QA process, releases
          can go ahead with greater confidence that errors will be significantly
          reduced. Lumar Protect has an extensive and flexible set of
          configuration options that let you define the outcome of tests either
          by blocking a release or raising a warning. This saves time and money
          in correcting any problems.
        </SectionBody>
      ),
    },
    {
      title: "How can Lumar Protect help SEOs?",
      id: "helpSEO",
      linkIcon: <TrendingUpIcon />,
      body: (
        <SectionBody variant="body1">
          Lumar Protect helps SEO teams to maintain existing optimisation to
          prevent unnecessary traffic and revenue loss. Using Lumar Protect as
          part of the QA process means many problems will be identified and
          fixed without the need for SEOs to be continuously involved with
          releases - saving you valuable time which can be spent on revenue
          generating opportunities.
        </SectionBody>
      ),
    },
  ];
}

export function About() {
  const classes = useStyles();

  const pageContent = useAboutPageContent();

  const { visibleElementId, createRefFunction } = useVisibleElement(
    pageContent[0].id,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Paper id="faq" data-testid="why-automator">
          <Box className={classes.container}>
            {pageContent.map(({ id, title, body }, index) => (
              <Section key={id}>
                <SectionTitle
                  variant="h3"
                  id={id}
                  data-cy={`heading-${id}`}
                  ref={createRefFunction(index)}
                >
                  {title}
                </SectionTitle>
                {body}
              </Section>
            ))}
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Overview style={{ position: "sticky", top: 60 }}>
          {pageContent.map(({ title, id, linkIcon }) => (
            <OverviewLink
              href={`#${id}`}
              data-cy={`link-${id}`}
              data-pendo={`auto-why-automator-${id}`}
              key={id}
              icon={linkIcon}
              active={visibleElementId === id}
            >
              {title}
            </OverviewLink>
          ))}
        </Overview>
      </Grid>
    </Grid>
  );
}
