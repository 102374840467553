import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export function SchedulerDetailLoadingView() {
  return (
    <Box data-testid="schedule-detail-loader">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: 20 }}
      >
        <Skeleton
          variant="rect"
          height={19}
          width={200}
          style={{ borderRadius: 6 }}
        />
        <Box display="flex" alignItems="center">
          <Skeleton
            variant="rect"
            height={32}
            width={32}
            style={{ borderRadius: 6 }}
          />
          <Skeleton
            variant="rect"
            height={32}
            width={32}
            style={{ marginLeft: 8, borderRadius: 6 }}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
        <Skeleton
          variant="text"
          height={20}
          width={200}
          style={{ marginRight: 64 }}
        />
        <Skeleton variant="text" height={20} width={200} />
      </Box>
      <Skeleton
        variant="text"
        height={30}
        width={220}
        style={{ marginBottom: 16 }}
      />
      <Skeleton
        variant="rect"
        height={300}
        width="100%"
        style={{ borderRadius: 8 }}
      />
    </Box>
  );
}
