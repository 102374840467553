import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { appSwitcherWidth, closedSidebarWidth, sidebarWidth, } from "./constants";
import useLocalStorageState from "use-local-storage-state";
const AppLayoutContext = React.createContext({
    sidebarState: "open",
    mainContentMarginLeft: sidebarWidth + appSwitcherWidth,
    sidebarRef: { current: null },
    openSidebar() {
        return;
    },
    hoverSidebar() {
        return;
    },
    closeSidebar() {
        return;
    },
    blockSidebar() {
        return;
    },
    unblockSidebar() {
        return;
    },
});
function useGetMainContentMarginLeft(state, sidebarContentIncluded) {
    const theme = useTheme();
    const belowMediumScreenSize = useMediaQuery(theme.breakpoints.down("md"));
    if (sidebarContentIncluded) {
        if (belowMediumScreenSize || state !== "open") {
            return appSwitcherWidth + closedSidebarWidth;
        }
        else {
            return appSwitcherWidth + sidebarWidth;
        }
    }
    else {
        return appSwitcherWidth;
    }
}
export function LayoutApiProvider(props) {
    const [rememberedState, setRememberedState] = useLocalStorageState("layout-sidebar-state", {
        defaultValue: "open",
    });
    const [sidebarState, setSidebarState] = React.useState(rememberedState);
    const [blockSidebar, setBlockSidebar] = React.useState(false);
    const mainContentMarginLeft = useGetMainContentMarginLeft(sidebarState, props.sidebarContentIncluded);
    const hoverDelayRef = React.useRef(null);
    const sidebarRef = React.useRef(null);
    // Reseting state when mouse pointer interaction within
    // sidebar content leads to removal of sidebar content component.
    // So it doesn't have "hovered" state when sidebar content is present again.
    if (!props.sidebarContentIncluded && sidebarState === "hovered") {
        setSidebarState("closed");
    }
    const value = React.useMemo(() => ({
        sidebarState: blockSidebar ? "open" : sidebarState,
        sidebarRef,
        mainContentMarginLeft,
        openSidebar() {
            if (hoverDelayRef.current) {
                clearTimeout(hoverDelayRef.current);
            }
            setSidebarState("open");
            setRememberedState("open");
            setBlockSidebar(false);
        },
        closeSidebar() {
            if (hoverDelayRef.current) {
                clearTimeout(hoverDelayRef.current);
            }
            setSidebarState("closed");
            setRememberedState("closed");
        },
        hoverSidebar() {
            if (hoverDelayRef.current) {
                clearTimeout(hoverDelayRef.current);
            }
            hoverDelayRef.current = setTimeout(() => {
                setSidebarState("hovered");
            }, 200);
        },
        blockSidebar() {
            if (sidebarState !== "open")
                setBlockSidebar(true);
        },
        unblockSidebar() {
            setBlockSidebar(false);
            if (sidebarState === "hovered") {
                setTimeout(() => {
                    var _a;
                    if (!((_a = sidebarRef.current) === null || _a === void 0 ? void 0 : _a.matches(":hover"))) {
                        if (hoverDelayRef.current) {
                            clearTimeout(hoverDelayRef.current);
                        }
                        setSidebarState("closed");
                        setRememberedState("closed");
                    }
                }, 400);
            }
        },
    }), [mainContentMarginLeft, setRememberedState, sidebarState, blockSidebar]);
    return (_jsx(AppLayoutContext.Provider, { value: value, children: props.children }));
}
export function useLayoutApi() {
    return React.useContext(AppLayoutContext);
}
