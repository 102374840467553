import React from "react";
import { ButtonProps } from "@material-ui/core";
import { RoleCode } from "../../../graphql";
import { doesUserMatchRole } from "../../utils/doesUserMatchRole/doesUserMatchRole";
import { PermissionsTooltip } from "../PermissionsTooltip/PermissionsTooltip";
import { Button } from "@lumar/shared";

export interface PrimaryActionButtonProps extends ButtonProps {
  dataTestId?: string;
  dataCy?: string;
  onClick?: () => void;
  label: string;
  disabled?: boolean;
  startIcon?: JSX.Element;
  type?: "button" | "reset" | "submit";
  disableForViewerRole?: boolean;
}

export function PrimaryActionButton(
  props: PrimaryActionButtonProps,
): JSX.Element {
  const { dataTestId, dataCy, disabled, disableForViewerRole, ...rest } = props;
  const isViewerRole = doesUserMatchRole(RoleCode.Viewer);
  const isButtonDisabled =
    disableForViewerRole && isViewerRole ? true : disabled;

  const button = (
    <Button
      color="primary"
      size="large"
      variant="contained"
      data-testid={dataTestId || "add-item-button"}
      data-cy={dataCy || "add-item-button"}
      disabled={isButtonDisabled}
      {...rest}
    >
      {props.label}
    </Button>
  );

  if (props.disableForViewerRole && isViewerRole) {
    return <PermissionsTooltip>{button}</PermissionsTooltip>;
  }

  return button;
}
