import React, { useEffect } from "react";
import chevronDownIcon from "../../../images/chevron-down.svg";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSession } from "@lumar/shared";

type WindowWithHubspot = Window &
  typeof globalThis & {
    hbspt?: {
      forms?: {
        create: (args: Record<string, string>) => void;
      };
    };
  };

function hasHubspot(val: WindowWithHubspot): val is WindowWithHubspot {
  return "hbspt" in val;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minHeight: 310,
    "& select, input[type='email'], input[type='text']": {
      width: "100% !important",
      padding: "9px 12px",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 6,
      boxShadow: "0px 1px 2px rgb(0 0 0 / 5%)",
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.grey[700],
      boxSizing: "border-box",
    },
    "& form fieldset": {
      maxWidth: "100%",
    },
    "& select": {
      padding: "8px 12px",
      appearance: "none",
    },
    "& .hs-form-field": {
      marginBottom: 11,
    },
    "& .actions": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
    "& input[type='submit']": {
      borderRadius: 6,
      color: "white",
      backgroundColor: theme.palette.primary.main,
      padding: "10px 16px",
      fontSize: theme.typography.pxToRem(14),
      border: "none",
      "&:hover": {
        backgroundColor: theme.palette.ultraviolet[700],
        cursor: "pointer",
      },
    },
    "& label": {
      color: theme.palette.grey[700],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(14),
      display: "block",
      marginBottom: 4,
    },
    "& ul.hs-error-msgs": {
      paddingInlineStart: 20,
      "& label": {
        color: theme.palette.red[600],
      },
    },
    "& .hs-fieldtype-select .input": {
      position: "relative",
      "&::after": {
        content: `url(${chevronDownIcon})`,
        position: "absolute",
        right: 14,
        top: 7,
      },
    },
  },
}));

const FORM_CONTAINER_ID = "hubspotForm";

function useURLSearchParams(): URLSearchParams {
  const location = useLocation();
  return React.useMemo(() => new URLSearchParams(location.search), [location]);
}

export function HubspotForm(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const urlSearchParams = useURLSearchParams();
  const session = useSession();

  if (!urlSearchParams.has("email") && session?.email) {
    urlSearchParams.set("email", session?.email);
    history.push({ search: urlSearchParams.toString() });
  }

  if (!urlSearchParams.has("utm_content")) {
    urlSearchParams.set("utm_content", "product_led_protect");
    history.replace({ search: urlSearchParams.toString() });
  }

  useEffect(() => {
    const script = document.createElement("script");
    // eslint-disable-next-line fp/no-mutation
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    const messageListener = (event: {
      data: { type: string; eventName: string };
    }): void => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormReady"
      ) {
        const inputs = [...document.getElementsByClassName("hs-input")];
        inputs.forEach((element) => {
          element.addEventListener("focus", function () {
            element.parentElement?.classList.add("hs-focus");
          });
          element.addEventListener("blur", function () {
            element.parentElement?.classList.remove("hs-focus");
          });
        });
      }
    };

    function handleScriptLoad(): void {
      if (hasHubspot(window)) {
        window.hbspt?.forms?.create({
          region: "na1",
          portalId: "7698075",
          formId: "9b173e39-6204-4bd7-89cd-5e6460a3c88f",
          target: `#${FORM_CONTAINER_ID}`,
        });
        window.addEventListener("message", messageListener);
      }
    }

    script.addEventListener("load", handleScriptLoad);

    return () => {
      script.removeEventListener("load", handleScriptLoad);
      document.body.removeChild(script);
      window.removeEventListener("message", messageListener);
      document
        .getElementById(FORM_CONTAINER_ID)
        ?.childNodes.forEach((element) => element.remove());
    };
  }, []);

  return (
    <div
      id={FORM_CONTAINER_ID}
      className={classes.formContainer}
      data-testid="automate-signup-form"
    />
  );
}
