import { AppIds } from "../SessionContext.types";
export function hasAppFactory(availableApps) {
    return (type) => {
        var _a, _b, _c, _d;
        switch (type) {
            case AppIds.protect:
                return (_a = availableApps === null || availableApps === void 0 ? void 0 : availableApps.automateAvailable) !== null && _a !== void 0 ? _a : false;
            case AppIds.analyze:
                return (_b = availableApps === null || availableApps === void 0 ? void 0 : availableApps.analyzeAvailable) !== null && _b !== void 0 ? _b : false;
            case AppIds.impact:
                return (_c = availableApps === null || availableApps === void 0 ? void 0 : availableApps.impactAvailable) !== null && _c !== void 0 ? _c : false;
            case AppIds.monitor:
                return (_d = availableApps === null || availableApps === void 0 ? void 0 : availableApps.monitorAvailable) !== null && _d !== void 0 ? _d : false;
            default:
                return false;
        }
    };
}
