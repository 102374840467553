import React from "react";
import { TestResultIcon } from "./TestResultIcon/TestResultIcon";
import {
  Grid,
  makeStyles,
  Typography,
  Box,
  useTheme,
  Tooltip,
  Button,
} from "@material-ui/core";
import { Severity } from "../../../graphql";
import {
  getTestStatus,
  getMatchingBorderColorForStatus,
} from "./TestResultIcon/TestResultIconUtils";
import { getCustomExtractionTooltip } from "../../../_common/utils/getCustomExtractionTooltip/getCustomExtractionTooltip";
import { isReportTemplateCodeCustomExtraction } from "../../../_common/utils/isReportTemplateCodeCustomExtraction/isReportTemplateCodeCustomExtraction";
import { ColouredLeftBorder } from "../../../_common/components/ColoredLeftBorder/ColouredLeftBorder";
import { InfoOutlined, ExternalLinkSolid } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  text: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[800],
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(15),
  },
  verticallyCentered: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    height: 18,
  },
  icon: {
    color: theme.palette.grey[400],
    fontSize: 18,
  },
  link: {
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      color: theme.palette.ultraviolet[700],
    },
  },
  statusText: {
    fontWeight: 600,
    marginLeft: 5,
    color: theme.palette.grey[800],
    size: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(15),
  },
  grid: {
    minHeight: 64,
    paddingLeft: 24,
    paddingRight: 32,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export interface TestSuiteReportTestResultProps {
  isPassed: boolean;
  severity: Severity;
  testName: string;
  testSummary: string | null;
  crawlDcWebUrl: string | null;
  reportCode: string;
}

export function TestSuiteReportTestResult(
  props: TestSuiteReportTestResultProps,
): JSX.Element {
  const { crawlDcWebUrl, reportCode, isPassed, severity, testName } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const borderColor = getMatchingBorderColorForStatus(
    getTestStatus(props.isPassed, props.severity),
    theme,
  );

  const customExtractionTooltip = getCustomExtractionTooltip(testName);
  const tooltipText = isReportTemplateCodeCustomExtraction(reportCode)
    ? customExtractionTooltip
    : props.testSummary || "No description available";

  return (
    <ColouredLeftBorder
      color={borderColor}
      rootBoxProps={{ style: { marginBottom: 8 } }}
    >
      <Grid
        container
        data-testid="test-suite-report-test-result"
        className={classes.grid}
      >
        <Grid item sm={2} xs={12}>
          <Box display="flex" alignItems="center" height="100%" marginRight={1}>
            <TestResultIcon isPassed={isPassed} severity={severity} />
            <Typography
              noWrap
              className={classes.statusText}
              data-testid="test-result-status"
            >
              {getTestStatus(isPassed, severity)}
            </Typography>
          </Box>
        </Grid>

        <Grid item className={classes.verticallyCentered} sm={5} xs={12}>
          <Typography
            noWrap
            className={classes.text}
            variant="body1"
            data-testid="test-suite-report-test-name"
          >
            {testName}
          </Typography>
          <Tooltip
            data-testid="test-suite-report-test-name-tooltip"
            data-pendo="auto-tooltip-testsuite-report-info"
            title={tooltipText}
          >
            <span className={classes.iconWrapper}>
              <InfoOutlined className={classes.icon} />
            </span>
          </Tooltip>
        </Grid>

        <Grid
          item
          sm={5}
          xs={12}
          className={classes.verticallyCentered}
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            component="a"
            variant="contained"
            href={`${crawlDcWebUrl}/reports/${reportCode}_basic`}
            data-pendo="auto-testsuite-report-item-step-into"
            data-testid="deepcrawl-report-link"
            className={classes.link}
            target="_blank"
            rel="noreferrer"
            color="secondary"
            endIcon={<ExternalLinkSolid />}
            size="large"
          >
            Open report
          </Button>
        </Grid>
      </Grid>
    </ColouredLeftBorder>
  );
}
