import React from "react";
import {
  Box,
  ButtonBase,
  Typography,
  Chip,
  makeStyles,
} from "@material-ui/core";
import { ChevronDownSolid, AdjustmentsOutlined } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  buttonBase: {
    backgroundColor: "white",
    width: "100%",
    border: `1px solid ${theme.palette.grey[300]}`,
    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
    "&:focus": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  startIconWrapper: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    alignItems: "center",
    padding: "10px",
    marginRight: 10,
  },
  startIcon: { fontSize: 18, color: theme.palette.grey[800] },
  label: {
    alignSelf: "center",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[700],
  },
  chipRoot: {
    height: "auto",
    background: theme.palette.ultraviolet[200],
    fontWeight: 500,
    color: "black",
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
  },
  chipLabel: {
    padding: "1px 9px",
  },
  endIcon: {
    fontSize: 20,
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export function FilterButton({
  onClick,
  chipLabel,
}: {
  onClick: () => void;
  chipLabel: number;
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      data-testid="tests-filter-expension-panel"
      data-pendo="auto-test-suite-edit-choose-tests-filter"
      onClick={onClick}
      className={classes.buttonBase}
    >
      <Box
        display="flex"
        alignItems="stretch"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" alignItems="stretch">
          <Box className={classes.startIconWrapper}>
            <AdjustmentsOutlined className={classes.startIcon} />
          </Box>
          <Typography className={classes.label}>Filters</Typography>
        </Box>

        <Box display="flex" alignItems="center" pr={1}>
          {chipLabel ? (
            <Chip
              label={chipLabel}
              data-testid="filters-applied"
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
            />
          ) : null}
          <ChevronDownSolid className={classes.endIcon} />
        </Box>
      </Box>
    </ButtonBase>
  );
}
