import { SimpleModule, UserAgentData, UserAgentType } from "./types";
import { ModuleCode } from "../../../../../graphql";

export function getUserAgents(args: {
  userAgents: Array<UserAgentType>;
  module: SimpleModule;
  include?: string[];
}): UserAgentData[] {
  return [
    {
      code: "Custom",
      name: "Custom User Agent",
      string: "",
      token: "",
      isMobile: false,
      isDeprecated: false,
      isPublic: true,
      supportedModules: [ModuleCode.Seo, ModuleCode.SiteSpeed],
    },
    ...args.userAgents.map((agent) => ({
      code: agent.code,
      name: agent.name,
      string: agent.userAgentStrings[0]?.full.replace(
        " https://deepcrawl.com/bot",
        "",
      ),
      token: agent.userAgentStrings[0]?.short.replace(/\sdeepcrawl$/g, ""),
      isDeprecated: agent.isDeprecated,
      isPublic: agent.isPublic,
      isMobile: agent.isMobile,
      supportedModules: agent.supportedModules ?? [],
    })),
  ]
    .filter(
      (userAgent) =>
        userAgent.supportedModules.includes(args.module.code) ||
        args.include?.includes(userAgent.code),
    )
    .map((userAgent) => ({
      ...userAgent,
      ...getUserAgentViewport(
        !!args.userAgents.find((agent) => agent.code === userAgent.code)
          ?.isMobile,
        args.module,
      ),
    }));
}

export function getUserAgentViewport(
  isMobile: boolean,
  module: SimpleModule,
): {
  viewportWidth: number;
  viewportHeight: number;
} {
  return {
    viewportHeight: isMobile
      ? module.viewportHeightMobile
      : module.viewportHeightDesktop,
    viewportWidth: isMobile
      ? module.viewportWidthMobile
      : module.viewportWidthDesktop,
  };
}
