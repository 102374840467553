import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useBrand } from "../../brand/BrandProvider";
import TagManager from "react-gtm-module";
export function GTMSetup() {
    const brand = useBrand();
    React.useEffect(() => {
        const tagManagerArgs = {
            gtmId: brand.gtmId,
        };
        TagManager.initialize(tagManagerArgs);
    }, [brand.gtmId]);
    return _jsx(_Fragment, {});
}
