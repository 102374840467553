import { getIn, isEmptyArray, setIn } from "formik";
import { get } from "lodash";
export function formToCheckboxProps(form, name) {
    return {
        id: name,
        checked: get(form.values, name),
        disabled: form.isSubmitting,
        onChange: form.handleChange,
    };
}
export function formToSelectProps(form, name) {
    return {
        id: name,
        name: name,
        value: get(form.values, name),
        disabled: form.isSubmitting,
        onChange: form.handleChange,
    };
}
export function formToTextFieldProps(form, name) {
    const fieldError = get(form.errors, name);
    const showError = get(form.touched, name) && !!fieldError;
    return {
        id: name,
        value: get(form.values, name),
        error: showError,
        helperText: showError ? fieldError : undefined,
        disabled: form.isSubmitting,
        onChange: form.handleChange,
        onBlur: form.handleBlur,
    };
}
export function formArrayAdd(form, name, value) {
    updateArrayField(form, name, (array) => [...array, value], false, false);
}
export function formArrayRemove(form, name, index) {
    updateArrayField(form, name, (array) => {
        const copy = array ? [...array] : [];
        copy.splice(index, 1);
        return copy;
    }, true, true);
}
export function formArrayMove(form, name, from, to) {
    updateArrayField(form, name, (array) => {
        if (array && from >= (array === null || array === void 0 ? void 0 : array.length)) {
            return array;
        }
        const increaseSize = Math.max(to - ((array === null || array === void 0 ? void 0 : array.length) || 0) + 1, 0);
        const copy = array
            ? [
                ...array,
                ...(increaseSize ? Array(increaseSize).fill(undefined) : []),
            ]
            : [];
        const value = copy[from];
        copy.splice(from, 1);
        copy.splice(to, 0, value);
        return copy;
    }, true, true);
}
function updateArrayField(form, name, updateFn, alterTouched, alterErrors) {
    form.setFormikState((prevState) => {
        const values = setIn(prevState.values, name, updateFn(getIn(prevState.values, name)));
        function getErrors() {
            const fieldError = updateFn(getIn(prevState.errors, name));
            if (isEmptyArray(fieldError))
                return undefined;
            return fieldError;
        }
        function getTouched() {
            const fieldTouched = updateFn(getIn(prevState.touched, name));
            if (isEmptyArray(fieldTouched))
                return undefined;
            return fieldTouched;
        }
        return Object.assign(Object.assign({}, prevState), { values, errors: alterErrors
                ? setIn(prevState.errors, name, getErrors())
                : prevState.errors, touched: alterTouched
                ? setIn(prevState.touched, name, getTouched())
                : prevState.touched });
    });
}
