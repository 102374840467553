import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, useMediaQuery, CircularProgress } from "@material-ui/core";
import { IntegrationSetupWizard } from "./IntegrationSetupWizard";
import { IntegrationNameSetup } from "./IntegrationNameSetup";
import { Alert } from "@lumar/shared";
import { EditJiraIntegration } from "./EditJiraIntegration";
import { JiraIntegration } from "../../../../ConnectedApps/JiraIntegration/graphql/useGetJiraIntegrationsQuery";
import { ProtectAppIcon } from "../../../../../_common/svg/ProtectAppIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.navy[500],
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(7, 17, 17, 17),
    width: 460,
    minHeight: 425,
    ["@media (max-width: 770px)"]: {
      padding: theme.spacing(3, 8, 8, 8),
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
    ["@media (max-width: 350px)"]: {
      padding: theme.spacing(3),
    },
  },
  logo: {
    width: "40px",
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fill: "#FFFFFF",
  },
  logoText: {
    fontSize: "23px",
  },
}));

export enum JiraIntegrationDialogAction {
  Create = "create",
  Edit = "edit",
}

interface JiraIntegrationDialogProps {
  accountId: string;
  isOpen: boolean;
  handleClose: () => void;
  action: JiraIntegrationDialogAction;
  jiraIntegrationLinker?: (jiraIntegrationId: string) => void;
  initialIntegrationName?: string;
  error?: boolean;
  submitHandler?: (name: string) => Promise<void>;
  loading?: boolean;
  jiraIntegration?: JiraIntegration;
}

// eslint-disable-next-line complexity, max-lines-per-function
export function JiraIntegrationDialog(
  props: JiraIntegrationDialogProps,
): JSX.Element {
  const classes = useStyles();
  const isXsScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs"),
  );
  const [integrationName, setIntegrationName] = useState(
    props.initialIntegrationName ?? "",
  );
  const [isSetupWizardOpen, setIsSetupWizardOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setIntegrationName((currentName) =>
      props.isOpen ? props.initialIntegrationName ?? "" : currentName,
    );

    switch (props.action) {
      case JiraIntegrationDialogAction.Create:
        setCreating(true);
        setIsSetupWizardOpen((isOpen) => !props.isOpen && isOpen);
        break;
      case JiraIntegrationDialogAction.Edit:
        setEditing(true);
        break;
      default:
        break;
    }
  }, [props.isOpen, props.initialIntegrationName, props.action]);

  async function handleSubmit(): Promise<void> {
    if (props.submitHandler) {
      await props.submitHandler(integrationName);
      props.handleClose();
    }

    setIsSetupWizardOpen(!props.submitHandler);
  }

  return (
    <Dialog
      onClose={props.handleClose}
      data-testid="jira-dialog"
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      maxWidth="md"
      fullWidth={isXsScreen}
    >
      <DialogTitle disableTypography className={classes.root}>
        <IconButton
          data-testid="close-jira-dialog"
          data-pendo="auto-jira-integration-add-close-X"
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box display="flex" justifyContent="center" alignItems="center" mb={6}>
          <ProtectAppIcon className={classes.logo} data-testid="logo" />
          <span className={classes.logoText}>Lumar Protect</span>
        </Box>

        {props.loading && (
          <CircularProgress
            color="secondary"
            data-testid="jira-integration-dialog-loading"
          />
        )}

        {props.error && (
          <Alert severity="error" data-testid="jira-integration-dialog-error">
            There was an error connecting. Please refresh the page and try
            again.
          </Alert>
        )}

        {creating && !isSetupWizardOpen && !props.error && !props.loading && (
          <IntegrationNameSetup
            onSubmit={() => handleSubmit()}
            integrationName={integrationName}
            onChange={(event) => setIntegrationName(event.currentTarget.value)}
          />
        )}

        {creating && isSetupWizardOpen && !props.error && !props.loading && (
          <IntegrationSetupWizard
            accountId={props.accountId}
            integrationName={integrationName}
            onComplete={() => props.handleClose()}
          />
        )}

        {editing && props.jiraIntegration && !props.error && !props.loading && (
          <EditJiraIntegration
            jiraIntegration={props.jiraIntegration}
            onComplete={() => props.handleClose()}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
