import { defaultMergeOptions } from "../../../../../_common/utils/mergePageInfo/mergePageInfo";
import merge from "deepmerge";
import { ApolloError, NetworkStatus } from "@lumar/shared";
import {
  GetReportTemplatesQuery,
  useGetReportTemplatesQuery,
} from "../../../../../graphql";

const baseSEOFilter = {
  primaryReportCategoryCode: { ne: "removed" },
};

export function useReportTemplates(after: string): {
  loading: boolean;
  data: GetReportTemplatesQuery | undefined;
  error: ApolloError | undefined;
} {
  const { data, loading, fetchMore, error, networkStatus } =
    useGetReportTemplatesQuery({
      variables: {
        after: after,
        filter: baseSEOFilter,
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    });

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getAutomatorReportTemplates?.pageInfo;
  const hasFetchedAllData =
    !loading && !isFetchingMore && !Boolean(pageInfo?.hasNextPage);

  function evaluateMoreFetch() {
    if (
      !loading &&
      !isFetchingMore &&
      !error &&
      data?.getAutomatorReportTemplates?.pageInfo.hasNextPage
    ) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
        updateQuery: (
          previousResult: GetReportTemplatesQuery,
          { fetchMoreResult },
        ) => {
          if (previousResult && fetchMoreResult) {
            return {
              ...previousResult,
              getAutomatorReportTemplates: merge(
                previousResult.getAutomatorReportTemplates,
                fetchMoreResult.getAutomatorReportTemplates,
                defaultMergeOptions,
              ),
            };
          }
          return previousResult;
        },
      }).catch((e) => {
        // Note: we have to catch the error here as otherwise some e2e tests fail when running locally.
        // It relates to the fact that apollo client doesn't cancel fetchMore requests even when the component making
        // the request has been unmounted. More info: https://github.com/apollographql/apollo-client/issues/4114
        // eslint-disable-next-line no-console
        console.log(`Error caught in useReportTemplate`, e);
      });
    }
  }

  if (!Boolean(error) && !hasFetchedAllData) {
    evaluateMoreFetch();
  }

  return {
    loading:
      loading || !!data?.getAutomatorReportTemplates.pageInfo.hasNextPage,
    error,
    data,
  };
}
