import {
  isValid,
  format,
  differenceInYears,
  isBefore,
  differenceInMonths,
  addYears,
  addMonths,
  differenceInDays,
  addDays,
  differenceInHours,
  addHours,
  addMinutes,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

const INVALID_DATE = "Invalid date";
const HUMAN_READIBLE_FORMAT = "MMM dd, yyyy h:mmaaaaa'm'";

function formatDateTimeToHumanReadableFormat(
  input: Date,
  defaultMessage?: string,
): string {
  if (isValid(input)) {
    return format(input, HUMAN_READIBLE_FORMAT);
  } else {
    return defaultMessage ? defaultMessage : INVALID_DATE;
  }
}

export function dateTimeToHumanReadableFormat(
  input: Date | string | number,
  defaultMessage?: string,
): string {
  if (typeof input === "number" || typeof input === "string") {
    return formatDateTimeToHumanReadableFormat(new Date(input), defaultMessage);
  }

  if (typeof input === "object" && input !== null) {
    return formatDateTimeToHumanReadableFormat(input, defaultMessage);
  }

  return defaultMessage ? defaultMessage : INVALID_DATE;
}

// FIXME: refactor so that we don't mutate stuff
/* eslint-disable fp/no-let, fp/no-mutating-methods, fp/no-mutation */
function getHumanReadableDuration(now: Date, future: Date): string {
  let n = now;
  const result = [];
  const years = differenceInYears(future, n);
  if (years > 0) {
    result.push(`${years} ${years > 1 ? "years" : "year"}`);
    n = addYears(n, years);
  }

  const months = differenceInMonths(future, n);
  if (months > 0) {
    result.push(`${months} ${months > 1 ? "months" : "month"}`);
    n = addMonths(n, months);
  }

  const days = differenceInDays(future, n);
  if (days > 0) {
    result.push(`${days}d`);
    n = addDays(n, days);
  }

  const hours = differenceInHours(future, n);
  if (hours > 0) {
    result.push(`${hours}h`);
    n = addHours(n, hours);
  }

  const minutes = differenceInMinutes(future, n);
  if (minutes > 0) {
    result.push(`${minutes}m`);
    n = addMinutes(n, minutes);
  }

  const seconds = differenceInSeconds(future, n);
  if (seconds > 0) {
    result.push(`${seconds}s`);
  }

  return result.length ? result.join(" ") : "less then 1 second";
}
/* eslint-enable fp/no-let, fp/no-mutating-methods, fp/no-mutation */

export function differenceToHumanReadableFormatFromStrings(
  dateA: string,
  dateB: string,
): string {
  return differenceToHumanReadableFormat(dateA, dateB);
}

function differenceToHumanReadableFormat(
  dateA: number | string | Date,
  dateB: number | string | Date,
): string {
  const dA: Date =
    typeof dateA === "number" || typeof dateA === "string"
      ? new Date(dateA)
      : dateA;
  const dB: Date =
    typeof dateB === "number" || typeof dateB === "string"
      ? new Date(dateB)
      : dateB;

  if (!isValid(dA) || !isValid(dB)) {
    return "N/A";
  }

  return isBefore(dA, dB)
    ? getHumanReadableDuration(dA, dB)
    : getHumanReadableDuration(dB, dA);
}
