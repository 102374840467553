import {
  Box,
  BoxProps,
  Divider,
  InputLabel,
  makeStyles,
  Typography,
  TypographyProps,
  withStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(18.5),
    color: theme.palette.grey[800],
  },
  optionalText: {
    color: theme.palette.grey[400],
  },
}));

interface SectionTitleProps extends TypographyProps {
  children: React.ReactNode;
  optional?: boolean;
}

/**
 * A component used as a title for a section of the testsuite setup form.
 */
export function SectionTitle({
  children,
  optional = false,
  ...typographyProps
}: SectionTitleProps) {
  const classes = useStyles();

  return (
    <Typography className={classes.typography} {...typographyProps}>
      {children}
      {optional ? (
        <span className={classes.optionalText}> (Optional)</span>
      ) : null}
    </Typography>
  );
}

export const SectionDivider = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[200],
    margin: `${theme.spacing(2)}px 0px`,
  },
}))(Divider);

export const Section = (props: BoxProps) => <Box marginX={4} {...props} />;

/**
 * A component that looks like a `SectionTitle`, but semantically is a `label`.
 * Useful when wanting an input label that also doubles up as a section title.
 */
export const SectionLabel = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(18.5),
    color: theme.palette.grey[800],
  },
}))(InputLabel);
