import React, { ReactNode } from "react";
import {
  Typography,
  makeStyles,
  Container,
  withStyles,
  Tooltip,
  Chip,
} from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StepChangeHandler } from "./UpdateTestSuite";
import clsx from "clsx";

interface CustomStepProps {
  stepHeader: string;
  stepNumber: number;
  id: string;
  children?: ReactNode;
  handleChange?: StepChangeHandler;
  isExpanded: boolean;
  testId?: string;
  disabled?: boolean;
  isParentStep?: boolean;
  pendoId: string;
  description?: string;
  isChildStep?: boolean;
}

const useStyles = makeStyles((theme) => ({
  header: (props: { disabled: boolean | undefined }) => ({
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(23),
    fontWeight: 600,
    margin: "10px 0px 7px 0px",
    color: props.disabled ? theme.palette.grey[500] : "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  }),
  stepNumber: (props: { disabled: boolean | undefined }) => ({
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: 400,
    marginRight: theme.spacing(2),
    color: props.disabled ? theme.palette.grey[400] : theme.palette.grey[600],
  }),
  colouredStepNumber: {
    color: theme.palette.purple[600],
  },
  stepContent: {
    borderTop: "none",
    borderRadius: "0 0 8px 8px",
    padding: 0,
    margin: 0,
  },
  noPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  stepDescription: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: 400,
  },
  chip: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    color: theme.palette.purple[700],
    backgroundColor: theme.palette.purple[100],
    height: 20,
    marginRight: 40,
  },
  iconBtn: (props: { disabled: boolean | undefined }) => ({
    display: props.disabled ? "none" : "inline-block",
  }),
}));

export function CustomStep(props: CustomStepProps) {
  const classes = useStyles({ disabled: props.disabled });

  const {
    isExpanded,
    handleChange,
    id,
    stepHeader,
    stepNumber,
    children,
    testId,
    disabled,
    description,
    isParentStep,
    isChildStep,
  } = props;

  const shouldShowTemplateChip = Boolean(isParentStep) || Boolean(isChildStep);

  const stepContent = (
    <StyledAccordion
      expanded={isExpanded}
      onChange={handleChange}
      disabled={disabled}
      elevation={0}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}bh-content`}
        id={`${id}bh-header`}
        data-cy={testId}
        data-testid={testId}
        data-pendo={props.pendoId}
        IconButtonProps={{ className: classes.iconBtn }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className={clsx(
                  classes.stepNumber,
                  props.isParentStep && classes.colouredStepNumber,
                )}
                data-testid="custom-step-number"
              >
                Step {stepNumber}
              </Typography>

              {shouldShowTemplateChip ? (
                <Chip
                  className={classes.chip}
                  label={
                    isParentStep
                      ? "Global template settings"
                      : "Global template applied"
                  }
                />
              ) : null}
            </div>

            <Typography
              variant="h2"
              className={classes.header}
              data-testid="custom-step-name"
            >
              {stepHeader}
            </Typography>

            {isExpanded && description ? (
              <Typography className={classes.stepDescription}>
                {description}
              </Typography>
            ) : null}
          </div>
        </div>
      </StyledAccordionSummary>
      {children && (
        <AccordionDetails className={classes.stepContent}>
          <Container maxWidth="xl" className={classes.noPadding}>
            {children}
          </Container>
        </AccordionDetails>
      )}
    </StyledAccordion>
  );

  return disabled ? (
    <Tooltip title="You can only edit steps 2 and 3 in the global template.">
      {stepContent}
    </Tooltip>
  ) : (
    stepContent
  );
}

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    padding: "19px 32px 16px 32px",
    "&.Mui-expanded": {
      paddingBottom: 29,
    },
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  expandIcon: {
    color: theme.palette.grey[700],
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}))(AccordionSummary);

const StyledAccordion = withStyles((theme) => ({
  root: {
    border: "1px solid white",
    boxShadow: `0px 1px 0px 1px ${theme.palette.grey[200]}`,
    "&:last-child": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    "&:first-child": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    "&.Mui-expanded": {
      margin: "6px 0",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  expanded: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.grey[400]}`,
    "& + div": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
  },
  rounded: {
    "& + .Mui-expanded": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
}))(Accordion);
