var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Divider, withStyles } from "@material-ui/core";
import { DatePicker as MuiDatePicker, } from "@material-ui/pickers";
import { CalendarSolid } from "../../icons/ui-friendly/solid/CalendarSolid";
import { ChevronDownSolid, ChevronUpSolid } from "../../icons";
import { Typography } from "../typography/Typography";
function DatePickerInner(_a) {
    var { classes } = _a, props = __rest(_a, ["classes"]);
    const [open, setOpen] = useState(false);
    const openResolved = props.open !== undefined ? props.open : open;
    const { label, className } = props, rest = __rest(props, ["label", "className"]);
    const startAdornment = props.iconPlacement === "start" || props.iconPlacement === undefined ? (_jsxs(_Fragment, { children: [_jsx(CalendarSolid, { className: classes === null || classes === void 0 ? void 0 : classes.calendar }), _jsx(Divider, { className: classes === null || classes === void 0 ? void 0 : classes.divider, orientation: "vertical" })] })) : null;
    const endAdornment = props.iconPlacement === "start" || props.iconPlacement === undefined ? (_jsx(_Fragment, { children: openResolved ? (_jsx(ChevronUpSolid, { className: classes === null || classes === void 0 ? void 0 : classes.expandIcon })) : (_jsx(ChevronDownSolid, { className: classes === null || classes === void 0 ? void 0 : classes.expandIcon })) })) : (_jsxs(_Fragment, { children: [_jsx(Divider, { className: classes === null || classes === void 0 ? void 0 : classes.divider, orientation: "vertical" }), _jsx(CalendarSolid, { className: classes === null || classes === void 0 ? void 0 : classes.calendarEnd })] }));
    return (_jsxs(Box, { display: "inline-flex", className: className, children: [label ? (_jsx(Typography, { className: classes === null || classes === void 0 ? void 0 : classes.label, variant: "button", children: label })) : null, _jsx(MuiDatePicker, Object.assign({ format: props.format, variant: "inline", inputVariant: "outlined", disableToolbar: true, PopoverProps: {
                    classes: {
                        paper: classes === null || classes === void 0 ? void 0 : classes.paper,
                    },
                }, open: open, onOpen: () => setOpen(true), onClose: () => setOpen(false), InputProps: {
                    classes: {
                        root: classes === null || classes === void 0 ? void 0 : classes.pickerRoot,
                        input: classes === null || classes === void 0 ? void 0 : classes.pickerInput,
                    },
                    startAdornment: startAdornment,
                    endAdornment: endAdornment,
                } }, rest))] }));
}
export const DatePicker = withStyles((theme) => ({
    pickerRoot: {
        cursor: "pointer",
        padding: theme.spacing(0, 0, 0, 0),
    },
    paper: {
        marginTop: theme.spacing(1.25),
    },
    pickerInput: {
        cursor: "pointer",
        paddingLeft: 10,
        color: theme.palette.grey[700],
        width: "100%",
        padding: theme.spacing(1, 0, 1, 0),
    },
    calendar: {
        marginLeft: theme.spacing(1),
        width: 20,
        height: 20,
        color: theme.palette.grey[700],
    },
    calendarEnd: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 20,
        height: 20,
        color: theme.palette.grey[700],
    },
    divider: {
        marginLeft: theme.spacing(0.8),
        color: theme.palette.grey[500],
        height: 36,
    },
    expandIcon: {
        marginRight: theme.spacing(1.2),
        width: 20,
        height: 20,
        color: theme.palette.grey[500],
    },
    label: {
        color: theme.palette.grey[600],
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.875),
    },
}))(DatePickerInner);
