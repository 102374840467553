import React from "react";
import { Typography, Box, withStyles } from "@material-ui/core";
import { FaqExpansionPanel } from "./FaqExpansionPanel";

const StyledTypography = withStyles((theme) => ({
  root: {
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(18),
    fontSize: theme.typography.pxToRem(13),
  },
}))(Typography);

const panelsConfig = [
  {
    title: "What is Lumar Protect?",
    testId: "what-is-automator",
    pendoId: "what-is-automator",
    content: (
      <div data-cy="what-is-automator-text">
        <StyledTypography variant="body1" paragraph>
          Engineering teams often release code without understanding the impact
          it can have on the overall SEO performance of the website.
        </StyledTypography>
        <StyledTypography variant="body1" paragraph>
          This creates risk for sudden losses of traffic, rankings and in turn
          overall revenue.
        </StyledTypography>
        <StyledTypography variant="body1">
          Lumar Protect gives development teams the ability to test their code
          for SEO impact before pushing to production with a smart, automated
          and frictionless tool that allows for better collaboration between
          development &amp; SEO/Marketing teams.
        </StyledTypography>
      </div>
    ),
  },
  {
    title: "What CI/CD tools does Lumar Protect integrate with?",
    testId: "what-ci-cd-tools",
    pendoId: "what-ci-cd-tools",
    content: (
      <StyledTypography variant="body1">
        Lumar Protect can connect to all major CI /CD tools via API and
        instructions are provided within the setup process.
      </StyledTypography>
    ),
  },
  {
    title: "How do I integrate with my CI/CD tool?",
    testId: "how-do-i-integrate",
    pendoId: "how-do-i-integrate",
    content: (
      <StyledTypography variant="body1">
        Lumar Protect can connect to all major CI /CD tools via API and
        instructions are provided within the setup process.
      </StyledTypography>
    ),
  },
  {
    title: "What are test suites?",
    testId: "what-are-test-suites",
    pendoId: "what-are-test-suites",
    content: (
      <StyledTypography variant="body1">
        A Test Suite is a group of tests you wish to run across a specific
        domain. You can create multiple Test Suites for each domain should you
        wish to group them based on different outcomes for example site speed,
        stability, missing pages.
      </StyledTypography>
    ),
  },
  {
    title: "Can you copy test suites?",
    testId: "can-you-copy-testsuites",
    pendoId: "can-you-copy-testsuites",
    content: (
      <StyledTypography variant="body1">
        Lumar Protect allows you to easily duplicate test suites to save you
        valuable time. This means you don’t have to re-enter configuration
        details for each staging environment. The duplicate will not copy across
        any URL lists and will automatically be set to a web crawl.
      </StyledTypography>
    ),
  },
  {
    title: "How many test suites can I create?",
    testId: "how-many-test-suites",
    pendoId: "how-many-test-suites",
    content: (
      <StyledTypography variant="body1">
        There are no limits to the number of test suites you can create.
      </StyledTypography>
    ),
  },
  {
    title: "How many URLs does Lumar Protect crawl?",
    testId: "how-many-urls",
    pendoId: "how-many-urls",
    content: (
      <StyledTypography variant="body1">
        You can choose how many URLs to crawl up to a maximum of 10,000.
      </StyledTypography>
    ),
  },
  {
    title: "Can Lumar Protect crawl sites that are behind a firewall?",
    testId: "behind-a-firewall",
    pendoId: "behind-a-firewall",
    content: (
      <StyledTypography variant="body1">
        Yes, we provide a selection of options for users for authentication and
        whitelabeling like user/password authentication and custom DNS set up.
      </StyledTypography>
    ),
  },
  {
    title: "What authentication options are available?",
    testId: "whitelisting",
    pendoId: "whitelisting",
    content: (
      <StyledTypography variant="body1">
        As well as Password authentication you can use custom DNS and/or
        whitelisted IP.
      </StyledTypography>
    ),
  },
  {
    title: "How do notifications work?",
    testId: "notifications",
    pendoId: "notifications",
    content: (
      <StyledTypography variant="body1">
        Notifications can be sent at the end of each test via Email or Slack
      </StyledTypography>
    ),
  },
  {
    title: "What do you mean by Fail or Warning?",
    testId: "failure-or-warning",
    pendoId: "failure-or-warning",
    content: (
      <div>
        <StyledTypography variant="body1" paragraph>
          Lumar Protect can run over 160 different tests on your domain.
        </StyledTypography>
        <StyledTypography variant="body1" paragraph>
          Each test can be set with a severity level of Fail or Warning. A Fail
          will stop your build and a Warning will send a notification either via
          Email or Slack but allow the build to continue.
        </StyledTypography>
        <StyledTypography variant="body1">
          You can apply a threshold against each test and can choose between a
          percentage or an absolute number.
        </StyledTypography>
      </div>
    ),
  },
];

export function Faqs() {
  return (
    <Box id="faq" data-testid="faq">
      {panelsConfig.map((config, index) => (
        <FaqExpansionPanel
          config={config}
          index={index}
          key={index}
          isDefaultExpanded={index === 0}
          data-pendo={`auto-faq-${config.pendoId}`}
        />
      ))}
    </Box>
  );
}
