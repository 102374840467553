import React from "react";
export function useFeedbackToggle() {
    const hotjarInitRef = React.useRef(false);
    React.useEffect(() => () => closeFeedback(), []);
    return React.useCallback(({ setState }) => toggleFeedback(setState, hotjarInitRef), [hotjarInitRef]);
}
function toggleFeedback(setState, hotjarInitRef) {
    const container = document.getElementById("_hj_feedback_container");
    if (!container)
        return;
    if (!hotjarInitRef.current) {
        hotjarInitRef.current = true;
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    var _a;
                    const added = (_a = node === null || node === void 0 ? void 0 : node.className) === null || _a === void 0 ? void 0 : _a.includes("__ExpandedWidget__outerContainer");
                    if (added)
                        setState(true);
                });
                mutation.removedNodes.forEach((node) => {
                    var _a;
                    const removed = (_a = node === null || node === void 0 ? void 0 : node.className) === null || _a === void 0 ? void 0 : _a.includes("__ExpandedWidget__outerContainer");
                    if (removed)
                        setState(false);
                });
            });
        });
        observer.observe(container, {
            childList: true,
            subtree: true,
        });
    }
    // Try find feedback close button
    const hotjarCloseButton = container === null || container === void 0 ? void 0 : container.querySelector('button[aria-label="Close"]');
    if (hotjarCloseButton) {
        hotjarCloseButton.click();
        return;
    }
    // Try find feedback open button
    const hotjarOpenButton = container === null || container === void 0 ? void 0 : container.querySelector('button[class*="__MinimizedWidgetMiddle__label"]');
    if (hotjarOpenButton)
        hotjarOpenButton.click();
}
function closeFeedback() {
    const container = document.getElementById("_hj_feedback_container");
    const hotjarCloseButton = container === null || container === void 0 ? void 0 : container.querySelector('button[aria-label="Close"]');
    hotjarCloseButton === null || hotjarCloseButton === void 0 ? void 0 : hotjarCloseButton.click();
}
