import { useState } from "react";
import { defaultMergeOptions } from "../../../../../_common/utils/mergePageInfo/mergePageInfo";
import merge from "deepmerge";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { ApolloError } from "@lumar/shared";
import {
  GetNotLinkedTestSuitesQuery,
  useGetNotLinkedTestSuitesQuery as generated_useGetNotLinkedTestSuitesQuery,
} from "../../../../../graphql";

function getPreviousResultAsLastOne(
  data: GetNotLinkedTestSuitesQuery,
): GetNotLinkedTestSuitesQuery {
  if (data.getAccount?.notLinkedTestSuites) {
    // eslint-disable-next-line fp/no-mutation
    data.getAccount.notLinkedTestSuites.pageInfo.hasNextPage = false;
  }
  return data;
}

export function useGetNotLinkedTestSuitesQuery(): {
  data: GetNotLinkedTestSuitesQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const accountId = useAccountRouteMatch();

  const { data, loading, error, fetchMore } =
    generated_useGetNotLinkedTestSuitesQuery({
      variables: { accountId, cursor: "" },
      fetchPolicy: "cache-and-network",
    });
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);

  async function fetchMoreResults(): Promise<void> {
    if (
      data &&
      data?.getAccount?.notLinkedTestSuites?.pageInfo.hasNextPage &&
      !loading &&
      !fetchingMore
    ) {
      setFetchingMore(true);
      fetchMore({
        variables: {
          cursor:
            data?.getAccount?.notLinkedTestSuites?.pageInfo.endCursor || "",
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getAccount?.notLinkedTestSuites?.edges) {
            return merge(
              previousResult,
              fetchMoreResult,
              defaultMergeOptions,
            ) as GetNotLinkedTestSuitesQuery;
          }
          return getPreviousResultAsLastOne(previousResult);
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  fetchMoreResults();

  return {
    data,
    loading: loading || fetchingMore,
    error,
  };
}
