import React, { useEffect } from "react";
import {
  Grid,
  Box,
  makeStyles,
  Card,
  CardMedia,
  Typography,
  CardContent,
  CardActions,
  Fade,
} from "@material-ui/core";
import dashboardImg from "./../../../images/empty-dashboard-banner.png";
import { useTimeout } from "../../../_common/hooks/useTimeout/useTimeout";
import { AddTestSuiteButton } from "../../../_common/components/AddTestSuiteButton/AddTestSuiteButton";
import { Routes } from "../../../_common/routes/routes";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  whyAutomatorBox: {
    height: "100%",
  },
  whyAutomatorCard: {
    height: "100%",
  },
  gettingStartedBox: {
    height: "100%",
  },
  gettingStartedCard: {
    height: "100%",
  },
  rightItem: {
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0",
    },
  },
  gettingStartedItem: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(1.5),
    },
  },
  visibleLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  mainItem: {
    height: "100%",
  },
  mainHeadingTitle: {
    fontSize: theme.typography.pxToRem(19),
    color: theme.palette.grey[800],
  },
  mainHeadingText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[800],
  },
  cardContent: {
    padding: "38px 0 17px 59px",
  },
  carnAction: {
    padding: "0 0 49px 59px",
  },
  rightItemTitle: {
    fontSize: theme.typography.pxToRem(19),
    color: theme.palette.grey[800],
    fontWeight: 600,
    marginBottom: 5,
  },
  rightItemText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[800],
  },
  rightCardContent: {
    padding: theme.spacing(4, 3.75, 4, 3.375),
  },
}));

export interface DashboardEmptyProps {
  accountId: string;
}
// eslint-disable-next-line max-lines-per-function
export function DashboardEmpty(props: DashboardEmptyProps): JSX.Element {
  const { trigger: mainVisible, clear: clearMainTimeout } = useTimeout(1);
  const { trigger: secondVisible, clear: clearSecondTimeout } = useTimeout(250);
  const { trigger: thirdVisible, clear: clearThirdVisible } = useTimeout(500);
  const { trigger: fourthVisible, clear: clearForthVisible } = useTimeout(750);
  const classes = useStyles();

  useEffect(() => {
    return function cleanup() {
      clearMainTimeout();
      clearSecondTimeout();
      clearThirdVisible();
      clearForthVisible();
    };
  }, [
    clearMainTimeout,
    clearSecondTimeout,
    clearThirdVisible,
    clearForthVisible,
  ]);

  return (
    <Grid
      container
      spacing={2}
      data-testid="dashboard-empty"
      data-cy="dashboard-empty"
    >
      <Grid item xs={12} md={8}>
        <Fade in={mainVisible} timeout={1000}>
          <Card className={classes.mainItem}>
            <CardMedia
              component="img"
              alt="Lumar Protect"
              width={869}
              height={196}
              image={dashboardImg}
              title="Lumar Protect!"
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#0a1124",
              }}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h2"
                component="h2"
                data-testid="empty-main-heading"
                className={classes.mainHeadingTitle}
              >
                Start creating test suites
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.mainHeadingText}
              >
                A test suite is a group of tests you wish to run across a
                specific domain.
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.mainHeadingText}
              >
                Create your first test suite now by clicking below.
              </Typography>
            </CardContent>
            <CardActions className={classes.carnAction}>
              <AddTestSuiteButton
                accountId={props.accountId}
                dataCy="empty-button-add"
                dataTestId="empty-button-add"
                data-pendo="auto-dashboard-empty-add-test-suite-button"
              />
            </CardActions>
          </Card>
        </Fade>
      </Grid>
      <Grid container item xs={12} md={4}>
        <Grid item xs={12} sm={4} md={12} className={classes.rightItem}>
          <Box className={classes.whyAutomatorBox}>
            <Fade in={secondVisible} timeout={1000}>
              <Card className={classes.whyAutomatorCard}>
                <CardContent className={classes.rightCardContent}>
                  <Typography
                    variant="h2"
                    data-testid="empty-why-heading"
                    className={classes.rightItemTitle}
                  >
                    Why Lumar Protect?
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    data-testid="empty-why-text"
                    className={classes.rightItemText}
                  >
                    Lumar Protect is integrated into a businesses CI/CD tools
                    allowing developers to test their code for SEO impact before
                    pushing to production. Click{" "}
                    <Link
                      className={classes.visibleLink}
                      to={Routes.GettingStarted.getUrl({
                        accountId: props.accountId,
                      })}
                      data-testid="empty-why-automator-link"
                      data-cy="empty-why-automator-link"
                    >
                      here
                    </Link>{" "}
                    to find out more.
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={12} className={classes.rightItem}>
          <Box className={classes.gettingStartedBox}>
            <Fade in={thirdVisible} timeout={1000}>
              <Card className={classes.gettingStartedCard}>
                <CardContent className={classes.rightCardContent}>
                  <Typography
                    variant="h2"
                    data-testid="empty-getting-started-heading"
                    className={classes.rightItemTitle}
                  >
                    Get Started with Lumar Protect
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    data-testid="empty-getting-started-text"
                    className={classes.rightItemText}
                  >
                    Follow our{" "}
                    <Link
                      className={classes.visibleLink}
                      to={Routes.GettingStarted.getUrl({
                        accountId: props.accountId,
                      })}
                      data-testid="empty-getting-started-link"
                    >
                      step by step guide
                    </Link>{" "}
                    to get started.
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={12}
          className={classes.gettingStartedItem}
        >
          <Box className={classes.gettingStartedBox}>
            <Fade in={fourthVisible} timeout={1000}>
              <Card className={classes.gettingStartedCard}>
                <CardContent className={classes.rightCardContent}>
                  <Typography
                    variant="h2"
                    data-testid="empty-faq-title"
                    className={classes.rightItemTitle}
                  >
                    Frequently asked questions
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    data-testid="empty-faq-text"
                    className={classes.rightItemText}
                  >
                    Questions? Check our{" "}
                    <Link
                      className={classes.visibleLink}
                      to={Routes.GettingStarted.getUrl({
                        accountId: props.accountId,
                      })}
                      data-testid="empty-faq-link"
                    >
                      FAQs
                    </Link>
                    .
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
