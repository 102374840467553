import React, { useState } from "react";
import { Alert } from "@lumar/shared";
import { EditJiraIntegrationForm } from "./EditJiraIntegrationForm";
import { JiraIntegration } from "../../../../ConnectedApps/JiraIntegration/graphql/useGetJiraIntegrationsQuery";

interface EditJiraIntegrationProps {
  jiraIntegration: JiraIntegration;
  onComplete: () => void;
}

// eslint-disable-next-line complexity
export function EditJiraIntegration(props: EditJiraIntegrationProps) {
  const [error, setError] = useState(false);

  function handleSuccess(): void {
    props.onComplete();
  }

  function handleError(): void {
    setError(true);
  }

  return (
    <div>
      {error && (
        <Alert severity="error" data-testid="jira-config-wizard-dialog-error">
          There was an error connecting. Please refresh the page and try again.
        </Alert>
      )}

      {!error && props.jiraIntegration && (
        <EditJiraIntegrationForm
          jiraIntegration={props.jiraIntegration}
          onError={handleError}
          onSuccess={handleSuccess}
        />
      )}
    </div>
  );
}
