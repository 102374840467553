import React from "react";
import { Field, useFormikContext } from "formik";
import {
  FormControlLabel,
  MenuItem,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { SectionLabel } from "../Section";
import { Select, Typography, formToSelectProps } from "@lumar/shared";
import { WcagLevel } from "../../../../graphql";
import { CustomCheckbox } from "./CustomCheckbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  select: {
    maxWidth: "400px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(1.625),
    marginBottom: theme.spacing(1),
  },
}));

export const AccesibilitySettings = React.memo(AccesibilitySettingsInner);

function AccesibilitySettingsInner(): JSX.Element {
  const form = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div style={{ display: "flex", marginBottom: theme.spacing(2) }}>
        <SectionLabel>WCAG Level and Version</SectionLabel>
      </div>

      <div className={classes.root}>
        <Typography variant="caption" className={classes.description}>
          Select which WCAG level and version to test against and whether to
          include best practices.
        </Typography>

        <Select
          {...formToSelectProps(form, "selectedWcagLevel")}
          label="WCAG Level (up to)"
          className={classes.select}
          data-testid="accessibility-wcag-level"
        >
          <MenuItem value={WcagLevel.A}>{WcagLevel.A}</MenuItem>
          <MenuItem value={WcagLevel.Aa}>{WcagLevel.Aa}</MenuItem>
          <MenuItem value={WcagLevel.Aaa}>
            {WcagLevel.Aaa} (recommended)
          </MenuItem>
        </Select>

        <Select
          {...formToSelectProps(form, "selectedWcagVersion")}
          label="WCAG Version (up to)"
          className={classes.select}
          data-testid="accessibility-wcag-version"
        >
          <MenuItem value={2.0}>2.0</MenuItem>
          <MenuItem value={2.1}>2.1</MenuItem>
          <MenuItem value={2.2}>2.2 (recommended)</MenuItem>
        </Select>

        <FormControlLabel
          label="Include best practices"
          className={classes.checkbox}
          control={
            <Field
              name="includeBestPractices"
              variant="filled"
              type="checkbox"
              color="primary"
              component={CustomCheckbox}
              data-testid="accessibility-include-best-practice"
            />
          }
        />
      </div>
    </>
  );
}
