import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import { NoIntegrationsView } from "./NoIntegrationsView";
import { ConnectedIntegrationView } from "./ConnectedIntegrationView";
import { Alert, Snackbar } from "@lumar/shared";
import { useSnackbar } from "notistack";
import {
  GetTestSuiteQuery,
  useUnlinkJiraIntegrationFromTestSuiteMutation,
} from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.grey[800],
    fontWeight: 400,
  },
}));

interface JiraTabProps {
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  jiraIntegration?: NonNullable<
    NonNullable<GetTestSuiteQuery["node"]>["testSuiteJiraIntegration"]
  >["jiraIntegration"];
}

// eslint-disable-next-line complexity, max-lines-per-function
export function JiraTab(props: JiraTabProps): JSX.Element {
  const classes = useStyles();
  const [isLinkingAlertOpen, setIsLinkingAlertOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [
    unlinkJiraIntegrationFromTestSuite,
    { loading: isUnlinkIntegrationLoading, error: unlinkError },
  ] = useUnlinkJiraIntegrationFromTestSuiteMutation({
    refetchQueries: ["getTestSuite"],
  });

  const isLoading = isUnlinkIntegrationLoading;
  const createAndLinkError = !!unlinkError;

  async function unlinkIntegration(): Promise<void> {
    const testSuiteJiraIntegrationId =
      props.testSuite?.testSuiteJiraIntegration?.id;
    if (!testSuiteJiraIntegrationId) return;

    try {
      await unlinkJiraIntegrationFromTestSuite({
        variables: {
          testSuiteJiraIntegrationId,
        },
      });
      enqueueSnackbar(
        <Snackbar
          title="Jira integration disconnected successfully."
          variant="success"
        />,
      );
    } catch {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Box display="flex" alignItems="center" mb={3.5}>
        <Typography
          variant="h3"
          className={classes.title}
          data-testid="jira-tab-title"
        >
          Jira
        </Typography>
      </Box>

      {unlinkError && (
        <Alert severity="error" data-testid="error-alert">
          Sorry, looks like something went wrong while trying to disconnect a
          Jira integration from the test suite. Please try again or contact
          support.
        </Alert>
      )}

      {isLoading && <CircularProgress color="secondary" />}

      <Fade in={!props.jiraIntegration && !isLoading}>
        <div
          style={{
            display: !props.jiraIntegration && !isLoading ? "block" : "none",
          }}
        >
          <NoIntegrationsView
            testSuiteId={props.testSuite.id}
            onSuccessfulLinking={() => {
              setIsLinkingAlertOpen(true);
            }}
          />
        </div>
      </Fade>

      <Fade in={!!props.jiraIntegration && !isLoading && !createAndLinkError}>
        <div
          style={{
            display:
              !!props.jiraIntegration && !isLoading && !createAndLinkError
                ? "block"
                : "none",
          }}
        >
          <ConnectedIntegrationView
            jiraIntegration={props.jiraIntegration}
            isLinkingAlertOpen={isLinkingAlertOpen}
            unlinkIntegration={unlinkIntegration}
            handleCloseLinkingAlert={() => {
              setIsLinkingAlertOpen(false);
            }}
          />
        </div>
      </Fade>
    </Box>
  );
}
