import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line max-lines-per-function
export const useNoIntegrationsStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    marginBottom: "27px",
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: theme.palette.grey[700],
    paddingRight: "5px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    verticalAlign: "middle",
  },
  cardText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  cardLogoWrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  cardContent: {
    maxWidth: 400,
    padding: theme.spacing(2.625, 6, 4, 3.25),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 3, 3, 3),
    },
  },
  cardActions: {
    display: "block",
    padding: theme.spacing(0, 6, 2.5, 3.25),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 3, 3, 3),
    },
  },
  icon: {
    color: theme.palette.orange[500],
  },
  integrationDetailsWrapper: {
    padding: theme.spacing(6),
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(6, 3),
    },
  },
  buttonsWrapper: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(4, 6),
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 3),
    },
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));
