import React, { useEffect, useRef, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Checkbox } from "@lumar/shared";

import { useDebounce } from "../../../../_common/hooks/useDebounce/UseDebounce";

const useStyles = makeStyles((theme) => ({
  notifyMeText: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  formLabelRoot: {
    marginBottom: theme.spacing(2),
  },
}));

interface NotificationOptionsProps {
  disabled: boolean;
  initialValues: {
    pass: boolean;
    warning: boolean;
    fail: boolean;
  };
  id: string;
  updateNotifications: (pass: boolean, warning: boolean, fail: boolean) => void;
}

export function NotificationOptions(
  props: NotificationOptionsProps,
): JSX.Element {
  const { updateNotifications } = props;
  const classes = useStyles();
  const [notifications, setNotifications] = useState(props.initialValues);
  const { pass, warning, fail } = useDebounce(notifications, 250);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    // We don't want to trigger the callback when the component is initially mounted.
    if (isMounted.current) {
      updateNotifications(pass, warning, fail);
    } else {
      // eslint-disable-next-line fp/no-mutation
      isMounted.current = true;
    }
  }, [pass, warning, fail, updateNotifications]);

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    setNotifications({
      ...notifications,
      [event.target.name]: event.target.checked,
    });
  }

  return (
    <div data-testid="notification-options">
      <Typography className={classes.notifyMeText} color="textPrimary">
        Notify me about
      </Typography>
      <FormGroup>
        <FormControlLabel
          data-testid="notification-options-pass"
          data-pendo={`auto-${props.id}-notification-options-pass`}
          classes={{ root: classes.formLabelRoot }}
          control={
            <Checkbox
              checked={notifications.pass}
              onChange={handleCheckboxChange}
              name="pass"
            />
          }
          label="Passes"
          disabled={props.disabled}
        />
        <FormControlLabel
          data-testid="notification-options-warn"
          data-pendo={`auto-${props.id}-notification-options-warn`}
          classes={{ root: classes.formLabelRoot }}
          control={
            <Checkbox
              checked={notifications.warning}
              onChange={handleCheckboxChange}
              name="warning"
            />
          }
          label="Passes with warnings"
          disabled={props.disabled}
        />
        <FormControlLabel
          data-testid="notification-options-fail"
          data-pendo={`auto-${props.id}-notification-options-fail`}
          classes={{ root: classes.formLabelRoot }}
          control={
            <Checkbox
              checked={notifications.fail}
              onChange={handleCheckboxChange}
              name="fail"
            />
          }
          label="Fails"
          disabled={props.disabled}
        />
      </FormGroup>
    </div>
  );
}
