export function parseStringToArray(searchText: string): string[] {
  return searchText.split(/[\s,]+/).filter((text) => {
    return text.length > 0;
  });
}

export function parseStringToLowercaseArray(searchText: string): string[] {
  if (!searchText.trim().length) return [];
  return [searchText.toLocaleLowerCase().trim()];
}
