import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon, Switch, withStyles, } from "@material-ui/core";
export function SwitchWithIcon(props) {
    return (_jsx(StyledSwitch, Object.assign({ checkedIcon: _jsx(CheckIcon, { viewBox: "0 0 25 25" }), icon: _jsx(CrossIcon, { viewBox: "0 0 25 25" }) }, props)));
}
const StyledSwitch = withStyles({
    root: {
        "& [class*=MuiIconButton-root]:hover": {
            backgroundColor: "transparent",
        },
    },
    checked: {
        transform: "translate(17px, -2px)",
    },
    switchBase: {
        transform: "translate(-2px, -2px)",
        "& svg": {
            fontSize: 25,
        },
        "&$checked": {
            transform: "translate(17px, -2px)",
        },
    },
})(Switch);
function CheckIcon(props) {
    return (_jsxs(SvgIcon, Object.assign({}, props, { children: [_jsxs("g", { filter: "url(#filter0_dd_2315_3185)", children: [_jsx("rect", { x: "3", y: "2", width: "20", height: "20", rx: "10", fill: "white" }), _jsx("path", { d: "M16 10L12 14L10 12", stroke: "#9CA3AF", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsxs("filter", { id: "filter0_dd_2315_3185", x: "0", y: "0", width: "26", height: "26", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [_jsx("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }), _jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }), _jsx("feOffset", { dy: "1" }), _jsx("feGaussianBlur", { stdDeviation: "1" }), _jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" }), _jsx("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2315_3185" }), _jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }), _jsx("feOffset", { dy: "1" }), _jsx("feGaussianBlur", { stdDeviation: "1.5" }), _jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" }), _jsx("feBlend", { mode: "normal", in2: "effect1_dropShadow_2315_3185", result: "effect2_dropShadow_2315_3185" }), _jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect2_dropShadow_2315_3185", result: "shape" })] }) })] })));
}
function CrossIcon(props) {
    return (_jsxs(SvgIcon, Object.assign({}, props, { children: [_jsxs("g", { filter: "url(#filter0_dd_2327_3214)", children: [_jsx("rect", { x: "2", y: "2", width: "20", height: "20", rx: "10", fill: "white" }), _jsx("path", { d: "M12 12L14 14M10 14L12 12L10 14ZM12 12L14 10L12 12ZM12 12L10 10L12 12Z", stroke: "#9CA3AF", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsxs("filter", { id: "filter0_dd_2327_3214", x: "-1", y: "0", width: "26", height: "26", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [_jsx("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }), _jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }), _jsx("feOffset", { dy: "1" }), _jsx("feGaussianBlur", { stdDeviation: "1" }), _jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" }), _jsx("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_2327_3214" }), _jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }), _jsx("feOffset", { dy: "1" }), _jsx("feGaussianBlur", { stdDeviation: "1.5" }), _jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" }), _jsx("feBlend", { mode: "normal", in2: "effect1_dropShadow_2327_3214", result: "effect2_dropShadow_2327_3214" }), _jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect2_dropShadow_2327_3214", result: "shape" })] }) })] })));
}
