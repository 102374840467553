import { arrayToTextAreaValue } from "../../../../../_common/utils/array/coerce/arrayToTextAreaValue";
import {
  GetTestSuiteQuery,
  ModuleCode,
  RenderingRobotsCheckMode,
  TestSuiteCrawlType,
} from "../../../../../graphql";
import { CustomExtraction } from "../CustomExtractions";
import { InitialCrawlSettingsValues } from "./InitialCrawlSettingsValues";

function getTestSuiteCrawlTypes(
  testSuite: NonNullable<GetTestSuiteQuery["node"]>,
) {
  const types = {
    web: testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.Web) !== -1,
    list: testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.List) !== -1,
    sitemap: testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.Sitemap) !== -1,
    google_analytics:
      testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.GoogleAnalytics) !== -1,
    backlinks:
      testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.Backlinks) !== -1,
    google_search_console:
      testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.GoogleSearchConsole) !==
      -1,
    log_summary:
      testSuite?.crawlTypes.indexOf(TestSuiteCrawlType.LogSummary) !== -1,
  };
  return types;
}

export function removeTypenameFromCustomExtractions(
  customExtractions: CustomExtraction[],
) {
  // eslint-disable-next-line fp/no-mutating-methods
  return customExtractions
    .map(({ label, regex, reportTemplateCode }) => {
      return { label, regex, reportTemplateCode };
    })
    .sort(
      (a, b) =>
        parseInt(a.reportTemplateCode.split("_")[2]) -
        parseInt(b.reportTemplateCode.split("_")[2]),
    );
}

export function getInitialValuesFromTestSuite(
  data: GetTestSuiteQuery,
  accessibilityContainerId: string | undefined,
  moduleCode: ModuleCode,
): InitialCrawlSettingsValues {
  const testSuite = data?.node as NonNullable<GetTestSuiteQuery["node"]>;
  const project = data?.getProject as NonNullable<
    GetTestSuiteQuery["getProject"]
  >;

  const a11yContainerId =
    data?.accessibilityModule?.customMetricContainers?.find(
      (container) => container.name === "AccessibilityIssues",
    )?.id;

  const a11yContainer =
    data?.getProject?.customMetricContainerProjects.nodes.find(
      (container) => container.customMetricContainer.id == a11yContainerId,
    );
  const containerProjects =
    project.customMetricContainerProjects?.nodes ?? null;

  const hasAccessibility = moduleCode === ModuleCode.Accessibility;

  return {
    maximumCrawlRate: Math.min(
      testSuite?.maximumCrawlRate,
      testSuite?.account.maxCrawlRate,
    ),
    limitUrlsMax: testSuite?.limitUrlsMax,
    rendererBlockAds: testSuite?.rendererBlockAds ?? hasAccessibility,
    rendererBlockAnalytics:
      testSuite?.rendererBlockAnalytics ?? hasAccessibility,
    emptyPageThreshold: testSuite?.emptyPageThreshold,
    logSummaryRequestsHigh: testSuite?.logSummaryRequestsHigh,
    logSummaryRequestsLow: testSuite?.logSummaryRequestsLow,
    maxBodyContentLength: testSuite?.maxBodyContentLength,
    maxDescriptionLength: testSuite?.maxDescriptionLength,
    maxExternalLinks: testSuite?.maxExternalLinks,
    maxHtmlSize: testSuite?.maxHtmlSize,
    maxLinks: testSuite?.maxLinks,
    maxLoadTime: testSuite?.maxLoadTime,
    maxRedirections: testSuite?.maxRedirections,
    maxTitleWidth: testSuite?.maxTitleWidth,
    maxUrlLength: testSuite?.maxUrlLength,
    duplicatePrecision: testSuite?.duplicatePrecision,
    minDescriptionLength: testSuite?.minDescriptionLength,
    minTitleLength: testSuite?.minTitleLength,
    thinPageThreshold: testSuite?.thinPageThreshold,
    crawlTypes: getTestSuiteCrawlTypes(testSuite),
    accessibilityScript: a11yContainer?.customJsScripts?.[0] || "",
    rendererBlockCustom: arrayToTextAreaValue(testSuite.rendererBlockCustom),
    rendererJsString: testSuite.rendererJsString || "",
    rendererJsUrls: arrayToTextAreaValue(testSuite.rendererJsUrls),
    robotsOverwrite: {
      enable: !!testSuite.useRobotsOverwrite,
      robots: testSuite.robotsOverwrite || "",
      ignoreRobotsForNavigationRequests:
        testSuite.ignoreRobotsForNavigationRequests,
      renderingRobotsEnabled:
        testSuite.renderingRobotsCheckMode ===
        RenderingRobotsCheckMode.RequestDisallowed,
    },
    excludeUrlPatterns: arrayToTextAreaValue(testSuite.excludeUrlPatterns),
    includeUrlPatterns: arrayToTextAreaValue(testSuite.includeUrlPatterns),
    useRenderer: testSuite.useRenderer.toString(),
    customExtractions: testSuite.customExtractions || [],
    startUrls: testSuite.startUrls ?? [],
    customJsScripts:
      containerProjects &&
      containerProjects.length &&
      containerProjects[0].customJsScripts
        ? containerProjects[0].customJsScripts[0]
        : "",
    accessibilityEnabled: hasAccessibility,
    selectedWcagLevel: testSuite.selectedWcagLevel,
    selectedWcagVersion: testSuite.selectedWcagVersion,
    includeBestPractices: testSuite.includeBestPractices,
  };
}

function getArrayForCrawlType(enabled: boolean, value: TestSuiteCrawlType) {
  return enabled ? [value] : [];
}

export function getArrayOfCrawlTypes(types: {
  web: boolean;
  list: boolean;
  sitemap: boolean;
  google_analytics: boolean;
  backlinks: boolean;
  google_search_console: boolean;
  log_summary: boolean;
}) {
  const arr: TestSuiteCrawlType[] = [];
  const arrayForWeb: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.web,
    TestSuiteCrawlType.Web,
  );
  const arrayForList: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.list,
    TestSuiteCrawlType.List,
  );
  const arrayForSitemap: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.sitemap,
    TestSuiteCrawlType.Sitemap,
  );
  const arrayForGA: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.google_analytics,
    TestSuiteCrawlType.GoogleAnalytics,
  );
  const arrayForBacking: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.backlinks,
    TestSuiteCrawlType.Backlinks,
  );
  const arrayForGSC: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.google_search_console,
    TestSuiteCrawlType.GoogleSearchConsole,
  );
  const arrayForBLogSummary: TestSuiteCrawlType[] = getArrayForCrawlType(
    types.log_summary,
    TestSuiteCrawlType.LogSummary,
  );
  return arr.concat(
    ...arrayForWeb,
    ...arrayForList,
    ...arrayForSitemap,
    ...arrayForGA,
    ...arrayForBacking,
    ...arrayForGSC,
    ...arrayForBLogSummary,
  );
}
