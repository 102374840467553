import React from "react";
import { Typography, makeStyles, Box, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  testSuiteName: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 600,
    marginBottom: 4,
  },
  url: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: 400,
  },
}));

export interface SchedulerTestSuiteItemInfoProps {
  url: string;
  name: string;
}

export function SchedulerTestSuiteItemInfo(
  props: SchedulerTestSuiteItemInfoProps,
): JSX.Element {
  const classes = useStyles();

  return (
    <Box width="100%">
      <Tooltip title={props.name}>
        <Typography
          className={classes.testSuiteName}
          data-cy="scheduler-detail-testsuite-name"
          data-testid="scheduler-test-suite-list-item-name"
          noWrap
        >
          {props.name}
        </Typography>
      </Tooltip>
      <Typography
        data-testid="scheduler-test-suite-list-item-url"
        data-cy="scheduler-detail-testsuite-url"
        className={classes.url}
        noWrap
      >
        {props.url}
      </Typography>
    </Box>
  );
}
