import React from "react";
import { Threshold } from "../TestCollapse/TestCollapse";
import { ThresholdType } from "../../../graphql";
import { Tabs, Tab, withStyles } from "@material-ui/core";

const StyledTabs = withStyles({
  root: {
    borderRadius: 8,
    border: "none",
    minHeight: 0,
  },
})(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    padding: "8.5px 15px",
    border: `1px solid ${theme.palette.grey[200]} !important`,
    minHeight: 0,
    "&:first-of-type": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&:last-of-type": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    "&:not(.Mui-selected)": {
      "&:hover": {
        backgroundColor: theme.palette.ultraviolet[50],
        border: `1px solid ${theme.palette.ultraviolet[50]} !important`,
      },
    },
  },
  selected: {
    border: `1px solid ${theme.palette.ultraviolet[400]} !important`,
    backgroundColor: theme.palette.ultraviolet[50],
  },
  textColorPrimary: {
    "&.Mui-selected": {
      color: theme.palette.grey[600],
    },
  },
}))(Tab);

export interface TestThresholdToggleProps {
  threshold: Threshold;
  handleThresholdTypeChange: (thresholdType: ThresholdType) => void;
}

export function TestThresholdToggle(
  props: TestThresholdToggleProps,
): JSX.Element {
  const { handleThresholdTypeChange, threshold } = props;
  const [tabIndex, setTabIndex] = React.useState(
    threshold.type === ThresholdType.Relative ? 0 : 1,
  );

  function handleTabChange(
    _: React.ChangeEvent<{ value?: unknown }>,
    newValue: number,
  ): void {
    const thresholds = [ThresholdType.Relative, ThresholdType.Absolute];
    setTabIndex(newValue);
    handleThresholdTypeChange(thresholds[newValue]);
  }

  return (
    <StyledTabs
      value={tabIndex}
      onChange={handleTabChange}
      variant="fullWidth"
      data-testid="test-threshold-toggle"
      TabIndicatorProps={{ hidden: true }}
    >
      <StyledTab
        data-cy="severity-threshold-unit-percentage"
        data-testid="severity-threshold-unit-percentage"
        data-pendo="auto-test-suite-edit-choose-tests-test-threhsold-percentage"
        label="Percentage %"
      />
      <StyledTab
        data-cy="severity-threshold-unit-abs-number"
        data-testid="severity-threshold-unit-abs-number"
        data-pendo="auto-test-suite-edit-choose-tests-test-threhsold-absolute"
        label="Absolute number"
      />
    </StyledTabs>
  );
}
