import { JiraIntegration } from "./useGetJiraIntegrationsQuery";
import {
  GetJiraIntegrationsWithTestSuitesQuery,
  GetJiraIntegrationsWithTestSuitesQueryVariables,
  useGetJiraIntegrationsWithTestSuitesQuery as generated_useGetJiraIntegrationsWithTestSuitesQuery,
} from "../../../../graphql";

export type JiraIntegrationWithTestSuites = JiraIntegration & {
  testSuiteJiraIntegrations: NonNullable<
    GetJiraIntegrationsWithTestSuitesQuery["getAccount"]
  >["jiraAuthentications"]["nodes"][0]["jiraIntegrations"]["nodes"][0]["testSuiteJiraIntegrations"];
};

export function useGetJiraIntegrationsWithTestSuitesQuery(
  variables: GetJiraIntegrationsWithTestSuitesQueryVariables,
) {
  const { data, error, loading, refetch } =
    generated_useGetJiraIntegrationsWithTestSuitesQuery({
      variables,
      fetchPolicy: "cache-first",
    });

  const jiraIntegrations = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(
      (jiraAuthentication) =>
        jiraAuthentication.jiraIntegrations.nodes.map((jiraIntegration) => ({
          jiraAuthenticationId: jiraAuthentication.id,
          jiraAuthenticationName: jiraAuthentication.name,
          ...jiraIntegration,
        })),
    ) ?? []),
  ];

  const totalCount = [
    ...(data?.getAccount?.jiraAuthentications.nodes.flatMap(
      (jiraAuthenticationNode) =>
        jiraAuthenticationNode.jiraIntegrations.totalCount,
    ) ?? []),
  ].reduce((acc, curr) => acc + curr, 0);

  return {
    data: { jiraIntegrations, totalCount },
    error,
    loading,
    refetch,
  };
}
