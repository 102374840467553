import React from "react";
import { Grid, makeStyles, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import { AddTestSuiteButton } from "../AddTestSuiteButton/AddTestSuiteButton";
import { Routes } from "../../routes/routes";
import { PrimaryActionButton } from "../PrimaryActionButton/PrimaryActionButton";
import { getLastSelectedAccount } from "../../utils/LastSelectedAccount/getLastSelectedAccount";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  compactView: {
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
  },
  title: {
    fontSize: theme.typography.pxToRem(23),
    fontWeight: 600,
  },
}));

export interface PageHeaderProps {
  mainText: string;
  includeAddTestSuite?: boolean;
  includeGoTo?: boolean;
  pendoIdSuffix: string;
}

export function PageHeader(props: PageHeaderProps): JSX.Element {
  const classes = useStyles();
  const accountId = getLastSelectedAccount();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.gridContainer}
      spacing={2}
    >
      <Grid item data-testid="page-header-h1">
        <Typography
          variant="h1"
          data-cy="page-header"
          className={classes.title}
        >
          {props.mainText}
        </Typography>
      </Grid>
      {props.includeAddTestSuite && (
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <AddTestSuiteButton
              dataTestId={`page-header-add-test-suite`}
              data-pendo={`auto-header-${props.pendoIdSuffix}-add-test-suite`}
              accountId={accountId}
              dataCy="btn-add-test-suite"
            />
          </Box>
        </Grid>
      )}
      {props.includeGoTo && (
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <Link
              data-testid="page-header-go-dashboard"
              to={Routes.Dashboard.getUrl({ accountId })}
              className={classes.link}
            >
              <PrimaryActionButton
                dataCy="btn-go-dashboard"
                startIcon={<DashboardRoundedIcon />}
                data-pendo={`auto-header-${props.pendoIdSuffix}-dashboard`}
                label="Dashboard"
              />
            </Link>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
