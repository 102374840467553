import { useState, useEffect } from "react";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../../../_common/utils/mergePageInfo/mergePageInfo";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { ApolloError } from "@lumar/shared";
import {
  GetAvailableParentTestSuitesQuery,
  useGetAvailableParentTestSuitesQuery,
} from "../../../../../graphql";

function getPreviousResultAsLastOne(
  data: GetAvailableParentTestSuitesQuery,
): GetAvailableParentTestSuitesQuery {
  if (data.getAccount?.notLinkedTestSuites) {
    // eslint-disable-next-line fp/no-mutation
    data.getAccount.notLinkedTestSuites.pageInfo.hasNextPage = false;
  }
  if (data.getAccount?.parentTestSuites) {
    // eslint-disable-next-line fp/no-mutation
    data.getAccount.parentTestSuites.pageInfo.hasNextPage = false;
  }
  return data;
}

export function useGetAvailableParentTestSuites(): {
  data: GetAvailableParentTestSuitesQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const accountId = useAccountRouteMatch();

  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [accumulatedData, setAccumulatedData] =
    useState<GetAvailableParentTestSuitesQuery>();
  const { data, loading, fetchMore, error } =
    useGetAvailableParentTestSuitesQuery({
      variables: {
        accountId,
        cursorNotLinkedTestSuites: null,
        cursorParentTestSuites: null,
      },
    });

  useEffect(() => {
    setAccumulatedData(data);
  }, [data]);

  function fetchMoreData(): void {
    if (
      (accumulatedData?.getAccount?.notLinkedTestSuites?.pageInfo
        ?.hasNextPage ||
        accumulatedData?.getAccount?.parentTestSuites?.pageInfo?.hasNextPage) &&
      !isFetchingMore &&
      !loading
    ) {
      setIsFetchingMore(true);

      const newVariables = {
        cursorNotLinkedTestSuites:
          accumulatedData?.getAccount.notLinkedTestSuites?.pageInfo?.endCursor,
        cursorParentTestSuites:
          accumulatedData?.getAccount.parentTestSuites?.pageInfo?.endCursor,
        accountId: accountId,
      };

      fetchMore({
        variables: newVariables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult?.getAccount?.__typename === "Account" &&
            fetchMoreResult.getAccount.notLinkedTestSuites?.edges &&
            fetchMoreResult.getAccount.parentTestSuites?.edges
          ) {
            const newData = merge(
              previousResult,
              fetchMoreResult,
              defaultMergeOptions,
            ) as GetAvailableParentTestSuitesQuery;
            setAccumulatedData(newData);
            return newData;
          }
          const oldData = getPreviousResultAsLastOne(previousResult);
          setAccumulatedData(oldData);
          return oldData;
        },
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  }

  fetchMoreData();

  return { data: accumulatedData, loading: loading || isFetchingMore, error };
}
