import { SlackAlertType } from "../../../../../../graphql";

export function getAlertTypesFromNotificationSettings(
  pass: boolean,
  warning: boolean,
  fail: boolean,
): SlackAlertType[] {
  const notificationSettings = [pass, warning, fail];
  return [
    SlackAlertType.Pass,
    SlackAlertType.Warning,
    SlackAlertType.Fail,
  ].filter((_, i) => notificationSettings[i]);
}
