var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sortBy, cloneDeep } from "lodash";
import { PermissionCode, SessionDocument, AdminAdditionalAccountDocument, RoleCode, } from "../../graphql";
import { getPreferredAccountUser } from "./preferredAccount";
/**
 * @author Alex Sánchez
 * @deprecated Needs refactor to remove unused code portions and optimize fetching
 */
export function composedSessionQuery(client, preferredAccountId) {
    return __awaiter(this, void 0, void 0, function* () {
        const session = yield sessionQuery(client);
        const canFetchAdditionalAccount = session.data.me.permissions.find((p) => p.code === PermissionCode.Admin || p.code === PermissionCode.SsoClient);
        const shouldFetchPreferredAccount = !!preferredAccountId &&
            !getPreferredAccountUser(session.data, preferredAccountId);
        if (canFetchAdditionalAccount && shouldFetchPreferredAccount) {
            const preferredAccount = yield additionalAccountQuery(client, 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            preferredAccountId).result();
            const o = {
                sessionQuery: getSortedSessionData(mergeAdditionalAccountsWithSession(session, preferredAccount.data.getAccount ? [preferredAccount] : [])),
                additionalRelationships: [],
                // additionalRelationships: [preferredAccount]
                //   .filter((acc) => !!acc.data.getAccount)
                //   .map((acc) => mapAdditionalAccountToAccountUser(acc)),
            };
            return o;
        }
        return {
            sessionQuery: getSortedSessionData(session.data),
            additionalRelationships: [],
        };
    });
}
function getSortedSessionData(data) {
    // Note: Sorting to match legacy behaviour.
    // Legacy API returns account users sorted by lowercased account name. - Michal
    const edges = sortBy(data.me.accountsUsers.edges, (edge) => { var _a; return (_a = edge.node.account.name) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
    const dataCopy = cloneDeep(data);
    // Note: Using `cloneDeep` so it doesn't mutate the argument.
    // eslint-disable-next-line fp/no-mutation
    dataCopy.me.accountsUsers.edges = edges;
    return dataCopy;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function sessionQuery(client) {
    return __awaiter(this, void 0, void 0, function* () {
        const sessionQuery = client.watchQuery({
            query: SessionDocument,
            fetchPolicy: "cache-first",
        });
        yield sessionQuery.result();
        // eslint-disable-next-line fp/no-loops
        while (sessionQuery.getCurrentResult().data.me.accountsUsers.pageInfo.hasNextPage) {
            const cursor = sessionQuery.getCurrentResult().data.me.accountsUsers.pageInfo.endCursor;
            yield sessionQuery.fetchMore({
                variables: {
                    cursor,
                },
            });
        }
        return sessionQuery.getCurrentResult();
    });
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function additionalAccountQuery(client, preferredAccountId) {
    return client.watchQuery({
        query: AdminAdditionalAccountDocument,
        fetchPolicy: "cache-first",
        variables: { accountId: preferredAccountId },
    });
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapAdditionalAccountToAccountUser(query) {
    if (!query.data.getAccount)
        throw new Error("Expected getAccount to return an account");
    return {
        role: { code: RoleCode.Admin, name: "Admin" },
        account: query.data.getAccount,
    };
}
function mergeAdditionalAccountsWithSession(session, additionalAccountQueries) {
    const sessionDataCopy = cloneDeep(session.data);
    // Note: Using `cloneDeep` so it doesn't mutate the argument.
    // eslint-disable-next-line fp/no-mutation
    sessionDataCopy.me.accountsUsers.edges = [
        ...sessionDataCopy.me.accountsUsers.edges,
        ...additionalAccountQueries.map((query) => ({
            node: mapAdditionalAccountToAccountUser(query),
        })),
    ];
    return sessionDataCopy;
}
