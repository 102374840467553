import React from "react";

import { Field, FieldArrayRenderProps, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";

import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { CustomDnsPair } from "./ConnectSiteForm";
import { StyledInputLabel } from "../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { ToggleIconButton, TrashSolid } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 0 8px 8px",
    display: "flex",
    flexDirection: "row",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  inputControl: {
    width: 250,
  },
  ipAddress: {
    margin: theme.spacing(0, 2),
  },
}));

export function CustomDnsPairs(props: FieldArrayRenderProps): JSX.Element {
  const classes = useStyles();
  const { values } = useFormikContext<{
    customDns: CustomDnsPair[];
  }>();

  return values.customDns ? (
    <>
      {values.customDns.map((_dnsPair, index) => {
        return (
          <Box className={classes.container} key={index}>
            <Grid className={classes.inputGroup} spacing={2}>
              <Grid>
                <StyledInputLabel
                  htmlFor={`connect-site-form-custom-dns-hostname${index}`}
                >
                  Hostname
                </StyledInputLabel>
                <Field
                  name={`customDns[${index}].hostname`}
                  id={`connect-site-form-custom-dns-hostname${index}`}
                  data-testid={`connect-site-form-custom-dns-hostname-${index}`}
                  data-pendo="auto-edit-add-test-suite-custom-dns-hostname"
                  type="text"
                  placeholder="e.g. testdomain.com"
                  component={TextField}
                  fullWidth={true}
                  variant="outlined"
                  className={classes.inputControl}
                />
              </Grid>
              <Grid className={classes.ipAddress}>
                <StyledInputLabel
                  htmlFor={`connect-site-form-custom-dns-ip${index}`}
                >
                  IP Address
                </StyledInputLabel>
                <Field
                  name={`customDns[${index}].ipAddress`}
                  id={`connect-site-form-custom-dns-ip${index}`}
                  data-testid={`connect-site-form-custom-dns-ip-${index}`}
                  data-pendo="auto-edit-add-test-suite-custom-dns-ip"
                  component={TextField}
                  placeholder="e.g. 12.34.56.78"
                  fullWidth={true}
                  variant="outlined"
                  className={classes.inputControl}
                />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <ToggleIconButton
                style={{ marginTop: 25 }}
                size="large"
                title="Delete"
                colorVariant="red"
                variant="outlined"
                aria-label="delete"
                id={`btn-delete-connect-site-custom-dns-${index}`}
                data-testid={`btn-delete-connect-site-custom-dns-${index}`}
                data-pendo="auto-edit-add-test-suite-custom-dns-hostname-remove"
                onClick={() => props.remove(index)}
              >
                <TrashSolid />
              </ToggleIconButton>
            </Grid>
          </Box>
        );
      })}
      <Grid item xs={12}>
        <Box component="div">
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            size="small"
            id="connect-site-form-add-custom-dns-pair"
            data-testid="connect-site-form-add-custom-dns-pair"
            data-pendo="auto-edit-add-test-suite-custom-dns-add"
            onClick={() => {
              // eslint-disable-next-line fp/no-mutating-methods
              props.push({
                hostname: "",
                ipAddress: "",
              });
            }}
          >
            Add Custom DNS Pair
          </Button>
        </Box>
      </Grid>
    </>
  ) : (
    <></>
  );
}
