import { ConnectSiteForm } from "./ConnectSiteForm";
import { TestSuiteVariables } from "./TestSuiteVariables";
import { StepChangeHandler } from "../../UpdateTestSuite";
import { ApolloQueryResult } from "@lumar/shared";
import {
  GetTestSuiteQuery,
  GetTestSuiteQueryVariables,
  useUpdateConnectSiteMutation,
} from "../../../../graphql";

interface UpdateConnectSiteProps {
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  project: NonNullable<GetTestSuiteQuery["getProject"]>;
  onSubmit: StepChangeHandler;
  refetchTestSuite: (
    variables?: GetTestSuiteQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<GetTestSuiteQuery>>;
}

export function UpdateConnectSite(props: UpdateConnectSiteProps) {
  const [mutateConnectSite] = useUpdateConnectSiteMutation({
    notifyOnNetworkStatusChange: true,
  });
  const testSuiteId = props.testSuite.id;

  async function handleSubmit(testSuiteVariables: TestSuiteVariables) {
    const { rendererCookies, ...values } = testSuiteVariables;

    await mutateConnectSite({
      variables: {
        projectInput: {
          projectId: testSuiteId,
          rendererCookies,
        },
        testSuiteInput: {
          testSuiteId,
          name: values.name,
          sitePrimary: values.primaryDomain,
          location: values.location,
          siteTestUser: values.testSiteUsername,
          ...(values.testSiteDomainPass !== null
            ? { siteTestPass: values.testSiteDomainPass }
            : {}),
          customHeaderUserAgent: values.userAgentString,
          customHeaderUserAgentShort: values.userAgentToken,
          userAgentCode: values.userAgentCode,
          viewportHeight: values.viewportHeight,
          viewportWidth: values.viewportWidth,
          userAgentIsMobile: values.userAgentIsMobile,
          customDns:
            Array.isArray(values.customDns) || values.customDns === null
              ? values.customDns
              : [values.customDns],
        },
      },
    });
  }

  return (
    <ConnectSiteForm
      testSuite={props.testSuite}
      project={props.project}
      onSuccess={props.onSubmit}
      onSubmit={handleSubmit}
      refetchTestSuite={props.refetchTestSuite}
    />
  );
}
