import { SlackAlertType } from "../../../../../../graphql";

export function getInitialValuesFromAlerts(alertTypes: SlackAlertType[]): {
  pass: boolean;
  warning: boolean;
  fail: boolean;
} {
  const [pass, warning, fail] = [
    SlackAlertType.Pass,
    SlackAlertType.Warning,
    SlackAlertType.Fail,
  ].map((type) => alertTypes.includes(type));

  return {
    pass,
    warning,
    fail,
  };
}
