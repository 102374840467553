import React from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    borderRadius: 6,
    padding: 4,
    height: 36,
    width: 36,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginRight: theme.spacing(1),
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[700],
    },
  },
  checkIcon: {
    color: "#FFF",
  },
  cancelIcon: {
    color: theme.palette.grey[700],
  },
}));

interface ConfirmOrDeleteProps {
  handleConfirm: () => void;
  handleCancel: () => void;
  dataTestId: string;
  confirmButtonRef?: React.MutableRefObject<null>;
  cancelButtonRef?: React.MutableRefObject<null>;
}

export function ConfirmOrDelete(props: ConfirmOrDeleteProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <IconButton
        ref={props.cancelButtonRef}
        className={classes.iconButton}
        aria-label="cancel"
        type="button"
        data-testid={`cancel-${props.dataTestId}`}
        id={`cancel-${props.dataTestId}`}
        onClick={props.handleCancel}
        title="Cancel"
      >
        <ClearRoundedIcon className={classes.cancelIcon} />
      </IconButton>
      <IconButton
        ref={props.confirmButtonRef}
        className={clsx(classes.iconButton, classes.confirmButton)}
        aria-label="confirm"
        type="button"
        data-testid={`confirm-${props.dataTestId}`}
        data-pendo={`auto-confirm-${props.dataTestId}-btn`}
        id={`confirm-${props.dataTestId}`}
        onClick={props.handleConfirm}
        title="Confirm"
      >
        <CheckRoundedIcon className={classes.checkIcon} />
      </IconButton>
    </>
  );
}
