var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
export const useAuthConfig = () => {
    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null,
    });
    const fetchAuthConfig = () => __awaiter(void 0, void 0, void 0, function* () {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        if (!apiBaseUrl) {
            throw Error("Environment not correctly set up. 'REACT_APP_API_BASE_URL' variable is missing.");
        }
        const authConfigUrl = new URL("auth_config", apiBaseUrl);
        yield fetch(authConfigUrl.href)
            .then((res) => res.json())
            .then((data) => setState({ data, loading: false, error: false }))
            .catch(() => {
            setState({
                data: null,
                loading: false,
                error: true,
            });
        });
    });
    useEffect(() => {
        fetchAuthConfig();
    }, []);
    return state;
};
