import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  GlobeNetwork,
  FlagBugReportV2,
  ImportantMessageV2,
  DatabaseSystem,
} from "@lumar/shared";
import gradientImage from "../../../images/marketing-gradient-background.svg";
import dashboardGraphic from "../../../images/dashboard-marketing-graphic.svg";
import { HubspotForm } from "./HubspotForm";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(1),
  },
  description: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    marginBottom: theme.spacing(4),
    color: theme.palette.grey[700],
  },
  list: {
    padding: 0,
    marginBottom: 52,
  },
  listItem: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: "black",
    "& svg": {
      fontSize: 20,
      marginRight: 4,
    },
  },
  imageContainer: {
    backgroundImage: `url(${gradientImage})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "0px 6px 6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 2,
  },
  image: {
    height: "auto",
    width: "80%",
    maxWidth: 600,
    margin: theme.spacing(2, 0),
  },
}));

export function AutomateMarketingPage(): JSX.Element {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container>
        <Grid item lg={4} md={5} sm={12}>
          <Box padding="46px">
            <Typography
              component="h1"
              className={classes.title}
              data-testid="automate-marketing-page-title"
            >
              Protect traffic and revenue, with automated technical health QA
              tests
            </Typography>
            <Typography className={classes.description}>
              Save time and money, and improve collaboration by uncovering
              potentially painful errors before code is published.
            </Typography>

            <List className={classes.list}>
              <ListItem className={classes.listItem} disableGutters>
                <GlobeNetwork />
                Integrate with any CI/CD (continuous integration/delivery) tool.
              </ListItem>
              <ListItem className={classes.listItem} disableGutters>
                <FlagBugReportV2 />
                Set warnings for important but non-critical SEO issues.
              </ListItem>
              <ListItem className={classes.listItem} disableGutters>
                <ImportantMessageV2 />
                Automatically stop builds if they fail your SEO tests.
              </ListItem>
              <ListItem className={classes.listItem} disableGutters>
                <DatabaseSystem />
                Choose from over 200 SEO tests.
              </ListItem>
            </List>

            <HubspotForm />
          </Box>
        </Grid>
        <Grid item lg={8} md={7} sm={12}>
          <Box className={classes.imageContainer}>
            <img
              src={dashboardGraphic}
              alt="Graphic of dashboard page with graphs"
              width="609"
              height="527"
              className={classes.image}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
