export function ProtectAppIcon({
  className,
  ...props
}: {
  className: string;
}): JSX.Element {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <rect width="40" height="40" rx="5.10432" fill="currentColor" />
      <path d="M 10.6274 30 H 19.8324 H 20.166 H 29.3709 C 29.9266 30 29.9983 30 29.9983 29.4444 V 24.8387 C 29.9983 22.3432 28.1268 20.3388 25.6667 20.0741 C 25.5985 20.0667 25.5939 19.9784 25.6667 19.972 C 28.1065 19.7576 29.9983 17.6568 29.9983 15.1613 V 10.3333 C 29.9983 10.3038 29.9988 10.2767 29.9992 10.2518 C 30.0029 10.0582 30.004 10 29.741 10 H 20.166 H 19.8324 H 10.2574 C 10.0001 10 10.0001 10.0741 10.0001 10.3333 V 15.1613 C 10.0001 17.6568 11.8919 19.7576 14.3316 19.972 C 14.4044 19.9784 14.3999 20.0667 14.3316 20.0741 C 11.8716 20.3388 10.0001 22.3432 10.0001 24.8387 V 29.4444 C 9.9975 30 10.0347 30 10.6274 30 Z" />
    </svg>
  );
}
