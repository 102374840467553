import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import { Typography } from "../typography/Typography";
import { TextField } from "../text-field/TextField";
import { FixedSizeList } from "react-window";
import { SearchOutlined } from "../../icons";
import { MenuItem } from "./MenuItem";
import { focusableClass, focusableGroupClass, virtualizedListClass, } from "./SubMenu";
import { CircularProgress } from "@material-ui/core";
export const MENU_ITEM_HEIGHT = 37;
export const MenuGroup = React.memo(function MenuGroup({ menu, menuGroup, idx, classes, setFocusedEl, }) {
    var _a, _b, _c;
    const [filterInternal, setFilterInternal] = useState("");
    const isControlled = ((_a = menuGroup.search) === null || _a === void 0 ? void 0 : _a.filter) !== undefined;
    const [filter, setFilter] = isControlled
        ? [((_b = menuGroup.search) === null || _b === void 0 ? void 0 : _b.filter) || "", (_c = menuGroup.search) === null || _c === void 0 ? void 0 : _c.onFilterChanged]
        : [filterInternal, setFilterInternal];
    // Reset filter value on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => () => { var _a, _b; return (_b = (_a = menuGroup.search) === null || _a === void 0 ? void 0 : _a.onFilterChanged) === null || _b === void 0 ? void 0 : _b.call(_a, ""); }, []);
    const filteredItems = (() => {
        if (isControlled || !filter)
            return menuGroup.items;
        return menuGroup.items.filter((item) => {
            var _a;
            if ((_a = menuGroup.search) === null || _a === void 0 ? void 0 : _a.filterPredicate)
                return menuGroup.search.filterPredicate(item, filter);
            return item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
        });
    })();
    const searchFieldRef = useRef(null);
    function handleKeyDown(e) {
        if (!searchFieldRef.current)
            return;
        const key = String.fromCharCode(e.nativeEvent.keyCode);
        if (!key.match(/(\w)/g))
            return;
        e.preventDefault();
        e.stopPropagation();
        setFilter === null || setFilter === void 0 ? void 0 : setFilter(e.key);
        searchFieldRef.current.focus();
    }
    const menuItemListProps = {
        menu,
        menuGroup,
        groupIdx: idx,
        items: filteredItems,
        isFiltered: Boolean(filter),
        classes,
        setFocusedEl,
        handleKeyDown,
    };
    return (_jsxs(_Fragment, { children: [_jsx(MenuGroupDivider, { menuGroup: menuGroup, idx: idx, classes: classes }), menuGroup.search !== undefined && (_jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.search, children: _jsx(TextField, { value: filter, onChange: (e) => setFilter === null || setFilter === void 0 ? void 0 : setFilter(e.target.value), placeholder: menuGroup.search.placeholder, InputProps: {
                        startAdornment: (_jsx(SearchOutlined, { className: classes === null || classes === void 0 ? void 0 : classes.searchIcon })),
                    }, inputProps: {
                        className: clsx(focusableClass, focusableGroupClass),
                    }, inputRef: searchFieldRef }) })), menuGroup.height ? (_jsx(VirtualizedMenuItemList, Object.assign({}, menuItemListProps))) : (_jsx(MenuItemList, Object.assign({}, menuItemListProps)))] }));
});
function MenuGroupDivider({ menuGroup, idx, classes, }) {
    if (menuGroup.name === undefined)
        return idx !== 0 ? (_jsx(Divider, { className: classes === null || classes === void 0 ? void 0 : classes.groupDivider }, idx)) : (_jsx("div", { style: { marginTop: 9 } }, idx));
    return (_jsx(Typography, { variant: "captionSemiBold", className: classes === null || classes === void 0 ? void 0 : classes.groupLabel, children: menuGroup.name }, idx));
}
function VirtualizedMenuItemList({ menu, menuGroup, groupIdx, items, isFiltered, classes, setFocusedEl, handleKeyDown, }) {
    var _a, _b;
    function getInitialOffset() {
        var _a;
        const half = Math.floor(((_a = menuGroup.height) !== null && _a !== void 0 ? _a : 0) / MENU_ITEM_HEIGHT / 2);
        return Math.max((items.findIndex((x) => x.checked) - half) * MENU_ITEM_HEIGHT, 0);
    }
    if (menuGroup.loading)
        return (_jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.loadingIndicator, style: { height: menuGroup.height }, children: _jsx(CircularProgress, { size: "20px" }) }));
    const showNoResultMessage = isFiltered && !items.length && ((_a = menuGroup.search) === null || _a === void 0 ? void 0 : _a.noResultMessage);
    if (showNoResultMessage)
        return (_jsx("div", { style: { height: menuGroup.height, display: "inline-block" }, children: _jsx(Typography, { variant: "body2", className: classes === null || classes === void 0 ? void 0 : classes.noResultMessage, children: (_b = menuGroup.search) === null || _b === void 0 ? void 0 : _b.noResultMessage }) }));
    return (_jsx(FixedSizeList, { height: menuGroup.height || 0, width: "100%", itemSize: MENU_ITEM_HEIGHT, itemCount: items.length, itemData: { items }, initialScrollOffset: getInitialOffset(), className: clsx(focusableClass, virtualizedListClass, focusableGroupClass), children: ({ index, data: { items }, style }) => (_jsx(MenuItem, { menu: menu, menuGroup: menuGroup, menuItem: items[index], groupIdx: groupIdx, idx: index, style: style, classes: classes, setFocusedEl: setFocusedEl, onKeyDown: handleKeyDown }, items[index].name)) }));
}
function MenuItemList({ menu, menuGroup, groupIdx, items, isFiltered, classes, setFocusedEl, }) {
    var _a, _b;
    if (menuGroup.loading)
        return (_jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.loadingIndicator, children: _jsx(CircularProgress, { size: "20px" }) }));
    const showNoResultMessage = isFiltered && !items.length && ((_a = menuGroup.search) === null || _a === void 0 ? void 0 : _a.noResultMessage);
    if (showNoResultMessage)
        return (_jsx("div", { style: { display: "inline-block" }, children: _jsx(Typography, { variant: "body1", className: classes === null || classes === void 0 ? void 0 : classes.noResultMessage, children: (_b = menuGroup.search) === null || _b === void 0 ? void 0 : _b.noResultMessage }) }));
    return (_jsx("div", { className: focusableGroupClass, children: items.map((item, idx) => (_jsx(MenuItem, { menu: menu, menuGroup: menuGroup, menuItem: item, groupIdx: groupIdx, idx: idx, classes: classes, setFocusedEl: setFocusedEl }, item.name))) }));
}
