import { ExtendedTest } from "../../../../pages/TestSuite/components/ChooseTestsForm/CreateTests.interfaces";

export function numericSort(array: ExtendedTest[]): ExtendedTest[] {
  // Note: safe to do as we are spreading into a new array first
  // eslint-disable-next-line fp/no-mutating-methods
  const newArray = [...array].sort(
    (a, b) =>
      +a.data.reportTemplate.name.localeCompare(
        b.data.reportTemplate.name,
        undefined,
        {
          numeric: true,
        },
      ),
  );
  return newArray;
}
