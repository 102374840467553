import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SentimentVeryDissatisfied } from "@material-ui/icons";
import { Fade, Grid, Button, Box, makeStyles, } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "100vh",
        background: "#24333e",
    },
    logo: {
        color: theme.palette.common.white,
        textAlign: "center",
    },
    icon: {
        fontSize: 96,
    },
    progress: {
        height: "1px",
    },
    button: {
        margin: theme.spacing(0, 0.5, 0, 0.5),
    },
}));
export function ErrorScreen(props) {
    const classes = useStyles();
    return (_jsx(Grid, { container: true, spacing: 0, alignItems: "center", justifyContent: "center", className: classes.container, "data-testid": "error-screen", children: _jsx(Grid, { item: true, children: _jsx("div", { className: classes.logo, children: _jsx(Fade, { in: true, children: _jsxs("div", { children: [_jsx(props.icon, { className: classes.icon }), _jsx(Box, { paddingBottom: 2, children: props.message }), props.actions.map((action, index) => {
                                return (_jsx(Button, { variant: "contained", size: "small", onClick: action.onClick, className: classes.button, children: action.name }, index));
                            })] }) }) }) }) }));
}
export function SessionError({ message, actions, }) {
    return (_jsx(ErrorScreen, { icon: SentimentVeryDissatisfied, message: message, actions: actions }));
}
