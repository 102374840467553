import React from "react";
import { Box, InputAdornment, Typography, makeStyles } from "@material-ui/core";
import { crawlSettingsTooltips } from "../crawlSettingsTooltips";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { SectionTitle } from "../../Section";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";

const useStyles = makeStyles((theme) => ({
  endAdornment: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[700],
    fontWeight: 400,
  },
  customWidth: {
    maxWidth: "800px",
    "& p": {
      margin: "3px 0",
    },
  },
}));

export function TestSettings() {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <SectionTitle>Test Settings</SectionTitle>
        <InfoTooltip
          interactive
          title={crawlSettingsTooltips.testSettings}
          data-pendo="auto-tooltip-test-settings"
          IconProps={{
            style: { marginLeft: 8 },
          }}
          classes={{ tooltip: classes.customWidth }}
        />
      </Box>

      {textFieldConfigs.map(({ id, label, endAdornment, ...rest }) => {
        return (
          <Box key={id} marginBottom={2}>
            <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
            <Field
              fullWidth
              type="number"
              component={TextField}
              variant="outlined"
              style={{ maxWidth: 250 }}
              id={id}
              InputProps={{
                endAdornment: endAdornment ? (
                  <InputAdornment position="start">
                    <Typography className={classes.endAdornment}>
                      {endAdornment}
                    </Typography>
                  </InputAdornment>
                ) : undefined,
              }}
              {...rest}
            />
          </Box>
        );
      })}
    </>
  );
}

interface TextFieldConfig {
  name: string;
  id: string;
  label: string;
  "data-testid"?: string;
  "data-pendo": string;
  endAdornment?: string;
  max?: string;
  min?: string;
}

const textFieldConfigs: TextFieldConfig[] = [
  {
    name: "maxTitleWidth",
    id: "max-title-width",
    label: "Max title width",
    "data-testid": "max-title-width",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-title-width",
    endAdornment: "pixels",
  },
  {
    name: "minTitleLength",
    id: "min-title-length",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-min-title-length",
    label: "Min title length",
    endAdornment: "characters",
  },
  {
    name: "minDescriptionLength",
    id: "min-description-length",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-min-description-length",
    label: "Min description length",
    endAdornment: "characters",
  },
  {
    name: "maxDescriptionLength",
    id: "max-description-length",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-description-length",
    label: "Max description length",
    endAdornment: "characters",
  },
  {
    id: "max-html-size",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-html-size",
    name: "maxHtmlSize",
    label: "Max HTML size",
    endAdornment: "bytes",
  },
  {
    name: "maxLinks",
    label: "Max links",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-links",
    id: "max-links",
  },
  {
    name: "thinPageThreshold",
    label: "Thin page threshold",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-thin-page-threshold",
    id: "thin-page-threshold",
    endAdornment: "bytes",
  },
  {
    name: "emptyPageThreshold",
    label: "Empty page threshold",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-empty-page-threhsold",
    id: "empty-page-threshold",
    endAdornment: "bytes",
  },
  {
    name: "maxExternalLinks",
    label: "Max external links",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-external-links",
    id: "max-external-links",
  },
  {
    "data-testid": "max-content-size",
    id: "max-content-size",
    name: "maxBodyContentLength",
    label: "Max content size",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-content-size",
    endAdornment: "bytes",
  },
  {
    name: "maxUrlLength",
    label: "Max URL length",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-url-length",
    id: "max-url-length",
    endAdornment: "characters",
  },
  {
    name: "maxLoadTime",
    label: "Max fetch time",
    id: "max-fetch-time",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-fetch-time",
    endAdornment: "seconds",
  },
  {
    name: "duplicatePrecision",
    label: "Duplicate precision",
    id: "duplicate-precision",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-duplicate-precision",
    min: "1",
    max: "5",
  },
  {
    name: "maxRedirections",
    label: "Max redirections",
    id: "max-redirections",
    "data-pendo":
      "auto-test-suite-edit-choose-crawl-settings-advanced-settings-test-settings-max-redirections",
    endAdornment: "redirections",
  },
];
