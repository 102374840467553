import React from "react";
import { FormControlLabel, useTheme } from "@material-ui/core";
import { crawlSettingsTooltips } from "../crawlSettingsTooltips";
import { Field } from "formik";
import { CustomCheckbox } from "../CustomCheckbox";
import { TextField } from "formik-material-ui";
import { SectionTitle } from "../../Section";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";

export function JsRenderingSettings({
  hasAccessibility,
}: {
  hasAccessibility: boolean;
}) {
  const theme = useTheme();
  return (
    <>
      <div>
        <div
          style={{
            marginBottom: theme.spacing(2),
            display: "flex",
            alignItems: "center",
          }}
        >
          <SectionTitle>JavaScript Rendering Settings</SectionTitle>
          <InfoTooltip
            IconProps={{ style: { marginLeft: 8 } }}
            title={crawlSettingsTooltips.jsRendering}
            data-pendo="auto-tooltip-js-rendering-settings"
            interactive
          />
        </div>
        {hasAccessibility ? undefined : (
          <>
            <div
              data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-block-ad-scripts"
              style={{ marginBottom: theme.spacing(2) }}
            >
              <FormControlLabel
                label="Block ad scripts"
                control={
                  <Field
                    name="rendererBlockAds"
                    id="renderer-block-ads"
                    component={CustomCheckbox}
                    data-cy="js-block-ad-checkbox"
                    color="primary"
                  />
                }
              />
            </div>
            <div
              style={{ marginBottom: theme.spacing(2) }}
              data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-block-analytics-scripts"
            >
              <FormControlLabel
                label="Block analytics scripts"
                control={
                  <Field
                    name="rendererBlockAnalytics"
                    id="renderer-block-analytics"
                    component={CustomCheckbox}
                    data-cy="js-block-analytics-checkbox"
                    color="primary"
                  />
                }
              />
            </div>
          </>
        )}
      </div>
      <div style={{ marginBottom: theme.spacing(2) }}>
        <StyledInputLabel htmlFor="js-block-script-urls-input">
          Block Script URLs
        </StyledInputLabel>
        <Field
          fullWidth
          multiline={true}
          rows="4"
          name="rendererBlockCustom"
          component={TextField}
          variant="outlined"
          type="text"
          id="js-block-script-urls-input"
          data-cy="js-block-script-urls-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-block-script-url"
          style={{ maxWidth: 800 }}
        />
      </div>

      <div style={{ marginBottom: theme.spacing(2) }}>
        <StyledInputLabel htmlFor="custom-js-snippet-input">
          Custom JavaScript Snippet
        </StyledInputLabel>
        <Field
          fullWidth
          name="rendererJsString"
          variant="outlined"
          type="text"
          multiline={true}
          rows="4"
          component={TextField}
          id="custom-js-snippet-input"
          data-cy="custom-js-snippet-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-custom-js-snippets"
          style={{ maxWidth: 800 }}
        />
      </div>

      <div style={{ marginBottom: theme.spacing(2) }}>
        <StyledInputLabel htmlFor="external-js-resources-input">
          Include External JavaScript Resources
        </StyledInputLabel>
        <Field
          fullWidth
          variant="outlined"
          type="text"
          multiline={true}
          rows="4"
          component={TextField}
          name="rendererJsUrls"
          placeholder="e.g. https://code.jquery.com/jquery-3.2.1.slim.min.js"
          data-cy="external-js-resources-input"
          id="external-js-resources-input"
          data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-javascript-rendering-include-external-js-resources"
          style={{ maxWidth: 800 }}
        />
      </div>
      {hasAccessibility ? (
        <div style={{ marginBottom: theme.spacing(2) }}>
          <StyledInputLabel htmlFor="accessibility-script">
            Custom JavaScript for Accessibility
          </StyledInputLabel>
          <Field
            fullWidth
            variant="outlined"
            type="text"
            multiline={true}
            rows="4"
            component={TextField}
            name="accessibilityScript"
            placeholder=""
            data-cy="accessibility-script"
            id="accessibility-script"
            data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-accessibility-script"
            style={{ maxWidth: 800 }}
          />
        </div>
      ) : null}
    </>
  );
}
