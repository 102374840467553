import React, { useContext, useState } from "react";
import {
  Box,
  Collapse,
  FormControlLabel,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { ChooseTestsState } from "../../chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { getAppliedFiltersCount } from "../ChooseTestsFormCountersUtils";
import { Button, Checkbox } from "@lumar/shared";
import { FilterButton } from "./FilterButton";

const useStyles = makeStyles((theme) => ({
  collapseContent: {
    background: "#fff",
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: "15px 13px",
    borderRadius: theme.shape.borderRadius,
    marginTop: 3,
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(1.5),
  },
  clearFilterButton: {
    padding: 0,
  },
}));

interface ChooseTestsFilterProps {
  chooseTestsFormState: ChooseTestsState;
  filterToggle: () => void;
}

export function ChooseTestsFilter({
  chooseTestsFormState: { filter, categories },
  filterToggle,
}: ChooseTestsFilterProps) {
  const classes = useStyles();
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const numberOfFiltersApplied = getAppliedFiltersCount(filter, categories);

  function handleShowAllClick(
    _: React.ChangeEvent<HTMLInputElement>,
    selected: boolean,
  ) {
    if (selected) {
      chooseTestsFormDispatch({ type: "SHOW_ALL_CATEGORIES", payload: {} });
    }
  }

  function handleFilterButtonClick(): void {
    setIsFilterOpen((o) => !o);
    filterToggle();
  }

  function handleShowSelectedClick(
    _: React.ChangeEvent<HTMLInputElement>,
    selected: boolean,
  ) {
    chooseTestsFormDispatch({
      type: "TOGGLE_SELECTED_TESTS",
      payload: { showSelected: selected },
    });
  }

  function handleResetButtonClick() {
    chooseTestsFormDispatch({ type: "RESET", payload: {} });
  }

  return (
    <Box marginBottom={1}>
      <FilterButton
        onClick={handleFilterButtonClick}
        chipLabel={numberOfFiltersApplied}
      />

      <Collapse in={isFilterOpen}>
        <Box className={classes.collapseContent}>
          {categories.map((category) => {
            return (
              <Box
                key={`${category.code}-category-box`}
                display="flex"
                alignItems="center"
                data-pendo={`auto-test-suite-edit-choose-tests-filter-${category.code}`}
                mb={1.5}
              >
                <FormControlLabel
                  label={category.name}
                  control={
                    <Checkbox
                      id={`${category.code}-category-checkbox`}
                      data-testid={`${category.code}-checkbox`}
                      data-cy={`${category.code}-checkbox`}
                      checked={category.selected}
                      onChange={(_, selected) =>
                        chooseTestsFormDispatch({
                          type: "TOGGLE_CATEGORY",
                          payload: {
                            categoryId: category.code,
                            isCategorySelected: selected,
                          },
                        })
                      }
                    />
                  }
                />
              </Box>
            );
          })}

          <Divider className={classes.divider} />

          <Box
            key={`show-selected-box`}
            display="flex"
            alignItems="center"
            data-pendo="auto-test-suite-edit-choose-tests-filter-selected"
            mb={1.5}
          >
            <FormControlLabel
              control={
                <Checkbox
                  id={`show-selected-checkbox`}
                  data-testid={`show-selected-checkbox`}
                  checked={filter.showSelected}
                  onChange={handleShowSelectedClick}
                />
              }
              label="Show selected tests"
            />
          </Box>
          <Box
            key={`show-all-box`}
            display="flex"
            alignItems="center"
            data-pendo="auto-test-suite-edit-choose-tests-filter-all"
          >
            <FormControlLabel
              control={
                <Checkbox
                  id={`show-all-checkbox`}
                  data-testid={`show-all-checkbox`}
                  checked={filter.showAllCategories}
                  onChange={handleShowAllClick}
                />
              }
              label="Show all tests"
            />
          </Box>
        </Box>
      </Collapse>

      {numberOfFiltersApplied ? (
        <Box display="flex" justifyContent="flex-end" marginTop={1}>
          <Button
            onClick={handleResetButtonClick}
            data-testid="reset-filter"
            size="small"
            color="primary"
            disableElevation
            data-pendo="auto-test-suite-edit-choose-tests-filter-reset"
            className={classes.clearFilterButton}
          >
            Clear filter
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
