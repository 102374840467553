import React from "react";
import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";
import { SectionTitle } from "../../Section";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { TagInput } from "@lumar/shared";

const INPUT_NAME = "startUrls";

export function StartUrls() {
  const { values, setFieldValue, errors } = useFormikContext<{
    startUrls: string[];
  }>();

  const errorMessage = Array.isArray(errors.startUrls)
    ? errors.startUrls.find((e) => typeof e === "string")
    : errors.startUrls;

  const errorArray = Array.isArray(errors.startUrls)
    ? errors.startUrls.map(Boolean)
    : values.startUrls.map(() => false);

  const handleChange = (urls: string[]): void => {
    setFieldValue(INPUT_NAME, urls);
  };

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <SectionTitle>Start URLs</SectionTitle>
        <InfoTooltip
          IconProps={{ style: { marginLeft: 8 } }}
          data-pendo="auto-tooltip-start-override"
          data-testid="starts-url-tooltip"
          title="A crawl will start from your primary domain by default. If you need the crawl to start from different points, specify them below."
        />
      </Box>

      <StyledInputLabel htmlFor="start-urls">Start URLs</StyledInputLabel>
      <TagInput
        value={values.startUrls}
        onChange={handleChange}
        addOnComma
        variant="outlined"
        fullWidth
        style={{ maxWidth: 800 }}
        id="start-urls"
        name={INPUT_NAME}
        data-testid="start-urls-input"
        data-pendo="auto-test-suite-edit-choose-crawl-settings-advanced-settings-start-urls"
        error={errorMessage}
        errors={errorArray}
        tagProps={{
          "data-testid": "start-url-chip",
        }}
      />
    </>
  );
}
