import { GetTestSuiteOptionsForScheduleQuery } from "../../../../../graphql";

export function getTestSuiteOptionsFromData(
  data: GetTestSuiteOptionsForScheduleQuery | undefined,
) {
  const testSuiteEdges = data?.getAccount?.unscheduledTestSuites?.edges || [];
  return testSuiteEdges.map(({ node: { id, name } }) => ({
    name,
    id,
  }));
}
