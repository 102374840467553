import * as yup from "yup";
import { ValidationErrors } from "../../../../../validation/ValidationErrors";

interface CrawlTypes {
  web: boolean;
  list: boolean;
  sitemap: boolean;
  google_analytics: boolean;
  backlinks: boolean;
  google_search_console: boolean;
  log_summary: boolean;
}

export const crawlSettingsFormValidation = yup.object().shape({
  crawlTypes: yup
    .object()
    .test(
      "crawl-type-requied",
      "At least one URL Source is required",
      (value) => {
        const types = value as unknown as CrawlTypes | null | undefined;
        return (
          [
            types?.list,
            types?.web,
            types?.sitemap,
            types?.google_analytics,
            types?.backlinks,
            types?.google_search_console,
            types?.log_summary,
          ].reduce(
            (
              accumulator: boolean | undefined,
              currentValue: boolean | undefined,
            ) => accumulator || currentValue,
          ) ?? false
        );
      },
    ),
  useRenderer: yup.string().required(),
  limitUrlsMax: yup.string().required(),
  includeUrlPatterns: yup.string(),
  excludeUrlPatterns: yup.string(),
  startUrls: yup.array().of(
    yup
      .string()
      .url(ValidationErrors.HttpProtocol)
      .matches(/^http(s)?:\/\/.*/, ValidationErrors.HttpProtocol),
  ),
  rendererBlockAds: yup.boolean().required(),
  rendererBlockAnalytics: yup.boolean().required(),
  rendererJsString: yup.string(),
  rendererBlockCustom: yup.string(),
  rendererJsUrls: yup.string(),
  robotsOverwrite: getRobotsOverwriteSchema(),
  emptyPageThreshold: yup
    .number()
    .required(ValidationErrors.EmptyPageThreshold)
    .positive()
    .integer(),
  logSummaryRequestsHigh: yup.number().required().positive().integer(),
  logSummaryRequestsLow: yup.number().required().positive().integer(),
  maxBodyContentLength: yup
    .number()
    .required(ValidationErrors.MaxContentSize)
    .positive()
    .integer(),
  maxDescriptionLength: yup
    .number()
    .required(ValidationErrors.MaxDescriptionLength)
    .positive()
    .integer(),
  maxExternalLinks: yup
    .number()
    .required(ValidationErrors.MaxExternalLinks)
    .positive()
    .integer(),
  maxHtmlSize: yup
    .number()
    .required(ValidationErrors.MaxHtmlSize)
    .positive()
    .integer(),
  maxLinks: yup
    .number()
    .required(ValidationErrors.MaxLinks)
    .positive()
    .integer(),
  maxLoadTime: yup
    .number()
    .required(ValidationErrors.MaxLoadTime)
    .positive()
    .integer(),
  maxRedirections: yup
    .number()
    .required(ValidationErrors.MaxRedirections)
    .positive()
    .integer(),
  maxTitleWidth: yup
    .number()
    .required(ValidationErrors.MaxTitleWidth)
    .positive()
    .integer(),
  maxUrlLength: yup
    .number()
    .required(ValidationErrors.MaxUrlLength)
    .positive()
    .integer(),
  duplicatePrecision: yup
    .number()
    .required(ValidationErrors.DuplicatePrecision)
    .integer()
    .min(1)
    .max(5),
  minDescriptionLength: yup
    .number()
    .required(ValidationErrors.MinDescriptionLength)
    .positive()
    .integer(),
  minTitleLength: yup
    .number()
    .required(ValidationErrors.MinTitleLength)
    .positive()
    .integer(),
  thinPageThreshold: yup
    .number()
    .required(ValidationErrors.ThinPageThreshold)
    .positive()
    .integer(),
  customExtractions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(ValidationErrors.CustomExtractionLabel),
      regex: yup.string().required(ValidationErrors.CustomExtractionRegex),
    }),
  ),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRobotsOverwriteSchema(): yup.AnySchema<any, any, any> {
  return yup.object().shape({
    enable: yup.boolean().required(),
    robots: yup.string().when("enable", {
      is: (value: unknown) => value === true,
      then: (schema) => schema.required(ValidationErrors.RobotsOverwrite),
      otherwise: (schema) => schema.notRequired(),
    }),
    renderingRobotsEnabled: yup.boolean().required(),
    ignoreRobotsForNavigationRequests: yup.boolean().required(),
  });
}
