import React, { useState, useRef, useEffect } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions, Skeleton } from "@material-ui/lab";
import { useGetNotLinkedTestSuitesQuery } from "../graphql/useGetNotLinkedTestSuitesQuery";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useStyles } from "./popoverTemplateStyles";
import { ConfirmationDialogInfoIcon, Alert } from "@lumar/shared";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { usePopupState, bindPopover } from "material-ui-popup-state/hooks";
import { ConfirmationDialogue } from "./ConfirmationDialogue";
import { GetNotLinkedTestSuitesQuery } from "../../../../../graphql";

export interface TestSuiteOption {
  name: string;
  id: string;
  group: string;
}

export enum UseAsGlobalTemplateVariant {
  AddNewChild = "add-new-child",
  AddAnotherChild = "add-another-child",
}

interface UseAsGlobalTemplateProps {
  handleUseAsGlobalTemplate: (
    childId: string,
    childName: string,
  ) => Promise<void>;
  handleClose?: () => void;
  label: string;
  placeholder: string;
  variant: UseAsGlobalTemplateVariant;
  testSuiteId: string;
  availableTestSuitesDetected?: (count: number) => void;
}

export function UseAsGlobalTemplate(
  props: UseAsGlobalTemplateProps,
): JSX.Element {
  const classes = useStyles();
  const autocompleteRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState<TestSuiteOption | null>(
    null,
  );

  const { data, error, loading } = useGetNotLinkedTestSuitesQuery();
  const optionsList = getOptions(data, props.testSuiteId);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "use-as-global-template-popup",
  });

  const { title, description, confirmationText } = useDialogueContent(
    props.variant,
  );

  const handleConfirmationClick = () => {
    popupState.close();

    if (selectedOption?.id) {
      props.handleClose?.();
      props.handleUseAsGlobalTemplate(selectedOption.id, selectedOption.name);
    }
  };

  const handleAutocompleteChange = (
    _: React.ChangeEvent<{ value?: unknown }>,
    newOption: TestSuiteOption | null,
  ) => {
    setSelectedOption(newOption);
    if (newOption) {
      popupState.open(autocompleteRef.current);
    } else {
      popupState.close();
    }
  };

  const filterOptions = createFilterOptions<TestSuiteOption>({
    stringify: (option) => option.name,
  });

  useEffect(() => {
    if (props.availableTestSuitesDetected) {
      props.availableTestSuitesDetected(optionsList.length);
    }
  }, [props, optionsList]);

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        height={59}
        data-testid="use-as-global-template-loading"
      />
    );
  }

  if (error) {
    return (
      <Box mb={2}>
        <Alert severity="error" data-testid="use-as-global-template-error">
          An error occurred. Please refresh the page and try again.
        </Alert>
      </Box>
    );
  }

  return (
    <Box data-testid="use-as-global-template">
      {optionsList.length ? (
        <>
          <StyledInputLabel htmlFor="add-parent-autocomplete">
            {props.label}
          </StyledInputLabel>
          <Autocomplete
            classes={{
              groupLabel: classes.groupLabel,
              groupUl: classes.groupUl,
              paper: classes.paper,
            }}
            openOnFocus
            popupIcon={<ExpandMoreRoundedIcon />}
            ref={autocompleteRef}
            value={selectedOption}
            onChange={handleAutocompleteChange}
            id="add-parent-autocomplete"
            data-testid="add-parent-autocomplete"
            data-pendo="auto-test-suite-edit-template-settings-link-use-as-global-template-select"
            options={optionsList}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            filterOptions={filterOptions}
            groupBy={(option) => option.group}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={props.placeholder}
              />
            )}
          />
        </>
      ) : (
        <Alert severity="info" data-testid="no-testsuites-available-info">
          All your test suites are currently linked to templates or no test
          suites are available.
        </Alert>
      )}

      <ConfirmationDialogue
        title={title}
        description={description}
        icon={<ConfirmationDialogInfoIcon className={classes.icon} />}
        PopoverProps={bindPopover(popupState)}
      >
        <Button
          onClick={popupState.close}
          variant="outlined"
          data-testid="popover-dialog-button-cancel"
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmationClick}
          variant="contained"
          color="primary"
          data-testid="popover-dialog-button-ok"
          size="large"
        >
          {confirmationText}
        </Button>
      </ConfirmationDialogue>
    </Box>
  );
}

function useDialogueContent(variant: UseAsGlobalTemplateVariant) {
  const isFirstChild = variant === UseAsGlobalTemplateVariant.AddNewChild;

  if (isFirstChild) {
    return {
      title:
        "Make this a global template and apply it to the chosen test suite?",
      description:
        "This will replace any configurations in steps 2 (Crawl settings) and 3 (Tests) of linked test suite.",
      confirmationText: "Yes, create global template",
    };
  }

  return {
    title: "Apply this global template to the test suite?",
    description:
      "This will replace any configurations in steps 2 (Crawl settings) and 3 (Tests) of the chosen test suite.",
    confirmationText: "Yes, apply global template",
  };
}

function getOptions(
  data: GetNotLinkedTestSuitesQuery | undefined,
  testSuiteId: string,
): {
  name: string;
  id: string;
  group: string;
}[] {
  return (
    data?.getAccount?.notLinkedTestSuites?.edges
      .filter((testSuiteEdge) => {
        return testSuiteEdge.node.id !== testSuiteId;
      })
      .map((testSuiteEdge) => {
        return {
          name: testSuiteEdge.node.name,
          id: testSuiteEdge.node.id,
          group: "Available test suites",
        };
      }) || []
  );
}
