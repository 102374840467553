import { CustomDnsPair } from "../ConnectSiteForm";

export function removeTypenameFromDnsSettings(
  customDnsSettings: CustomDnsPair[],
) {
  return customDnsSettings.map((setting) => ({
    hostname: setting.hostname,
    ipAddress: setting.ipAddress,
  }));
}
