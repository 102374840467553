import { useState } from "react";

export function useTimeout(delay: number): {
  trigger: boolean;
  clear: () => void;
} {
  const [trigger, setTrigger] = useState(false);

  const timeoutOneShowMain = setTimeout(() => {
    setTrigger(true);
    clear();
  }, delay);

  const clear = (): void => {
    clearTimeout(timeoutOneShowMain);
  };

  return {
    trigger: trigger,
    clear: clear,
  };
}
