import { ApolloQueryResult } from "@lumar/shared";
import {
  ChooseTestsForm,
  ChooseTestsFormProps,
} from "./components/ChooseTestsForm/ChooseTestsForm";
import { ConnectCiCdTool } from "./components/ConnectCiCdTool/ConnectCiCdTool";
import { UpdateConnectSite } from "./components/ConnectSiteForm/UpdateConnectSite";
import { CrawlSettingsForm } from "./components/CrawlSettingsForm/CrawlSettingsForm";
import { SetAlertsForm } from "./components/SetAlerts/SetAlertsForm";
import { CustomStep } from "./CustomStep";
import { StepChangeHandler } from "./UpdateTestSuite";
import {
  GetTestSuiteQuery,
  GetTestSuiteQueryVariables,
  useGetTestSuiteTestsAndCustomExtractionsQuery,
} from "../../graphql";
import { useReportTemplates } from "./components/ChooseTestsForm/graphql/useReportTemplates";

interface UpdateTestSuiteStepsProps
  extends Omit<
    ChooseTestsFormProps,
    | "onSuccess"
    | "useTestSuiteTestsAndExtractionsQueryObject"
    | "reportTemplateObject"
  > {
  absoluteThresholdUpLimit: number;

  refetchTestSuite: (
    variables?: GetTestSuiteQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<GetTestSuiteQuery>>;
  testSuiteId: string;
  handleStepChange: (
    step: string,
    shouldExpandNextStep?: boolean | undefined,
  ) => StepChangeHandler;
  expandedStep: string;
  useReportTemplateObject: ReturnType<typeof useReportTemplates>;
  data: GetTestSuiteQuery;
}

export function UpdateTestSuiteSteps(props: UpdateTestSuiteStepsProps) {
  const { testSuite, data, project, handleStepChange } = props;
  const doesParentExist = Boolean(testSuite?.parent);
  const useTestSuiteTestsAndExtractionsQueryObject =
    useGetTestSuiteTestsAndCustomExtractionsQuery({
      variables: { id: props.testSuiteId },
      notifyOnNetworkStatusChange: true,
    });

  function refetchData() {
    useTestSuiteTestsAndExtractionsQueryObject.refetch();
    props.refetchTestSuite();
  }

  const stepsConfigs = [
    {
      name: "Connect site",
      number: 1,
      pendoId: "auto-edit-test-suite-step1",
      description:
        "Configure the website that will be crawled during the test, along with any necessary authentication settings.",
      children: (
        <UpdateConnectSite
          refetchTestSuite={props.refetchTestSuite}
          testSuite={testSuite}
          project={project}
          onSubmit={handleStepChange("step2", !doesParentExist)}
        />
      ),
    },
    {
      name: "Choose crawl settings",
      number: 2,
      pendoId: "auto-edit-test-suite-step2",
      description:
        "Configure how the crawler treats your website and the URLs that will be crawled.",
      children: (
        <CrawlSettingsForm
          testSuite={testSuite}
          data={data}
          project={project}
          onSubmit={handleStepChange("step3", !doesParentExist)}
          refetchData={refetchData}
        />
      ),
    },
    {
      name: "Choose tests",
      number: 3,
      pendoId: "auto-edit-test-suite-step3",
      description:
        "While Lumar Protect crawls it will run the below tests. These will dictate the overall success of the build. The maximum number of tests that can be selected for each test suite is 100.",
      children: (
        <ChooseTestsForm
          absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
          hasChildren={props.hasChildren}
          smartThresholdSettings={props.smartThresholdSettings}
          setSmartThresholdSettings={props.setSmartThresholdSettings}
          testSuite={testSuite}
          project={project}
          onSuccess={handleStepChange("step4", true)}
          useTestSuiteTestsAndExtractionsQueryObject={
            useTestSuiteTestsAndExtractionsQueryObject
          }
          reportTemplateObject={props.useReportTemplateObject}
        />
      ),
    },
    {
      name: "Set notifications",
      number: 4,
      pendoId: "auto-edit-test-suite-step4",
      children: (
        <SetAlertsForm
          testSuite={testSuite}
          onSubmit={handleStepChange("step5", true)}
        />
      ),
    },
    {
      name: "Run test suite",
      number: 5,
      pendoId: "auto-edit-test-suite-step5",
      children: (
        <ConnectCiCdTool
          testSuite={testSuite}
          refetchTestSuite={props.refetchTestSuite}
        />
      ),
    },
  ];

  return (
    <>
      {stepsConfigs.map((step) => {
        const isStep2or3 = step.number === 2 || step.number === 3;
        const isParentStep = isStep2or3 && props.hasChildren;
        const isChildStep = isStep2or3 && !!testSuite.parent;
        const isExpanded = isChildStep
          ? false
          : props.expandedStep === `step${step.number}`;

        return (
          <CustomStep
            disabled={isChildStep}
            key={step.number}
            isExpanded={isExpanded}
            handleChange={handleStepChange(`step${step.number}`)}
            stepHeader={step.name}
            stepNumber={step.number}
            id={`step${step.number}`}
            testId={`step${step.number}-collapse-container`}
            isParentStep={isParentStep}
            pendoId={step.pendoId}
            description={step.description}
            isChildStep={isChildStep}
          >
            {step.children}
          </CustomStep>
        );
      })}
    </>
  );
}
