var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import ReactScrollbar from "react-scrollbars-custom";
import clsx from "clsx";
import { omit } from "lodash";
const useStyles = makeStyles(() => ({
    innerThumbYStyle: {
        cursor: "pointer",
        borderRadius: "20px",
        background: "rgba(209, 213, 219, 1)",
        width: "3px",
        marginLeft: 5,
        height: "100%",
    },
}));
export function Scrollbar({ children, classes: providedClasses, reactScrollbarProps = null, }) {
    const classes = useStyles();
    return (_jsx(ReactScrollbar, Object.assign({ noScrollX: true, disableTracksWidthCompensation: true, trackYProps: {
            renderer: (props) => {
                const style = {
                    position: "absolute",
                    overflow: "hidden",
                    borderRadius: 20,
                    background: "transparent",
                    width: "10px",
                    height: "calc(100% - 1px)",
                    top: 1,
                    right: 0,
                    userSelect: "none",
                };
                const { elementRef } = props, restProps = __rest(props, ["elementRef"]);
                return (_jsx("div", Object.assign({}, omit(restProps, "axis"), { style: style, ref: elementRef })));
            },
        }, thumbYProps: {
            renderer: (props) => {
                const { elementRef } = props, restProps = __rest(props, ["elementRef"]);
                const outerThumYStyle = {
                    cursor: "pointer",
                    background: "transparent",
                    width: "10px",
                    borderRadius: "20px",
                };
                return (_jsx("div", Object.assign({ ref: elementRef }, omit(restProps, "axis"), { style: outerThumYStyle, children: _jsx("div", { className: classes.innerThumbYStyle }) })));
            },
        }, contentProps: {
            renderer: (props) => {
                const { elementRef } = props, restProps = __rest(props, ["elementRef"]);
                const style = {
                    boxSizing: "border-box",
                    padding: "0.05px",
                    minHeight: "100%",
                    minWidth: "100%",
                };
                return (_jsx("div", Object.assign({}, restProps, { style: style, className: clsx(restProps.className, providedClasses === null || providedClasses === void 0 ? void 0 : providedClasses.content), ref: elementRef })));
            },
        } }, reactScrollbarProps, { children: children })));
}
