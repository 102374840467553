enum SeverityOptions {
  low = "low",
  medium = "medium",
  high = "high",
}

export function handleSeverity(value: number): SeverityOptions | undefined {
  if (value > 40) return SeverityOptions.high;
  if (value > 20) return SeverityOptions.medium;
  if (value > 10) return SeverityOptions.low;
}
