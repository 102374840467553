var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button as MuiButton, CircularProgress, makeStyles, } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    hiddenContent: {
        visibility: "hidden",
    },
}));
export const Button = React.forwardRef(function Button(props, ref) {
    const classes = useStyles();
    const { loading, circularProgressProps } = props, rest = __rest(props, ["loading", "circularProgressProps"]);
    if (loading) {
        return (_jsxs(MuiButton, Object.assign({ ref: ref }, rest, { classes: Object.assign(Object.assign({}, rest.classes), { startIcon: classes.hiddenContent, endIcon: classes.hiddenContent }), children: [_jsx("span", { className: classes.hiddenContent, children: props.children }), _jsx(CircularProgress, Object.assign({ style: { position: "absolute" }, size: "17px", color: "inherit" }, circularProgressProps))] })));
    }
    return _jsx(MuiButton, Object.assign({}, rest, { ref: ref }));
});
