import React from "react";
import { Alert, WarningOutlined } from "@lumar/shared";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 14,
  },
  standardWarning: {
    backgroundColor: theme.palette.yellow[50],
    color: theme.palette.navy[500],
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  message: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    padding: "7px 0px",
  },
  icon: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

export function TestSuiteListItemWarning(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Alert
      data-testid="clone-test-warning"
      severity="warning"
      classes={classes}
      iconMapping={{
        warning: (
          <WarningOutlined style={{ color: theme.palette.yellow[400] }} />
        ),
      }}
    >
      <strong>Sorry</strong>, we didn’t copy over any URL list files. Currently
      set to web crawl.
    </Alert>
  );
}
