import React from "react";
import { ExtendedBuildStatus } from "../../../_common/interfaces/BuildTestStatus";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { TestSuiteItemInfo } from "./TestSuiteItemInfo";
import { Grid, Box, Typography, Tooltip, makeStyles } from "@material-ui/core";
import { BuildStatus } from "../../../graphql";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import { TestSuiteItemActionsMenu } from "./TestSuiteItemActionsMenu";
import { shouldCloneWarningBeVisible } from "./TestSuiteListItemUtils";
import { dateTimeToHumanReadableFormat } from "../../../_common/utils/date/date";
import { Chip } from "@lumar/shared";
import { GreyButton } from "../../../_common/components/GreyButton/GreyButton";
import { generateEmailTextAndCopyToClipboard } from "../../../_common/components/CiCdForm/CiCdFormUtils";
import { TestSuiteListItemProps } from "./TestSuiteListItem";
import { TestResults } from "../../../_common/components/TestResults/TestResults";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: (props: TestSuiteListItemProps) => ({
    minHeight: 110,
    display: "flex",
    width: "100%",
    alignItems: "center",
    borderRadius: 8,
    "&:hover": {
      backgroundColor:
        props.status === BuildStatus.Running ? "white" : theme.palette.grey[50],
    },
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(1)}px 0px`,
    },
  }),
  grid: {
    maxWidth: `calc(100% - 118px)`,
  },
  centerAligned: { display: "flex", alignItems: "center" },
  lastRun: {
    fontWeight: 400,
    color: theme.palette.grey[600],
    fontSize: 14,
  },
  iconWrapper: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  statusWrapper: {
    [theme.breakpoints.only("sm")]: {
      justifyContent: "flex-end",
    },
  },
}));

export function TestSuiteListItemContent(
  props: TestSuiteListItemProps,
): JSX.Element {
  const classes = useStyles(props);

  const showCloneWarning = shouldCloneWarningBeVisible(
    props.testSuiteUpdatedAt,
    props.testSuiteClonedAt,
  );

  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;

  const isTestSuiteRunning =
    props.status === BuildStatus.Running || props.status === BuildStatus.Queued;

  const isTestSuiteFinished =
    props.status === ExtendedBuildStatus.Fail ||
    props.status === ExtendedBuildStatus.Pass ||
    props.status === ExtendedBuildStatus.Warning;

  function handleCopyToClipboard(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void {
    e.preventDefault();
    generateEmailTextAndCopyToClipboard(props.name, props.url, props.id);
  }

  return (
    <Box
      data-cy="test-suite-list-item"
      data-testid="test-suite-list-item-finished"
      className={classes.container}
    >
      <Box className={classes.iconWrapper}>
        <BuildStatusIcon
          status={props.status}
          size={StatusIconSize.LG}
          tooltipVariant={true}
        />
      </Box>
      <Grid container spacing={1} className={classes.grid}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ paddingRight: 48 }}
        >
          <TestSuiteItemInfo {...props} showCloneWarning={showCloneWarning} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          className={classes.centerAligned}
        >
          {isTestSuiteFinished ? (
            <TestResults
              id={props.id}
              buildId={props.buildId}
              totalTests={props.totalTests}
              passedTests={props.passedTests}
              warningTests={props.warningTests}
              failedTests={props.failedTests}
            />
          ) : isNotYetRun ? (
            <GreyButton
              onClick={handleCopyToClipboard}
              data-testid="copy-to-clipboard-button"
              data-pendo="auto-testsuiteslist-item-copy-to-clipboard"
            >
              Copy instructions
            </GreyButton>
          ) : null}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          className={clsx(classes.centerAligned, classes.statusWrapper)}
        >
          {isTestSuiteFinished ? (
            <Tooltip title="Last crawl date">
              <Typography
                className={classes.lastRun}
                data-cy="test-suite-list-item-last-run"
                data-testid="test-suite-list-item-last-run"
              >
                {dateTimeToHumanReadableFormat(props.lastRun)}
              </Typography>
            </Tooltip>
          ) : (
            <Chip
              data-testid="status-message"
              color={
                isTestSuiteRunning
                  ? "yellow"
                  : isNotYetRun
                  ? "lightgrey"
                  : "red"
              }
              label={
                isTestSuiteRunning
                  ? "Crawling the website"
                  : isNotYetRun
                  ? "Not yet run"
                  : "Cancelled"
              }
            />
          )}
        </Grid>
      </Grid>
      <Box paddingRight={3}>
        <TestSuiteItemActionsMenu
          testSuiteId={props.id}
          isTestSuiteRunning={isTestSuiteRunning}
          refetchData={props.refetchData}
          testSuiteJiraIntegrationId={props.testSuiteJiraIntegrationId}
        />
      </Box>
    </Box>
  );
}
