import { makeStyles } from "@material-ui/core";
import React from "react";
import { HidableBlock } from "../../../../../_common/components/HideableBlock/HideableBlock";
import { InstructionsList } from "../../../../../_common/components/InstructionsList/InstructionsList";

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 600,
  },
}));

export function SlackInstructions(): JSX.Element {
  const classes = useStyles();

  const instructions: JSX.Element[] = [
    <span key="instruction-step-1">
      In your Slack app{" "}
      <span className={classes.bold}>Create a new channel</span> to receive the
      notifications, unless you want to use an existing channel.
    </span>,
    <span key="instruction-step-2">
      Navigate to{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://slack.com/intl/en-gb/help/articles/115005265063-Incoming-webhooks-for-Slack"
        data-testid="incoming-webhooks-link"
      >
        Incoming Webhooks Slack page
      </a>
      .
    </span>,
    <span key="instruction-step-3">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://api.slack.com/apps?new_app=1"
        data-testid="slack-app-link"
      >
        Create a new Slack app{" "}
      </a>
      in the workspace where you want to post messages.
    </span>,
    <span key="instruction-step-4">
      From the Features page, toggle{" "}
      <span className={classes.bold}>Activate incoming webhooks</span> on.
    </span>,
    <span key="instruction-step-5">
      Click <span className={classes.bold}>Add new webhook to workspace</span>.
    </span>,
    <span key="instruction-step-6">
      Pick the channel where you want to receive the Lumar Protect
      notifications, then click <span className={classes.bold}>Authorise</span>.
    </span>,
    <span key="instruction-step-8">
      Copy the <span className={classes.bold}>Webhook URL</span> that was
      created.
    </span>,
    <span key="instruction-step-9">
      Go back to Lumar Protect and paste{" "}
      <span className={classes.bold}>Webhook URL</span> into the input field
      below, then click <span className={classes.bold}>Save</span>.
    </span>,
    <span key="instruction-step-10">
      Repeat steps 1-5 if you want to post alerts to additional Slack channels.
    </span>,
  ];

  return (
    <HidableBlock
      title="Instructions how to connect to Slack"
      descriptor="instructions"
      dataIdDescriptor="slack-webhooks"
    >
      <InstructionsList instructions={instructions} dataId="slack-webhooks" />
    </HidableBlock>
  );
}
