import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function CopyIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6C3.5 4.34315 4.84315 3 6.5 3H14.5C16.1569 3 17.5 4.34315 17.5 6V7H18.5C20.1569 7 21.5 8.34315 21.5 10V18C21.5 19.6569 20.1569 21 18.5 21H10.5C8.84315 21 7.5 19.6569 7.5 18V17H6.5C4.84315 17 3.5 15.6569 3.5 14V6ZM9.5 18C9.5 18.5523 9.94772 19 10.5 19H18.5C19.0523 19 19.5 18.5523 19.5 18V10C19.5 9.44772 19.0523 9 18.5 9H10.5C9.94772 9 9.5 9.44772 9.5 10V18ZM15.5 7H10.5C8.84315 7 7.5 8.34315 7.5 10V15H6.5C5.94772 15 5.5 14.5523 5.5 14V6C5.5 5.44772 5.94772 5 6.5 5H14.5C15.0523 5 15.5 5.44772 15.5 6V7Z"
      />
    </SvgIcon>
  );
}
