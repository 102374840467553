import React from "react";
import { useGetAccountsLazyQuery } from "../graphql";
export function useAdminAccountSearch() {
    const [fetch] = useGetAccountsLazyQuery();
    return React.useCallback((filter) => {
        return new Promise((resolve, reject) => {
            fetch({
                variables: { filter },
                onCompleted: (data) => resolve((data === null || data === void 0 ? void 0 : data.internalGetAccounts.edges.map((x) => ({
                    id: x.node.id,
                    name: x.node.name || undefined,
                }))) || []),
                onError: (error) => reject(error),
            });
        });
    }, [fetch]);
}
