import { BuildScheduleRepetitionRate } from "../../../../../graphql";
import { ScheduleFrequency } from "../scheduleSettingsValidationSchema";

export function isBuildScheduleRepetitionRate(
  frequency: ScheduleFrequency,
): frequency is BuildScheduleRepetitionRate {
  return Object.values(BuildScheduleRepetitionRate).includes(
    frequency as BuildScheduleRepetitionRate,
  );
}
