import { Theme } from "@material-ui/core";
import { StatusIconType } from "./StatusIcon";

export interface StatusIconColors {
  errorColor: string;
  warningColor: string;
  checkColor: string;
  naColor: string;
  alertColor: string;
  cancelColor: string;
  loadingColor: string;
}

export function getBorderMatchingColors(theme: Theme): StatusIconColors {
  const errorColor = theme.palette.red[600];
  const warningColor = theme.palette.yellow[500];
  const checkColor = theme.palette.green[500];
  const defaultGrey = theme.palette.grey[500];

  return {
    errorColor,
    warningColor,
    checkColor,
    naColor: defaultGrey,
    alertColor: defaultGrey,
    cancelColor: defaultGrey,
    loadingColor: defaultGrey,
  };
}

export function getColorForType(
  colors: {
    errorColor: string;
    warningColor: string;
    checkColor: string;
    naColor: string;
    alertColor: string;
    cancelColor: string;
    loadingColor: string;
  },
  type: StatusIconType,
): string {
  const map = [
    [StatusIconType.ALERT, colors.alertColor],
    [StatusIconType.ERROR, colors.errorColor],
    [StatusIconType.WARNING, colors.warningColor],
    [StatusIconType.CHECK, colors.checkColor],
    [StatusIconType.CANCELLED, colors.cancelColor],
    [StatusIconType.LOADING, colors.loadingColor],
    [StatusIconType.QUEUED, colors.cancelColor],
    [StatusIconType.ROUND_ERROR, colors.cancelColor],
    [StatusIconType.SCHEDULED, colors.cancelColor],
  ];
  const color = map.filter((item) => item[0] === type).map((item) => item[1]);
  return color.length ? color[0] : colors.naColor;
}
