import { CircularProgress, List } from "@material-ui/core";
import { Alert } from "@lumar/shared";
import React from "react";
import { JiraAuthenticationCreateListItem } from "./JiraAuthenticationCreateListItem";
import { JiraAuthenticationListItem } from "./JiraAuthenticationListItem";
import { useGetJiraAuthenticationsQuery } from "../../../../../graphql";

interface IJiraAuthenticationProps {
  accountId: string;
  createHandler: () => void;
  onSelect: (jiraAuthentication: {
    id: string;
    jiraUrl: string;
    name: string;
  }) => void;
}

export function JiraAuthenticationList({
  accountId,
  createHandler,
  onSelect,
}: IJiraAuthenticationProps): JSX.Element {
  const { data, loading, error } = useGetJiraAuthenticationsQuery({
    variables: { accountId },
    fetchPolicy: "cache-and-network",
  });

  return (
    <>
      {loading && (
        <CircularProgress
          color="secondary"
          data-testid="jira-authentication-list-loading"
        />
      )}

      {error && (
        <Alert
          severity="error"
          data-testid="jira-authentication-list-dialog-error"
        >
          There was an error connecting. Please refresh the page and try again.
        </Alert>
      )}

      {!loading && !error && (
        <List data-testid="jira-authentication-list">
          <JiraAuthenticationCreateListItem onClick={createHandler} />

          {data?.getAccount?.jiraAuthentications.nodes.map(
            ({ id, jiraUrl, name }) => (
              <JiraAuthenticationListItem
                onClick={() => onSelect({ id, jiraUrl, name })}
                key={id}
                id={id}
                jiraUrl={jiraUrl}
                name={name}
              />
            ),
          )}
        </List>
      )}
    </>
  );
}
