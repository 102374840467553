export function getAccountOptionsStyle(theme) {
    return {
        root: {
            borderRadius: "61px",
            height: "36px",
            backgroundColor: "white",
            borderWidth: "1.5px",
            borderStyle: "solid",
            borderColor: theme.palette.grey[300],
            boxShadow: theme.shadows[1],
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(0.6),
            paddingRight: theme.spacing(0.9),
        },
        accountLogo: {
            display: "flex",
            height: "20px",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1.2),
            alignItems: "center",
            filter: "grayscale(1)",
            mixBlendMode: "multiply",
        },
        accountIcon: {
            width: "22px",
            height: "22px",
            marginRight: theme.spacing(0.1),
            color: theme.palette.green[500],
        },
        expandIcon: {
            fontSize: "1.5em",
            color: theme.palette.grey[500],
        },
        menu: {
            width: "300px",
            marginTop: "12px",
            boxSizing: "border-box",
            paddingLeft: 0,
            paddingRight: 0,
        },
        menuList: {
            paddingBottom: 0,
        },
        menuContainer: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        accountInfo: {
            margin: theme.spacing(0.2, 1.2, 0, 1.9),
        },
        accountName: {
            color: theme.palette.grey[800],
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        accountId: {
            color: theme.palette.grey[500],
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(13),
            lineHeight: theme.typography.pxToRem(19.5),
            marginTop: theme.spacing(0.9),
            display: "block",
        },
        email: {
            color: theme.palette.grey[500],
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(13),
            lineHeight: theme.typography.pxToRem(19.5),
            marginBottom: theme.spacing(0.4),
            width: "250px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
        },
        role: {
            display: "inline-block",
            color: theme.palette.grey[500],
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(10),
            lineHeight: theme.typography.pxToRem(15),
            background: theme.palette.grey[200],
            borderRadius: "3px",
            padding: theme.spacing(0.1, 0.5, 0.1, 0.5),
            marginBottom: theme.spacing(1.6),
        },
        groupLabel: {
            color: theme.palette.grey[500],
            padding: theme.spacing(2, 1.25, 1, 1.25),
            display: "block",
        },
        menuItem: {
            color: theme.palette.grey[700],
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(14),
            "&:hover": {
                color: theme.palette.grey[700],
            },
            "&:active": {
                color: theme.palette.grey[700],
            },
        },
        checkedMenuItem: {
            background: theme.palette.primary.light,
        },
        menuIcon: {
            color: theme.palette.grey[600],
            marginRight: theme.spacing(0.7),
            width: "24px",
            height: "21px",
            display: "flex",
        },
        checkedMenuIcon: {
            color: theme.palette.primary.main,
            height: "20px",
        },
        menuContent: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "hidden",
        },
        menuName: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginRight: theme.spacing(1),
            flexShrink: 1,
            flexGrow: 0,
        },
        menuValue: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.grey[600],
            textOverflow: "ellipsis",
            overflow: "hidden",
            textAlign: "end",
            flexShrink: 0,
            flexGrow: 1,
            flexBasis: 0,
            minWidth: 0,
        },
        subMenuIcon: {
            color: theme.palette.grey[400],
            fontSize: theme.typography.pxToRem(20),
            marginLeft: "auto",
            marginRight: theme.spacing(-0.5),
        },
        backMenu: {
            color: theme.palette.grey[500],
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 600,
            marginTop: theme.spacing(1),
        },
        backIcon: {
            color: theme.palette.grey[600],
            fontSize: theme.typography.pxToRem(20),
            marginRight: theme.spacing(1),
        },
        groupDivider: {
            margin: theme.spacing(1.9, 1.2, 0.7, 1.2),
        },
        search: {
            margin: theme.spacing(0, 1.25, 1, 1.25),
        },
        searchIcon: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.grey[500],
            marginRight: theme.spacing(1),
        },
        noResultMessage: {
            margin: theme.spacing(1.375, 2.25),
            color: theme.palette.grey[400],
        },
        loadingIndicator: {
            boxSizing: "border-box",
            padding: theme.spacing(1, 2),
        },
    };
}
