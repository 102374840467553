import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { easeCubicInOut } from "d3-ease";
import { animated, useSpring } from "react-spring";
export function HorizontalSlider({ steps, step, }) {
    const [state, setState] = useState({
        step,
        activeSteps: [],
    });
    function setHeight(step, height) {
        setState((state) => {
            if (step !== state.step || height === state.height)
                return state;
            return Object.assign(Object.assign({}, state), { height });
        });
    }
    useEffect(() => {
        // eslint-disable-next-line fp/no-let
        let isMounted = true;
        function removeStep(step) {
            if (!isMounted)
                return;
            setState((state) => (Object.assign(Object.assign({}, state), { activeSteps: state.activeSteps.filter((x) => x.step !== step) })));
        }
        function setActive(step, steps) {
            if (!steps.length)
                return [{ step: step, state: "none" }];
            const lastStep = steps[steps.length - 1];
            const fromLeft = !lastStep || lastStep.step < step;
            return [
                Object.assign(Object.assign({}, lastStep), { state: fromLeft ? "slideOutLeft" : "slideOutRight", onClose: () => removeStep(lastStep.step) }),
                { step: step, state: fromLeft ? "slideInRight" : "slideInLeft" },
            ];
        }
        setState((state) => (Object.assign(Object.assign({}, state), { step, activeSteps: setActive(step, state.activeSteps) })));
        return () => {
            // eslint-disable-next-line fp/no-mutation
            isMounted = false;
        };
    }, [step]);
    const transitioning = state.activeSteps.length > 1;
    const container = useSpring({
        to: { height: transitioning ? state.height : "auto" },
        config: { duration: 300, easing: easeCubicInOut },
    });
    return (_jsx("div", { style: { position: "relative", overflow: "hidden" }, children: _jsx(animated.div, { style: container, children: state.activeSteps.map((x) => (_jsx(Step, { state: x.state, heightCallback: (height) => setHeight(x.step, height), onClose: x.onClose, transitioning: transitioning, children: steps[x.step] }, x.step))) }) }));
}
function Step({ children, state, heightCallback, onClose, transitioning, }) {
    function getStyle() {
        switch (state) {
            case "slideInLeft":
                return {
                    from: { left: "-100%", opacity: 0 },
                    to: { left: "0%", opacity: 1 },
                    config: { duration: 300, easing: easeCubicInOut },
                };
            case "slideInRight":
                return {
                    from: { left: "100%", opacity: 0 },
                    to: { left: "0%", opacity: 1 },
                    config: { duration: 300, easing: easeCubicInOut },
                };
            case "slideOutLeft":
                return {
                    from: { left: "0%", opacity: 1 },
                    to: { left: "-100%", opacity: 0 },
                    config: { duration: 300, easing: easeCubicInOut },
                    onResolve: onClose,
                };
            case "slideOutRight":
                return {
                    from: { left: "0%", opacity: 1 },
                    to: { left: "100%", opacity: 0 },
                    config: { duration: 300, easing: easeCubicInOut },
                    onResolve: onClose,
                };
            default:
                return {};
        }
    }
    const container = useSpring(getStyle());
    return (_jsx(animated.div, { style: Object.assign({ width: "100%", position: transitioning ? "absolute" : "initial" }, container), ref: (target) => (target === null || target === void 0 ? void 0 : target.scrollHeight) && (heightCallback === null || heightCallback === void 0 ? void 0 : heightCallback(target.scrollHeight)), children: children }));
}
