import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { InternalAuthContext, InternalAuthProvider, } from "./InternalAuthContext";
import { useAuthConfig } from "./useAuthConfig";
import { useTranslation } from "../i18n";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { LoadingScreen, EmptyState } from "../components";
import { XCircleOutlined } from "../icons";
import { useBrand } from "../brand/BrandProvider";
export const AuthProvider = ({ app, children, }) => {
    var _a, _b, _c, _d;
    const { loading, data, error } = useAuthConfig();
    const { i18n } = useTranslation();
    const history = useHistory();
    const brand = useBrand();
    if (error) {
        return (_jsx(Box, { height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", children: _jsx(EmptyState, { title: "Something went wrong", description: "Please try to reload the page", icon: _jsx(XCircleOutlined, { fontSize: "large" }) }) }));
    }
    if (loading || !data) {
        return _jsx(LoadingScreen, {});
    }
    const { domain, audience, clients } = data;
    const onRedirectCallback = (appState) => {
        history.replace((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    };
    const overridenClientApp = (_a = brand.auth0ClientsOverrides) === null || _a === void 0 ? void 0 : _a[app];
    const clientId = overridenClientApp
        ? clients[overridenClientApp]
        : clients[app];
    return (_jsx(Auth0Provider, { domain: domain, clientId: clientId, onRedirectCallback: onRedirectCallback, authorizationParams: {
            redirect_uri: window.location.origin,
            audience: audience,
            ui_locales: (_b = i18n.language) !== null && _b !== void 0 ? _b : "en",
            connection: (_d = (_c = new URLSearchParams(history.location.search).get("connection")) !== null && _c !== void 0 ? _c : brand.connectionId) !== null && _d !== void 0 ? _d : undefined,
        }, children: _jsx(InternalAuthProvider, { domain: domain, clientId: clientId, children: children }) }));
};
export const useAuth = () => {
    const context = useContext(InternalAuthContext);
    if (!context) {
        throw Error("'InternalAuthContext' not found in the tree. Make sure 'AuthProvider' is in your component tree.");
    }
    return {
        /**
         * Returns if the user is authenticated with any method (share token, cookie or Auth0)
         * @author Alex Sánchez
         */
        isAuthenticated: context.isAuthenticated,
        /**
         * Obtain the current active token (by priority: share token, cookie, Auth0)
         * @author Alex Sánchez
         */
        getToken: context.getToken,
        /**
         * @author Alex Sánchez
         * @param {string} options.cookieToken - Set this token on a cookie for authentication
         */
        login: context.login,
        /**
         * @author Alex Sánchez
         * @param {string} options.onBeforeLogout - Callback to execute just before logout logic
         */
        logout: context.logout,
        /**
         * @author Alex Sánchez
         * @param {string} email - Email to send the reset password email
         */
        resetPassword: context.resetPassword,
        /**
         * @author Alex Sánchez
         * @returns {string} clientId - The Auth0 client id
         */
        clientId: context.clientId,
        /**
         * @author Alex Sánchez
         * @returns {User} auth0User - The Auth0 user object
         */
        auth0User: context.auth0User,
    };
};
