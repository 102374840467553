export function getIdFromEncodedString(
  encodedString: string,
  idType: "Account" | "User",
): string {
  try {
    const decodedString = atob(encodedString);
    const arr = decodedString.split(idType);
    return arr[1] || "";
  } catch {
    return "";
  }
}
