import React from "react";
import { insertIf } from "../../../helpers/insertIf";
import { useFeedbackToggle } from "../../../helpers/tags/useFeedbackToggle";
import { useHelpToggle } from "../../../helpers/tags/useHelpToggle";
import { useTranslation } from "../../../i18n";
import { EmojiHappyCircle } from "../../../icons/lumar/EmojiHappyCircle";
import { QuestionCircle } from "../../../icons/lumar/QuestionCircle";
import { ProtectRoutes } from "../../../routes/protectRoutes";
import { useSession } from "../../../session";
import { AppIds } from "../../../session/SessionContext.types";
import { getAnalyzeAppSwitcherConfig } from "./configs/getAnalyzeAppSwitcherConfig";
import { getImpactAppSwitcherConfig } from "./configs/getImpactAppSwitcherConfig";
import { getMonitorAppSwitcherConfig } from "./configs/getMonitorAppSwitcherConfig";
import { getProtectAppSwitcherConfig } from "./configs/getProtectAppSwitcherConfig";
import { useBrand } from "../../../brand/BrandProvider";
// FIXME: Refactor
// FIXME: Use brand domains everywhere
export function useAppSwitcherConfig(app) {
    const session = useSession();
    const brand = useBrand();
    const { t } = useTranslation("appSwitcher");
    const toggleHelp = useHelpToggle();
    const toggleFeedback = useFeedbackToggle();
    // FIXME: This can be cleaned up and made easier to reason with.
    return React.useMemo(() => ({
        appSwitcherBrandLogoLink: brand.brandLogoLink,
        appSwitcherItems: [
            ...insertIf(brand.featureAvailability.analyzeApp &&
                (!session.account.disableUpselling ||
                    session.hasApp(AppIds.analyze) ||
                    session.isDeepCrawlAdmin), getAnalyzeAppSwitcherConfig({
                domain: brand.appsURLs.analyze,
                isMain: app === AppIds.analyze,
                name: t("analyze.name"),
                session,
                labels: {
                    allProjects: t("analyze.allProjects"),
                    runningCrawls: t("analyze.runningCrawls"),
                    scheduled: t("analyze.scheduled"),
                    testSuites: t("analyze.testSuites"),
                    accountTasks: t("analyze.accountTasks"),
                },
            })),
            ...insertIf(brand.featureAvailability.protectApp &&
                (!session.account.disableUpselling ||
                    session.hasApp(AppIds.protect) ||
                    session.isDeepCrawlAdmin), getProtectAppSwitcherConfig({
                isMain: app === AppIds.protect,
                name: t("protect.name"),
                session,
                labels: {
                    dashboard: t("protect.dashboard"),
                    testSuites: t("protect.testSuites"),
                    scheduler: t("protect.scheduler"),
                    connectedApps: t("protect.connectedApps"),
                },
            })),
            ...insertIf(brand.featureAvailability.monitorApp &&
                (!session.account.disableUpselling ||
                    session.hasApp(AppIds.monitor) ||
                    session.isDeepCrawlAdmin), getMonitorAppSwitcherConfig({
                isMain: app === AppIds.monitor,
                name: t("monitor.name"),
                session,
                labels: {
                    boards: t("monitor.boards"),
                    notifications: t("monitor.notifications"),
                },
            })),
            ...insertIf(brand.featureAvailability.impactApp &&
                (!session.account.disableUpselling ||
                    session.hasApp(AppIds.impact) ||
                    session.isDeepCrawlAdmin), getImpactAppSwitcherConfig({
                isMain: app === AppIds.impact,
                name: t("impact.name"),
                session,
                labels: { allProjects: t("impact.allProjects") },
            })),
        ],
        appSwitcherActions: brand.featureAvailability.appSwitcherActions
            ? [
                {
                    id: "feedback",
                    name: t("actions.feedback"),
                    icon: EmojiHappyCircle,
                    onClick: toggleFeedback,
                    testId: "feedback-button",
                },
                ...insertIf(app === AppIds.protect, {
                    id: "help",
                    name: t("actions.help"),
                    icon: QuestionCircle,
                    href: ProtectRoutes.GettingStarted.getUrl({
                        accountId: session.account.id,
                    }),
                    testId: "help-page-nav-link",
                }),
                ...insertIf(app !== AppIds.protect, {
                    id: "help",
                    name: t("actions.help"),
                    icon: QuestionCircle,
                    onClick: toggleHelp,
                    testId: "help-button",
                }),
            ]
            : [],
    }), [
        brand.brandLogoLink,
        brand.featureAvailability.analyzeApp,
        brand.featureAvailability.protectApp,
        brand.featureAvailability.monitorApp,
        brand.featureAvailability.impactApp,
        brand.featureAvailability.appSwitcherActions,
        brand.appsURLs.analyze,
        session,
        app,
        t,
        toggleFeedback,
        toggleHelp,
    ]);
}
