import { withStyles, Button } from "@material-ui/core";

export const GreyButton = withStyles((theme) => ({
  root: {
    background: theme.palette.grey[200],
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    fontWeight: 500,
    height: 28,
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
}))(Button);
