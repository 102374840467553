import React from "react";
import { makeStyles, useTheme, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BuildStatusIcon } from "../../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import { ColouredLeftBorder } from "../../../../_common/components/ColoredLeftBorder/ColouredLeftBorder";
import { StatusIconSize } from "../../../../_common/components/StatusIcon/StatusIcon";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import {
  BuildTestStatus,
  ExtendedBuildStatus,
} from "../../../../_common/interfaces/BuildTestStatus";
import { Routes } from "../../../../_common/routes/routes";
import { doesUserMatchRole } from "../../../../_common/utils/doesUserMatchRole/doesUserMatchRole";
import { TestsToDisplayOption } from "../../../TestSuiteReport/components/TestSuiteReportListUtils";
import { getMatchingBorderColorForStatus } from "../../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { TestSuiteMenu } from "./TestSuiteMenu";
import { BuildStatus, RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  showAsNotLink: (props: { status: BuildTestStatus }) => {
    const shouldBeDisabled =
      props.status === BuildStatus.Running ||
      props.status === ExtendedBuildStatus.NotYetRun;
    return {
      display: "flex",
      minHeight: 80,
      padding: "8px 24px 8px 0px",
      boxSizing: "border-box",
      textDecoration: "none",
      borderRadius: 8,
      "&:hover": {
        cursor: shouldBeDisabled ? "default" : "pointer",
        backgroundColor: shouldBeDisabled
          ? "transparent"
          : theme.palette.grey[50],
      },
    };
  },
}));

export function TestSuiteListItemWrapper(props: {
  id: string;
  buildId: string | null;
  status: BuildTestStatus;
  onActionMenuEvent: () => Promise<boolean>;
  nextRunAt: Date | null;
  children: React.ReactNode;
}) {
  const classes = useStyles({ status: props.status });
  const theme = useTheme();

  const accountId = useAccountRouteMatch();
  const history = useHistory();

  const isTestSuiteRunning = props.status === BuildStatus.Running;
  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;
  const isTestSuiteScheduled = isNotYetRun && Boolean(props.nextRunAt);

  const isViewOnly = doesUserMatchRole(RoleCode.Viewer);

  function handleLinkClick(
    event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) {
    if (
      event.isDefaultPrevented() ||
      isTestSuiteRunning ||
      isTestSuiteScheduled
    )
      return;
    else {
      history.push(
        Routes.TestResults.getUrl({
          accountId,
          testSuiteId: props.id,
          buildTestId: props.buildId || "",
          selectedType: TestsToDisplayOption.ALL,
        }),
      );
    }
  }

  return (
    <ColouredLeftBorder
      color={getMatchingBorderColorForStatus(props.status, theme)}
      rootBoxProps={{ style: { marginBottom: 12 } }}
    >
      <div
        onClick={handleLinkClick}
        className={classes.showAsNotLink}
        data-testid="scheduler-test-suite-list-item-started"
        data-cy="scheduler-test-suite-list-item"
        data-pendo={`auto-scheduler-testsuites-list-item-${props.status
          .replace(/\s+/g, "-")
          .toLowerCase()}`}
      >
        <Box display="flex" alignItems="center" paddingTop="4px" paddingX={3}>
          <BuildStatusIcon
            status={
              isTestSuiteScheduled
                ? ExtendedBuildStatus.Scheduled
                : props.status
            }
            size={StatusIconSize.LG}
            tooltipVariant={true}
          />
        </Box>

        {props.children}

        <Box display="flex" alignItems="center" marginLeft={1}>
          {!isViewOnly ? (
            <TestSuiteMenu onActionMenuEvent={props.onActionMenuEvent} />
          ) : null}
        </Box>
      </div>
    </ColouredLeftBorder>
  );
}
