import React from "react";
import { useAccountRouteMatch } from "../useAccountRouteMatch/useAccountRouteMatch";
import {
  AppIds,
  SessionAccount,
  SessionAccountUser,
  useSession,
} from "@lumar/shared";
import { RoleCode } from "../../../graphql";

interface AutomatorSession {
  account: SessionAccount;
  allRelationships: SessionAccountUser[];
  hasApp: (type: AppIds) => boolean;
  role: {
    code: RoleCode;
    name: string;
  };
  id: string;
}

export function useAutomatorSession(): AutomatorSession {
  const session = useSession();
  const accountId = useAccountRouteMatch();

  const automatorRelationships = React.useMemo(() => {
    return session.allRelationships.filter(
      ({ account }) => account.availableApps.automateAvailable,
    );
  }, [session]);

  const accountUser = React.useMemo(() => {
    return (
      automatorRelationships.find(({ account }) => account.id === accountId) ??
      automatorRelationships[0]
    );
  }, [automatorRelationships, accountId]);

  const automatorSession = {
    account: accountUser?.account,
    allRelationships: automatorRelationships,
    hasApp: session.hasApp,
    role: session.role,
    id: session.id,
  };

  return automatorSession;
}
