import React from "react";
import { makeStyles, Typography, Box, TextField } from "@material-ui/core";
import { Alert } from "@lumar/shared";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: theme.spacing(10),
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "1rem",
    marginRight: theme.spacing(1),
  },
  buttonWrapper: (props: IntegrationNameSetup) => {
    return {
      marginTop: props.integrationName.length > 50 ? "30px" : theme.spacing(7),
    };
  },
  error: {
    color: theme.palette.error.main,
  },
  alert: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.ultraviolet[50],
    color: theme.palette.navy[500],
  },
}));

interface IntegrationNameSetup {
  integrationName: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

export function IntegrationNameSetup(props: IntegrationNameSetup): JSX.Element {
  const isNameTooLong = props.integrationName.length > 50;
  const classes = useStyles(props);
  const { integrationName, onChange, onSubmit } = props;

  return (
    <div data-testid="integration-name-setup" data-cy="integration-name-setup">
      <Typography
        variant="h1"
        className={classes.title}
        data-testid="integration-name-title"
      >
        Jira Integration
      </Typography>

      <Box display="flex" alignItems="center" mb={3}>
        <Typography
          variant="h2"
          className={classes.subtitle}
          data-testid="integration-name-subtitle"
        >
          Integration name
        </Typography>

        <InfoTooltip
          data-pendo="auto-tooltip-integration-name-setup"
          title={
            <>
              <b>Your Jira integration name</b>
              <p>
                You should name your integration with a unique identifier based
                on the configuration settings you make if you plan to have
                multiple Jira integrations for a single Lumar Protect account.
              </p>
            </>
          }
        />
      </Box>

      <StyledInputLabel htmlFor="integration-name-input">
        Integration name
      </StyledInputLabel>
      <TextField
        id="integration-name-input"
        variant="outlined"
        placeholder="e.g. My jira integration"
        fullWidth
        value={integrationName}
        onChange={onChange}
        data-testid="integration-name-input"
        data-pendo="auto-add-jira-integration-name-input"
      />

      {isNameTooLong && (
        <Typography
          data-testid="validation-error"
          variant="caption"
          className={classes.error}
        >
          Integration name cannot be more than 50 characters.
        </Typography>
      )}

      <Alert
        classes={{ root: classes.alert }}
        severity="info"
        data-testid="integration-name-msg"
      >
        Please use a unique integration name if you have multiple Jira
        integrations.
      </Alert>

      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.buttonWrapper}
      >
        <PrimaryActionButton
          data-pendo="auto-testsuite-integration-name-continue"
          dataTestId="integration-name-button"
          dataCy="integration-name-button"
          disabled={!integrationName || isNameTooLong}
          onClick={onSubmit}
          label="Continue"
        />
      </Box>
    </div>
  );
}
