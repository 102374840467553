import React, { useState } from "react";
// Note: disabling because there is no alternative import path
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Box, Grid, makeStyles, MenuItem, Select } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { generateTimeOptions } from "./utils/generateTimeOptions";
import { generateSchedulerFrequencyOptions } from "./utils/generateSchedulerFrequencyOptions";
import { Field, FormikErrors } from "formik";
import {
  FormHelperTextStyled,
  FormHelperTextStyledType,
} from "../../../../_common/components/FormHelperTextStyled/FormHelperTextStyled";
import { getStartDateWithMinutes } from "./utils/getStartDateWithMinutes";
import {
  getFirstAvailableTimeOption,
  getSelectedTimeOption,
} from "./utils/getAvailableTimeOption";
import { ScheduleFrequency } from "./scheduleSettingsValidationSchema";
import { getMenuProps } from "../../../../_common/visualOverrides/selectVisualOverrides";
import { StyledInputLabel } from "../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { DatePicker } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    fontWeight: 300,
  },
  alertWrapper: {
    height: theme.spacing(4),
  },
  menuPaper: {
    maxHeight: 240,
  },
  alertIcon: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

interface ScheduleTimeProps {
  errors: FormikErrors<{
    date: Date;
    minutes: number;
    frequency: ScheduleFrequency;
  }>;
  handleChange: {
    (e: React.ChangeEvent): void;
    <T = string | React.ChangeEvent>(field: T): T extends React.ChangeEvent
      ? void
      : (e: string | React.ChangeEvent) => void;
  };
  initialDate: Date;
  initialFrequency: ScheduleFrequency;
  minutesIntervalSkip: number;
}

// eslint-disable-next-line max-lines-per-function
export function ScheduleTime(props: ScheduleTimeProps) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    props.initialDate,
  );
  const timeOptions = generateTimeOptions(
    props.minutesIntervalSkip,
    selectedDate,
  );
  const closestMinutesToStart = selectedDate
    ? getSelectedTimeOption(timeOptions, selectedDate)
    : getFirstAvailableTimeOption(timeOptions);
  const [selectedMinutes, setSelectedMinutes] = useState<number | undefined>(
    closestMinutesToStart?.minutes,
  );
  const frequencyOptions = generateSchedulerFrequencyOptions(selectedDate);
  const [selectedFrequency, setSelectedFrequency] = useState<
    ScheduleFrequency | unknown
  >(props.initialFrequency);

  function handlePropDateChange(newDate: Date | null) {
    props.handleChange({
      target: { value: newDate, name: "date" },
      type: "date",
    });
  }

  function handleDateChange(date: MaterialUiPickersDate) {
    const newDate = date
      ? getStartDateWithMinutes(date, selectedMinutes)
      : null;
    setSelectedDate(newDate);
    handlePropDateChange(newDate);
  }

  function handleTimeChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) {
    setSelectedMinutes(event.target.value as number);
    props.handleChange(event);
    const newDate = selectedDate
      ? getStartDateWithMinutes(selectedDate, event?.target?.value as number)
      : null;
    setSelectedDate(newDate);
    handlePropDateChange(newDate);
  }

  function handleFrequencyChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) {
    setSelectedFrequency(event.target.value);
    props.handleChange({
      target: { value: event.target.value, name: "frequency" },
      type: "string",
    });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={5} xs={12}>
          <StyledInputLabel htmlFor="scheduler-date-picker">
            Date
          </StyledInputLabel>
          <Field
            component={DatePicker}
            autoOk
            name="date"
            id="scheduler-date-picker"
            disableToolbar
            variant="inline"
            disablePast
            format="MMM d, yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            data-testid="schedule-date-picker"
            data-pendo="auto-scheduler-edit-date"
            aria-describedby="select-schedule-date"
            error={!!props.errors.date}
            helperText={null}
            fullWidth
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <StyledInputLabel htmlFor="select-schedule-time">
            Time
          </StyledInputLabel>
          <Select
            fullWidth
            name="minutes"
            variant="outlined"
            IconComponent={ExpandMoreRoundedIcon}
            labelId="select-schedule-time"
            id="select-schedule-time"
            data-testid="select-schedule-time"
            data-pendo="auto-scheduler-edit-time"
            aria-describedby="select-schedule-time"
            value={selectedMinutes}
            onChange={handleTimeChange}
            error={!!props.errors.minutes || !!props.errors.date}
            MenuProps={getMenuProps({ paper: classes.menuPaper })}
          >
            {timeOptions.map((time) => {
              return (
                <MenuItem
                  value={time.minutes}
                  key={`time-${time.minutes}`}
                  disabled={time.isDisabled}
                  data-testid="select-schedule-time-option"
                >
                  {time.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item sm={4} xs={12}>
          <StyledInputLabel htmlFor="select-schedule-frequency">
            Repeat
          </StyledInputLabel>
          <Select
            fullWidth
            name="frequency"
            variant="outlined"
            IconComponent={ExpandMoreRoundedIcon}
            labelId="select-schedule-frequency"
            id="select-schedule-frequency"
            data-testid="select-schedule-frequency"
            data-pendo="auto-scheduler-edit-frequency"
            value={selectedFrequency}
            onChange={handleFrequencyChange}
            error={!!props.errors.frequency}
            aria-describedby="select-schedule-frequency"
            MenuProps={getMenuProps()}
          >
            {frequencyOptions.map((option) => {
              return (
                <MenuItem
                  value={option.value}
                  key={option.value}
                  data-testid="select-schedule-frequency-option"
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      <Box>
        <FormHelperTextStyled
          testId="select-schedule-date-error"
          id="select-schedule-date"
          text={props.errors?.date}
          show={!!props.errors.date}
          type={FormHelperTextStyledType.ERROR}
        />
        <FormHelperTextStyled
          id="select-schedule-time"
          testId="select-schedule-time-error"
          text={props.errors?.minutes}
          show={!!props.errors.minutes}
          type={FormHelperTextStyledType.ERROR}
        />
        <FormHelperTextStyled
          id="select-schedule-frequency"
          testId="select-schedule-frequency-error"
          text={props.errors?.frequency}
          show={!!props.errors.frequency}
          type={FormHelperTextStyledType.ERROR}
        />
      </Box>
    </>
  );
}
