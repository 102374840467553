import { GetTestSuitesQuery } from "../../../graphql";

export function getTestSuiteInfo(
  testSuiteEdge: NonNullable<
    NonNullable<GetTestSuitesQuery["getAccount"]>["testSuites"]
  >["edges"][0],
) {
  const buildStatus = testSuiteEdge.node.builds.nodes[0]?.status ?? "NotYetRun";
  return {
    id: testSuiteEdge.node.id,
    name: testSuiteEdge.node.name,
    primaryDomain: testSuiteEdge.node.primaryDomain,
    status: buildStatus,
    createdAt: testSuiteEdge.node.createdAt,
  };
}
