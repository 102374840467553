import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { SnackbarProvider as NotistackSnackbarProvider, } from "notistack";
import { useTheme } from "@material-ui/core";
export function SnackbarProvider(props) {
    var _a;
    const theme = useTheme();
    const themedProps = Object.assign(Object.assign({ maxSnack: 3, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, Components: {
            default: SnackbarWrapper,
        } }, (_a = theme.props) === null || _a === void 0 ? void 0 : _a.SnackbarProvider), props);
    return _jsx(NotistackSnackbarProvider, Object.assign({}, themedProps));
}
export const SnackbarWrapper = React.forwardRef(function SnackbarWrapper(props, ref) {
    return (_jsx("div", { ref: ref, children: React.isValidElement(props.message) &&
            React.cloneElement(props.message, {
                id: props.id,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) }));
});
