import { useEffect, useState } from "react";
import merge from "deepmerge";
import { defaultMergeOptions } from "../../../_common/utils/mergePageInfo/mergePageInfo";
import { getPreviousResultAsLast } from "./useTestSuiteQueryUtils";
import { shouldRefetchData } from "../../../_common/utils/testSuiteRefetchEvaluation/testSuiteRefetchEvaluation";
import { ApolloError } from "@lumar/shared";
import {
  GetTestSuitesQuery,
  useGetTestSuitesQuery as generated_useGetTestSuitesQuery,
} from "../../../graphql";

export function useTestSuiteQuery(
  accountId: string,
  perRequest: number,
): {
  loading: boolean;
  data: GetTestSuitesQuery | undefined;
  error: ApolloError | undefined;
  lastDataRetirevalAttemptTimestamp: number | undefined;
} {
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [gatheredData, setGatheredData] = useState<
    GetTestSuitesQuery | undefined
  >();
  const [oldData, setOldData] = useState<GetTestSuitesQuery>();
  const [msOfLastRefetch, setMsOfLastRefetch] = useState<number>(
    new Date().getTime(),
  );
  const { data, loading, fetchMore, error, refetch } =
    generated_useGetTestSuitesQuery({
      variables: {
        perRequest,
        accountId,
        cursor: "",
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    setGatheredData(data);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !loading &&
        !isFetchingMore &&
        shouldRefetchData(msOfLastRefetch, oldData || gatheredData)
      ) {
        const msOfDateNow = new Date().getTime();
        setMsOfLastRefetch(msOfDateNow);
        setOldData(gatheredData);
        setGatheredData(undefined);
        refetch().then((data) => {
          setGatheredData(data.data);
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMore, loading, gatheredData, msOfLastRefetch, oldData]);

  async function evaluateMoreFetch() {
    const shouldFetchMoreData =
      !loading &&
      !isFetchingMore &&
      gatheredData?.getAccount?.testSuites?.pageInfo.hasNextPage;

    if (shouldFetchMoreData) {
      setIsFetchingMore(true);
      fetchMore({
        variables: {
          cursor:
            gatheredData?.getAccount?.testSuites?.pageInfo.endCursor || "",
        },
        updateQuery: (
          previousResult: GetTestSuitesQuery,
          { fetchMoreResult },
        ) => {
          const newData = fetchMoreResult
            ? (merge(
                previousResult,
                fetchMoreResult,
                defaultMergeOptions,
              ) as GetTestSuitesQuery)
            : getPreviousResultAsLast(previousResult);

          setGatheredData(newData);
          return newData;
        },
      }).finally(() => {
        setIsFetchingMore(false);
      });
    }
  }
  evaluateMoreFetch();

  const gatheredDataLoading = gatheredData
    ? !!gatheredData?.getAccount?.testSuites?.pageInfo.hasNextPage
    : true;

  const isLoading = oldData ? false : gatheredDataLoading;

  return {
    loading: isLoading,
    error,
    data: gatheredDataLoading ? oldData : gatheredData,
    lastDataRetirevalAttemptTimestamp: msOfLastRefetch,
  };
}
