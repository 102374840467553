import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export function PendoStyleOverwrite() {
    React.useLayoutEffect(() => {
        const style = document.createElement("style");
        // eslint-disable-next-line fp/no-mutation
        style.innerHTML = getPendoStyleOverwrite();
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);
    return _jsx(_Fragment, {});
}
function getPendoStyleOverwrite() {
    return `
    #pendo-resource-center-container {
      left: 80px !important;
    }
  `;
}
