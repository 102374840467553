import React from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Box,
  CircularProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExclamationCircleSolid, ExclamationSolid } from "@lumar/shared";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "130px",
    minHeight: "68px",
  },
  informationWrapper: {
    padding: theme.spacing(1.875, 2),
  },
  informationWrapperWithoutIcon: {
    padding: theme.spacing(1.875, 2),
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: "14px",
    marginBottom: theme.spacing(1.5),
    fontWeight: 500,
    color: theme.palette.grey[500],
  },
  value: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: "16px",
    color: theme.palette.grey[900],
  },
  checkIcon: {
    color: theme.palette.green[500],
  },
  warningIcon: {
    color: theme.palette.yellow[500],
  },
  errorIcon: {
    color: theme.palette.red[600],
  },
  tooltip: {
    maxWidth: "150px",
  },
}));

export enum InformationBoxIcon {
  WARNING = "warning",
  ERROR = "error",
  CHECK = "check",
}

export interface InformationBoxProps {
  label: string;
  value: string | undefined;
  icon?: InformationBoxIcon;
  isLoadingValue?: boolean;
  testId?: string;
  tooltip: string;
  pendoId: string;
}

export function InformationBox(props: InformationBoxProps): JSX.Element {
  const classes = useStyles();

  function getIconStyles(): string {
    switch (props.icon) {
      case InformationBoxIcon.CHECK:
        return classes.checkIcon;
      case InformationBoxIcon.ERROR:
        return classes.errorIcon;
      case InformationBoxIcon.WARNING:
        return classes.warningIcon;
      default:
        return "";
    }
  }

  function getClassForInformationWrapper(): string {
    return props.icon
      ? classes.informationWrapper
      : classes.informationWrapperWithoutIcon;
  }

  function renderIcon(): JSX.Element {
    const cssClass = getIconStyles();
    switch (props.icon) {
      case InformationBoxIcon.CHECK:
        return (
          <CheckCircle
            data-testid={`information-box-icon-check`}
            data-cy={`information-box-icon-check`}
            className={cssClass}
            style={{ marginTop: -4 }}
          />
        );
      case InformationBoxIcon.ERROR:
        return (
          <ExclamationCircleSolid
            className={cssClass}
            data-testid={`information-box-icon-error`}
            data-cy={`information-box-icon-error`}
            style={{ marginTop: -4 }}
          />
        );
      case InformationBoxIcon.WARNING:
        return (
          <ExclamationSolid
            className={cssClass}
            data-testid={`information-box-icon-warning`}
            data-cy={`information-box-icon-warning`}
            style={{ marginTop: -4 }}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <Tooltip arrow placement="top" title={props.tooltip}>
      <Paper
        className={classes.paper}
        data-testid={props.testId ? props.testId : "information-box"}
        data-cy={props.testId ? props.testId : "information-box"}
        data-pendo={`auto-dashboard-seo-health-widget-${props.pendoId}`}
      >
        <Grid container className={getClassForInformationWrapper()}>
          <Grid item container direction="column" xs={props.icon ? 8 : 12}>
            <Grid item data-testid="information-box-label">
              <Typography
                className={classes.label}
                data-cy="information-box-label"
              >
                {props.label}
              </Typography>
            </Grid>
            <Grid item data-testid="information-box-value">
              {props.isLoadingValue ? (
                <CircularProgress size={14} />
              ) : (
                <Typography
                  className={classes.value}
                  data-cy="information-box-value"
                >
                  {props.value?.toLocaleString()}
                </Typography>
              )}
            </Grid>
          </Grid>
          {props.icon && (
            <Grid item xs={4} container justifyContent="flex-end">
              <Box>{renderIcon()}</Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Tooltip>
  );
}
