import { Box, Grid, Hidden } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";

export function UpdateTestSuiteLoadingView() {
  return (
    <Box data-cy="test-suite-loader" data-testid="test-suite-loader">
      <PageContainer>
        <Skeleton height={35} style={{ marginBottom: 24 }} width={270} />
        <Grid container spacing={3}>
          <Hidden lgUp>
            <Grid item xs={12}>
              <Skeleton
                variant="rect"
                height={70}
                style={{ borderRadius: 6 }}
              />
            </Grid>
          </Hidden>

          <Grid item lg={9} md={12} xs={12}>
            <Skeleton variant="rect" height={750} style={{ borderRadius: 6 }} />
          </Grid>

          <Hidden mdDown>
            <Grid item xs={3}>
              <Skeleton
                variant="rect"
                height={330}
                style={{ borderRadius: 6 }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </PageContainer>
    </Box>
  );
}
