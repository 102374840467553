var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs as MuiBreadcrumbs, Typography, withStyles, } from "@material-ui/core";
import clsx from "clsx";
import { HomeSolid } from "../../icons/ui-friendly/solid/HomeSolid";
import { ChevronRightSolid } from "../../icons";
function BreadcrumbsInner(_a) {
    var { breadcrumbItems, homeIcon: CustomIcon, className, classes, itemProps } = _a, props = __rest(_a, ["breadcrumbItems", "homeIcon", "className", "classes", "itemProps"]);
    const icon = CustomIcon ? (_jsx(CustomIcon, { className: classes === null || classes === void 0 ? void 0 : classes.homeIcon })) : (_jsx(HomeSolid, { className: classes === null || classes === void 0 ? void 0 : classes.homeIcon }));
    return (_jsx(MuiBreadcrumbs, Object.assign({ separator: _jsx(ChevronRightSolid, {}), className: className, classes: {
            root: classes === null || classes === void 0 ? void 0 : classes.breadcrumbs,
            ol: classes === null || classes === void 0 ? void 0 : classes.ol,
            li: classes === null || classes === void 0 ? void 0 : classes.li,
            separator: classes === null || classes === void 0 ? void 0 : classes.separator,
        } }, props, { children: breadcrumbItems.map((_a, idx) => {
            var { label, link, icon: BreadcrumbIcon } = _a, breadcrumbProps = __rest(_a, ["label", "link", "icon"]);
            const isFirst = idx === 0;
            const isLast = idx === breadcrumbItems.length - 1 && !isFirst;
            const linkProps = link && !isLast
                ? {
                    component: Link,
                    to: link,
                }
                : {};
            return (_jsx(Box, Object.assign({ "data-pendo": "home-button", className: clsx(classes === null || classes === void 0 ? void 0 : classes.container, !isLast ? classes === null || classes === void 0 ? void 0 : classes.link : "") }, linkProps, itemProps, breadcrumbProps, { children: isFirst ? (icon) : (_jsxs(_Fragment, { children: [_jsx(Typography, { className: classes === null || classes === void 0 ? void 0 : classes.label, children: label }), BreadcrumbIcon && (_jsx(BreadcrumbIcon, { className: classes === null || classes === void 0 ? void 0 : classes.breadcrumbIcon }))] })) }), idx));
        }) })));
}
export const Breadcrumbs = withStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
    },
    link: {
        background: theme.palette.grey[200],
        borderRadius: 6,
        padding: theme.spacing(0.5, 0.75, 0.5, 0.75),
        textDecoration: "none",
        "&:hover": {
            background: theme.palette.grey[300],
        },
        "&:focus": {
            background: theme.palette.grey[300],
        },
    },
    homeIcon: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.grey[500],
    },
    label: {
        color: theme.palette.grey[800],
        fontSize: theme.typography.pxToRem(13),
        padding: theme.spacing(0, 0.25, 0, 0.5),
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: 300,
    },
    separator: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    ol: {
        flexWrap: "nowrap",
    },
    li: {
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    breadcrumbs: {
        "& li:first-child": {
            minWidth: "30px",
        },
        "& li:last-child": {
            marginRight: "5px",
        },
    },
    breadcrumbIcon: {
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.grey[500],
        marginLeft: theme.spacing(1),
    },
}))(BreadcrumbsInner);
