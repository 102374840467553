import React from "react";
import { Typography, makeStyles, Box, alpha, Tooltip } from "@material-ui/core";
import { TestSuiteListItemWarning } from "./TestSuiteListItemWarning";
import {
  BuildTestStatus,
  ExtendedBuildStatus,
} from "../../../_common/interfaces/BuildTestStatus";

import Highlighter from "react-highlight-words";
import { useSearchTermContext } from "../SearchTermContext";

const useStyles = makeStyles((theme) => ({
  container: {
    listStyleType: "none",
    margin: 0,
    paddingInlineStart: 0,
  },
  testSuiteName: ({
    showCloneWarning,
  }: {
    showCloneWarning: boolean | undefined;
  }) => ({
    color: theme.palette.grey[800],
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: theme.typography.pxToRem(20),
    wordBreak: "break-word",
    // Note: we want to limit the testsuite name to 1 line if the clone warning is showing, because otherwise the list item
    // looks pretty cramped (the testsuite list item has a fixed height)
    "-webkit-line-clamp": showCloneWarning ? 1 : 2,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
    fontSize: 15,
  }),

  warningWrapper: {
    marginTop: theme.spacing(0.5),
  },
  url: {
    color: theme.palette.grey[600],
    fontWeight: 400,
    fontSize: 14,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    lineHeight: "1.5",
  },
}));

export interface TestSuiteItemInfoProps {
  id: string;
  url: string;
  name: string;
  status: BuildTestStatus;
  showCloneWarning?: boolean;
}

export function TestSuiteItemInfo(props: TestSuiteItemInfoProps): JSX.Element {
  const { searchTerm } = useSearchTermContext();
  const classes = useStyles({ showCloneWarning: props.showCloneWarning });

  const isNotYetRun = props.status === ExtendedBuildStatus.NotYetRun;
  const nameTestId = isNotYetRun
    ? "test-suite-list-item-compact-name"
    : "test-suite-list-item-name";

  return (
    <Box className={classes.container}>
      <Tooltip title={props.name}>
        <Typography
          className={classes.testSuiteName}
          data-cy="test-suite-list-item-name"
          data-testid={nameTestId}
        >
          <Highlighter
            textToHighlight={props.name}
            searchWords={[searchTerm]}
            highlightStyle={{ backgroundColor: alpha("#3B82F6", 0.25) }}
            autoEscape={true}
          />
        </Typography>
      </Tooltip>

      <Tooltip title={props.url}>
        <Typography
          data-testid="test-suite-list-item-url"
          className={classes.url}
        >
          {props.url}
        </Typography>
      </Tooltip>

      {props.showCloneWarning && (
        <Box className={classes.warningWrapper}>
          <TestSuiteListItemWarning />
        </Box>
      )}
    </Box>
  );
}
