import { BuildTestStatus } from "../../interfaces/BuildTestStatus";
import { getPassWarnOrFail } from "../../../pages/Dashboard/utils/getPassWarnOrFail";
import { Build, BuildStatus } from "../../../graphql";

export function getBuildTestStatus(
  build: Pick<Build, "status" | "passed" | "warnedTestCount">,
): BuildTestStatus {
  if (build.status === BuildStatus.Finished) {
    return getPassWarnOrFail(build.passed, build.warnedTestCount);
  }
  return build.status;
}
