import React from "react";
import { FileRow } from "./FileRow";
import { List, makeStyles } from "@material-ui/core";
import { GetTestSuiteFileUploadsQuery } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

export interface FileTableProps {
  files: NonNullable<
    GetTestSuiteFileUploadsQuery["node"]
  >["urlFileUploads"]["nodes"];
  onFileDeleted: (id: string) => void;
  processing?: boolean;
}

export function FileTable(props: FileTableProps): JSX.Element {
  const classes = useStyles();
  if (props.files.length === 0) {
    return <></>;
  }
  return (
    <List dense={true}>
      {props.files.map((file) => {
        return (
          <div className={classes.row} key={file.id}>
            <FileRow
              file={file}
              onFileDeleted={props.onFileDeleted}
              processing={props.processing}
            />
          </div>
        );
      })}
    </List>
  );
}
