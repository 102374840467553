import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function EditIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2071 4.29289C19.8166 3.90237 19.1834 3.90237 18.7929 4.29289L10.5 12.5858L10.5 14H11.9142L20.2071 5.70711C20.5976 5.31658 20.5976 4.68342 20.2071 4.29289ZM17.3787 2.87868C18.5503 1.70711 20.4497 1.70711 21.6213 2.87868C22.7929 4.05025 22.7929 5.94975 21.6213 7.12132L13.0355 15.7071C12.848 15.8946 12.5936 16 12.3284 16H9.5C8.94772 16 8.5 15.5523 8.5 15L8.5 12.1716C8.5 11.9064 8.60536 11.652 8.79289 11.4645L17.3787 2.87868ZM6.5 6C5.94772 6 5.5 6.44772 5.5 7V18C5.5 18.5523 5.94772 19 6.5 19H17.5C18.0523 19 18.5 18.5523 18.5 18V13C18.5 12.4477 18.9477 12 19.5 12C20.0523 12 20.5 12.4477 20.5 13V18C20.5 19.6569 19.1569 21 17.5 21H6.5C4.84315 21 3.5 19.6569 3.5 18V7C3.5 5.34315 4.84315 4 6.5 4H11.5C12.0523 4 12.5 4.44772 12.5 5C12.5 5.55229 12.0523 6 11.5 6H6.5Z"
      />
    </SvgIcon>
  );
}
