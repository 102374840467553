import DateFnsUtils from "@date-io/date-fns";
import {
  AppThemeProvider,
  ChurnZeroProvider,
  GQLClientProvider,
  HelpScoutStyleOverwrite,
  HotjarStyleOverwrite,
  PendoStyleOverwrite,
  SessionProvider,
  SnackbarProvider,
  useAuth,
  useDocumentTitle,
  useTranslation,
} from "@lumar/shared";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ScrollToTop } from "./_common/components/ScrollToTop/ScrollToTop";
import { TagsController } from "./_common/components/TagsController/TagsController";
import { LocalStorageKeys } from "./_common/constants/localStorageKeys";
import { AuthContext } from "./_common/contexts/Permissions/AuthContext";
import { LoggedInView } from "./routes/LoggedInView";
import { LoggedOutView } from "./routes/LoggedOutView";

export function App(): JSX.Element {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation("navigation");

  useDocumentTitle(t("title"));

  function logoutHandler(): void {
    localStorage.removeItem(LocalStorageKeys.USER_ROLE);
  }

  return (
    <GQLClientProvider
      name="protect"
      version={process.env.REACT_APP_BUILD_HASH}
    >
      <SessionProvider onSessionError={{ message: "", actions: [] }}>
        <ChurnZeroProvider>
          <AuthContext.Provider value={{ isAuthorised: isAuthenticated }}>
            <ScrollToTop />
            <AppThemeProvider>
              <TagsController />
              <HotjarStyleOverwrite />
              <HelpScoutStyleOverwrite />
              <PendoStyleOverwrite />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {isAuthenticated ? (
                  <SnackbarProvider>
                    <LoggedInView logoutHandler={logoutHandler} />
                  </SnackbarProvider>
                ) : (
                  <LoggedOutView />
                )}
              </MuiPickersUtilsProvider>
            </AppThemeProvider>
          </AuthContext.Provider>
        </ChurnZeroProvider>
      </SessionProvider>
    </GQLClientProvider>
  );
}
