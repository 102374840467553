/* eslint-disable max-lines */
import React from "react";
import { Grid, Paper, makeStyles, Box } from "@material-ui/core";
import connectSiteImg from "../../images/connect-site.png";
import addTestSuiteImg from "../../images/add-test-suite.png";
import crawlSettingsImg from "../../images/crawl-settings.png";
import chooseTestsImg from "../../images/choose-tests.png";
import setEmails from "../../images/set-alerts-email.png";
import setSlack from "../../images/set-alerts-slack.png";
import connectCiCdImg from "../../images/connect-ci-cd.png";
import testResultsImg from "../../images/test-results.png";
import buildResultsImg from "../../images/build-tests.png";
import { Overview, OverviewLink } from "./Overview";
import {
  BookOpen,
  Broadcast,
  Pencil,
  BookmarkSimple,
  Envelope,
  CirclesThreePlus,
  Disc,
  ChartPieSlice,
  ClipboardText,
  Info,
} from "@lumar/shared";
import { Section, SectionTitle, SectionBody } from "./Section";
import { useVisibleElement } from "./useVisibleElement";

const useStyles = makeStyles((theme) => ({
  img: { width: "100%", height: "auto" },
  container: {
    maxWidth: 800,
    margin: "0px auto",
    padding: theme.spacing(3, 2),
    paddingTop: 60,
  },
}));

function useGettingStartedContent() {
  const classes = useStyles();

  return [
    {
      id: "what-is-a-test-suite",
      title: "What is a Test Suite?",
      linkIcon: <BookOpen />,
      body: (
        <SectionBody variant="body1">
          A Test Suite is a group of tests you wish to run across a specific
          domain. You can create multiple Test Suites for each domain should you
          wish to group them based on different outcomes for example site speed,
          stability, missing pages.
        </SectionBody>
      ),
    },
    {
      id: "adding-a-testsuite",
      title: "Adding a Test Suite",
      linkIcon: <ClipboardText />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            From the Home Page of Lumar Protect click + Add Test Suite to begin
            the setup process.
          </SectionBody>
          <img
            src={addTestSuiteImg}
            alt="Adding a test suite"
            width={800}
            height={208}
            className={classes.img}
          />
        </>
      ),
    },
    {
      id: "connecting-your-site",
      title: "Connecting your site",
      linkIcon: <Broadcast />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            Define the general settings of your test by giving it a name and
            entering the domain. If your site is behind a firewall, expand the
            Authentication section to enable configuration. We offer
            authentication via Username and Password, Whitelisting IP or Custom
            DNS (or a mixture of all 3).
          </SectionBody>
          <img
            src={connectSiteImg}
            alt="Connect Site Form"
            width={659}
            height={763}
            className={classes.img}
            style={{ marginBottom: 16 }}
          />
          <SectionBody variant="body1" paragraph>
            <b>Name</b> - the name of the test. This should be something easily
            recognizable.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            <b>Domain</b> - the domain that you want to crawl (your test, qa,
            staging or UAT environment). You have to add the full domain name
            including protocol (http or https).
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            <b>Authentication</b> - You need to define your username and
            password and our crawler will send these credentials to all pages on
            your website using basic authentication.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            <b>Whitelisting</b> - This section allows you to define the IP
            address we will use (which you have to whitelist) and the crawler
            user agent.
          </SectionBody>
          <SectionBody variant="body1">
            <b>Custom DNS</b> - Custom DNS entries can be configured if your
            website does not have public DNS records which is common in staging
            environments. Enter DNS entries as you would in a host file.
          </SectionBody>
        </>
      ),
    },
    {
      id: "choose-crawl-settings",
      title: "Choose crawl settings",
      linkIcon: <Pencil />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            This section enables users to define the crawl configuration like
            speed, limit and enable or disable JavaScript.
          </SectionBody>

          <img
            src={crawlSettingsImg}
            alt="Choose crawl settings"
            height={691}
            width={584}
            className={classes.img}
            style={{ marginBottom: 16 }}
          />

          <SectionBody variant="body1" paragraph>
            <b>URL source</b> - the user can choose from either crawling the
            website or crawling from a list of crawls. If a user selects a list
            of crawls they can upload a file that contains the list.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            <b>JS Rendering</b> - based on how the website is built the user has
            the ability to enable JavaScript Rendering.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            <b>Crawl speed</b> - Enables users to limit the maxim crawl speed to
            maintain the website’s performance stable. The limit that the user
            sets it’s the maximum limit and the crawl speed will vary up to the
            limit. We recommend 3 URL/s for development and staging sites.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            <b>URL limit</b> - The maximum limit of URLs that will be crawled.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            Advanced settings allow you to include or exclude specific URLs,
            overwrite robots.txt file and define specific javascript rendering
            settings.
          </SectionBody>
          <SectionBody variant="body1">
            Advanced Test Settings e.g. max page width, title length are set at
            Lumar’s recommended levels but can also be amended here.
          </SectionBody>
        </>
      ),
    },
    {
      id: "choose-tests",
      title: "Choose tests",
      linkIcon: <BookmarkSimple />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            Lumar Protect can run over 160 different tests on your domain.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            Each test can be set with a severity level of Fail or Warning. A
            Fail will stop your build and a Warning will send a notification
            either via Email or Slack but allow the build to continue.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            You can apply a threshold against each test and can choose between a
            percentage or an absolute number.
          </SectionBody>

          <img
            src={chooseTestsImg}
            alt="Choose tests"
            className={classes.img}
          />
        </>
      ),
    },
    {
      id: "set-alerts",
      title: "Set Alerts",
      linkIcon: <Envelope />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            You can set alerts to be sent via email or integrate into your Slack
            environment. Instructions to connect to Slack are included here.
          </SectionBody>

          <Box mb={2}>
            <img
              src={setEmails}
              alt="Set Alerts (Email)"
              className={classes.img}
            />
          </Box>
          <Box>
            <img
              src={setSlack}
              alt="Set Alerts (Slack)"
              className={classes.img}
            />
          </Box>
        </>
      ),
    },
    {
      id: "connect-to-ci-cd",
      title: "Connect to your CI/CD Tool",
      linkIcon: <CirclesThreePlus />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            Lumar Protect can connect to all major CI /CD tools via API and
            instructions are provided here.
          </SectionBody>

          <img
            src={connectCiCdImg}
            alt="Connect CI/CD Tool"
            className={classes.img}
          />
        </>
      ),
    },
    {
      id: "running-the-crawl",
      title: "Running the crawl",
      linkIcon: <Disc />,
      body: (
        <SectionBody variant="body1">
          Lumar Protect is integrated with your CI/CD tool which means that it
          will automatically run as part of your automated tests whenever you
          release code. This means it is part of your process and you don’t need
          to set any reminders.
        </SectionBody>
      ),
    },
    {
      id: "viewing-crawl-results",
      title: "Viewing Crawl Results",
      linkIcon: <ChartPieSlice />,
      body: (
        <>
          <SectionBody variant="body1" paragraph>
            The results can be seen on your Lumar Protect home page: The Builds
            list shows top level information about each build (crawl) and the
            number of tests that passed or failed.
          </SectionBody>
          <SectionBody variant="body1" paragraph>
            If all tests passed or warned, then the build will pass. If one test
            fails, then the build will fail
          </SectionBody>

          <img
            src={buildResultsImg}
            alt="Build Results"
            className={classes.img}
          />

          <SectionBody variant="body1" paragraph>
            The Build detail page shows each test that passed, failed, or
            warned. Users can see more detail about the URLs in each test by
            opening the Lumar report.
          </SectionBody>

          <img
            src={testResultsImg}
            alt="Test Results"
            className={classes.img}
          />
        </>
      ),
    },
    {
      id: "additional-tips",
      title: "Additional Tips",
      linkIcon: <Info />,
      body: (
        <SectionBody variant="body1">
          Duplicating a test suite: Lumar Protect allows you to easily duplicate
          test suites to save you valuable time. This means you don’t have to
          re-enter configuration details for each staging environment. The
          duplicate will not copy across any URL lists and will automatically be
          set to a web crawl.
        </SectionBody>
      ),
    },
  ];
}

export function GettingStarted() {
  const classes = useStyles();

  const pageContent = useGettingStartedContent();

  const { visibleElementId, createRefFunction } = useVisibleElement(
    pageContent[0].id,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Paper id="faq" data-testid="getting-started">
          <Box className={classes.container}>
            {pageContent.map(({ id, title, body }, index) => {
              return (
                <Section key={id}>
                  <SectionTitle
                    id={id}
                    data-cy={`header-${id}`}
                    ref={createRefFunction(index)}
                  >
                    {title}
                  </SectionTitle>
                  {body}
                </Section>
              );
            })}
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Overview style={{ position: "sticky", top: 60 }}>
          {pageContent.map(({ linkIcon, title, id }) => (
            <OverviewLink
              key={id}
              href={`#${id}`}
              data-cy={`link-${id}`}
              data-pendo={`auto-getting-started-${id}`}
              icon={linkIcon}
              // Note: only want a link to be active if its corresponding section title is the first section title in the viewport.
              active={id === visibleElementId}
            >
              {title}
            </OverviewLink>
          ))}
        </Overview>
      </Grid>
    </Grid>
  );
}
