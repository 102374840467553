import { StatusIconProps, StatusIconSize } from "./StatusIcon";
import { makeStyles } from "@material-ui/core";
import { getColorForType, getBorderMatchingColors } from "./StatusIconUtils";

function getFontSizeForIcon(size: StatusIconSize | undefined): string {
  if (size === StatusIconSize.XS) {
    return "14px";
  } else if (size === StatusIconSize.SM) {
    return "22px";
  } else if (size === StatusIconSize.MD) {
    return "30px";
  } else if (size === StatusIconSize.LG) {
    return "32px";
  } else {
    return "46px";
  }
}

export const useStyles = makeStyles((theme) => ({
  status: (props: StatusIconProps) => {
    const colors = getBorderMatchingColors(theme);
    return {
      display: "flex",
      alignItems: "center",
      textAlign: props.inline ? "left" : "center",
      flexDirection: props.inline ? "row" : "column",
      marginBottom: "0px",
      color: getColorForType(colors, props.type),
    };
  },
  icon: (props: StatusIconProps) => {
    return {
      marginBottom: props.size === StatusIconSize.SM ? theme.spacing(0.5) : "0",
      fontSize: getFontSizeForIcon(props.size),
      marginRight: props.inline ? "10px" : "0",
    };
  },
  statusText: (props: StatusIconProps) => {
    return {
      textTransform: props.inline ? "lowercase" : "uppercase",
      "&:first-letter": {
        textTransform: "uppercase",
      },
      fontWeight: 600,
      color: theme.palette.grey[800],
      // Note: using `!important` here because there seems to be an issue with MUI's CSS specificity in production builds,
      // meaning that without it the style gets overridden by a default MUI class
      fontSize: props.inline ? theme.typography.pxToRem(15) : `10px !important`,
      lineHeight: props.inline
        ? theme.typography.pxToRem(46)
        : theme.typography.pxToRem(20),
      marginRight: props.inline ? "40px" : 0,
      width: "80px",
    };
  },
}));
