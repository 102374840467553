import { generatePath } from "react-router-dom";
import queryString from "query-string";
const domain = process.env.REACT_APP_IMPACT_APP_URL &&
    removeTrailingSlash(process.env.REACT_APP_IMPACT_APP_URL);
function removeTrailingSlash(value) {
    if (value.endsWith("/")) {
        return value.substring(0, value.length - 1);
    }
    return value;
}
export const ImpactRoutes = {
    Domain: domain || "",
    Projects: {
        ROUTE: `/accounts/:accountId/projects`,
        getUrl({ accountId }) {
            const url = generatePath(ImpactRoutes.Projects.ROUTE, {
                accountId,
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
};
