import { insertIf } from "../../../../helpers/insertIf";
import { Dashboard, Done, FileboardDoneV3, GridSystem, } from "../../../../icons";
import { AppIds } from "../../../../session/SessionContext.types";
import { matchPath } from "react-router-dom";
import { getRawAccountId } from "../../../../session/helpers/getRawAccountId";
import { AccountProjectsVariant, AnalyzeRoutes, } from "../../../../routes/analyzeRoutes";
export function getAnalyzeAppSwitcherConfig({ domain, isMain, session, labels, name, }) {
    const enabled = session.hasApp(AppIds.analyze) || session.isDeepCrawlAdmin;
    const isExternal = !isMain;
    const accountId = getRawAccountId(session.account.id);
    const hasProtectApp = session.hasApp(AppIds.protect);
    function getHref(path) {
        return isExternal ? `${domain}${path}` : path;
    }
    function getIsSelected(isSelected) {
        return isExternal ? false : isSelected;
    }
    return {
        id: AppIds.analyze,
        name,
        isMain,
        enabled,
        href: getHref(AnalyzeRoutes.Projects.getUrl({ accountId })),
        testId: "analyze-app-icon",
        items: [
            {
                id: "projects",
                name: labels.allProjects,
                icon: GridSystem,
                href: getHref(AnalyzeRoutes.Projects.getUrl({
                    accountId,
                    type: AccountProjectsVariant.All,
                })),
                isSelected: getIsSelected((path, search) => {
                    var _a;
                    const match = matchPath(path, {
                        path: AnalyzeRoutes.Projects.ROUTE,
                    });
                    const searchParams = new URLSearchParams(search);
                    const isMatchingType = [
                        AccountProjectsVariant.All,
                        AccountProjectsVariant.SEO,
                        AccountProjectsVariant.Accessibility,
                        AccountProjectsVariant.SiteSpeed,
                    ].includes((_a = searchParams.get("type")) !== null && _a !== void 0 ? _a : AccountProjectsVariant.All);
                    return !!(match === null || match === void 0 ? void 0 : match.isExact) && isMatchingType;
                }),
            },
            {
                id: "runnningCrawls",
                name: labels.runningCrawls,
                icon: Dashboard,
                href: getHref(AnalyzeRoutes.Projects.getUrl({
                    accountId,
                    type: AccountProjectsVariant.Running,
                })),
                isSelected: getIsSelected(),
            },
            ...insertIf(hasProtectApp, {
                id: "testSuites",
                name: labels.testSuites,
                icon: FileboardDoneV3,
                href: getHref(AnalyzeRoutes.Projects.getUrl({
                    accountId,
                    type: AccountProjectsVariant.TestSuite,
                })),
                isSelected: getIsSelected(),
                testId: "app-menu-item-test-suites",
            }),
            {
                id: "tasks",
                name: labels.accountTasks,
                icon: Done,
                href: getHref(AnalyzeRoutes.AccountTasks.getUrl({ accountId })),
                isSelected: getIsSelected(),
            },
        ],
    };
}
