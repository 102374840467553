import React from "react";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  CircularProgress,
  Box,
} from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { DeleteIcon } from "../../../svg/DeleteIcon";
import { GetTestSuiteFileUploadsQuery } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  delete: {
    color: theme.palette.navy[500],
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  avatar: {
    backgroundColor: theme.palette.grey[300],
  },
  gutters: {
    paddingLeft: theme.spacing(0),
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  totalRows: {
    fontSize: 12,
    color: theme.palette.navy[300],
  },
  alertRow: {
    marginTop: theme.spacing(0.5),
    display: "flex",
  },
  warningMessages: {
    lineHeight: "23px",
    marginLeft: theme.spacing(0.5),
  },
}));

export interface FileRowProps {
  onFileDeleted: (id: string) => void;
  file: NonNullable<
    GetTestSuiteFileUploadsQuery["node"]
  >["urlFileUploads"]["nodes"][0];
  processing?: boolean;
}

interface ParsedFeedback {
  warnings?: string[];
  detected_project_upload_types?: string[];
}

export function FileRow(props: FileRowProps): JSX.Element {
  const classes = useStyles();

  function parseFeedback(text: string | null): Record<string, unknown> {
    try {
      return typeof text === "string" ? JSON.parse(text) : {};
    } catch {
      return {};
    }
  }
  const parsedObjectFromFeedBack: ParsedFeedback = parseFeedback(
    props.file.processingFeedback,
  );
  const warningMessages = parsedObjectFromFeedBack?.warnings;

  return (
    <ListItem classes={{ gutters: classes.gutters }} data-testid="file-row">
      <ListItemAvatar>
        <Avatar>
          {props.processing ? (
            <CircularProgress size={20} />
          ) : (
            <InsertDriveFileIcon />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Box data-testid="file-row-name">{props.file.fileName}</Box>}
        secondary={
          <>
            {props.file.totalRows !== null ? (
              <span
                className={classes.totalRows}
                data-testid="file-row-total-rows"
              >
                Uploaded list contains {props.file.totalRows} URLs
              </span>
            ) : (
              <span data-testid="file-row-total-rows">{props.file.status}</span>
            )}
            {warningMessages && (
              <span
                className={classes.alertRow}
                data-testid="file-row-warnings"
              >
                <WarningRoundedIcon
                  className={classes.warningIcon}
                  fontSize="small"
                />
                <span className={classes.warningMessages}>
                  {warningMessages}
                </span>
              </span>
            )}
          </>
        }
        data-cy={`url-file-upload-label-${props.file.fileName}`}
        data-testid={`url-file-upload-label-${props.file.fileName}`}
      />
      {!props.processing && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => props.onFileDeleted(props.file.id)}
            className={classes.delete}
            data-cy={`delete-url-file-upload-btn-${props.file.fileName}`}
            data-testid={`delete-url-file-upload-btn-${props.file.fileName}`}
            data-pendo="auto-test-suite-edit-upload-files-file-delete"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
