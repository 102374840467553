import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

export type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface AddInputSkeletonProps {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
  testId: string;
}

export function AddInputSkeleton(props: AddInputSkeletonProps): JSX.Element {
  const { testId, ...sizes } = props;

  return (
    <Grid container>
      <Grid item {...sizes}>
        <Skeleton data-testid={testId} variant="rect" height={58} />
      </Grid>
    </Grid>
  );
}
