import React from "react";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { JiraProjectSelect } from "./JiraProjectSelect";
import { JiraIssueTypeSelect } from "./JiraIssueTypeSelect";
import AuthIcon from "@material-ui/icons/AccountBoxRounded";
import { useSnackbar } from "notistack";
import { Alert, Snackbar } from "@lumar/shared";
import { useCreateJiraIntegrationMutation } from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: (props: ICreateJiraIntegrationFormProps) => {
    return {
      marginTop: props.integrationName.length > 50 ? "30px" : theme.spacing(7),
    };
  },
}));

interface ICreateJiraIntegrationFormProps {
  jiraAuthentication: {
    id: string;
    name: string;
    jiraUrl: string;
  };
  integrationName: string;
  onSuccess: () => void;
  onError: (e: Error) => void;
}

// eslint-disable-next-line complexity
export function CreateJiraIntegrationForm(
  props: ICreateJiraIntegrationFormProps,
): JSX.Element {
  const classes = useStyles(props);

  const [jiraProjectId, setJiraProjectId] = useState("");
  const [jiraIssueTypeId, setJiraIssueTypeId] = useState("");

  const [createJiraIntegration, { loading, error }] =
    useCreateJiraIntegrationMutation({
      refetchQueries: [
        "getJiraIntegrations",
        "getJiraIntegrationsWithTestSuites",
      ],
    });

  const { enqueueSnackbar } = useSnackbar();

  async function create(): Promise<void> {
    try {
      if (!jiraProjectId || !jiraIssueTypeId) return;

      await createJiraIntegration({
        variables: {
          name: props.integrationName,
          jiraAuthenticationId: props.jiraAuthentication.id,
          jiraProjectId,
          jiraIssueTypeId,
        },
      });

      enqueueSnackbar(
        <Snackbar
          title="Jira integration created successfully."
          variant="success"
        />,
      );

      props.onSuccess();
    } catch {
      // no need to process errors as the errors are coming from hooks.
    }
  }

  return (
    <div>
      {loading && <CircularProgress color="secondary" />}

      {error && (
        <Alert severity="error" data-testid="error-alert">
          Sorry, looks like something went wrong when creating a new Jira
          integration. Please try again or contact support.
        </Alert>
      )}

      {!loading && !error && (
        <>
          <List data-testid="jira-integration-authentication-details">
            <ListItem>
              <ListItemIcon>
                <AuthIcon />
              </ListItemIcon>
              <ListItemText
                primary={props.jiraAuthentication.name}
                secondary={props.jiraAuthentication.jiraUrl}
              />
            </ListItem>
            <Divider />
          </List>

          <Box mb={4} mt={4}>
            <JiraProjectSelect
              jiraAuthenticationId={props.jiraAuthentication.id}
              jiraProjectId={jiraProjectId}
              onSelect={(e) => setJiraProjectId(e.target.value as string)}
            />
          </Box>

          {jiraProjectId.length > 0 && (
            <Box mb={4} mt={4}>
              <JiraIssueTypeSelect
                jiraAuthenticationId={props.jiraAuthentication.id}
                jiraProjectId={jiraProjectId}
                issueTypeId={jiraIssueTypeId}
                onSelect={(e) => setJiraIssueTypeId(e.target.value as string)}
              />
            </Box>
          )}

          {jiraProjectId.length > 0 && jiraIssueTypeId.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.buttonWrapper}
            >
              <PrimaryActionButton
                dataTestId="create-jira-authentication-submit-btn"
                disabled={!jiraProjectId.length || !jiraIssueTypeId.length}
                onClick={async () => await create()}
                label="Create Jira Integration"
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
}
