import lodash from "lodash";
import { GetTestSuitesQuery } from "../../../graphql";

export function getPreviousResultAsLast(data: GetTestSuitesQuery) {
  if (data.getAccount?.__typename === "Account" && data.getAccount.testSuites) {
    const newData = lodash.cloneDeep(data);
    if (newData.getAccount !== null && newData.getAccount.testSuites !== null) {
      // eslint-disable-next-line fp/no-mutation, @typescript-eslint/no-non-null-assertion
      newData.getAccount!.testSuites!.pageInfo.hasNextPage = false;
    }
    return newData;
  }
  return data;
}
