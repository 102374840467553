import React from "react";
import { BuildStatusContext } from "../../contexts/BuildStatusProvider/BuildStatusProvider";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useBuildStatusContext() {
  const context = React.useContext(BuildStatusContext);

  if (!context) {
    throw new Error(
      "useBuildStatusContext must be used within a BuildStatusProvider",
    );
  }

  const testSuiteBuilds =
    context.data?.node?.__typename === "TestSuite"
      ? context.data.node.builds
      : { __typename: "BuildConnection", nodes: [] };

  return { ...context, builds: testSuiteBuilds };
}
