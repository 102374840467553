import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export function HotjarStyleOverwrite() {
    React.useLayoutEffect(() => {
        const style = document.createElement("style");
        // eslint-disable-next-line fp/no-mutation
        style.innerHTML = getHotjarStyleOverwrite();
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);
    return _jsx(_Fragment, {});
}
function getHotjarStyleOverwrite() {
    return `
  #_hj_feedback_container > div:first-child {
    left: 80px;
    top: unset;
    bottom: 100px;
    right: unset;
  }

  div[class*="__MinimizedWidgetMiddle__container"] {
    display: none !important;
  }

  @keyframes feedback_slideInLeft {
    0% {
      transform: translateX(-350px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  @keyframes feedback_slideOutLeft {
    0% {
      transform: translateX(0px);
      opacity: 1;
    }
    100% {
      transform: translateX(-350px);
      opacity: 0;
    }
  }

  div[class*="__ExpandedWidget__slideInRight"] {
    animation-name: feedback_slideInLeft !important;
  }

  div[class*="__ExpandedWidget__slideOutRight"] {
    animation-name: feedback_slideOutLeft !important;
  }
  `;
}
