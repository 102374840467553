import { Redirect, withRouter } from "react-router-dom";
import { getDisplayingAccountId } from "../../utils/getDisplayingAccountId/getDisplayingAccountId";
import { CircularProgress } from "@material-ui/core";
import { Routes } from "../../routes/routes";
import { useGetUserQuery } from "../../../graphql";

export const PostLoginLayer = withRouter(function PostLoginLayer() {
  const { data: userData } = useGetUserQuery();

  if (userData) {
    const accountId = getDisplayingAccountId(
      userData,
      userData.me.accounts.nodes[0].id,
    );
    return <Redirect to={Routes.Dashboard.getUrl({ accountId })} />;
  } else {
    return (
      <CircularProgress
        style={{ marginTop: 16 }}
        data-testid="post-login-loader"
        color="primary"
      />
    );
  }
});
