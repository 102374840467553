import React, { useState, ChangeEvent, useEffect, useContext } from "react";
import { Box, InputAdornment, IconButton, TextField } from "@material-ui/core";
import { useDebounce } from "../../hooks/useDebounce/UseDebounce";
import { ChooseTestsDispatch } from "../../contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { XCircleSolid, SearchOutlined } from "@lumar/shared";

const DEFAULT_SEARCH_DEBOUNCE = 250;

export function SearchField(): JSX.Element {
  const chooseTestsFormDispatch = useContext(ChooseTestsDispatch);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchTerm = useDebounce(searchText, DEFAULT_SEARCH_DEBOUNCE);

  function updateSearch(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setSearchText(event.target.value);
  }

  function handleClear(): void {
    setSearchText("");
  }

  useEffect(() => {
    chooseTestsFormDispatch({
      type: "UPDATE_SEARCH_TEXT",
      payload: { searchText: debouncedSearchTerm },
    });
  }, [debouncedSearchTerm, chooseTestsFormDispatch]);

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        id="filled-search"
        data-testid="search-input"
        data-cy="find-test-input"
        data-pendo="auto-test-suite-edit-choose-tests-search-field"
        value={searchText}
        onChange={updateSearch}
        placeholder="Search tests"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: searchText ? (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClear}
                title="Clear search bar"
                style={{
                  padding: 4,
                  marginRight: 7,
                }}
              >
                <XCircleSolid fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Box>
  );
}
