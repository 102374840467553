import React from "react";
import { Select } from "formik-material-ui";
import { Field } from "formik";
import { MenuItem, Box } from "@material-ui/core";
import { SectionLabel } from "../Section";
import { InfoTooltip } from "../../../../_common/components/InfoTooltip/InfoTooltip";

export function UrlLimit() {
  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <SectionLabel htmlFor="url-limit">URL Limit</SectionLabel>
        <InfoTooltip
          IconProps={{
            style: {
              marginLeft: 8,
            },
          }}
          data-pendo="auto-tooltip-url-limit"
          title="Crawling more URLs will provide you with a greater level of accuracy, but it will take longer for the test to complete."
        />
      </Box>
      <Field
        name="limitUrlsMax"
        component={Select}
        id="url-limit"
        data-cy="url-limit-dropdown"
        variant="outlined"
        fullWidth
        style={{ maxWidth: 400 }}
      >
        <MenuItem value={500}>Up to 500 URLs</MenuItem>
        <MenuItem data-cy="one-thousand-urls-select" value={1000}>
          Up to 1,000 URLs
        </MenuItem>
        <MenuItem value={3000}>Up to 3,000 URLs</MenuItem>
        <MenuItem value={5000}>Up to 5,000 URLs</MenuItem>
        <MenuItem value={10000}>Up to 10,000 URLs</MenuItem>
      </Field>
    </>
  );
}
