import React from "react";

export const crawlSettingsTooltips = {
  includeAndExcludeUrls: (
    <>
      <b>Include Only URLs</b>
      <p>
        Add URL paths on separate lines, to limit your crawl to URLs that only
        include your specified paths.
      </p>
      <p> E.g. /include matches http://domain.com/folder/include/page1</p>
      <p>
        Please note:
        <ul>
          <li>
            You can use regular expressions (RegEx) to match specific paths.
          </li>
          <li>Forward slashes do not need to be escaped.</li>
        </ul>
      </p>
      <b>Exclude URLs</b>
      <p>Add URL paths on separate lines to filter them from the crawl.</p>
      <p>
        E.g. /exclude matches http://domain.com/folder/include/exclude/page1
      </p>
      <p>Please note: exclude rules take precedence over include rules.</p>
    </>
  ),
  robotsOverwrite: (
    <>
      <span>
        Replace the live robots.txt for the primary domain only, with an
        alternative version in the field below.
      </span>
      <p>
        Select &apos;Use Robots.txt Overwrite&apos; option when starting a crawl
        to use this version instead of the live robots.txt file.
      </p>
      <p>
        e.g.User-agent: *<br />
        Allow: /<br />
        Disallow: /blog
      </p>
    </>
  ),
  jsRendering: (
    <>
      <span>
        When JavaScript Rendering is activated, some crawl settings will not
        apply: Custom DNS, Static/Geo IPs, Stealth Crawl. All requests will come
        from the IP: 52.5.118.182.{" "}
        <a
          href="https://www.lumar.io/product-guides/how-to-crawl/javascript-rendering/"
          target="_blank"
          rel="noopener noreferrer"
        >
          See more information about how we render and read our full FAQ here
        </a>
        .
      </span>
      <br />
      <b>Block Ad or Analytics Scripts</b>
      <p>
        To avoid activating ad or analytics scripts, the renderer can block the
        execution of some standard scripts. In some cases, this may impact the
        rendering of your pages.
      </p>
      <b>Block Script URLs</b>
      <p>
        To avoid activating custom scripts, the renderer can block their
        execution. In some cases, this may impact the rendering of your pages.
      </p>
      <p>e.g. */*.js</p>
      <p>
        Please note: you can add up to 100 resource url patterns to be blocked
        when rendering
      </p>
      <b>Custom JavaScript Snippet</b>
      <p>
        Injecting custom JavaScript into a page could cause the rendered page to
        be significantly different to what a user may see. Custom Javascript
        will not run if a page does not complete rendering within 10 seconds.
        Maximum 64KB.
      </p>
      <b>Include External JavaScript Resources</b>
      <p>
        Add the URLs of additional JavaScript resources that should be loaded
        before rendering pages. These resources are loaded after the page has
        rendered, but before the custom JavaScript snippet has been executed.
      </p>
    </>
  ),
  testSettings: (
    <>
      <b>Max title width:</b>
      <p>
        The maximum pixel width of a page title. This will impact some Page
        Title reports.
      </p>
      <b>Min title length:</b>
      <p>
        The minimum character count &apos;s title falls below this, it will be
        considered too short.
      </p>
      <b>Min description length:</b>
      <p>
        The minimum character count for a meta description. If a page&apos;s
        meta description falls below this, it will be considered too short.
      </p>
      <b>Max description length:</b>
      <p>
        The maximum character count for a meta description. If a page&apos;s
        meta description is longer than this, it will be considered too long.
      </p>
      <p>
        <b>Max HTML size:</b> The maximum size of a page (including all of the
        HTML). If a page is larger than this, it will still be crawled, but will
        be considered too large in performance reports.
      </p>
      <b>Max links:</b>
      <p>
        The maximum number of links that a page should contain before our
        reports begin flagging them.
      </p>
      <b>Thin page threshold:</b>
      <p>The threshold where we consider a page to have too little content.</p>
      <b>Empty page threshold:</b>
      <p>The threshold where we consider a page to have no content.</p>
      <b>Max external links:</b>
      <p>
        The maximum number of links to websites which fall outside of the crawl
        scope.
      </p>
      <b>Max content size:</b>
      <p>
        The maximum amount of content (i.e. text on the page) before we consider
        it to to have too much content.
      </p>
      <b>Max URL length:</b>
      <p>The longest that a URL can be before it is considered too long.</p>
      <b>Max fetch time:</b>
      <p>The threshold where we consider a page to be slow.</p>
      <b>Duplicate precision:</b>
      <p>
        Increasing the Duplicate Precision value above the default value of 3
        will require a greater degree of duplication before a page will be
        marked as a duplicate. Reducing the value will require less duplication.
        Enter value from 1 to 5.
      </p>
      <b>Max redirections:</b>
      <p>
        The maximum number of redirections that should exist in a chain before
        we flag it in the &apos;Max redirections&apos; test.
      </p>
    </>
  ),
  urlSource: (
    <>
      <span>
        Upload a list of URLs in a text file with each URL on a new line, or CSV
        with the URL in the first column (Up to 100MB).
      </span>
      <p>
        Relative URLs will inherit the project’s Primary Domain, or you can set
        a different base domain for each upload in the Upload Settings.
      </p>
      <p>
        If your upload format is not automatically recognised, you can reformat
        it into{" "}
        <a
          href="/files/ExampleListUpload.txt"
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          this example upload
        </a>
        , or send it to{" "}
        <a href="mailto:support@deepcrawl.com">support@deepcrawl.com</a> and
        we’ll see if it can be supported.
      </p>
    </>
  ),
  customExtractions: (
    <>
      <b>Custom extraction</b>
      <p>
        Use regular expressions (regex) to extract custom information from pages
        when they are crawled.
      </p>
      <p>
        <strong>Custom Extraction 1 - Name:</strong> Please provide a
        descriptive name for this custom extraction e.g. Out of stock. This will
        be used as part of your test name on step 3 in the format &apos;Custom
        Extraction 1 (Out of stock)&apos;.
        <br />
        <strong>Regex pattern:</strong> Please enter Ruby compatible regex. Use{" "}
        <a href="https://rubular.com" target="_blank" rel="noopener noreferrer">
          Rubular
        </a>{" "}
        to help validate your regex. Forward slashes do not need to be escaped.
      </p>
      <p>
        Please note:
        <ul>
          <li>
            You will need to add any custom extractions created here to your
            selected tests in step 3.
          </li>
          <li>
            Currently, you can test for 30 custom extractions only. If you need
            to add more, please{" "}
            <a href="mailto:product@deepcrawl.com">contact us.</a>
          </li>
        </ul>
      </p>
    </>
  ),
  cookies: (
    <>
      <b>Add or import cookies</b>
    </>
  ),
};
