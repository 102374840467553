import React, { useContext } from "react";
import {
  AppBar,
  Box,
  makeStyles,
  Slide,
  Theme,
  useScrollTrigger,
} from "@material-ui/core";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import {
  AccountOptions,
  Breadcrumbs,
  BreadcrumbsProps,
  useLayoutApi,
  useSession,
} from "@lumar/shared";
import { useAccountMenuItems } from "./useAccountMenuItems";
import { RoleCode } from "../../../graphql";
import { getIdFromEncodedString } from "../../../_common/utils/getIdFromEncodedString/getIdFromEncodedString";
import { LogoutContext } from "../../AppRoutesPrivate";
import {
  CONTAINER_PADDING,
  SMALL_CONTAINER_PADDING,
} from "../../../_common/components/PageContainer/PageContainer";

const useStyles = makeStyles<Theme, { mainContentMarginLeft: number }>(
  (theme) => ({
    appBar: {
      backgroundColor: "#eff3f6",
      boxShadow: "none",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 8,
      paddingTop: theme.spacing(2),
      paddingRight: CONTAINER_PADDING,
      paddingBottom: theme.spacing(3),
      paddingLeft: (v) => v.mainContentMarginLeft + CONTAINER_PADDING,
      [theme.breakpoints.down("sm")]: {
        paddingRight: SMALL_CONTAINER_PADDING,
        paddingLeft: (v) => v.mainContentMarginLeft + SMALL_CONTAINER_PADDING,
      },
    },
  }),
);

// eslint-disable-next-line react/display-name
export const TopNav = React.forwardRef(
  (
    props: {
      breadcrumbsProps?: BreadcrumbsProps;
    },
    ref,
  ) => {
    const { mainContentMarginLeft } = useLayoutApi();
    const classes = useStyles({ mainContentMarginLeft });
    const { logoutHandler } = useContext(LogoutContext);

    const accountId = useAccountRouteMatch();
    const session = useSession();

    const currentAccount = session.allRelationships.find(
      ({ account }) => account.id === accountId,
    );
    const currentUserRole = currentAccount?.role?.code ?? RoleCode.Viewer;

    const menuItems = useAccountMenuItems(
      logoutHandler,
      currentUserRole,
      session.isDeepCrawlAdmin,
    );

    return (
      <HideOnScroll>
        <AppBar className={classes.appBar} ref={ref}>
          {props.breadcrumbsProps ? (
            <Breadcrumbs {...props.breadcrumbsProps} />
          ) : null}

          <Box display="flex" alignItems="center" marginLeft="auto">
            <AccountOptions
              accountName={currentAccount?.account?.name ?? "Loading..."}
              accountId={`Account ID: ${getIdFromEncodedString(
                accountId,
                "Account",
              )}`}
              email={session.email || session.id}
              role={
                session.isDeepCrawlAdmin ? "ADMINISTRATOR" : currentUserRole
              }
              menuItems={menuItems}
            />
          </Box>
        </AppBar>
      </HideOnScroll>
    );
  },
);

function HideOnScroll({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
