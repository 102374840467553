import { PermissionCode, RoleCode } from "../../graphql";
export function hasSufficientRoleFactory(userPermissions, userRoleCode) {
    const hasAdminPermission = userPermissions.find((p) => p.code === PermissionCode.Admin);
    return function hasSufficientRole(minimumRole) {
        if (hasAdminPermission) {
            return true;
        }
        if (!minimumRole) {
            return false;
        }
        return getRolePriority(userRoleCode) >= getRolePriority(minimumRole);
    };
}
function getRolePriority(roleCode) {
    switch (roleCode) {
        case RoleCode.Admin:
            return 3;
        case RoleCode.Editor:
            return 2;
        case RoleCode.Viewer:
            return 1;
        default:
            return 0;
    }
}
