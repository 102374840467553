export function getIndexesOfDuplicates<T>(
  arrayOfObjects: T[],
  matchingFunction: (objA: T, objB: T) => boolean,
): number[] {
  const indexArray: number[] = [];

  arrayOfObjects.forEach((object, index) => {
    const remainingObjects = arrayOfObjects.filter((item, i) => i !== index);
    const doesDuplicateExist = remainingObjects.some((item) =>
      matchingFunction(item, object),
    );
    if (doesDuplicateExist) {
      // eslint-disable-next-line fp/no-mutating-methods
      indexArray.push(index);
    }
  });

  return indexArray;
}
