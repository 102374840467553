import {
  Box,
  makeStyles,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  footerCounter: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.grey[400],
    marginBottom: 0,
  },
  wrapper: (props: LoadMoreItemsProps) => {
    return props.variant !== "stacked"
      ? {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }
      : {};
  },
  loadMoreButton: {
    width: (props: LoadMoreItemsProps) =>
      props.variant == "stacked" ? "100%" : "auto",
  },
}));

interface LoadMoreItemsProps {
  currentCount: number;
  totalCount: number;
  onClick: () => void;
  isLoading: boolean;
  dataId: string;
  variant?: "stacked";
}

export function LoadMoreItems(props: LoadMoreItemsProps): JSX.Element {
  const classes = useStyles(props);

  return (
    <Box className={classes.wrapper}>
      <Typography
        className={classes.footerCounter}
        data-testid={`${props.dataId}-load-more-text`}
        data-cy={`${props.dataId}-load-more-text`}
      >
        Showing {props.currentCount} of {props.totalCount}
      </Typography>
      {props.currentCount !== props.totalCount && (
        <Button
          variant="outlined"
          data-testid={`${props.dataId}-load-more-button`}
          data-cy={`${props.dataId}-load-more-button`}
          onClick={props.onClick}
          className={classes.loadMoreButton}
          disabled={props.isLoading}
        >
          {props.isLoading ? (
            <CircularProgress
              size={20}
              color="secondary"
              disableShrink
              data-testid="load-more-button-spinner"
            />
          ) : (
            "Load more"
          )}
        </Button>
      )}
    </Box>
  );
}
