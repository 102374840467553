var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-types */
import { ClickAwayListener, Grid, makeStyles, OutlinedInput, Slider as MuiSlider, Typography, } from "@material-ui/core";
import { useState } from "react";
import { PencilOutlined } from "../../icons";
const useStyles = makeStyles((theme) => ({
    root: (props) => {
        var _a;
        const severityMap = new Map([
            ["low", theme.palette.primary.main],
            ["medium", theme.palette.orange[500]],
            ["high", theme.palette.red[500]],
        ]);
        return {
            color: severityMap.get((_a = props.severity) !== null && _a !== void 0 ? _a : "low"),
            marginTop: 10,
        };
    },
    thumb: {
        backgroundColor: "white",
        width: 29,
        height: 29,
        marginTop: -13,
        marginLeft: -16,
        border: "1px solid #D1D5DB",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    },
    track: { height: 4 },
    rail: { height: 4, backgroundColor: theme.palette.grey[400] },
    valueLabel: { left: "calc(-20% + 4px)" },
    labels: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        marginTop: 4,
        marginRight: theme.spacing(1),
    },
    inputLabel: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
    },
    input: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
        height: "26px",
        marginTop: 2,
        width: 90,
    },
    editIcon: {
        color: theme.palette.grey[700],
        width: 14,
        height: 14,
        marginLeft: theme.spacing(1),
    },
    inputDiv: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        "&:hover": {
            background: theme.palette.secondary.main,
        },
        borderRadius: 6,
        padding: theme.spacing(0.5, 1, 0.6, 1),
        cursor: "pointer",
    },
}));
export function Slider(props) {
    var _a, _b, _c;
    const { severity, inputLabel, leftLabel, rightLabel } = props, rest = __rest(props, ["severity", "inputLabel", "leftLabel", "rightLabel"]);
    const classes = useStyles({ severity: severity });
    const [innerValue, setInnerValue] = useState((_b = (_a = props.defaultValue) !== null && _a !== void 0 ? _a : props.min) !== null && _b !== void 0 ? _b : 0);
    const value = (_c = props.value) !== null && _c !== void 0 ? _c : innerValue;
    const onChange = (e, value) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e, value);
        setInnerValue(value);
    };
    const hasLabel = !!leftLabel || !!rightLabel || !!props.inputLabel;
    const getValues = () => (Array.isArray(value) ? value : [value]);
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, style: { height: 30 }, children: hasLabel ? (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, container: true, justifyContent: "flex-start", xs: 6, children: leftLabel ? (_jsx(Typography, { className: classes.labels, "data-testid": "slider-left-label", children: leftLabel(getValues()) })) : null }), _jsx(Grid, { item: true, container: true, justifyContent: "flex-end", xs: 6, children: rightLabel ? (_jsx(Typography, { className: classes.labels, "data-testid": "slider-right-label", children: rightLabel(getValues()) })) : inputLabel ? (_jsx(SliderInput, { value: value, min: props.min, max: props.max, onChange: onChange, classes: classes, children: _jsx(Typography, { className: classes.inputLabel, "data-testid": "slider-input-label", children: inputLabel(getValues()) }) })) : null })] })) : null }), _jsx(MuiSlider, Object.assign({}, rest, { value: value, onChange: onChange, classes: {
                    thumb: classes.thumb,
                    root: classes.root,
                    track: classes.track,
                    rail: classes.rail,
                    valueLabel: classes.valueLabel,
                } }))] }));
}
function SliderInput({ value, min, max, onChange, classes, children, }) {
    const [showInput, setShowInput] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleShowInput = () => {
        setShowInput(true);
        setInputValue(Array.isArray(value) ? String(value[0]) : String(value));
        setInputError(false);
    };
    if (!showInput)
        return (_jsxs("div", { className: classes.inputDiv, onClick: handleShowInput, children: [children, _jsx(PencilOutlined, { className: classes.editIcon })] }));
    return (_jsx(ClickAwayListener, { onClickAway: () => setShowInput(false), children: _jsx(OutlinedInput, { className: classes.input, autoFocus: true, value: inputValue, error: inputError, onChange: (e) => {
                setInputValue(e.target.value);
                const value = Number(e.target.value);
                if (!isNaN(value)) {
                    if (min !== undefined && value < min) {
                        onChange(e, min);
                        setInputError(true);
                    }
                    else if (max !== undefined && value > max) {
                        onChange(e, max);
                        setInputError(true);
                    }
                    else {
                        onChange(e, value);
                        setInputError(false);
                    }
                }
                else {
                    setInputError(true);
                }
            }, onKeyDown: (e) => {
                if (e.key === "Enter") {
                    setShowInput(false);
                }
            }, inputProps: {
                type: "number",
                "aria-labelledby": "input-slider",
                "data-testid": "slider-input",
            } }) }));
}
