import { useEffect, useRef } from "react";
import { useAccountRouteMatch } from "../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { getIdFromEncodedString } from "../_common/utils/getIdFromEncodedString/getIdFromEncodedString";
import { useAutomatorSession } from "../_common/hooks/useAutomatorSession/useAutomatorSession";
import { useLocation } from "react-router-dom";

interface WindowWithPendo extends Window {
  pendo?: {
    set_account_id?: (accountId: string) => void;
    initialize?: (initParams: {
      app: string;
      visitor: { id: string };
      account: { id: string };
    }) => void;
  };
}

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

const isProdEnv = process.env.NODE_ENV === "production";
const pendo = (window as WindowWithPendo)?.pendo;

export function usePendoAnalytics(): void {
  const accountIdFromUrl = useAccountRouteMatch();
  const session = useAutomatorSession();
  const isPendoInitialised = useRef<boolean>(false);
  const location = useLocation();

  const accountId = getIdFromEncodedString(accountIdFromUrl, "Account");
  const userId = session.id;

  useEffect(() => {
    const data = {
      app: "automator",
      visitor: { id: getIdFromEncodedString(userId, "User") },
      account: { id: accountId },
    };
    if (isProdEnv && accountId && !isPendoInitialised.current) {
      pendo?.initialize?.(data);

      isPendoInitialised.current = true;
    }
    if (window.dataLayer) {
      // eslint-disable-next-line fp/no-mutating-methods
      window.dataLayer.push({
        event: "routeChange",
        data: data,
        route: `${location.pathname}${location.search}`,
      });
    }
  }, [userId, accountId, location]);

  useEffect(() => {
    if (isProdEnv && accountId) {
      pendo?.set_account_id?.(accountId);
    }
  }, [accountId]);
}
