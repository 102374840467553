import React from "react";
import { Box } from "@material-ui/core";
import {
  CustomChip,
  CustomChipType,
  getCorrectPathType,
} from "../../../pages/TestSuiteList/test-suite-list/TestCountChips";
import { Routes } from "../../routes/routes";
import { TestSuiteListItemProps } from "../../../pages/TestSuiteList/test-suite-list/TestSuiteListItem";
import { useAccountRouteMatch } from "../../hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useHistory } from "react-router-dom";

type TestResultsProps = Pick<
  TestSuiteListItemProps,
  | "buildId"
  | "id"
  | "totalTests"
  | "failedTests"
  | "warningTests"
  | "passedTests"
>;

export function TestResults(props: TestResultsProps): JSX.Element {
  const accountId = useAccountRouteMatch();
  const history = useHistory();

  function handleClick(chipType: CustomChipType) {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      history.push(
        Routes.TestResults.getUrl({
          accountId,
          testSuiteId: props.id,
          buildTestId: props.buildId || "",
          selectedType: getCorrectPathType(chipType),
        }),
      );
    };
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      data-testid="test-results"
    >
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Fails}
          label={props.failedTests}
          showLabel
          renderAsDisabled={props.failedTests === 0}
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            Number(props.failedTests) > 0
              ? handleClick(CustomChipType.Fails)
              : undefined
          }
        />
      </Box>
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Warnings}
          label={props.warningTests}
          showLabel
          renderAsDisabled={props.warningTests === 0}
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            Number(props.warningTests) > 0
              ? handleClick(CustomChipType.Warnings)
              : undefined
          }
        />
      </Box>
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Passes}
          label={props.passedTests}
          showLabel
          renderAsDisabled={props.passedTests === 0}
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            Number(props.passedTests) > 0
              ? handleClick(CustomChipType.Passes)
              : undefined
          }
        />
      </Box>
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Total}
          label={props.totalTests}
          renderAsDisabled={props.totalTests === 0}
          showLabel
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            Number(props.totalTests) > 0
              ? handleClick(CustomChipType.Total)
              : undefined
          }
        />
      </Box>
    </Box>
  );
}
