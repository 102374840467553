import React from "react";
import { TestSuiteListItem } from "./TestSuiteListItem";
import { Box, Fade, makeStyles, Paper, Typography } from "@material-ui/core";
import { TestSuiteCompactList } from "./TestSuiteCompactList";
import { testSuiteItemInformationFactory } from "../../../_common/utils/testSuiteItemInformationFactory/testSuiteItemInformationFactory";
import { Skeleton } from "@material-ui/lab";
import { EmptyState } from "@lumar/shared";
import Lottie from "react-lottie-player";
import errorAnimation from "../../../_common/animations/exclamation-circle.json";
import { GetNextTestSuitesQuery } from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  compactView: {
    marginBottom: theme.spacing(4),
  },
  tableBox: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  header: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(28.5),
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(2),
  },
}));

interface TestSuiteListProps {
  testSuites?:
    | NonNullable<GetNextTestSuitesQuery["getAccount"]>["testSuites"]
    | null;
  totalTestSuiteCount: number | undefined;
  isLoading: boolean;
  refetchData: () => void;
}

export function TestSuiteList(props: TestSuiteListProps): JSX.Element {
  const classes = useStyles();

  const testSuitesItems =
    props.testSuites?.edges
      .map((edge) => edge.node)
      .map(testSuiteItemInformationFactory) || [];

  const notYetRunTestSuites = testSuitesItems.filter(
    (suite) => suite.status === "NotYetRun",
  );

  const alreadyRunTestSuites = testSuitesItems.filter(
    (suite) => suite.status !== "NotYetRun",
  );

  return (
    <>
      {props.isLoading && (
        <Fade in>
          <Skeleton
            variant="rect"
            role="progressbar"
            width="100%"
            height={500}
            style={{ borderRadius: 8 }}
          />
        </Fade>
      )}

      {!props.isLoading && (
        <>
          {testSuitesItems.length === 0 && props.totalTestSuiteCount === 0 ? (
            <Fade in>
              <Paper>
                <EmptyState
                  width="100%"
                  height={400}
                  title="Sorry, we couldn't find any results"
                  icon={
                    <Lottie
                      loop
                      animationData={errorAnimation}
                      play
                      style={{ width: "80px", margin: "auto" }}
                    />
                  }
                />
              </Paper>
            </Fade>
          ) : (
            <>
              {notYetRunTestSuites.length > 0 && (
                <Fade in>
                  <Box className={classes.compactView}>
                    <TestSuiteCompactList
                      testRuns={notYetRunTestSuites}
                      refetchData={props.refetchData}
                    />
                  </Box>
                </Fade>
              )}

              {alreadyRunTestSuites.length > 0 && (
                <Fade in>
                  <Box>
                    <Typography className={classes.header}>Active</Typography>
                    {/* eslint-disable-next-line fp/no-mutating-methods */}
                    {[...alreadyRunTestSuites]
                      .sort((a, b) => b.lastRun.localeCompare(a.lastRun))
                      .map((suite) => (
                        <span key={suite.id}>
                          <TestSuiteListItem
                            {...suite}
                            refetchData={props.refetchData}
                          />
                        </span>
                      ))}
                  </Box>
                </Fade>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
