import { MenuClassKey } from "@material-ui/core";

export function getMenuProps(
  classes?: Partial<Record<MenuClassKey, string>> | undefined,
): {
  classes: Partial<Record<MenuClassKey, string>> | undefined;
  anchorOrigin: {
    vertical: "bottom";
    horizontal: "left";
  };
  transformOrigin: {
    vertical: "top";
    horizontal: "left";
  };
  getContentAnchorEl: null;
} {
  return {
    classes,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
}
