import { GetTestSuitesQuery } from "../../../graphql";

function haveAnyBuildsFinished(
  testSuiteEdge: NonNullable<
    NonNullable<GetTestSuitesQuery["getAccount"]>["testSuites"]
  >["edges"][0],
) {
  const finishedBuilds = testSuiteEdge.node.builds.nodes.filter(
    (node) => node.finishedAt !== null && node.finishedAt !== undefined,
  );
  return finishedBuilds.length > 0;
}

export function getTestSuitesFromData(data: GetTestSuitesQuery | undefined) {
  if (!data) return undefined;

  return data?.getAccount?.testSuites?.edges
    .filter(haveAnyBuildsFinished)
    .map((edge) => {
      return {
        id: edge.node.id,
        name: edge.node.name,
      };
    });
}
