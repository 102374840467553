import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useLayoutEffect, useRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import { Typography } from "../typography/Typography";
import { useInView } from "react-intersection-observer";
import { ArrowNarrowLeftSolid } from "../../icons";
import { MenuGroup } from "./MenuGroup";
import { AccountOptionsContext } from "./AccountOptions";
export const focusableClass = "account-menu-focus";
export const focusableGroupClass = "account-menu-group";
export const virtualizedListClass = "account-menu-list";
export function SubMenu({ menu, classes }) {
    const { setActiveSubMenu } = useContext(AccountOptionsContext);
    // Initial focus should be set when the element is completely visible, otherwise
    // the animation will break. - Csaba
    const { ref, inView, entry } = useInView({ threshold: 1 });
    const focusCalled = useRef(false);
    useLayoutEffect(() => {
        var _a, _b;
        if (focusCalled.current || !inView)
            return;
        if ((_a = entry === null || entry === void 0 ? void 0 : entry.target) === null || _a === void 0 ? void 0 : _a.focus) {
            (_b = entry === null || entry === void 0 ? void 0 : entry.target) === null || _b === void 0 ? void 0 : _b.focus();
            focusCalled.current = true;
        }
    }, [inView, entry]);
    const root = useRef(null);
    function handleFocus(e) {
        if (!root.current)
            return;
        moveFocus(e, root.current);
    }
    return (_jsxs("div", { className: classes === null || classes === void 0 ? void 0 : classes.menuContainer, style: { paddingBottom: 8 }, ref: root, onKeyDown: handleFocus, children: [Boolean(menu.parent) && (_jsxs(MenuItem, { onClick: () => { var _a, _b; return setActiveSubMenu((_b = (_a = menu.parent) === null || _a === void 0 ? void 0 : _a.key) !== null && _b !== void 0 ? _b : ""); }, className: clsx(classes === null || classes === void 0 ? void 0 : classes.backMenu, focusableClass, focusableGroupClass), children: [_jsx(ArrowNarrowLeftSolid, { className: classes === null || classes === void 0 ? void 0 : classes.backIcon }), _jsx(Typography, { children: menu.name })] })), menu.items.map((group, idx) => (_jsx(MenuGroup, { menu: menu, menuGroup: group, idx: idx, classes: classes, setFocusedEl: ref }, idx)))] }));
}
function moveFocus(e, root) {
    // When a vistualized list gets focus, the focus should move to the active menu item,
    // if there is none, the first item should be focused.
    function focusListItem(list) {
        var _a, _b, _c;
        const active = list.querySelector("[data-active]");
        if (active) {
            (_a = active === null || active === void 0 ? void 0 : active.focus) === null || _a === void 0 ? void 0 : _a.call(active);
            return;
        }
        if (list.scrollTop === 0) {
            (_c = (_b = list.querySelector(`.${focusableClass}`)) === null || _b === void 0 ? void 0 : _b.focus) === null || _c === void 0 ? void 0 : _c.call(_b);
            return;
        }
        function eventHandler() {
            var _a, _b;
            (_b = (_a = list.querySelector(`.${focusableClass}`)) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
            list.removeEventListener("scroll", eventHandler);
        }
        list.addEventListener("scroll", eventHandler);
        list.scrollTo(0, 0);
    }
    // When navigating with the arrow keys, the focus should move to the next or previous
    // focusable element.
    function navigateWithArrows() {
        var _a, _b, _c, _d;
        const direction = e.key === "ArrowUp" ? -1 : e.key === "ArrowDown" ? 1 : 0;
        if (direction === 0)
            return;
        e.preventDefault();
        e.stopPropagation();
        const elements = [...root.querySelectorAll(`.${focusableClass}`)];
        const currentIndex = elements.findIndex((x) => x === document.activeElement);
        const newIndex = (currentIndex === -1 ? 0 : currentIndex) + direction;
        const element = elements[newIndex];
        const isVirtualizedList = (_a = element === null || element === void 0 ? void 0 : element.className) === null || _a === void 0 ? void 0 : _a.includes(virtualizedListClass);
        if (isVirtualizedList && direction < 0) {
            (_c = (_b = elements[newIndex - 1]) === null || _b === void 0 ? void 0 : _b.focus) === null || _c === void 0 ? void 0 : _c.call(_b);
        }
        else if (isVirtualizedList) {
            focusListItem(element);
        }
        else {
            (_d = element === null || element === void 0 ? void 0 : element.focus) === null || _d === void 0 ? void 0 : _d.call(element);
        }
    }
    // When navigating with tab, the focus should move to the next or previous focusable
    // gourp's first element.
    function navigateWithTab() {
        var _a, _b, _c, _d;
        const direction = e.key !== "Tab" ? 0 : e.shiftKey ? -1 : 1;
        if (direction === 0)
            return;
        e.preventDefault();
        e.stopPropagation();
        const elements = [...root.querySelectorAll(`.${focusableGroupClass}`)];
        const currentIndex = elements.findIndex((x) => x === document.activeElement || x.contains(document.activeElement));
        const idx = currentIndex + direction;
        const newIndex = (idx < 0 ? idx + elements.length : idx) % elements.length;
        const element = elements[newIndex];
        const isVirtualizedList = (_a = element === null || element === void 0 ? void 0 : element.className) === null || _a === void 0 ? void 0 : _a.includes(virtualizedListClass);
        const isMenuGroup = element instanceof HTMLDivElement;
        if (isVirtualizedList) {
            focusListItem(element);
        }
        else if (isMenuGroup) {
            (_c = (_b = element.querySelector(`.${focusableClass}`)) === null || _b === void 0 ? void 0 : _b.focus) === null || _c === void 0 ? void 0 : _c.call(_b);
        }
        else {
            (_d = element === null || element === void 0 ? void 0 : element.focus) === null || _d === void 0 ? void 0 : _d.call(element);
        }
    }
    navigateWithArrows();
    navigateWithTab();
}
