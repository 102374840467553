import { Box, Chip, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { BuildStatus } from "../../../graphql";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { BuildTestStatus } from "../../../_common/interfaces/BuildTestStatus";
import { Routes } from "../../../_common/routes/routes";
import { TestsToDisplayOption } from "../../TestSuiteReport/components/TestSuiteReportListUtils";

export enum CustomChipType {
  Total = "Total",
  Fails = "Fail",
  Warnings = "Warn",
  Passes = "Pass",
  Queued = "Queued",
  Running = "Running",
  Cancelled = "Cancelled",
}

export enum CustomChipTypeExceptions {
  Queued = "Queued",
  Running = "Running",
  Cancelled = "Cancelled",
}

interface ChipColors {
  backgroundColor: string;
  hoverColor: string;
  textColor: string;
}

function getColor(
  theme: Theme,
  type: CustomChipType,
  disabled?: boolean,
): ChipColors {
  if (disabled && !(type in CustomChipTypeExceptions)) {
    return {
      backgroundColor: theme.palette.grey[100],
      hoverColor: theme.palette.grey[100],
      textColor: theme.palette.grey[400],
    };
  }
  switch (type) {
    case CustomChipType.Fails:
      return {
        backgroundColor: theme.palette.red[200],
        hoverColor: theme.palette.red[100],
        textColor: theme.palette.red[800],
      };
    case CustomChipType.Warnings:
      return {
        backgroundColor: theme.palette.yellow[200],
        hoverColor: theme.palette.yellow[100],
        textColor: theme.palette.yellow[800],
      };
    case CustomChipType.Passes:
      return {
        backgroundColor: theme.palette.green[200],
        hoverColor: theme.palette.green[100],
        textColor: theme.palette.green[800],
      };
    case CustomChipType.Total:
      return {
        backgroundColor: theme.palette.grey[100],
        hoverColor: theme.palette.grey[200],
        textColor: theme.palette.grey[800],
      };
    case CustomChipType.Queued:
      return {
        backgroundColor: theme.palette.pink[100],
        hoverColor: theme.palette.pink[100],
        textColor: theme.palette.pink[700],
      };
    case CustomChipType.Cancelled:
      return {
        backgroundColor: theme.palette.red[200],
        hoverColor: theme.palette.red[100],
        textColor: theme.palette.red[800],
      };
    case CustomChipType.Running:
      return {
        backgroundColor: theme.palette.yellow[200],
        hoverColor: theme.palette.yellow[100],
        textColor: theme.palette.yellow[800],
      };
    default:
      return {
        backgroundColor: theme.palette.grey[100],
        hoverColor: theme.palette.grey[200],
        textColor: theme.palette.grey[800],
      };
  }
}

const useCustomChipStyles = makeStyles((theme) => ({
  underLabel: {
    textAlign: "center",
    display: "block",
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 400,
    color: theme.palette.grey[600],
  },
}));

const useChipStyles = makeStyles((theme) => ({
  root: (props: CustomChipProps) => {
    const cursor = props.renderAsDisabled ? "default !important" : "pointer";
    const { backgroundColor, hoverColor } = getColor(
      theme,
      props.type,
      props.renderAsDisabled,
    );

    return {
      borderRadius: props.renderAsText ? "4px" : "27px",
      boxShadow: "none",
      width: props.renderAsText ? "100%" : 50,
      height: 19,
      backgroundColor,
      cursor: cursor,
      "&:hover": {
        backgroundColor: hoverColor,
      },
      "&:focus": {
        cursor: cursor,
        backgroundColor: hoverColor,
      },
    };
  },
  label: (props: CustomChipProps) => {
    const { textColor } = getColor(theme, props.type, props.renderAsDisabled);
    return {
      fontSize: 12,
      fontWeight: 600,
      color: textColor,
    };
  },
}));

const useStyles = makeStyles((theme) => ({
  box: {
    marginLeft: 0,
    marginRight: 0,
  },
  text: {
    fontSize: 14,
    color: theme.palette.navy[300],
    fontWeight: 400,
  },
  chipWrapper: {
    marginLeft: theme.typography.pxToRem(4),
  },
  oneChipBlock: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
}));

interface TestCountChipsProps {
  totalTests: number | string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  buildId: string | null;
  testSuiteId: string;
  skipTotalChips?: boolean;
  status?: BuildTestStatus;
  pendoIdSuffix: string;
  showLabel: boolean;
}

interface CustomChipProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type: CustomChipType;
  label: number | string;
  renderAsDisabled: boolean;
  variant: "default" | "outlined" | undefined;
  renderAsText?: boolean;
  pendoIdSuffix: string;
  showLabel: boolean;
}

export function CustomChip(props: CustomChipProps): JSX.Element {
  const classes = useChipStyles(props);
  const classesCustom = useCustomChipStyles();
  const idFriendlyType = props.type?.toLowerCase().replace(/\s/g, "");

  return (
    <>
      <Chip
        data-testid={`test-count-chip-${idFriendlyType}`}
        data-pendo={`auto-${props.pendoIdSuffix}-item-chip-${idFriendlyType}`}
        onClick={props.onClick}
        variant={props.variant}
        label={props.label}
        classes={classes}
      />
      {props.showLabel && (
        <span className={classesCustom.underLabel}>
          {props.showLabel && props.type}
        </span>
      )}
    </>
  );
}

export function getCorrectPathType(type: CustomChipType): TestsToDisplayOption {
  switch (type) {
    case CustomChipType.Passes:
      return TestsToDisplayOption.PASS;
    case CustomChipType.Fails:
      return TestsToDisplayOption.FAIL;
    case CustomChipType.Warnings:
      return TestsToDisplayOption.WARNING;
    case CustomChipType.Total:
    default:
      return TestsToDisplayOption.ALL;
  }
}

// eslint-disable-next-line max-lines-per-function
export function TestCountChips(props: TestCountChipsProps): JSX.Element {
  const { testSuiteId, buildId } = props;
  const classes = useStyles(props);
  const accountId = useAccountRouteMatch();
  const history = useHistory();

  const chipsConfig = [
    {
      title: CustomChipType.Fails,
      label: props.failedTests,
      disabled: props.failedTests === 0,
    },
    {
      title: CustomChipType.Warnings,
      label: props.warningTests,
      disabled: props.warningTests === 0,
    },
    {
      title: CustomChipType.Passes,
      label: props.passedTests,
      disabled: props.passedTests === 0,
    },
    {
      title: CustomChipType.Total,
      label: props.totalTests,
      disabled: props.totalTests === 0,
    },
  ];

  // For the case where the test count is 'Processing' or 'N/A'
  const areResultsStillProcessing = chipsConfig.some(
    (chip) => typeof chip.label === "string",
  );

  function handleClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: CustomChipType,
    disabled: boolean,
  ): void {
    event.stopPropagation();
    event.preventDefault();
    const clickResultPath = buildId
      ? Routes.TestResults.getUrl({
          accountId,
          testSuiteId,
          buildTestId: buildId,
          selectedType: getCorrectPathType(type),
        })
      : Routes.Builds.getUrl({ accountId, testSuiteId });
    if (!disabled) history.push(clickResultPath);
  }

  if (
    props.status === BuildStatus.Cancelled ||
    props.status === BuildStatus.Aborted
  ) {
    return (
      <Box data-testid="cancelled-chip" className={classes.oneChipBlock}>
        <CustomChip
          renderAsDisabled={true}
          type={CustomChipType.Cancelled}
          label="Cancelled"
          variant={undefined}
          renderAsText={true}
          pendoIdSuffix={props.pendoIdSuffix}
          showLabel={false}
        />
      </Box>
    );
  }
  if (props.status === BuildStatus.Queued) {
    return (
      <Box data-testid="queued-chip" className={classes.oneChipBlock}>
        <CustomChip
          renderAsDisabled={true}
          type={CustomChipType.Queued}
          label="Queued"
          variant={undefined}
          renderAsText={true}
          pendoIdSuffix={props.pendoIdSuffix}
          showLabel={false}
        />
      </Box>
    );
  }

  if (areResultsStillProcessing) {
    return (
      <Box data-testid="crawlink-web-site" className={classes.oneChipBlock}>
        <CustomChip
          renderAsDisabled={true}
          type={CustomChipType.Running}
          label="Crawling the website"
          variant={undefined}
          renderAsText={true}
          pendoIdSuffix={props.pendoIdSuffix}
          showLabel={false}
        />
      </Box>
    );
  }

  return (
    <Box
      data-testid="test-count-chips-container"
      display="flex"
      className={classes.box}
    >
      {chipsConfig
        .filter((chip) => {
          return props.skipTotalChips
            ? chip.title !== CustomChipType.Total
            : true;
        })
        .map((chip) => (
          <Box key={chip.title} className={classes.chipWrapper}>
            <Box>
              <CustomChip
                onClick={(event) =>
                  handleClick(event, chip.title, chip.disabled)
                }
                variant={undefined}
                type={chip.title}
                label={chip.label}
                renderAsDisabled={chip.disabled}
                pendoIdSuffix={props.pendoIdSuffix}
                showLabel={props.showLabel}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
}
