import React from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { setAlertsFormValidation } from "../setAlertsFormValidation";
import { AddInput } from "../../../../../_common/components/AddInput/AddInput";
import { AddInputSkeleton } from "../../../../../_common/components/AddInput/AddInputSkeleton";
import { Snackbar } from "@lumar/shared";
import { useCreateEmailAlertMutation } from "../../../../../graphql";

type EmailValue = { alertEmail: string };

interface AddEmailInputProps {
  showConfirmationButton: () => void;
  hideConfirmationButton: () => void;
  testSuiteId: string;
}

export function AddEmailInput(props: AddEmailInputProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [createAlertEmail, { loading }] = useCreateEmailAlertMutation({
    refetchQueries: ["getTestSuite"],
  });

  async function handleCreateEmail(email: string) {
    try {
      await createAlertEmail({
        variables: {
          testSuiteId: props.testSuiteId,
          email,
        },
      });
      enqueueSnackbar(
        <Snackbar title="Email saved successfully" variant="success" />,
      );
    } catch {
      enqueueSnackbar(
        <Snackbar
          title="Something went wrong while trying to save your email"
          variant="error"
        />,
      );
    }
  }

  async function handleSubmit(value: EmailValue) {
    await handleCreateEmail(value.alertEmail);
    props.hideConfirmationButton();
  }

  if (loading) {
    return <AddInputSkeleton xs={10} md={8} testId="add-email-loading" />;
  }

  return (
    <Formik
      initialValues={{ alertEmail: "" }}
      onSubmit={handleSubmit}
      validationSchema={setAlertsFormValidation}
    >
      {(subformik) => (
        <AddInput
          onFocus={props.showConfirmationButton}
          onBlur={props.hideConfirmationButton}
          // eslint-disable-next-line
          formik={subformik as any}
          id="add-email"
          formikLabel="alertEmail"
          label="Add Email"
        />
      )}
    </Formik>
  );
}
