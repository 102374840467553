import { GetTestSuiteReportQuery } from "../../../../../graphql";

export function getCustomExtractionIndexInReportTemplates(
  reportTemplate: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0]["reportTemplate"],
  customExtractions:
    | NonNullable<
        GetTestSuiteReportQuery["node"]
      >["testSuite"]["customExtractions"]
    | undefined
    | null,
) {
  return Array.isArray(customExtractions)
    ? customExtractions
        .map((ce) => ce.reportTemplateCode)
        .indexOf(reportTemplate.code)
    : -1;
}

export function isCustomExtractionInReportTemplate(
  reportTemplate: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0]["reportTemplate"],
  customExtractions:
    | NonNullable<
        GetTestSuiteReportQuery["node"]
      >["testSuite"]["customExtractions"]
    | undefined
    | null,
) {
  return (
    getCustomExtractionIndexInReportTemplates(
      reportTemplate,
      customExtractions,
    ) !== -1
  );
}
