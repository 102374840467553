import React from "react";
import { TestReportList } from "./components/TestSuiteReportList";
import { Box, CircularProgress, Fade, makeStyles } from "@material-ui/core";
import { Alert } from "@lumar/shared";
import { withRouter } from "react-router-dom";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { useGetTestSuiteReportQuery } from "../../graphql";

interface TestSuiteReportProps {
  match: {
    params: {
      buildTestId: string;
      testSuiteId: string;
    };
  };
}

const useStyles = makeStyles((theme) => ({
  loader: {
    margin: theme.spacing(3.5),
  },
  error: {
    marginTop: theme.spacing(11),
  },
}));

interface TestSuiteReportContainerProps extends TestSuiteReportProps {
  children?: React.ReactNode;
}

const TestSuiteReportContainer = withRouter(
  (props: TestSuiteReportContainerProps) => {
    return (
      <>
        {props.children ? (
          <PageContainer>{props.children}</PageContainer>
        ) : null}
      </>
    );
  },
);

export const TestSuiteReport = withRouter((props: TestSuiteReportProps) => {
  const classes = useStyles();

  const { loading, data } = useGetTestSuiteReportQuery({
    variables: {
      id: props.match.params.buildTestId,
    },
    fetchPolicy: "cache-first",
  });

  return (
    <TestSuiteReportContainer>
      {loading && (
        <CircularProgress
          color="secondary"
          data-testid="test-suite-report-loader"
          className={classes.loader}
        />
      )}

      {!loading && !data?.node && (
        <Alert
          severity="error"
          data-testid="test-suite-report-error"
          className={classes.error}
        >
          No data found.
        </Alert>
      )}

      {!loading && data?.node && (
        <Fade in>
          <Box>
            <TestReportList testSuiteReport={data.node} />
          </Box>
        </Fade>
      )}
    </TestSuiteReportContainer>
  );
});
