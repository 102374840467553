import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from "@material-ui/core";
import { createAppTheme } from "./createAppTheme";
import useLocalStorageState from "use-local-storage-state";
import React from "react";
import { useBrand } from "../brand/BrandProvider";
function setFavicon(themeId) {
    const faviconPath = `/favicon-${themeId}.png`;
    const linkElement = document.querySelector("link[rel*='icon']");
    if (linkElement) {
        linkElement.setAttribute("href", faviconPath);
    }
    else {
        const newLinkElement = document.createElement("link");
        newLinkElement.setAttribute("rel", "icon");
        newLinkElement.setAttribute("href", faviconPath);
        document.head.appendChild(newLinkElement);
    }
}
function loadStylesheets(hrefs) {
    hrefs
        .filter((href) => !isStylesheetPresent(href))
        .map((href) => {
        const linkElement = document.createElement("link");
        linkElement.setAttribute("rel", "stylesheet");
        linkElement.setAttribute("href", href);
        return linkElement;
    })
        .forEach((element) => document.head.appendChild(element));
}
function isStylesheetPresent(href) {
    return (Array.from(document.head.querySelectorAll(`link[href='${href}'][rel='stylesheet']`)).length > 0);
}
export function AppThemeProvider(props) {
    const { brandId, stylesheetHrefs } = useBrand();
    const [disableAnimations] = useLocalStorageState("disable-animations", {
        defaultValue: false,
    });
    React.useEffect(() => setFavicon(brandId), [brandId]);
    React.useEffect(() => loadStylesheets(stylesheetHrefs), [stylesheetHrefs]);
    const theme = React.useMemo(() => createAppTheme(props.locale, disableAnimations, brandId), [props.locale, brandId, disableAnimations]);
    return _jsx(ThemeProvider, { theme: theme, children: props.children });
}
