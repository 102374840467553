var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useRef, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLessRounded";
import { UserCircle } from "../../icons";
import { Typography } from "../typography/Typography";
import { SubMenu } from "./SubMenu";
import { HorizontalSlider } from "../transitions/HorizontalSlider";
import { getAccountOptionsStyle } from "./Styles";
export const AccountOptions = withStyles(getAccountOptionsStyle)(AccountOptionsInner);
export const AccountOptionsContext = React.createContext({
    setActiveSubMenu: () => undefined,
    closeMenu: () => undefined,
});
function AccountOptionsInner(_a) {
    var { accountName, accountId, email, role, logo, components, menuItems, className, classes, menuProps, pendoPrefix } = _a, props = __rest(_a, ["accountName", "accountId", "email", "role", "logo", "components", "menuItems", "className", "classes", "menuProps", "pendoPrefix"]);
    const [optionsAnchor, setOptionsAnchor] = React.useState(null);
    const menus = useMemo(() => {
        function getMenus(menu) {
            return [
                menu,
                ...menu.items
                    .flatMap(({ items }) => items.flatMap((subMenu) => subMenu.subMenu
                    ? getMenus({
                        items: subMenu.subMenu,
                        parent: menu,
                        name: subMenu.name,
                        key: `${menu.key}.${subMenu.name}`,
                    })
                    : []))
                    .filter(Boolean),
            ];
        }
        return getMenus({ items: menuItems, key: "root", name: "root" });
    }, [menuItems]);
    const [activeMenu, setActiveMenu] = useState(menus[0].key);
    const prevActiveMenuRef = useRef(activeMenu);
    const prevActiveMenu = prevActiveMenuRef.current;
    prevActiveMenuRef.current = activeMenu;
    const isMenuOpen = Boolean(optionsAnchor);
    // The menu component puts tabIndex prop on it's children.
    // This prevents the account info box to be focusable.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tabIndex = null;
    const { Header } = components !== null && components !== void 0 ? components : {};
    const handleClick = (event) => {
        setOptionsAnchor(event.currentTarget);
    };
    const handleClose = () => {
        setOptionsAnchor(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: handleClick, className: className, "aria-label": "Open account options menu", "data-testid": "account-options-button", "data-pendo": pendoPrefix ? `${pendoPrefix}-account-options-toggle` : undefined, classes: {
                    root: classes === null || classes === void 0 ? void 0 : classes.root,
                } }, props, { disableFocusRipple: true, children: _jsxs(_Fragment, { children: [logo && _jsx("span", { className: classes === null || classes === void 0 ? void 0 : classes.accountLogo, children: logo }), _jsx(UserCircle, { className: classes === null || classes === void 0 ? void 0 : classes.accountIcon }), isMenuOpen ? (_jsx(ExpandLessIcon, { className: classes === null || classes === void 0 ? void 0 : classes.expandIcon })) : (_jsx(ExpandMoreIcon, { className: classes === null || classes === void 0 ? void 0 : classes.expandIcon }))] }) })), _jsx(Menu, Object.assign({ open: isMenuOpen, onClose: handleClose, anchorEl: optionsAnchor, keepMounted: true, 
                // Explanation why it's `null` here: https://github.com/mui-org/material-ui/issues/7961
                getContentAnchorEl: null, anchorOrigin: { horizontal: "right", vertical: "bottom" }, transformOrigin: { horizontal: "right", vertical: "top" }, classes: {
                    paper: classes === null || classes === void 0 ? void 0 : classes.menu,
                    list: classes === null || classes === void 0 ? void 0 : classes.menuList,
                } }, menuProps, { TransitionProps: {
                    mountOnEnter: true,
                    unmountOnExit: true,
                    onExited: () => setActiveMenu(menus[0].key),
                }, children: _jsxs(AccountOptionsContext.Provider, { value: {
                        setActiveSubMenu: setActiveMenu,
                        closeMenu: handleClose,
                        recentlyClosedSubMenu: prevActiveMenu,
                    }, children: [_jsxs("div", { className: classes === null || classes === void 0 ? void 0 : classes.menuContainer, children: [_jsxs("div", { className: classes === null || classes === void 0 ? void 0 : classes.accountInfo, tabIndex: tabIndex, children: [_jsx(Typography, { className: classes === null || classes === void 0 ? void 0 : classes.accountName, component: "div", "data-testid": "account-options-account-name", children: accountName }), _jsx(Typography, { "data-testid": "account-options-account-id", className: classes === null || classes === void 0 ? void 0 : classes.accountId, children: accountId }), _jsx(Tooltip, { title: email || "", children: _jsx(Typography, { "data-testid": "account-options-email", className: classes === null || classes === void 0 ? void 0 : classes.email, children: email }) }), _jsx(Typography, { "data-testid": "account-options-user-role", className: classes === null || classes === void 0 ? void 0 : classes.role, children: role }), _jsx(Divider, {})] }), _jsx("div", { children: Header })] }), _jsx(HorizontalSlider, { step: menus.findIndex((x) => x.key === activeMenu), steps: menus.map((menu) => (_jsx(SubMenu, { menu: menu, classes: classes }, menu.key))) })] }) }))] }));
}
