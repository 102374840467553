import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IndeterminateCheckBox } from "@material-ui/icons";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    indeterminate: {
        maxWidth: 14,
        maxHeight: 14,
        borderRadius: "4px",
        color: theme.palette.primary.light,
        "input:hover ~ &": {
            color: theme.palette.primary.main,
        },
        "input:disabled ~ &": {
            color: theme.palette.grey[200],
        },
    },
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        boxSizing: "border-box",
        overflow: "hidden",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        minWidth: 14,
        minHeight: 14,
        backgroundColor: "#fff",
        "input:hover ~ &": {
            border: `1px solid ${theme.palette.grey[600]}`,
        },
        "input:checked ~ &": {
            border: "none",
        },
        "input:focus ~ &": {
            border: `1px solid ${theme.palette.grey[600]}`,
        },
        "input:disabled ~ &": {
            border: `1px solid ${theme.palette.grey[200]}`,
            boxShadow: "none",
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 14,
            height: 14,
            backgroundImage: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        "input:focus ~ &": {
            border: "none",
        },
        "input:hover ~ &": {
            border: "none",
            backgroundColor: theme.palette.primary.main,
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: theme.palette.grey[200],
            border: "none",
        },
    },
}));
export function Checkbox(props) {
    const classes = useStyles();
    return (_jsx(MuiCheckbox, Object.assign({ indeterminate: props.indeterminate, indeterminateIcon: _jsx(IndeterminateCheckBox, { viewBox: "4 4 16 16", className: classes.indeterminate }), className: classes.root, disableRipple: true, checkedIcon: _jsx("span", { className: clsx(classes.icon, classes.checkedIcon) }), icon: _jsx("span", { className: classes.icon }), inputProps: { "aria-label": "decorative checkbox" } }, props)));
}
