import React from "react";
import { TestThresholdToggle } from "../TestThresholdToggle/TestThresholdToggle";
import {
  TextField,
  Typography,
  Select,
  makeStyles,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import {
  isAbsoluteThresholdInError,
  getHelperTextForAbsoluteThreshold,
} from "./TestThresholdUtils";
import { getMenuProps } from "../../visualOverrides/selectVisualOverrides";
import {
  TestThresholdPredicate,
  Threshold,
} from "../TestCollapse/TestCollapse";
import { Slider } from "@lumar/shared";
import {
  ReportTemplateUnit,
  Severity,
  ThresholdPredicate,
  ThresholdType,
} from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(17),
    marginBottom: 16,
  },
  sectionWrapper: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: "15px 11px",
    borderRadius: 8,
    marginBottom: 13,
  },
  adornmentText: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(17),
  },
  sliderWrapper: {
    width: "100%",
    // Note: this is so that we can keep the slider and the URL text input vertically aligned with the predicate dropdown
    marginTop: -31,
    marginLeft: theme.spacing(1),
  },
  dropdown: { minWidth: 203, marginRight: 8, width: "100%" },
}));

const avaliableThresholdPredicates: TestThresholdPredicate[] = [
  {
    label: "greater than or equal to",
    value: ThresholdPredicate.GreaterThanOrEqual,
    default: true,
  },
  {
    label: "less than",
    value: ThresholdPredicate.LessThan,
    default: false,
  },
];

interface TestThresholdProps {
  threshold: Threshold;
  severity: Severity;
  onChange: (threshold: Threshold) => void;
  testCode: string;
  absoluteThresholdUpLimit: number;
  forceNumericThreshold: boolean;
  unit: ReportTemplateUnit;
}

export function TestThreshold(props: TestThresholdProps): JSX.Element {
  const classes = useStyles();
  const { threshold, severity, forceNumericThreshold, onChange } = props;

  const { title, description } = useTextContent(severity, threshold.type);

  function handleThresholdTypeChange(thresholdType: ThresholdType): void {
    const newThresholdObj = { ...threshold, type: thresholdType };
    onChange(newThresholdObj);
  }

  function handleThresholdPredicateChange(
    thresholdPredicate: ThresholdPredicate | undefined | unknown,
  ): void {
    if (
      thresholdPredicate === ThresholdPredicate.GreaterThanOrEqual ||
      thresholdPredicate === ThresholdPredicate.LessThan
    ) {
      const newThresholdObj = { ...threshold, predicate: thresholdPredicate };
      onChange(newThresholdObj);
    }
  }

  function handleThresholdValueChange(
    newValue: number | number[],
    thresholdType: ThresholdType,
  ): void {
    const isRelativeThreshold = thresholdType === ThresholdType.Relative;
    const inputValue = typeof newValue === "number" ? newValue : newValue[0];
    const newThresholdObj = isRelativeThreshold
      ? { ...threshold, relative: inputValue }
      : { ...threshold, absolute: inputValue };
    onChange(newThresholdObj);
  }

  return (
    <div data-testid="test-threshold">
      {!forceNumericThreshold ? (
        <div className={classes.sectionWrapper}>
          <Typography
            color="textPrimary"
            className={classes.sectionTitle}
            data-testid="test-threshold-main-text"
          >
            {title}
          </Typography>

          <TestThresholdToggle
            threshold={threshold}
            handleThresholdTypeChange={handleThresholdTypeChange}
          />
        </div>
      ) : null}

      <div className={classes.sectionWrapper}>
        <Typography
          className={classes.sectionTitle}
          variant="body1"
          data-testid="test-threshold-information"
        >
          {description}
        </Typography>
        <div style={{ display: "flex", alignItems: "start" }}>
          <Select
            variant="outlined"
            onChange={(event) =>
              handleThresholdPredicateChange(event?.target?.value)
            }
            defaultValue={
              avaliableThresholdPredicates.filter(
                (predicate) => predicate.default,
              )[0]
            }
            value={props.threshold.predicate}
            data-testid="test-threshold-predicament-select"
            data-cy="test-threshold-predicament-select"
            data-pendo="auto-test-suite-edit-choose-tests-test-predicament-select"
            inputProps={{
              "data-testid": "test-threshold-predicament-select-input",
            }}
            MenuProps={getMenuProps()}
            className={classes.dropdown}
          >
            {avaliableThresholdPredicates.map((predicate) => {
              return (
                <MenuItem
                  key={predicate.label.toLowerCase()}
                  value={predicate.value}
                >
                  {predicate.label}
                </MenuItem>
              );
            })}
          </Select>

          {threshold.type === ThresholdType.Relative ? (
            <div className={classes.sliderWrapper}>
              <Slider
                id={`${props.testCode}-relative-threshold-slider`}
                valueLabelDisplay="auto"
                onChange={(_, newValue) => {
                  handleThresholdValueChange(newValue, ThresholdType.Relative);
                }}
                value={threshold.relative}
                rightLabel={() => `${threshold.relative}%`}
                step={1}
                min={1}
                max={100}
                data-cy="test-severity-threshold-slider"
                data-testid="test-severity-threshold-slider"
                data-pendo={`auto-test-suite-edit-choose-tests-test-slider`}
              />
            </div>
          ) : (
            <TextField
              type="number"
              variant="outlined"
              id={`${props.testCode}-absolute-threshold-input`}
              data-pendo={`auto-test-suite-edit-choose-tests-test-threshold-value`}
              data-testid={`${props.testCode}-absolute-threshold-input`}
              data-cy="absolute-threshold-input"
              value={threshold.absolute || ""}
              style={{ width: "100%" }}
              error={isAbsoluteThresholdInError(
                threshold,
                props.absoluteThresholdUpLimit,
              )}
              helperText={getHelperTextForAbsoluteThreshold(
                threshold,
                props.absoluteThresholdUpLimit,
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography className={classes.adornmentText}>
                      {props.unit}
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleThresholdValueChange(
                  Math.max(parseInt(e.target.value), 1),
                  ThresholdType.Absolute,
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function useTextContent(
  severity: Severity,
  thresholdType: ThresholdType,
): { title: string; description: string } {
  const isFail = severity === Severity.Fail;
  const isThresholdRelative = thresholdType === ThresholdType.Relative;

  const description = `${isFail ? "Fail" : "Warn"} if the number of ${
    isThresholdRelative ? "URLs" : "results"
  } is`;

  const title = `${isFail ? "Failure" : "Warning"} threshold`;

  return {
    title,
    description,
  };
}
