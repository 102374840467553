import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export function HelpScoutStyleOverwrite() {
    React.useLayoutEffect(() => {
        const style = document.createElement("style");
        // eslint-disable-next-line fp/no-mutation
        style.innerHTML = getHelpScoutStyleOverwrite();
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);
    return _jsx(_Fragment, {});
}
function getHelpScoutStyleOverwrite() {
    return `
  .BeaconContainer {
    left: 80px !important;
    bottom: 20px !important;
    z-index: 3000 !important;
    width: 600px !important;
  }

  .BeaconFabButtonFrame {
    display: none;
  }
  `;
}
