import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  error: {
    marginBottom: theme.spacing(2),
  },
  groupLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 600,
    color: theme.palette.grey[700],
    padding: theme.spacing(1.25, 2, 1.25, 2),
    background: theme.palette.grey[50],
  },
  groupUl: {
    margin: 0,
    padding: 0,
    "& li": {
      padding: theme.spacing(1, 2, 1, 2),
      margin: "2px 8px",
      color: theme.palette.grey[700],
      borderRadius: 6,
    },
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 400,
  },
  paper: {
    marginTop: "14px",
  },
  icon: {
    color: theme.palette.ultraviolet[500],
    margin: -6,
  },
}));
