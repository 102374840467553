import {
  AuthProvider,
  BrandProvider,
  GTMSetup,
  I18nProvider,
} from "@lumar/shared";
import "normalize.css";
import ReactDOM from "react-dom";
import "./index.css";
import { resources } from "./_common/locale/resources";
import { ErrorDisplay } from "@lumar/shared";
import {
  ErrorBoundary as RollbarErrorBoundary,
  LEVEL_ERROR,
  Provider as RollbarProvider,
} from "@rollbar/react";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { rollbarConfig } from "./rollbarConfig";

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <RollbarErrorBoundary level={LEVEL_ERROR} fallbackUI={ErrorDisplay}>
      <BrandProvider>
        <GTMSetup />
        <I18nProvider resources={resources}>
          <BrowserRouter>
            <AuthProvider app="protect">
              <App />
            </AuthProvider>
          </BrowserRouter>
        </I18nProvider>
      </BrandProvider>
    </RollbarErrorBoundary>
  </RollbarProvider>,
  document.getElementById("root"),
);
