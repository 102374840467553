import { generatePath } from "react-router-dom";
import queryString from "query-string";
const domain = process.env.REACT_APP_MONITOR_APP_URL &&
    removeTrailingSlash(process.env.REACT_APP_MONITOR_APP_URL);
function removeTrailingSlash(value) {
    if (value.endsWith("/")) {
        return value.substring(0, value.length - 1);
    }
    return value;
}
export const MonitorRoutes = {
    Domain: domain || "",
    Dashboard: {
        ROUTE: `/accounts/:accountId/dashboard/:collectionId?/:dashboardId?`,
        getUrl: ({ accountId, collectionId, dashboardId, }) => {
            const url = generatePath(MonitorRoutes.Dashboard.ROUTE, {
                accountId,
                collectionId,
                dashboardId,
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
    Notifications: {
        ROUTE: `/accounts/:accountId/notifications`,
        getUrl: ({ accountId }) => {
            const url = generatePath(MonitorRoutes.Notifications.ROUTE, {
                accountId,
            });
            return queryString.stringifyUrl({ url, query: {} });
        },
    },
};
