import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarKey, useSnackbar } from "notistack";
import { AlertTitle } from "@material-ui/lab";
import {
  Alert,
  CheckCircle,
  XCircleSolid,
  ToggleIconButton,
} from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    zIndex: 2000,
  },
  alert: {
    border: `1px solid ${theme.palette.green[300]}`,
    borderRadius: 8,
    maxWidth: 472,
    backgroundColor: theme.palette.green[50],
    padding: theme.spacing(2),
  },
  alertTitle: {
    color: theme.palette.grey[900],
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
    marginBottom: theme.spacing(1),
  },
  message: {
    padding: 0,
    "& p": {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(17),
      color: theme.palette.grey[900],
    },
  },
  icon: {
    fontSize: "28px",
    marginTop: "-7px",
  },
  alertAction: {
    alignItems: "flex-start",
    "& svg": {
      padding: 0,
      marginRight: 7,
      fontSize: "1.5rem",
      color: theme.palette.grey[500],
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  iconButton: {
    "&:hover": {
      background: "unset !important",
    },
  },
}));

interface CustomSnackbarProps {
  id: SnackbarKey;
  title: string;
  body: string | JSX.Element;
}

export const CustomSnackbar = React.forwardRef(function CustomSnackbar(
  props: CustomSnackbarProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  function handleDismiss(): void {
    closeSnackbar(props.id);
  }

  return (
    <div ref={ref}>
      <Alert
        action={
          <ToggleIconButton className={classes.iconButton}>
            <XCircleSolid onClick={handleDismiss} />
          </ToggleIconButton>
        }
        severity="success"
        data-testid="custom-snackbar"
        className={classes.alert}
        classes={{ message: classes.message, action: classes.alertAction }}
        iconMapping={{
          success: (
            <CheckCircle
              data-testid="close-alert"
              fontSize="default"
              className={classes.icon}
            />
          ),
        }}
      >
        <AlertTitle
          className={classes.alertTitle}
          data-testid="custom-snackbar-title"
        >
          {props.title}
        </AlertTitle>
        <div data-testid="custom-snackbar-body">{props.body}</div>
      </Alert>
    </div>
  );
});
