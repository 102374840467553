import { GetTestSuiteReportQuery, Severity } from "../../../graphql";

export enum TestsToDisplayOption {
  ALL = "all",
  WARNING = "warning",
  PASS = "pass",
  FAIL = "fail",
}

export function getTotalTestCount(
  suiteReport: NonNullable<GetTestSuiteReportQuery["node"]>,
): number {
  return (
    (suiteReport.failedTestCount || 0) +
    (suiteReport.warnedTestCount || 0) +
    (suiteReport.passedTestCount || 0)
  );
}

function displayFail(testsToDisplay: TestsToDisplayOption): boolean {
  return testsToDisplay === TestsToDisplayOption.FAIL;
}
function displayWarning(testsToDisplay: TestsToDisplayOption): boolean {
  return testsToDisplay === TestsToDisplayOption.WARNING;
}
function displayPass(testsToDisplay: TestsToDisplayOption): boolean {
  return testsToDisplay === TestsToDisplayOption.PASS;
}
function displayAll(testsToDisplay: TestsToDisplayOption): boolean {
  return testsToDisplay === TestsToDisplayOption.ALL;
}

export function didTestPassed(
  testResult: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0],
  displayPass: boolean,
): boolean {
  return displayPass && !!testResult.passed;
}

export function isTestResultWarninig(
  testResult: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0],
  displayWarning: boolean,
): boolean {
  return (
    displayWarning && !testResult.passed && testResult.severity === "Warning"
  );
}

export function isTestResultFail(
  testResult: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0],
  displayFail: boolean,
): boolean {
  return displayFail && !testResult.passed && testResult.severity === "Fail";
}

export function isTestToBeDisplayed(
  testResult: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"][0],
  testsToDisplay: TestsToDisplayOption,
): boolean {
  const pass = didTestPassed(testResult, displayPass(testsToDisplay));
  const warning = isTestResultWarninig(
    testResult,
    displayWarning(testsToDisplay),
  );
  const fail = isTestResultFail(testResult, displayFail(testsToDisplay));
  return pass || warning || fail || displayAll(testsToDisplay);
}

export function sortTestResults(
  testResults: NonNullable<
    GetTestSuiteReportQuery["node"]
  >["testResults"]["nodes"],
): NonNullable<GetTestSuiteReportQuery["node"]>["testResults"]["nodes"] {
  // eslint-disable-next-line fp/no-mutating-methods
  const testResultsSortedByName = [...testResults].sort((a, b) =>
    a.reportTemplate.name.localeCompare(b.reportTemplate.name),
  );
  const failedTests = testResultsSortedByName.filter(
    (test) => test.severity === Severity.Fail && !test.passed,
  );
  const warningTests = testResultsSortedByName.filter(
    (test) => test.severity === Severity.Warning && !test.passed,
  );
  const passedTests = testResultsSortedByName.filter((test) => test.passed);

  return [...failedTests, ...warningTests, ...passedTests];
}

export function isSelectedtTypeValid(
  selectedType: TestsToDisplayOption | string | undefined,
): boolean {
  return (
    selectedType === TestsToDisplayOption.ALL ||
    selectedType === TestsToDisplayOption.FAIL ||
    selectedType === TestsToDisplayOption.PASS ||
    selectedType === TestsToDisplayOption.WARNING
  );
}
