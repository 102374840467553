import React from "react";
import { BuildInfo } from "../../Dashboard";
import { Grid, makeStyles } from "@material-ui/core";
import { BuildStatus } from "../../../../graphql";
import { Link } from "react-router-dom";
import { Routes } from "../../../../_common/routes/routes";
import { BuildListItemContent } from "./BuildListItemContent";
import { TestsToDisplayOption } from "../../../TestSuiteReport/components/TestSuiteReportListUtils";

interface BuildListItemProps {
  buildInfo: BuildInfo;
  accountId: string;
}

const useStyles = makeStyles((theme) => ({
  overflowText: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  bold: {
    fontWeight: "bold",
  },
  link: { textDecoration: "none" },
  elapsedMessage: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.navy[300],
  },
  timestamp: { fontWeight: 500 },
}));

export function BuildListItem(props: BuildListItemProps) {
  const { buildInfo } = props;
  const classes = useStyles(props);

  const isBuildFinished = buildInfo.status === BuildStatus.Finished;
  const editTestSuiteBuildURL = Routes.TestResults.getUrl({
    accountId: props.accountId,
    testSuiteId: buildInfo.testSuiteId,
    buildTestId: buildInfo.id,
    selectedType: TestsToDisplayOption.ALL,
  });

  return (
    <Grid
      container
      key={buildInfo.id}
      data-testid="build-list-item"
      direction="row"
      alignItems="center"
    >
      <Grid item xs={12}>
        {isBuildFinished ? (
          <Link
            to={editTestSuiteBuildURL}
            className={classes.link}
            data-testid="build-list-item-link"
          >
            <BuildListItemContent buildInfo={buildInfo} />
          </Link>
        ) : (
          <BuildListItemContent buildInfo={buildInfo} />
        )}
      </Grid>
    </Grid>
  );
}
