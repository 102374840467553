var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
const useTabsStyles = makeStyles((theme) => ({
    root: (props) => ({
        minHeight: 0,
        background: props.colorVariant === "grey"
            ? theme.palette.grey[200]
            : theme.palette.grey[100],
        borderRadius: 8,
        border: props.colorVariant === "grey"
            ? `1px solid ${theme.palette.grey[300]}`
            : "none",
    }),
}));
const useTabStyles = makeStyles((theme) => ({
    root: (props) => ({
        minWidth: "104px !important",
        minHeight: 0,
        lineHeight: "14.52px",
        margin: 3,
        borderRadius: 8,
        fontWeight: 500,
        fontSize: 14,
        border: props.colorVariant === "grey"
            ? `1px solid ${theme.palette.grey[200]}`
            : "none",
        color: theme.palette.grey[700],
        paddingTop: 5,
        paddingBottom: 5,
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        },
    }),
    selected: (props) => ({
        backgroundColor: "white !important",
        fontWeight: 600,
        border: props.colorVariant === "grey"
            ? `1px solid ${theme.palette.grey[300]} !important`
            : "none",
    }),
}));
const ColorVariantContext = React.createContext({});
export const SecondaryTabs = (_a) => {
    var { colorVariant = "white", onChange } = _a, tabsProps = __rest(_a, ["colorVariant", "onChange"]);
    const classes = useTabsStyles({ colorVariant });
    return (_jsx(ColorVariantContext.Provider, { value: { colorVariant }, children: _jsx(Tabs, Object.assign({ classes: classes }, tabsProps, { TabIndicatorProps: { hidden: true }, onChange: onChange })) }));
};
export const SecondaryTab = (props) => {
    const context = React.useContext(ColorVariantContext);
    const classes = useTabStyles({ colorVariant: context.colorVariant });
    return _jsx(Tab, Object.assign({ classes: classes }, props));
};
