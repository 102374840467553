import React from "react";
import { TestSuiteReportTestResult } from "./TestSuiteReportTestResult";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";
import {
  getTotalTestCount,
  isTestToBeDisplayed,
  sortTestResults,
  TestsToDisplayOption,
} from "./TestSuiteReportListUtils";
import { getCustomExtractionTestName } from "../../../_common/utils/getCustomExtractionTestName/getCustomExtractionTestName";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { CogSolid, SecondaryTabs, SecondaryTab } from "@lumar/shared";
import { GetTestSuiteReportQuery } from "../../../graphql";

const useTestSuiteReportStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(4.5),
  },
  allTestsButton: {
    fontSize: "2rem",
    marginRight: theme.spacing(4),
  },
  h2: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.grey[600],
    marginBottom: 2,
  },
  h3: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(19.5),
    color: theme.palette.grey[600],
    marginBottom: 4,
  },
  h1: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(34.5),
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(1),
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    width: "max-content",
    "&:hover": {
      color: theme.palette.ultraviolet[500],
    },
  },
  linkIcon: {
    fontSize: 18,
    marginRight: 5,
  },
  tabs: {
    maxWidth: 442,
  },
}));

export function TestReportList(props: {
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>;
}): JSX.Element {
  const { selectedType } = useParams<{ selectedType?: TestsToDisplayOption }>();

  const [testsToDisplay, setTestsToDisplay] = React.useState(
    selectedType ?? TestsToDisplayOption.ALL,
  );
  const classes = useTestSuiteReportStyles();
  const accountId = useAccountRouteMatch();

  const { testSuiteReport } = props;
  const testsResults = testSuiteReport.testResults.nodes;
  const sortedTestsResults = sortTestResults(testsResults);

  const handleTabChange = (
    _: React.ChangeEvent<{ value?: number }>,
    newValue: TestsToDisplayOption,
  ) => {
    setTestsToDisplay(newValue);
  };

  // GraphQL query uses a filter so that it only returns builds that have a status of 'Running' or 'Queued'
  const isBuildRunning = Boolean(testSuiteReport.testSuite.builds.nodes.length);

  const totalTestCount = getTotalTestCount(testSuiteReport);

  return (
    <>
      <Typography className={classes.h1} component="h1">
        Test results ({totalTestCount})
      </Typography>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className={classes.grid}
      >
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <Box>
            <Typography
              noWrap
              title={testSuiteReport.testSuite.name}
              className={classes.h2}
              component="h2"
            >
              {testSuiteReport.testSuite.name}
            </Typography>
            <Typography noWrap className={classes.h3} component="h3">
              {testSuiteReport.testSuite.primaryDomain}
            </Typography>

            {!isBuildRunning ? (
              <Link
                to={Routes.EditTestSuite.getUrl({
                  accountId,
                  testSuiteId: testSuiteReport.testSuite.id,
                })}
                data-testid="edit-test-suite-button"
                data-pendo="auto-testsuite-report-edit-testsuite"
                className={classes.link}
              >
                <CogSolid className={classes.linkIcon} />
                <span style={{ paddingTop: 2 }}>Test suite settings</span>
              </Link>
            ) : null}
          </Box>
        </Grid>
        <Grid item>
          <SecondaryTabs
            value={testsToDisplay}
            onChange={handleTabChange}
            className={classes.tabs}
            colorVariant="grey"
          >
            <SecondaryTab
              label="All"
              value={TestsToDisplayOption.ALL}
              data-testid="all-reports-button"
              data-pendo="auto-report-all-reports-button"
            />
            <SecondaryTab
              value={TestsToDisplayOption.FAIL}
              label={
                <TabLabel
                  firstLine="Fails"
                  secondLine={`${testSuiteReport.failedTestCount}`}
                />
              }
              data-testid="fail-reports-button"
              data-pendo="auto-report-fail-reports-button"
              disabled={!testSuiteReport.failedTestCount}
            />
            <SecondaryTab
              value={TestsToDisplayOption.WARNING}
              label={
                <TabLabel
                  firstLine="Warnings"
                  secondLine={`${testSuiteReport.warnedTestCount}`}
                />
              }
              data-testid="warning-reports-button"
              data-pendo="auto-report-warning-reports-button"
              disabled={!testSuiteReport.warnedTestCount}
            />
            <SecondaryTab
              value={TestsToDisplayOption.PASS}
              label={
                <TabLabel
                  firstLine="Passes"
                  secondLine={`${testSuiteReport.passedTestCount}`}
                />
              }
              data-testid="pass-reports-button"
              data-pendo="auto-report-pass-reports-button"
              disabled={!testSuiteReport.passedTestCount}
            />
          </SecondaryTabs>
        </Grid>
      </Grid>

      {sortedTestsResults.map((testResult) => {
        return isTestToBeDisplayed(testResult, testsToDisplay) ? (
          <TestSuiteReportTestResult
            testSummary={testResult.reportTemplate.automatorSummary}
            key={testResult.reportTemplate.code}
            isPassed={!!testResult.passed}
            severity={testResult.severity}
            testName={getCustomExtractionTestName(
              testResult.reportTemplate,
              testSuiteReport?.testSuite?.customExtractions,
            )}
            crawlDcWebUrl={testSuiteReport.crawlDcWebUrl}
            reportCode={testResult.reportTemplate.code}
          />
        ) : null;
      })}
    </>
  );
}

const TabLabel = (props: { firstLine: string; secondLine: string }) => (
  <>
    {props.firstLine}
    <br />
    {props.secondLine}
  </>
);
