import {
  GetJiraIntegrationsQuery,
  GetTestSuiteQuery,
} from "../../../../../graphql";

export enum JiraIntegrationKey {
  NAME = "integration_name",
  JIRA_URL = "jira_url",
  JIRA_PROJECT_NAME = "jira_project_name",
  JIRA_ISSUE_TYPE_NAME = "jira_issue_type_name",
  JIRA_INTEGRATION_STATUS = "jira_integration_status",
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function decorateMetadata(
  integrationStatus: string,
  jiraIntegration?:
    | null
    | NonNullable<
        NonNullable<GetTestSuiteQuery["node"]>["testSuiteJiraIntegration"]
      >["jiraIntegration"]
    | NonNullable<
        GetJiraIntegrationsQuery["getAccount"]
      >["jiraAuthentications"]["nodes"][0]["jiraIntegrations"]["nodes"][0],
) {
  return jiraIntegration
    ? [
        { key: JiraIntegrationKey.NAME, value: jiraIntegration.name },
        { key: JiraIntegrationKey.JIRA_URL, value: jiraIntegration.jiraUrl },
        {
          key: JiraIntegrationKey.JIRA_PROJECT_NAME,
          value: jiraIntegration.jiraProjectName,
        },
        {
          key: JiraIntegrationKey.JIRA_ISSUE_TYPE_NAME,
          value: jiraIntegration.jiraIssueTypeName,
        },
        {
          key: JiraIntegrationKey.JIRA_INTEGRATION_STATUS,
          value: integrationStatus,
        },
      ]
    : [];
}
