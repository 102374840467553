import React from "react";
import { AuthPage } from "@lumar/shared";
import { Redirect, Switch, withRouter } from "react-router-dom";
import { AuthRoute } from "../_common/components/AuthRoute/AuthRoute";
import { Routes } from "../_common/routes/routes";

export const AppRoutesPublic = withRouter(() => {
  return (
    <Switch>
      <AuthRoute
        exact
        path={Routes.Login.ROUTE}
        render={() => {
          return (
            <AuthPage
              onSession={(_, history) => {
                history.replace(Routes.LoginCallback.ROUTE);
              }}
            />
          );
        }}
      />
      <AuthRoute
        path={Routes.Any.ROUTE}
        render={() => <Redirect to={Routes.Login.ROUTE} />}
      />
    </Switch>
  );
});
