import { Resource } from "i18next";

const req = require.context("./", true, /\.json$/);

const getResources = (lng: "en" | "ja"): Resource =>
  Object.fromEntries(
    req
      .keys()
      .filter((key) => key.startsWith(`./locales/${lng}/`))
      .map((key) => {
        const namespace = key
          .replace(`./locales/${lng}/`, "")
          .replace(".json", "");
        return [namespace, req(key)];
      }),
  );

export const resources = {
  en: getResources("en"),
  ja: getResources("ja"),
};
