import React, { useState } from "react";
import {
  PopoverDialog,
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers,
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import {
  Typography,
  Box,
  makeStyles,
  ButtonBase,
  Collapse,
  Chip,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import { Alert, Snackbar, ToggleIconButton, XOutlined } from "@lumar/shared";
import { UseAsGlobalTemplate } from "./UseAsGlobalTemplate";
import { Link } from "react-router-dom";
import { useAccountRouteMatch } from "../../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { UseAsGlobalTemplateVariant } from "./UseAsGlobalTemplate";
import { Routes } from "../../../../../_common/routes/routes";
import { useSnackbar } from "notistack";
import { defaultPopOverId } from "../../../../../_common/constants/popover";
import { LoadMoreItems } from "../../../../../_common/components/LoadMoreItems/LoadMoreItems";
import { ExpandLess } from "@material-ui/icons";
import { DisableForViewer } from "../../../../../_common/components/DisableForViewer/DisableForViewer";
import {
  GetTestSuiteChildrenQuery,
  useUnlinkChildTestSuiteFromParentTestSuiteMutation,
} from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  testSuiteItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    padding: "5px 5px 5px 12px",
    marginBottom: 4,
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[100],
      "& p": {
        color: theme.palette.primary.main,
      },
    },
  },
  testSuiteWrapper: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  header: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(15),
  },
  subtitle: {
    color: theme.palette.navy[200],
  },
  testSuiteName: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    height: "auto",
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    "& [class*=MuiChip-label]": {
      padding: "1px 9px",
    },
  },
  collapsePanelIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    fontSize: 18,
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(3, 0, 2, 0),
  },
}));

interface ChildrenAddedSuccessfullyProps {
  childrenTestSuites: NonNullable<
    NonNullable<GetTestSuiteChildrenQuery["node"]>["children"]
  >["edges"];
  totalAvailableChildren?: number;
  loadMoreChildren: () => void;
  isFetchingMoreChildren: boolean;
  useAsGlobalTemplate: (childId: string, childName: string) => Promise<void>;
  testSuiteId: string;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Cancel",
    identifier: PopoverDialogDefaultIdentifiers.CANCEL,
    color: "primary",
  },
  {
    label: "Yes, remove",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "alert",
  },
];

//eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function ChildrenAddedSuccessfully(
  props: ChildrenAddedSuccessfullyProps,
): JSX.Element {
  const classes = useStyles();
  const [unlinkChildFromParent, { loading, error }] =
    useUnlinkChildTestSuiteFromParentTestSuiteMutation({
      refetchQueries: ["getTestSuite", "getTestSuiteChildren"],
    });
  const [childTestSuite, setChildTestSuite] = useState({ name: "", id: "" });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const accountId = useAccountRouteMatch();
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  async function handleUnlinkChildTestsuite(
    id: PopoverDialogDefaultIdentifiers | string,
  ): Promise<void> {
    setAnchorEl(null);
    if (id === PopoverDialogDefaultIdentifiers.OK) {
      try {
        const {} = await unlinkChildFromParent({
          variables: { childId: childTestSuite.id },
        });
        enqueueSnackbar(
          <Snackbar
            title="Test suite removed successfully."
            variant="success"
          />,
        );
      } catch {
        // should be handled by GraphQL
      }
    }
    setChildTestSuite({ name: "", id: "" });
  }

  function handleClick(childId: string, childName: string) {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      if (!loading) {
        setAnchorEl(event.currentTarget);
        setChildTestSuite({ id: childId, name: childName });
      }
    };
  }

  function handlePanelClick(): void {
    setIsPanelOpen((o) => !o);
  }

  if (loading)
    return (
      <Skeleton
        variant="rect"
        height={200}
        data-testid="children-testsuites-loading"
      />
    );

  if (error) {
    return (
      <Alert
        className={classes.error}
        severity="error"
        data-testid="unlink-child-error"
      >
        An error occurred while trying to unlink the test suite. Please refresh
        the page and try again.
      </Alert>
    );
  }

  return (
    <Box data-testid="children-applied-view" paddingTop={3}>
      <ButtonBase
        data-testid="children-test-suites-expansion-panel"
        onClick={handlePanelClick}
        style={{ width: "100%" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            color="textPrimary"
            className={classes.header}
            data-testid="children-added-title"
            noWrap
          >
            Test suites using this template
          </Typography>
          <Box display="flex" alignItems="center">
            {isPanelOpen ? (
              <ExpandLess className={classes.collapsePanelIcon} />
            ) : (
              <>
                <Chip
                  data-testid="children-added-testsuite-count"
                  label={props.totalAvailableChildren}
                  className={classes.chip}
                />
                <ExpandMoreIcon className={classes.collapsePanelIcon} />
              </>
            )}
          </Box>
        </Box>
      </ButtonBase>

      <Collapse in={isPanelOpen}>
        <Box className={classes.testSuiteWrapper}>
          {props.childrenTestSuites.map(({ node: { id, name } }) => {
            return (
              <Link
                key={id}
                to={Routes.EditTestSuite.getUrl({ accountId, testSuiteId: id })}
                style={{ textDecoration: "none" }}
              >
                <div className={classes.testSuiteItem}>
                  <Typography
                    noWrap
                    className={classes.testSuiteName}
                    data-testid="child-testsuite-name"
                    data-cy={`child-testsuite-name-${name}`}
                  >
                    {name}
                  </Typography>

                  <DisableForViewer tooltipTitle="Remove from global template">
                    <ToggleIconButton
                      size="small"
                      colorVariant="red"
                      data-testid="unlink-child-testsuite"
                      data-cy={`unlink-child-testsuite-${name}`}
                      data-pendo="auto-test-suite-edit-template-settings-global-template-unlink-child"
                      onClick={handleClick(id, name)}
                      active={Boolean(anchorEl)}
                    >
                      <XOutlined />
                    </ToggleIconButton>
                  </DisableForViewer>
                </div>
              </Link>
            );
          })}
        </Box>
        <Box marginTop={1}>
          <LoadMoreItems
            currentCount={props.childrenTestSuites.length}
            totalCount={props.totalAvailableChildren || 0}
            onClick={props.loadMoreChildren}
            isLoading={props.isFetchingMoreChildren}
            dataId="children-test-suites"
            variant="stacked"
          />
        </Box>
      </Collapse>

      <Divider className={classes.divider} />

      <UseAsGlobalTemplate
        handleUseAsGlobalTemplate={props.useAsGlobalTemplate}
        label="Apply template to more test suites"
        variant={UseAsGlobalTemplateVariant.AddAnotherChild}
        testSuiteId={props.testSuiteId}
        placeholder="Choose test suites..."
      />

      <PopoverDialog
        anchorElement={anchorEl}
        handleAction={handleUnlinkChildTestsuite}
        open={Boolean(anchorEl)}
        text="This test suite will be disconnected from the global template. Any future changes made to the global template will not be applied."
        title="Remove test suite from global template?"
        buttons={popoverButtons}
        id={defaultPopOverId}
      />
    </Box>
  );
}
