import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  withStyles,
} from "@material-ui/core";
import { NoTemplateSettingsView } from "./components/NoTemplateSettingsView";
import { GlobalTemplateApplied } from "./components/GlobalTemplateApplied";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Alert, Snackbar, useSession } from "@lumar/shared";
import { useAccountRouteMatch } from "../../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { ChildrenAddedSuccessfully } from "./components/ChildrenAddedSuccessfully";
import clsx from "clsx";
import { Routes } from "../../../../_common/routes/routes";
import { useSnackbar } from "notistack";
import { CustomSnackbar } from "../../../../_common/components/CustomSnackbar/CustomSnackbar";
import {
  isResolutionWithin,
  ResolutionStep,
} from "../../../../_common/utils/window/window";
import { useTemplateSettingsStyles } from "./templateSettingsStyles";
import { TemplateSettingsHeader } from "./TemplateSettingsHeader";
import {
  GetTestSuiteChildrenQuery,
  GetTestSuiteQuery,
  LinkChildTestSuiteToParentTestSuiteMutationVariables,
  useLinkChildTestSuiteToParentTestSuiteMutation,
  useUnlinkChildTestSuiteFromParentTestSuiteMutation,
} from "../../../../graphql";

export interface TemplateSettingsProps {
  loading: boolean;
  loadError: boolean;
  parent: NonNullable<GetTestSuiteQuery["node"]>["parent"] | null;
  testSuiteId: string;
  childrenTestSuites:
    | NonNullable<GetTestSuiteChildrenQuery["node"]>["children"]
    | null;
  testSuiteName: string;
  isFetchingMoreChildren: boolean;
  loadMoreChildren: () => void;
}

export function TemplateSettings(props: TemplateSettingsProps): JSX.Element {
  const classes = useTemplateSettingsStyles(props);

  const [linkChildToParent, { error: linkError, loading: linkLoading }] =
    useLinkChildTestSuiteToParentTestSuiteMutation({
      refetchQueries: ["getTestSuite", "getTestSuiteChildren"],
    });

  const [unlinkChildToParent, { error: unlinkError, loading: unlinkLoading }] =
    useUnlinkChildTestSuiteFromParentTestSuiteMutation({
      refetchQueries: ["getTestSuite", "getTestSuiteChildren"],
    });

  const [isExpanded, setIsExpanded] = useState<boolean>(
    isResolutionWithin(ResolutionStep.LG),
  );

  const accountId = useAccountRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { hasFeatureFlagEnabled } = useSession();

  if (!hasFeatureFlagEnabled("global-templates")) {
    return <></>;
  }

  const children = props.childrenTestSuites?.edges;
  const childrenTotalCount = props.childrenTestSuites?.totalCount;
  const isLoading = linkLoading || unlinkLoading;

  async function handleUnlinkGlobalTemplate(): Promise<void> {
    try {
      await unlinkChildToParent({
        variables: { childId: props.testSuiteId },
      });
      enqueueSnackbar(
        <Snackbar
          title="Global template removed successfully."
          variant="success"
        />,
      );
    } catch {
      // no need to handle ... erros from Apollo are handling this
    }
  }

  async function handleApplyGlobalTemplate(
    parentTestSuiteId: string,
  ): Promise<void> {
    try {
      const variables: LinkChildTestSuiteToParentTestSuiteMutationVariables = {
        parentId: parentTestSuiteId,
        childId: props.testSuiteId,
      };
      await linkChildToParent({ variables });
      enqueueSnackbar("custom snackbar", {
        // eslint-disable-next-line react/display-name
        content: (key) => (
          <CustomSnackbar
            id={key}
            title="Your chosen test suite is now the global template for this test suite"
            body={
              <>
                <Typography gutterBottom className={clsx(classes.textBlock)}>
                  This has replaced configurations in steps 2 (Crawl settings)
                  and 3 (Tests) of this test suite.{" "}
                  <Link
                    to={Routes.EditTestSuite.getUrl({
                      accountId,
                      testSuiteId: parentTestSuiteId,
                    })}
                  >
                    Edit global template
                  </Link>
                </Typography>
                <Typography>This test suite is set to web crawl</Typography>
                <Typography>
                  Start URLs and URL file lists are not copied
                </Typography>
              </>
            }
          />
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 10000, // make sure unit test is covering this change.
      });
    } catch {
      // do not need to catch this. Error is catched by Apollo error.
    }
  }

  async function handleUseAsGlobalTemplate(
    childTestSuiteId: string,
  ): Promise<void> {
    try {
      const variables: LinkChildTestSuiteToParentTestSuiteMutationVariables = {
        parentId: props.testSuiteId,
        childId: childTestSuiteId,
      };
      await linkChildToParent({ variables });
      const snackbarMessage = !children?.length
        ? `Test suite is a global template now.`
        : `Test suite added successfully.`;
      enqueueSnackbar(<Snackbar title={snackbarMessage} variant="success" />);
    } catch {
      // do not need to catch this. Error is catched by Apollo error.
    }
  }

  const showNoTemplateInfo =
    !isLoading && !props.loading && !props.parent && !children?.length;
  const showGlobalTemplateApplied =
    !isLoading && !props.loading && !children?.length;
  const showChildrenView = !isLoading && !props.loading && !props.parent;

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={(_, expanded) => {
        setIsExpanded(expanded);
      }}
    >
      <TemplateSettingsHeader
        hasChild={Boolean(children?.length)}
        hasParent={Boolean(props.parent)}
      />

      <AccordionDetails
        classes={{
          root: classes.content,
        }}
      >
        <Box className={classes.contentWrapper}>
          {(isLoading || props.loading) && (
            <Skeleton variant="rect" height={200} />
          )}
          {linkError && (
            <Alert severity="error" className={classes.error}>
              An error occurred when trying to apply the global template. Please
              refresh the page and try again.
            </Alert>
          )}
          {unlinkError && (
            <Alert severity="error" className={classes.error}>
              An error occurred when trying to unlink the global template.
              Please refresh the page and try again.
            </Alert>
          )}

          {props.loadError && (
            <Alert severity="error" className={classes.error}>
              An error occurred when trying load Template information. Please
              refresh the page and try again.
            </Alert>
          )}
          {showGlobalTemplateApplied && props.parent && (
            <GlobalTemplateApplied
              parent={props.parent}
              unlinkGlobalTemplate={handleUnlinkGlobalTemplate}
            />
          )}
          {showNoTemplateInfo && (
            <NoTemplateSettingsView
              applyGlobalTemplate={handleApplyGlobalTemplate}
              testSuiteId={props.testSuiteId}
              useAsGlobalTemplate={handleUseAsGlobalTemplate}
            />
          )}
          {showChildrenView && !!children?.length && (
            <ChildrenAddedSuccessfully
              childrenTestSuites={children}
              totalAvailableChildren={childrenTotalCount}
              loadMoreChildren={props.loadMoreChildren}
              isFetchingMoreChildren={props.isFetchingMoreChildren}
              useAsGlobalTemplate={handleUseAsGlobalTemplate}
              testSuiteId={props.testSuiteId}
            />
          )}
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
}

const StyledAccordion = withStyles((theme) => ({
  root: {
    borderRadius: "8px !important",
    border: `1px solid ${theme.palette.grey[300]}`,
    background: "#EBEFF3",
    filter: "none",
  },
}))(Accordion);
