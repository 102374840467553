import React, { useRef } from "react";
import { TextField } from "formik-material-ui";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { Field, FormikProps, FormikValues } from "formik";
import { ConfirmOrDelete } from "../ConfirmOrDelete/ConfirmOrDelete";
import { Typography } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    paddingBottom: 7,
  },
  textField: {
    maxWidth: 400,
  },
}));

interface AddInputProps {
  onFocus: () => void;
  onBlur: () => void;
  formik: FormikProps<FormikValues>;
  id: string;
  label: string;
  formikLabel: string;
}

export function AddInput(props: AddInputProps): JSX.Element {
  const classes = useStyles();
  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  function handleBlur({ relatedTarget }: FocusEvent): void {
    if (
      relatedTarget &&
      relatedTarget !== confirmBtnRef.current &&
      relatedTarget !== cancelBtnRef.current
    ) {
      props.onBlur();
    }
  }

  return (
    <Grid container alignItems="center">
      <Grid container>
        <Typography className={classes.label}>{props.label}</Typography>
      </Grid>
      <Box display="flex" width="100%">
        <Field
          className={classes.textField}
          component={TextField}
          id={`${props.id}-input`}
          type="text"
          name={props.formikLabel}
          variant="outlined"
          fullWidth
          data-testid={`${props.id}-input`}
          onFocus={props.onFocus}
          InputProps={{ onBlur: handleBlur }}
          autoFocus
        />
        <Box marginLeft={1}>
          <ConfirmOrDelete
            confirmButtonRef={confirmBtnRef}
            cancelButtonRef={cancelBtnRef}
            dataTestId={props.id}
            handleCancel={() => {
              props.formik.setFieldValue(`${props.formikLabel}`, "");
              props.onBlur();
            }}
            handleConfirm={props.formik.submitForm}
          />
        </Box>
      </Box>
    </Grid>
  );
}
