import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export function JiraIntegrationDetailLoadingView() {
  return (
    <Box
      data-testid="integration-detail-loading"
      style={{ padding: "0px 20px 20px 28px" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ minHeight: 68 }}
      >
        <Skeleton variant="text" height={40} width={200} />
        <Box display="flex" alignItems="center">
          <Skeleton variant="circle" height={46} width={46} />
          <Skeleton
            variant="circle"
            height={46}
            width={46}
            style={{ marginLeft: 8 }}
          />
        </Box>
      </Box>
      <Skeleton
        variant="rect"
        height={66}
        width={420}
        style={{ marginBottom: 56 }}
      />
      <Skeleton
        variant="text"
        height={30}
        width={300}
        style={{ marginBottom: 40 }}
      />
      <Skeleton variant="rect" width="100%" height={400} />
    </Box>
  );
}
