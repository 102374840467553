import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useSession } from "../session";
import { useHistory } from "react-router";
import { LoadingScreen } from "../components";
export function AuthPage({ onSession }) {
    const history = useHistory();
    const session = useSession();
    useEffect(() => {
        if (session && onSession) {
            onSession(session, history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, history]);
    return _jsx(LoadingScreen, {});
}
