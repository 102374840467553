import React from "react";
import { makeStyles, Box, Typography, Card } from "@material-ui/core";
import clsx from "clsx";
import { Alert } from "@lumar/shared";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import {
  decorateMetadata,
  JiraIntegrationKey,
} from "../utils/decorateMetadata";
import { getRowLabelFromKey } from "../utils/getRowLabelFromKey";
import {
  GetJiraIntegrationsQuery,
  GetTestSuiteQuery,
} from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  integrationDetails: {
    color: theme.palette.navy[400],
    fontSize: "1.125rem",
  },
  table: {
    backgroundColor: "#FAFAFA",
    border: `1px solid ${theme.palette.navy[50]}`,
    borderRadius: "3px",
    boxShadow: "none",
    marginTop: theme.spacing(4),
  },
  tableRow: {
    minHeight: theme.spacing(8),
    padding: theme.spacing(1, 4.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
      display: "block",
      minHeight: 0,
    },
  },
  tableValue: {
    color: theme.palette.navy[400],
    fontSize: "0.875rem",
  },
  boldText: {
    fontWeight: "bold",
    minWidth: "115px",
    marginRight: theme.spacing(3),
  },
  integrationStatusAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
  },
  success: {
    backgroundColor: theme.palette.green[500],
  },
  warning: {
    backgroundColor: theme.palette.yellow[500],
  },
  description: {
    fontSize: "0.875rem",
    color: theme.palette.grey[800],
    marginTop: theme.spacing(2),
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export enum IntegrationDetailConnectionType {
  WARNING = "warning",
  OK = "ok",
}

export interface IntegrationDetailsProps {
  jiraIntegration?:
    | NonNullable<
        NonNullable<GetTestSuiteQuery["node"]>["testSuiteJiraIntegration"]
      >["jiraIntegration"]
    | NonNullable<
        GetJiraIntegrationsQuery["getAccount"]
      >["jiraAuthentications"]["nodes"][0]["jiraIntegrations"]["nodes"][0];
  status: string;
  description?: string;
  statusType: IntegrationDetailConnectionType;
}

export function IntegrationDetails(
  props: IntegrationDetailsProps,
): JSX.Element {
  const classes = useStyles();
  const metadata = decorateMetadata(props.status, props.jiraIntegration);

  return (
    <Box data-testid="jira-integration-details">
      <Typography
        variant="h5"
        className={classes.integrationDetails}
        data-testid="integration-details-title"
      >
        Integration details
      </Typography>
      {props.description && (
        <Typography className={classes.description}>
          {props.description}
        </Typography>
      )}
      <Box data-testid="integration-details-table">
        <Card className={classes.table}>
          {metadata.map((data, index) => {
            const isLastItem = index === metadata.length - 1;
            const isIntegrationStatus =
              data.key === JiraIntegrationKey.JIRA_INTEGRATION_STATUS;

            return (
              <Box
                key={data.key}
                className={clsx(
                  classes.tableRow,
                  !isLastItem && classes.cardHeader,
                )}
              >
                <Typography
                  variant="body1"
                  data-testid={`${data.key}-label`}
                  className={clsx(
                    classes.tableValue,
                    classes.boldText,
                    classes.label,
                  )}
                >{`${getRowLabelFromKey(data.key)}:`}</Typography>
                {isIntegrationStatus ? (
                  <Alert
                    variant="filled"
                    severity={
                      props.statusType === IntegrationDetailConnectionType.OK
                        ? "success"
                        : "warning"
                    }
                    data-testid={`${data.key}-value`}
                    iconMapping={{
                      success: (
                        <CheckCircleOutlineRoundedIcon fontSize="inherit" />
                      ),
                      warning: <WarningRoundedIcon fontSize="inherit" />,
                    }}
                    classes={{
                      root: classes.integrationStatusAlert,
                      filledSuccess: classes.success,
                      filledWarning: classes.warning,
                    }}
                  >
                    {data.value}
                  </Alert>
                ) : (
                  <Typography
                    variant="body1"
                    data-testid={`${data.key}-value`}
                    noWrap={true}
                    className={classes.tableValue}
                  >
                    {data.value}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Card>
      </Box>
    </Box>
  );
}
