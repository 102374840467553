import React from "react";
import { makeStyles, Box, Typography, Chip } from "@material-ui/core";
import {
  formatSchedulingInterval,
  getTimeDescription,
} from "./../utils/schedulerTime";
import { GetBuildSchedulesQuery } from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  box: (props: { selected: boolean }) => {
    return {
      margin: "4px",
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      cursor: "pointer",
      border: props.selected
        ? "1px solid " + theme.palette.ultraviolet[300]
        : `1px solid ${theme.palette.grey[300]}`,
      "&:hover": {
        backgroundColor: props.selected
          ? theme.palette.ultraviolet[100]
          : theme.palette.grey[200],
        "& [class*=MuiChip-root]": {
          backgroundColor: props.selected
            ? theme.palette.primary.main
            : theme.palette.ultraviolet[200],
        },
      },
      borderRadius: "8px",
      background: props.selected ? theme.palette.ultraviolet[100] : "#fff",
    };
  },
  name: (props: { selected: boolean }) => {
    return {
      fontSize: theme.typography.pxToRem(14),
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(17),
      color: props.selected
        ? theme.palette.primary.main
        : theme.palette.grey[700],
      paddingBottom: "8px",
    };
  },
  text: {
    fontWeight: 400,
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    paddingTop: "3px",
  },
  testSuiteConnectedNumber: (props: { selected: boolean }) => {
    return {
      borderRadius: 27,
      height: 18,
      background: props.selected
        ? theme.palette.primary.main
        : theme.palette.grey[200],
      color: props.selected ? "#fff" : theme.palette.grey[600],
      fontSize: 13,
      alignItems: "center",
      fontWeight: 500,
    };
  },
}));

export interface SchedulerListItemProps {
  scheduler: NonNullable<
    GetBuildSchedulesQuery["getAccount"]
  >["buildSchedules"]["nodes"][0];
  onSelect: (schedulerId: string) => void;
  selected: boolean;
}

export function SchedulerListItem({
  scheduler,
  selected,
  onSelect,
}: SchedulerListItemProps) {
  const classes = useStyles({ selected });

  const frequency = formatSchedulingInterval(
    new Date(scheduler.startAt),
    scheduler.repetitionRate,
  );

  const handleClick = () => {
    onSelect(scheduler.id);
  };

  return (
    <Box
      className={classes.box}
      onClick={handleClick}
      data-testid="scheduler-list-item"
      data-pendo="auto-scheduler-list-item"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box maxWidth="80%">
          <Typography
            variant="h3"
            className={classes.name}
            data-testid="scheduler-list-item-name"
            noWrap
          >
            {scheduler.name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Typography className={classes.text}>Starts:</Typography>
              <Typography className={classes.text}>Repeat:</Typography>
            </Box>
            <Box>
              <Typography
                className={classes.text}
                data-testid="scheduler-list-item-time"
              >
                {getTimeDescription(scheduler.startAt)}
              </Typography>
              <Typography
                className={classes.text}
                data-testid="scheduler-list-item-frequency"
              >
                {frequency}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Chip
            label={scheduler.buildScheduleTestSuites.totalCount}
            variant="default"
            data-testid="scheduler-list-item-testsuites"
            className={classes.testSuiteConnectedNumber}
          />
        </Box>
      </Box>
    </Box>
  );
}
