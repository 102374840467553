import React from "react";
import { FormControlLabel, makeStyles } from "@material-ui/core";
import { ExtendedTest } from "../CreateTests.interfaces";
import { Checkbox } from "@lumar/shared";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";

const useStyles = makeStyles({
  tooltip: {
    maxWidth: 200,
  },
});

export interface ChooseTestsSelectionCheckBoxProps {
  test: ExtendedTest;
  onChange: (selected: boolean) => void;
  disabled: boolean;
}

function compareProps(
  prevProp: ChooseTestsSelectionCheckBoxProps,
  newProp: ChooseTestsSelectionCheckBoxProps,
) {
  return (
    prevProp.test.extended.selected === newProp.test.extended.selected &&
    prevProp.test.data.id === newProp.test.data.id &&
    prevProp.disabled === newProp.disabled
  );
}

export const ChooseTestsSelectionCheckBox = React.memo(
  function ChooseTestsSelectionCheckBox({
    test,
    disabled,
    onChange,
  }: ChooseTestsSelectionCheckBoxProps) {
    const classes = useStyles();

    const isDisabled = disabled && !test.extended.selected;
    const reportTemplateCode = test.data.reportTemplate.code;

    return (
      <div
        key={`${reportTemplateCode}-checkbox`}
        data-pendo={`auto-test-suite-edit-choose-tests-selection-${reportTemplateCode}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id={`${reportTemplateCode}-checkbox`}
              data-testid={`${reportTemplateCode}-checkbox`}
              checked={test.extended.selected}
              onChange={() => !isDisabled && onChange(!test.extended.selected)}
              disabled={isDisabled}
            />
          }
          label={test.data.reportTemplate.name}
        />
        <InfoTooltip
          data-pendo="auto-tooltip-choose-test-selection"
          title={test.data.reportTemplate.automatorSummary || "N/A"}
          data-testid={`${reportTemplateCode}-tooltip`}
          classes={{ tooltip: classes.tooltip }}
          IconProps={{
            style: {
              marginRight: 8,
            },
          }}
        />
      </div>
    );
  },
  compareProps,
);
