import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Alert, ToggleIconButton } from "@lumar/shared";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3, 0, 3),
    margin: 0,
  },
  dialogTitle: {
    padding: theme.spacing(2.8125, 0, 2.6875, 0),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    fontWeight: 600,
  },
  dialogContent: {
    padding: theme.spacing(4, 3, 7.5, 3),
  },
  paper: {
    maxWidth: 689,
  },
}));

interface TemplateDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  title: string;
  message: string;
  component?: JSX.Element;
  skipMessage?: boolean;
}

export function TemplateDialog(props: TemplateDialogProps): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="md"
      fullWidth={false}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
      data-testid="template-dialog"
      classes={{ paper: classes.paper }}
    >
      <MuiDialogTitle
        disableTypography
        className={classes.dialogTitleContainer}
      >
        <Typography
          classes={{ body1: classes.dialogTitle }}
          data-testid="template-dialog-title"
        >
          {props.title}
        </Typography>
        <ToggleIconButton
          aria-label="close"
          onClick={props.handleClose}
          data-testid="template-dialog-close"
          style={{ marginRight: -6 }}
        >
          <CloseIcon />
        </ToggleIconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers className={classes.dialogContent}>
        {props.component}
        {!props.skipMessage && (
          <Box mt={3.375}>
            <Alert
              severity="info"
              data-testid="template-dialog-message"
              size="small"
            >
              {props.message}
            </Alert>
          </Box>
        )}
      </MuiDialogContent>
    </Dialog>
  );
}
