import { EmailTab } from "./EmailTab/EmailTab";
import { SlackTab } from "./SlackTab/SlackTab";
import MailIcon from "@material-ui/icons/Mail";
import { Box, Button, makeStyles } from "@material-ui/core";
import { JiraTab } from "./Jira/JiraTab";
import { StickyWrapper } from "../StickyWrapper/StickyWrapper";
import {
  ResponsiveTabs,
  ResponsiveTabsConfig,
} from "../../../../_common/components/ResposiveTabs/ResponsiveTabs";
import { SlackIcon } from "../../../../_common/svg/slackIcon";
import { JiraIcon } from "../../../../_common/svg/jiraIcon";
import { StepChangeHandler } from "../../UpdateTestSuite";
import { GetTestSuiteQuery } from "../../../../graphql";

export interface SetAlertsFormProps {
  onSubmit: StepChangeHandler;
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
}

const useStyles = makeStyles({
  wrapper: {
    minHeight: 200,
    margin: 0,
  },
});

export function SetAlertsForm(props: SetAlertsFormProps): JSX.Element {
  const { testSuite } = props;
  const classes = useStyles();

  const tabsConfig: ResponsiveTabsConfig[] = [
    {
      id: "email",
      label: "Email",
      icon: <MailIcon />,
      content: (
        <EmailTab
          alertEmails={testSuite.emailAlerts?.nodes || []}
          testSuiteId={testSuite.id}
        />
      ),
    },
    {
      id: "slack",
      label: "Slack",
      icon: <SlackIcon />,
      content: (
        <SlackTab
          slackWebhooks={testSuite.slackWebhooks?.nodes || []}
          testSuiteId={testSuite.id}
        />
      ),
    },
    {
      id: "jira",
      label: "Jira",
      icon: <JiraIcon />,
      content: (
        <JiraTab
          testSuite={testSuite}
          jiraIntegration={
            props.testSuite.testSuiteJiraIntegration?.jiraIntegration
          }
        />
      ),
    },
  ];

  return (
    <Box data-cy="set-alerts-content">
      <Box component="div" mb={4} className={classes.wrapper}>
        <ResponsiveTabs config={tabsConfig} label="Set notifications" />
      </Box>

      <StickyWrapper>
        <Button
          data-testid="set-alerts-save"
          data-cy="set-alerts-save"
          data-pendo="auto-testsuite-edit-add-set-alerts-save-step"
          onClick={props.onSubmit}
          variant="contained"
          color="primary"
        >
          Next step
        </Button>
      </StickyWrapper>
    </Box>
  );
}
