import Rollbar from "rollbar";

function getEnv(): string {
  const host = window.location.host;
  if (host.includes("dev")) {
    return "development";
  } else if (host.includes("staging")) {
    return "staging";
  } else if (host.includes("pr")) {
    return "pullrequest";
  } else if (host.includes("localhost")) {
    return "localhost";
  } else {
    return "production";
  }
}

export const rollbarConfig: Rollbar.Configuration = {
  enabled: Boolean(process.env.REACT_APP_ROLLBAR_TOKEN),
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  codeVersion: process.env.REACT_APP_BUILD_HASH,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: getEnv(),
};
