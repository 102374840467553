import * as yup from "yup";
import isEmail from "validator/lib/isEmail";

export const setAlertsFormValidation = yup.object().shape({
  alertEmail: yup
    .string()
    .test(
      "email",
      "Valid email address is required",
      (email) => !email || isEmail(email),
    )
    .required("Please fill in this field"),
});
