import { ClockV3, FileboardDoneV3, GalleryGridView, ObjectConnectionV4, } from "../../../../icons";
import { ProtectRoutes } from "../../../../routes/protectRoutes";
import { getRawAccountId } from "../../../../session";
import { AppIds } from "../../../../session/SessionContext.types";
export function getProtectAppSwitcherConfig({ isMain, session, labels, name, }) {
    const enabled = session.hasApp(AppIds.protect) || session.isDeepCrawlAdmin;
    const isExternal = !isMain;
    const accountId = getRawAccountId(session.account.id);
    function getHref(path) {
        return isExternal ? `${ProtectRoutes.Domain}${path}` : path;
    }
    function getIsSelected(isSelected) {
        return isExternal ? false : isSelected;
    }
    return {
        id: AppIds.protect,
        name,
        isMain,
        enabled,
        href: getHref(ProtectRoutes.Dashboard.getUrl({ accountId })),
        testId: "protect-app-icon",
        items: [
            {
                id: "dashboard",
                name: labels.dashboard,
                icon: GalleryGridView,
                href: getHref(ProtectRoutes.Dashboard.getUrl({ accountId })),
                isSelected: getIsSelected(),
                testId: "dashboard-nav-link",
            },
            {
                id: "test-suites",
                name: labels.testSuites,
                icon: FileboardDoneV3,
                href: getHref(ProtectRoutes.TestSuites.getUrl({ accountId })),
                isSelected: getIsSelected(),
                testId: "test-suites-nav-link",
            },
            {
                id: "scheduler",
                name: labels.scheduler,
                icon: ClockV3,
                href: getHref(ProtectRoutes.FrontendScheduler.getUrl({ accountId })),
                isSelected: getIsSelected(isSchedulerSelected),
                testId: "scheduler-nav-link",
            },
            {
                id: "connected-apps",
                name: labels.connectedApps,
                icon: ObjectConnectionV4,
                href: getHref(ProtectRoutes.ConnectedApps.getUrl({ accountId })),
                isSelected: getIsSelected(),
                testId: "connected-apps-nav-link",
            },
        ],
    };
}
function isSchedulerSelected(currentPath) {
    return Boolean(currentPath.match("\\/accounts\\/\\w+\\/scheduler(\\/\\w+)?$"));
}
