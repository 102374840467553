import React from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Paper,
  Box,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { Routes } from "../../../../_common/routes/routes";
import { ArrowNarrowRightSolid } from "@lumar/shared";
import {
  DashboardWidgetHeader,
  WIDGET_HEADER_HEIGHT,
} from "../DashboardWidget";

export interface NotYetRunTestsuiteInfo {
  createdAt: number | string;
  id: string;
  primaryDomain: string;
  name: string;
  status: string;
}

interface NotYetRunWidgetProps {
  testSuites: NotYetRunTestsuiteInfo[];
  accountId: string;
}

const SUBHEADER_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
    borderLeft: `3px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(0, 3),
    height: 88,
    display: "flex",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  topListItem: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  viewAllContainer: {
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  link: { textDecoration: "none" },
  fullHeight: { height: "100%" },
  subheading: {
    color: theme.palette.navy[300],
    fontSize: 14,
  },
  subheadingWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    minHeight: SUBHEADER_HEIGHT,
  },
  chevronWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  name: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  primaryDomain: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 400,
    color: theme.palette.grey[600],
    lineHeight: theme.typography.pxToRem(20),
  },
  testSuiteId: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.grey[400],
    lineHeight: theme.typography.pxToRem(20),
  },
  contentWrapper: {
    // Needed in order to keep `See all` button at the bottom of the widget
    height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px - ${SUBHEADER_HEIGHT}px - 1px)`,
  },
}));

export function NotYetRunWidget(props: NotYetRunWidgetProps) {
  const classes = useStyles();

  if (!props.testSuites.length) {
    return null;
  }

  // Note: it's ok to use .sort() as we are spreading to a new array before sorting
  // eslint-disable-next-line fp/no-mutating-methods
  const newestTestSuites = [...props.testSuites]
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
    .filter((_, i) => i < 3);

  return (
    <Paper
      data-testid="not-yet-run-widget-container"
      data-cy="not-yet-run-widget-container"
      data-pendo="auto-dashboard-widget-notyetrun"
      className={classes.fullHeight}
    >
      <DashboardWidgetHeader>Not yet run test suites</DashboardWidgetHeader>
      <Divider />
      <Box className={classes.subheadingWrapper}>
        <Typography className={classes.subheading}>
          No runs/builds detected yet for the test suites below
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        className={classes.contentWrapper}
      >
        <Box>
          {newestTestSuites.map((testSuite, index) => {
            return (
              <Link
                to={Routes.EditTestSuite.getUrl({
                  accountId: props.accountId,
                  testSuiteId: testSuite.id,
                })}
                className={classes.link}
                data-testid="notyetrun-testsuite-link"
                key={testSuite.id}
              >
                <Box
                  className={clsx(
                    classes.listItem,
                    index == 0 && classes.topListItem,
                  )}
                  data-testid="notyetrun-testsuite-item"
                  data-cy="notyetrun-testsuite-item"
                  data-pendo="auto-dashboard-widget-notyetrun-testsuite-item"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        data-testid="notyetrun-testsuite-name"
                        data-cy="notyetrun-testsuite-name"
                        color="textPrimary"
                        className={classes.name}
                        noWrap
                        title={testSuite.name}
                      >
                        {testSuite.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        data-testid="notyetrun-testsuite-siteprimary"
                        data-cy="notyetrun-testsuite-siteprimary"
                        color="textPrimary"
                        className={classes.primaryDomain}
                        noWrap
                      >
                        {testSuite.primaryDomain}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        data-cy="notyetrun-testsuite-id-message"
                        className={classes.testSuiteId}
                      >
                        Test suite ID:{" "}
                        <span
                          data-cy="notyetrun-testsuite-id"
                          data-testid="notyetrun-testsuite-id"
                        >
                          {testSuite.id}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            );
          })}
        </Box>
        <div className={classes.viewAllContainer}>
          <Link
            className={classes.link}
            to={Routes.TestSuites.getUrl({ accountId: props.accountId })}
            data-testid="view-all-link"
            data-cy="view-all-link"
          >
            <Button
              variant="outlined"
              size="small"
              data-pendo="auto-dashboard-widget-notyetrun-view-all"
              data-testid="view-all-button"
              style={{ height: 32 }}
              endIcon={
                props.testSuites.length > 1 ? (
                  <ArrowNarrowRightSolid />
                ) : undefined
              }
            >
              See all
              {props.testSuites.length > 1
                ? ` ${props.testSuites.length.toLocaleString()}`
                : ""}
            </Button>
          </Link>
        </div>
      </Box>
    </Paper>
  );
}
