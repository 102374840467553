import { getHours, getMinutes } from "date-fns";
import { SchedulerMinutesTimeOption } from "./generateTimeOptions";

export function getFirstAvailableTimeOption(
  options: SchedulerMinutesTimeOption[],
) {
  return options.filter((option) => !option.isDisabled)[0];
}

export function getSelectedTimeOption(
  options: SchedulerMinutesTimeOption[],
  date: Date,
) {
  const minutesInHour = 60;
  const hours = getHours(date);
  const minutes = getMinutes(date);
  const totalMinutes = hours * minutesInHour + minutes;
  const avaliableOptions = options.filter(
    (option) => option.minutes >= totalMinutes,
  );
  return avaliableOptions[0];
}
