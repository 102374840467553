import { makeStyles } from "@material-ui/core";

export const useScheduleSettingsStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  subtitle: {
    color: theme.palette.grey[800],
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  root: {
    margin: 0,
    padding: "15.5px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogPaper: {
    maxWidth: 690,
  },
  dialogContent: {
    padding: "32px 24px 40px 24px",
    borderTop: "1px solid " + theme.palette.grey[200],
    borderBottom: "none",
    "&:first-child": {
      paddingTop: 32,
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.grey[200],
  },
  actionButton: {
    minWidth: 0,
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  mainInputBox: {
    marginBottom: theme.spacing(2),
  },
  buttonsWrapper: {
    backgroundColor: "rgba(209, 213, 219, 0.3)",
  },
  cancelButton: {
    backgroundColor: "#FFF",
  },
  tooltip: {
    maxWidth: 200,
  },
}));
