var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { Snackbar } from "../snackbars/Snackbar";
import { MENU_ITEM_HEIGHT } from "./MenuGroup";
import { getRawAccountId } from "../../session/helpers/getRawAccountId";
export function useAccountSelectionSubMenu({ accounts, selectedAccountId, onAccountSelected, isDeepCrawlAdmin, searchPlaceholder, noResultMessage, formatValue, adminAccountSearch, dataAttributes, }) {
    const snackbar = useSnackbar();
    const [search, setSearch] = React.useState("");
    const functionRef = React.useRef(undefined);
    const [loading, setLoading] = React.useState(false);
    const [foundAccounts, setFoundAccounts] = React.useState([]);
    function handleFilterChange(filter) {
        var _a;
        setSearch(filter);
        (_a = functionRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
        if (filter) {
            setLoading(true);
            functionRef.current = debounce(() => __awaiter(this, void 0, void 0, function* () {
                try {
                    const accounts = yield adminAccountSearch(filter);
                    setFoundAccounts(accounts);
                }
                catch (error) {
                    snackbar === null || snackbar === void 0 ? void 0 : snackbar.enqueueSnackbar(_jsx(Snackbar, { variant: "error", title: `Failed to get accounts: ${error.message}` }));
                }
                setLoading(false);
            }), 300);
            functionRef.current();
        }
        else {
            setLoading(false);
        }
    }
    if (!isDeepCrawlAdmin)
        return [
            {
                items: accounts.map((account) => {
                    const id = getRawAccountId(account.id);
                    return {
                        name: account.name || "",
                        value: formatValue(id),
                        checked: id === selectedAccountId,
                        onClick: () => {
                            onAccountSelected(id);
                            return false;
                        },
                        dataAttributes,
                    };
                }),
            },
        ];
    const accountsList = !search ? accounts : foundAccounts;
    return [
        {
            items: accountsList.map((account) => {
                const id = getRawAccountId(account.id);
                return {
                    name: account.name || "",
                    value: formatValue(id),
                    checked: id === selectedAccountId,
                    onClick: () => {
                        onAccountSelected(id);
                        return false;
                    },
                };
            }),
            height: MENU_ITEM_HEIGHT * 9,
            loading: loading && Boolean(search),
            search: {
                placeholder: searchPlaceholder,
                noResultMessage: noResultMessage,
                filter: search,
                onFilterChanged: handleFilterChange,
            },
        },
    ];
}
