import { BuildStatus } from "../../graphql";

export enum ExtendedBuildStatus {
  Pass = "Pass",
  Fail = "Fail",
  NotYetRun = "NotYetRun",
  Warning = "Warn",
  Scheduled = "Scheduled",
}

export type BuildTestStatus = ExtendedBuildStatus | BuildStatus;
