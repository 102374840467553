import { AppLayout, useAuth } from "@lumar/shared";
import { Container, makeStyles } from "@material-ui/core";
import { AppRoutesPrivate } from "./AppRoutesPrivate";
import { usePendoAnalytics } from "./usePendoAnalytics";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 2,
    padding: 0,
    paddingBottom: 50,
    minHeight: `calc(100vh - 244px)`,
    [theme.breakpoints.down("xs")]: {
      minHeight: `calc(100vh - 314px)`,
    },
  },
  appGrid: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    gridTemplateColumns: "100%",
    minHeight: "100%",
  },
}));

interface LoggedInViewProps {
  logoutHandler: () => void;
}

export function LoggedInView(props: LoggedInViewProps): JSX.Element {
  const classes = useStyles();
  const { logout } = useAuth();
  usePendoAnalytics();

  function handleLogout(): void {
    logout();
    props.logoutHandler();
  }

  return (
    <AppLayout app="protect">
      <div className={classes.appGrid} data-testid="logged-in-view">
        <Container
          maxWidth="xl"
          className={classes.container}
          data-testid="logged-in-container"
        >
          <AppRoutesPrivate logoutHandler={handleLogout} />
        </Container>
      </div>
    </AppLayout>
  );
}
