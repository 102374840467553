import { GalleryGridView, NotificationBellV2 } from "../../../../icons";
import { MonitorRoutes } from "../../../../routes/monitorRoutes";
import { getRawAccountId } from "../../../../session";
import { AppIds } from "../../../../session/SessionContext.types";
import { MonitorAppNotificationCountBadge } from "./MonitorAppNotificationCountBadge";
export function getMonitorAppSwitcherConfig({ isMain, session, labels, name, }) {
    const enabled = session.hasApp(AppIds.monitor) || session.isDeepCrawlAdmin;
    const isExternal = !isMain;
    const accountId = getRawAccountId(session.account.id);
    function getHref(path) {
        return isExternal ? `${MonitorRoutes.Domain}${path}` : path;
    }
    function isSelected(isSelected) {
        return isExternal ? false : isSelected;
    }
    return {
        id: AppIds.monitor,
        name,
        isMain,
        enabled,
        href: getHref(MonitorRoutes.Dashboard.getUrl({ accountId })),
        testId: "monitor-app-icon",
        items: [
            {
                id: "boards",
                name: labels.boards,
                icon: GalleryGridView,
                href: getHref(MonitorRoutes.Dashboard.getUrl({ accountId })),
                isSelected: isSelected(isDashboardSelected),
            },
            {
                id: "notifications",
                name: labels.notifications,
                icon: NotificationBellV2,
                href: getHref(MonitorRoutes.Notifications.getUrl({ accountId })),
                isSelected: isSelected(),
                testId: "sidenav-notifications-link",
                countBadgeValue: () => MonitorAppNotificationCountBadge({ skip: !isMain }),
            },
        ],
    };
}
function isDashboardSelected(currentPath) {
    const m1 = "\\/accounts\\/\\d+$";
    const m2 = "\\/accounts\\/\\d+\\/dashboard(\\/\\w+)+$";
    return Boolean(currentPath.match(m1)) || Boolean(currentPath.match(m2));
}
