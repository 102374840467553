import { InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const StyledInputLabel = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);
