import React from "react";

export function useVisibleElement(initiallySelectedId: string) {
  const refs = React.useRef<Array<HTMLElement | null>>([]);

  const [visibleElementId, setVisibleElementId] = React.useState<
    string | undefined
  >(initiallySelectedId);

  function createRefFunction(index: number) {
    return (el: HTMLElement) => {
      refs.current[index] = el;
    };
  }

  React.useEffect(() => {
    function isInViewport(elem: HTMLElement) {
      const rect = elem.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function isAboveViewport(elem: HTMLElement) {
      const rect = elem.getBoundingClientRect();
      return rect.top < 0;
    }

    function handleScroll() {
      const firstElementInViewport = refs.current.find((el) =>
        el ? isInViewport(el) : false,
      );

      const elementsAboveViewport = refs.current.filter((el) =>
        el ? isAboveViewport(el) : false,
      );

      // Note: there could potentially be no elements of interest in the viewport. If that is the case, then we take the last element in the
      // array of elements above the viewport, since the array is ordered by DOM order.
      const activeElement =
        firstElementInViewport ??
        elementsAboveViewport[elementsAboveViewport.length - 1];

      setVisibleElementId(activeElement?.id);
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { visibleElementId, createRefFunction };
}
