import { hasLastSelectedAccount } from "../LastSelectedAccount/hasLastSelectedAccount";
import { getLastSelectedAccount } from "../LastSelectedAccount/getLastSelectedAccount";
import { GetUserQuery } from "../../../graphql";

function isLastSelectedAccountValid(data: GetUserQuery | undefined): boolean {
  const lastSelectedAccountId = getLastSelectedAccount();
  const availableAccounts = data?.me.accounts.nodes || [];
  const isLastSelectedAccountAvailable = availableAccounts.find(
    (account) => account.id === lastSelectedAccountId,
  );
  return !!isLastSelectedAccountAvailable;
}

export function getDisplayingAccountId(
  data: GetUserQuery | undefined,
  accountToDisplay: string,
): string {
  return hasLastSelectedAccount() && isLastSelectedAccountValid(data)
    ? getLastSelectedAccount()
    : accountToDisplay;
}
