/*
 * A cleaner way to conditionally insert an element to an array.
 *
 * Example:
 * ```
 * const array = [
 *  1,
 *  2,
 *  3,
 *  4,
 *  ...insertIf(false, 5),
 *  ...insertIf(true, 6)
 * ];
 *
 * console.log(array); // outputs: [1, 2, 3, 4, 6];
 * ```
 *
 */
export function insertIf(condition, value) {
    if (condition) {
        return [value];
    }
    return [];
}
