import React, { useState, ChangeEvent } from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Autocomplete,
  FilterOptionsState,
  createFilterOptions,
} from "@material-ui/lab";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import clsx from "clsx";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { PrimaryActionButton } from "../../../../../_common/components/PrimaryActionButton/PrimaryActionButton";
import { StyledInputLabel } from "../../../../../_common/components/StyledInputLabel/StyledInputLabel";
import { GetTestSuiteQuery } from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  autoCompleteRoot: {
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  groupUl: {
    "& .MuiAutocomplete-option": {
      paddingLeft: "32px",
    },
  },
  addIntegrationBtn: {
    padding: "9px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  addNewOption: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
  addNewIcon: {
    marginRight: 5,
  },
  optionName: {
    color: theme.palette.text.primary,
  },
  optionUrl: {
    color: theme.palette.grey[800],
  },
  text: {
    fontSize: 14,
  },
}));

export const ADD_NEW_INTEGRATION_VALUE = "Add new integration";

interface AddOrSelectJiraIntegrationProps {
  isLoading: boolean;
  jiraIntegrations: NonNullable<
    NonNullable<GetTestSuiteQuery["node"]>["testSuiteJiraIntegration"]
  >["jiraIntegration"][];
  selectedJiraIntegrationName: string | null;
  handleChange: (
    event: ChangeEvent<{ value?: unknown }>,
    newValue: string | null,
  ) => void;
  handleOpenDialog: () => void;
}

function getOptions(
  jiraIntegrations: NonNullable<
    NonNullable<GetTestSuiteQuery["node"]>["testSuiteJiraIntegration"]
  >["jiraIntegration"][],
) {
  const options = jiraIntegrations.map(
    ({ name, jiraUrl }) => `${name} - ${jiraUrl}`,
  );
  return [ADD_NEW_INTEGRATION_VALUE, ...options];
}

export function AddOrSelectJiraIntegration(
  props: AddOrSelectJiraIntegrationProps,
) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const options = getOptions(props.jiraIntegrations);

  function filterAutocompleteOptions(
    options: string[],
    state: FilterOptionsState<string>,
  ) {
    const filterOptions = createFilterOptions<string>();
    const filteredOptions = filterOptions(options, state).filter(
      (option) => option !== ADD_NEW_INTEGRATION_VALUE,
    );
    return [ADD_NEW_INTEGRATION_VALUE, ...filteredOptions];
  }

  function getOptionGroup(option: string) {
    return option === ADD_NEW_INTEGRATION_VALUE
      ? "New"
      : "Existing integrations";
  }

  function handleInputChange(
    _: React.ChangeEvent<{ value?: unknown }>,
    newInputValue: string,
  ) {
    const newValue =
      newInputValue === ADD_NEW_INTEGRATION_VALUE ? "" : newInputValue;
    setInputValue(newValue);
  }

  if (props.isLoading) {
    return (
      <CircularProgress color="secondary" data-testid="add-or-select-loader" />
    );
  }

  return props.jiraIntegrations.length > 0 ? (
    <Box>
      <StyledInputLabel htmlFor="add-or-select-jira-integration">
        Add new or existing integration
      </StyledInputLabel>
      <Autocomplete
        fullWidth
        popupIcon={<ExpandMoreRoundedIcon />}
        data-testid="add-or-select-dropdown"
        data-pendo="auto-test-suite-edit-add-or-select-integration-dropdown"
        value={props.selectedJiraIntegrationName}
        groupBy={getOptionGroup}
        onChange={props.handleChange}
        onOpen={(event) => {
          props.handleChange(event, null);
        }}
        classes={{
          root: classes.autoCompleteRoot,
          groupUl: classes.groupUl,
        }}
        openOnFocus
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            id="add-or-select-jira-integration"
            variant="outlined"
            placeholder="Choose integration"
          />
        )}
        filterOptions={filterAutocompleteOptions}
        getOptionSelected={(option, value) => option === value}
        renderOption={(option) => {
          const isAddNew = option === ADD_NEW_INTEGRATION_VALUE;
          const nameAndUrl = option.split(" - ");

          return (
            <Typography
              noWrap
              className={clsx(classes.text, isAddNew && classes.addNewOption)}
            >
              {isAddNew && <AddRoundedIcon className={classes.addNewIcon} />}
              <span className={clsx(!isAddNew && classes.optionName)}>
                {nameAndUrl[0]}
              </span>
              {nameAndUrl[1] && (
                <span
                  className={classes.optionUrl}
                >{` - ${nameAndUrl[1]}`}</span>
              )}
            </Typography>
          );
        }}
      />
    </Box>
  ) : (
    <PrimaryActionButton
      data-pendo="add-or-select-jira-integration-add"
      label="New jira integration"
      dataTestId="jira-card-btn"
      dataCy="jira-card-btn"
      onClick={props.handleOpenDialog}
      startIcon={<AddRoundedIcon />}
      color="secondary"
      size="large"
      variant="contained"
    />
  );
}
