import { Container, ContainerProps, makeStyles } from "@material-ui/core";
import React from "react";

export const CONTAINER_PADDING = 32;
export const SMALL_CONTAINER_PADDING = 8;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(0),
    paddingLeft: CONTAINER_PADDING,
    paddingRight: CONTAINER_PADDING,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: SMALL_CONTAINER_PADDING,
      paddingRight: SMALL_CONTAINER_PADDING,
    },
  },
}));

export function PageContainer(props: ContainerProps): JSX.Element {
  const classes = useStyles();

  return (
    <Container
      maxWidth="xl"
      className={classes.container}
      data-testid="page-container"
      {...props}
    />
  );
}
