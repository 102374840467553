import { ModuleCode } from "../../../../graphql";
import { TestCategory } from "./CreateTests.interfaces";

export const MAX_TESTS_SELECTION_LIMIT = 100;

export const RECOMMENDED_TESTS_CATEGORY_CODE = "recommended-tests";

export const recommendedTestsCategory: TestCategory = {
  selected: false,
  name: "Recommended Tests",
  code: RECOMMENDED_TESTS_CATEGORY_CODE,
  tests: [],
};

export const recomendedTestCodeList: Partial<Record<ModuleCode, string[]>> = {
  [ModuleCode.Accessibility]: [
    "best_practices_issues",
    "wcag_aaa_issues",
    "wcag_aa_issues",
    "wcag_a_issues",
  ],
  [ModuleCode.Seo]: [
    "4xx_errors",
    "5xx_errors",
    "max_load_time",
    "missing_titles",
    "empty_pages",
    "pages_without_canonical_tag",
  ],
  [ModuleCode.SiteSpeed]: [
    "first_contentful_paint_slow",
    "largest_contentful_paint_slow",
    "total_blocking_time_slow",
    "cumulative_layout_shift_slow",
    "speed_index_slow",
  ],
};
