import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { RunTestSuiteNowButton } from "./RunTestSuiteNowButton";
import { Alert, ApolloQueryResult } from "@lumar/shared";
import { useBuildStatusContext } from "../../../hooks/useBuildStatusProvider/useBuildStatusProvider";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import {
  GetTestSuiteQuery,
  GetTestSuiteQueryVariables,
} from "../../../../../graphql";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: 20,
    color: "#BDC2C5",
  },
}));

interface RunTestSuiteNowProps {
  refetchTestSuite: (
    variables?: GetTestSuiteQueryVariables | undefined,
  ) => Promise<ApolloQueryResult<GetTestSuiteQuery>>;
  testSuiteId: string;
  disabled?: boolean;
}

export function RunTestSuiteNow(props: RunTestSuiteNowProps) {
  const classes = useStyles();
  const buildStatusContext = useBuildStatusContext();

  return (
    <div data-testid="run-now-tab-content">
      <Box display="flex" alignItems="center" marginBottom={3.5}>
        <Typography
          color="textPrimary"
          className={classes.title}
          data-testid="run-now-title"
        >
          Run test suite now
        </Typography>
        <InfoTooltip
          data-pendo="auto-tooltip-run-test-suite-now"
          data-testid="template-tooltip"
          data-cy="template-tooltip"
          IconProps={{ style: { marginLeft: 8 } }}
          title={
            <>
              <h1 style={{ fontSize: 16, fontWeight: 500 }}>
                Run test suite immediately
              </h1>
              <p>
                Run test suite immediately after you have made changes to the
                test suite.
              </p>
            </>
          }
        />
      </Box>
      {buildStatusContext?.error ? (
        <Alert severity="error" data-testid="run-test-suite-now-error">
          Something went wrong. Please refresh the page and try again.
        </Alert>
      ) : null}
      <RunTestSuiteNowButton
        testId="run-test-suite-now-step-5"
        refetchTestSuite={props.refetchTestSuite}
        testSuiteId={props.testSuiteId}
        disabled={props.disabled}
        pendoId="auto-run-now-btn-step-5"
      />
    </div>
  );
}
